/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

/**
 * InputField Component
 */
class Checkbox extends Component {
	
	/**
	 * Constructs the component
	 */
	constructor() {
		super();
		this.inputRef = React.createRef();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return ( 
			<div className='checkbox'>
				<input ref={ this.inputRef } id={ this.props.id } type='checkbox' disabled={ this.props.disabled } onChange={ this.props.changeHandler } defaultChecked={ this.props.checked }/>
				{ this.props.label != null && <label onClick={ this.#handleLabelClick.bind( this ) }>{ this.props.label }</label> }
			</div>
		);
	}

	/**
	 * Handles a click on the label, checking the box if it is enabled.
	 */
	#handleLabelClick() {
		if( !this.props.disabled ) this.inputRef.current.click();
	}
	
}

export default Checkbox;
