/*
 * (c) Verra Technology Corporation
 */

import AudienceRule from "./AudienceRule";

/**
 * Represents the available ways AudienceRules can be combined
 */
class AudienceRuleCombinators {

	/**
	 * And operator
	 */
	static AND = 0;

	/**
	 * Or operator
	 */
	static OR = 1;
	
}

//

export default AudienceRuleCombinators;