import React from 'react';

const AddIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg height={size} width={size} viewBox="0 -960 960 960" >
			<g className='nc-icon-wrapper' stroke='none' fill={color}>
				<path d="M433-433H175v-94h258v-259h94v259h259v94H527v258h-94v-258Z"/>
			</g>
		</svg>
	);
};

export default AddIcon;