/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import PublishableObject from '../../../model/PublishableObject.mjs';
import AdminStates from '../../model/AdminStates';
import SphereAdminSession from '../../model/SphereAdminSession';

import EditChannelCommand from '../../commands/EditChannelCommand';
import OpenModalCommand from '../../commands/OpenModalCommand';
import ValidateChannelCommand from '../../commands/ValidateChannelCommand';
import SaveChannelRequest from '../../requests/channels/SaveChannelRequest';

import Alert from '../controls/Alert';
import DataGrid from '../controls/DataGrid';
import DropDownField from '../controls/DropDownField';
import Hint from '../controls/Hint';
import InputField from '../controls/InputField';
import ContentSearchModal from '../modals/ContentSearchModal';
import PredictiveAttributesEditor from '../PredictiveAttributesEditor';

import ChannelObjectRetrievalDepth from '../../../model/ChannelObjectRetrievalDepth.mjs';
import ChannelObjectReturnTypes from '../../../model/ChannelObjectReturnTypes.mjs';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import MatchPanelsHeightCommand from '../../commands/MatchPanelsHeightCommand';
import SetStateCommand from '../../commands/SetStateCommand';
import PencilIcon from '../../icons/PencilIcon';
import ChannelFactory from '../../managers/ChannelFactory';
import ChannelModelTypes from '../../model/ChannelModelTypes';
import ChannelOptimizationTypes from '../../model/ChannelOptimizationTypes';
import EventInteractionTypes from '../../model/EventInteractionTypes';
import ObjectStatusMap from '../../model/ObjectStatusMap';

//

const MAX_TRAINING_FREQUENCY = 168;

/**
 * Defines the model type labels
 */
const modelTypes = [
	//'Static',
	//'Random',
	//'Weighted Random',
	{ label: 'A/B', type: ChannelModelTypes.AB },
	//'Personalization',
	{ label: 'Predictive', type: ChannelModelTypes.PREDICTIVE }
];

/**
 * Maps the Channel's object return type (@see ChannelObjectReturnTypes) to labels for the drop down
 */
/*
const returnTypes = [
	{label: 'Full Record', value: ChannelObjectReturnTypes.FULL},
	{label: 'Value Only', value: ChannelObjectReturnTypes.VALUE},
	{label: 'ID an Index', value: ChannelObjectReturnTypes.ID_AND_INDEX}
];
*/

/**
 * Maps the Channel's object retrieval depth (@see ChannelObjectRetrievalDepth) to labels for the drop down
 */
/*
const retrievalDepths = [
	{label: 'Max', value: 0},
	{label: 'Single', value: 1},
	{label: 'Specific Number', value: 2}
];
*/

/**
 * How Verra optimizes the channel
 */
const optimizationTypes = [
	{label: 'Volume', value: ChannelOptimizationTypes.VOLUME},
	{label: 'Conversion', value: ChannelOptimizationTypes.CONVERSION} //,
	// {label: 'Value', value: ChannelOptimizationTypes.VALUE}
];

/**
 * Keeps track of whether or not changes to the Channel will impact the ML model
 */
let needsModelRebuild;

/**
 * Keeps track of events selected in the data grid
 */
let selectedEvents = [];

/**
 * Keeps track of whether or not the ID has been edited
 */
let hasEditedId;

//

/**
 * The ChannelEditor contains the administration UI for a Channel
 */
class ChannelEditor extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();

		// TODO: setting the model should be done in a modal or step prior to editing the channel
		// TODO: 

		/**
		 * Maps a Channel's model to a function that returns markup specific to that model type 
		 * TODO: ugly, wow
		 */
		this.modelMarkupMap = [
			null,
			null,
			null,
			null,
			this.#getPredictiveAttributesMarkup
		];

		this.state = {
			invalidated: false,
			selectedContent: []
		};

		//needsModelRebuild = ( SphereAdminSession.channel.status === ModifiableObject.CREATED );

		// refs

		this.additionalFieldsContainerRef = React.createRef();
		this.additionalFieldsRef = React.createRef();
		this.eventsContainerRef = React.createRef();
		this.eventsGridRef = React.createRef();

		this.contentGridRef = React.createRef();

		setTimeout( () => { this.#resizePanels(); }, 300 );
		window.addEventListener( 'resize', this.#handleWindowResizes.bind( this ));
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const jsx = this.#getChannelEditorMarkup();
		return jsx;
	}

	/**
	 * Handles the updating of the component
	 */
	componentDidUpdate() {
		this.#resizePanels();
	}
	
	// Initialization & Invalidation

	/**
	 * Invalidates the state of the panel, used to trigger state updates
	 */
	#invalidate() {
		SphereAdminSession.channel.status = PublishableObject.MODIFIED;
		this.setState({ invalidated: true });
	};

	/**
	 * Resizes the editing panels keeping the UI looking hot
	 */
	#resizePanels(){
		// console.info( 'resizePanels' ); 
		const resizePanels = new MatchPanelsHeightCommand( 
			[ this.additionalFieldsRef.current, this.eventsContainerRef.current ]
		);
		resizePanels.execute();
	}

	// Markup

	/**
	 * @return the markup to display the channel and edit it
	 */
	#getChannelEditorMarkup(){
		const channel = SphereAdminSession.channel;
		
		const modelMarkupMethod = this.modelMarkupMap[ channel.model ];
		const modelMarkup = ( modelMarkupMethod != null ) ? modelMarkupMethod.apply( this ) : null;
		const isEditing = ( SphereAdminSession.currentState === AdminStates.ADMIN_CHANNELS_EDIT );
		const title = ( isEditing ) ? 'Edit Channel' : 'Create Channel';
		const saveDisabled = channel.status === ModifiableObject.SAVED || channel.status === PublishableObject.PUBLISHED || channel.locked;
		const saveButtonsDisabledClass = ( saveDisabled ) ? ' disabled' : '';

		/*
		let divider = '';
		let publishBtn = '';
		
		if( this.props.showPublishBtn ){
			divider = <span className='divider control-pad-left'>|</span>;
			publishBtn = <button className={'attention-button control-pad-left' + saveButtonsDisabledClass} disabled={saveDisabled} style={{width: '80px'}}>Publish</button>;
		}
		*/

		/*
		const lockedToolTip = 'Locking prevents multiple users from saving conflicting changes';
		const lockedMarkup = ( !channel.locked ) ? '' : 
							<div className='grid'><div className='grid-cell default-100 panel-cell locked-message'><LockIcon/> This channel is locked and cannot be edited, would you like to unlock it now? <button className='link-button' onClick={ this.#handleUnlockChannel.bind( this ) }>Unlock</button> <Hint width='250px' content={lockedToolTip}/></div></div> ;
		*/
		
		/* 
		const additionalText = ( this.state.viewingMore ) ? 'Hide Additional Settings' : 'Show Additional Settings';
		const additionalIcon = ( this.state.viewingMore ) ? <ArrowDownIcon/> : <ArrowUpIcon/>;
		const advancedToggleMarkup = ( modelMarkupMethod == null ) ? null : 
			<div className='grid'>
				<div className='grid-cell default-100'>
					<div className='panel-cell'>
						<button className='link-button' onClick={ this.#handleToggleAdvanced.bind( this )}>{additionalText} {additionalIcon}</button>
					</div>
				</div>
			</div>;
		*/

		return <div className='content-panel'>
					<div className='grid'>
						<div className='grid-cell default-50'>
							<h2>{title}</h2>
							<div className='breadcrumb'>
								<a href='/channels/' className='breadcrumb'>Channels</a> / {channel.name}
							</div>
						</div>
						<div className='grid-cell default-50 align-right header-actions'>
							<button className={'primary-button control-pad-left' + saveButtonsDisabledClass} disabled={saveDisabled} style={{width: '80px'}} onClick={ this.#handleSave.bind( this )}>Save</button>
							<button className={'button control-pad-left'} style={{width: '80px'}} onClick={ this.#handleCancel.bind( this )}>Cancel</button>
							{/* 
							{divider}
							{publishBtn}
							*/}
							{ /*<button className={'button control-pad-left'} style={{width: '80px'}} onClick={ this.#handlePreview.bind( this ) }>Preview</button> */}
							{/*
							<div className='control-pad-left' style={{display: 'inline-block', float: 'right'}}>
								<DropDownField 
									itemsWidth='150px' 
									alignRight={true} 
									hideLabel={true} 
									items={['Lock', 'Revert to Saved', 'Revert to Published', 'Performance']}
									disabled={channel.locked}/>
							</div>
							*/}
						</div>
					</div>
					{this.#getPrimaryFieldsMarkup()}
					{this.#getContentMarkup()}
					{modelMarkup}
				</div>;
	}

	/**
	 * @return The markup for displaying id, name, and model fields
	 */
	#getPrimaryFieldsMarkup() {
		const channel = SphereAdminSession.channel;
		const idToolTip = 'Uniquely identifies the Channel. Values can only contain letters, numbers, underscores, dashes, and periods. Cannot be changed after saving.';
		const nameToolTip = 'User friendly name for the Channel.';
		const modelToolTip = 'The model defines how the Channel chooses the content in which to display. Refer to the help documentation for more information';
		const status = ( channel.locked ) ? 'locked' : ObjectStatusMap[ channel.status ];
		const statusIndicatorElement = <div className={'status-indicatator ' + status}></div>;
		const selectedModelIndex = modelTypes.findIndex( modelType => modelType.type === channel.model );
		const idReadOnly = ( SphereAdminSession.currentState === AdminStates.ADMIN_CHANNELS_EDIT );

		return <div className='grid panel-cell primary-fields'>
					<div className='grid-cell default-40'>
						{statusIndicatorElement}
						<label>Name <Hint width='250px' content={nameToolTip}/></label>
						<InputField 
							value={channel.name} 
							maxLength='256' 
							disabled={channel.locked} 
							onChange={( value ) => {  this.#handleFieldChanged( 'name', value ); }}/>
					</div>
					<div className='grid-cell default-40 pad-cell-left'>
						<label>ID <Hint width='250px' content={idToolTip}/></label>
						<InputField 
							value={channel.id}
							maxLength='256' 
							pattern='[A-z0-9_.-]+' 
							disabled={channel.locked}
							readOnly={idReadOnly} 
							onChange={( value ) => { this.#handleFieldChanged( 'id', value ); }}/>
					</div>
					<div className='grid-cell default-20 pad-cell-left'>
						<label>Model <Hint width='250px' position='left' content={modelToolTip}/></label>
						<DropDownField 
							itemsWidth='100%' 
							labelField='label' 
							items={modelTypes}
							selectedIndex={selectedModelIndex} 
							disabled={channel.locked || idReadOnly} 
							changeHandler={( value ) => { this.#handleModelChanged( value ); }}/>
					</div>
				</div>;
	}

	/**
	 * Gets the markup for displaying Content assigned to the Channel
	 */
	#getContentMarkup() {
		const channel = SphereAdminSession.channel;
		const contentToolTip = 'Here is where you specify the content that should, or could, be displayed in the Channel';
		const controlToolTip = 'Used to compare the results of the predictions against a static control';
		const noContentMsg = <div className='grid-cell default-100'>No content has been assigned to the Channel. Use the Add button to the right to select and assign content.</div>;
		const noControlMsg = <div className='grid-cell default-100'>Here you can add an optional control to the Channel.</div>;
		const buttonsDisabled = ( channel.locked ) ? 'disabled' : '';
		const removeDisabled = ( !channel.locked && this.state.selectedContent.length > 0 ) ? '' : 'disabled';

		const contentHeaders = [ 
			{ label: 'Name', field: 'name', width: '45' },
			{ label: 'ID', field: 'id', width: '45' }
		];

		/*
		const controlOptions = [
			{ id: '1', label: 'Single' },
			{ id: '0', label: 'Random' }
		];
		const selectedControl = controlOptions[ 0 ];
		*/

		const controlHeaders = [ 
			{ label: 'Name', field: 'name', width: '35' },
			{ label: 'ID', field: 'id', width: '35' },
			{ label: 'Distribution', width: 5, field: () => { 
				return <InputField 
							value={channel.controlWeight} 
							className='slim'
							maxLength='2' 
							pattern='[0-9]{1,2}' 
							disabled={channel.locked} 
							onChange={( value ) => {  this.#handleControlDistributionChange( value ); }}/>; 
			}}
		];

		const addControlBtnLabel = ( channel.control == null ) ? 'Add' : 'Update';
		const removeControlDisabled = ( channel.control == null ) ? 'disabled' : '';
		const controlData = ( channel.control != null ) ? [ channel.control ] : [];

		return <div>
					<div className='content-panel'>
						<div className='grid panel-cell'>
							<div className='grid-cell default-60'>
								<h3>Content <Hint width='250px' content={contentToolTip}/></h3>
							</div>
							<div className='grid-cell default-40 align-right'>
								<button className={'button ' + buttonsDisabled} onClick={ this.#handleAddContent.bind( this ) }>Add</button>
								<button className={'button control-pad-left ' + removeDisabled} onClick={ this.#handleRemoveContent.bind( this ) }>Remove</button>
							</div>
							{( channel.content.length === 0 ) ? noContentMsg : ''}
						</div>
					</div>
					<DataGrid 
						ref={this.contentGridRef} 
						headers={contentHeaders} 
						data={channel.content} 
						idField='id' 
						checkColumn={true} 
						editIcon={<PencilIcon/>}
						showHeaderIfEmpty={false} 
						statusIndicator={true} 
						statusField='status' 
						disabled={channel.locked} 
						maxBodyHeight='300px'
						checkBoxChangeHandler={ this.#handleContentChecked.bind( this )}/>
					<div className='content-panel'>
						<div className='grid panel-cell'>
							<div className='grid-cell default-60'>
								<h3>Control <Hint width='250px' content={controlToolTip}/></h3>
							</div>
							<div className='grid-cell default-40 align-right'>
								<button className={'button ' + buttonsDisabled} onClick={ this.#handleAddControl.bind( this ) }>{addControlBtnLabel}</button>
								<button className={'button control-pad-left ' + removeControlDisabled} onClick={ this.#handleRemoveControl.bind( this ) }>Remove</button>
							</div>
							{( channel.controlId == null ) ? noControlMsg : ''}
						</div>
					</div>
					<DataGrid 
						headers={controlHeaders} 
						data={controlData} 
						idField='id' 
						checkColumn={false} 
						editIcon={<PencilIcon/>}
						showHeaderIfEmpty={false} 
						statusIndicator={true} 
						statusField='status' 
						disabled={channel.locked} 
						maxBodyHeight='300px'/>

				</div>
	};
	
	/**
	 * Gets the markup for the Predictive Attributes UI 
	 */
	#getPredictiveAttributesMarkup() {
		const channel = SphereAdminSession.channel;
		return 	<div>
					{this.#getChannelConfigurationMarkup()}
					<PredictiveAttributesEditor 
						predictiveAttributes={channel.predictiveAttributes} 
						disabled={channel.locked} 
						onChange={() => { needsModelRebuild = true; this.#invalidate(); }}/>
				</div>;
	};

	/**
	 * Gets the succcss criteria and configuration markup
	 */
	#getChannelConfigurationMarkup(){
		const channel = SphereAdminSession.channel;

		// const returnTypeToolTip = 'Specifies the what type of data is returned to the Channel';
		const retrievalDepthToolTip = 'Specifies how many records are returned to the Channel';
		// const noEventsContent = <div>You do not have any Events added to the Channel. <button className='link-button' onClick={this.#handleAddEvent.bind( this )}>Add Event</button></div>;
		const optimizeToolTip = 'Determines what events Verra uses to learn how to optimize the Channel.';
		const optimizeForToolTip = 'How would you like Verra to optimize the Channel? Optimizing for a specific event will count the number of times that event occurs. Optimizing for conversion will measure the conversion rate of that event.';
		const successEventToolTip = 'The event type that determines success of the Channel. For example, add to cart or place order.';
		const enagementEventToolTip = 'The event type that determines what interaction with the Channel needs to occur. Example, view or click.';
		const frequencyToolTip = 'How often, in hours, the Channel is trained. The maximum allowed value is 168 (1 week).';

		const retrievalDepthDropDownIndex = Math.min( channel.retrievalDepth, ChannelObjectRetrievalDepth.SPECIFIC );
		const depthInputMarkup = ( channel.retrievalDepth < ChannelObjectRetrievalDepth.SPECIFIC ) ? '' : 
							<div className='pad-cell-top'>
								<InputField 
									value={channel.retrievalDepth}
									maxLength='3' 
									pattern='[0-9]+' 
									disabled={channel.locked} 
									onChange={( value ) => { this.#handleFieldChanged( 'retrievalDepth', value ); }}/>
							</div>;

		const eventHeaders = [ 
			{label: 'ID', field: 'id', width: '30'}, 
			{label: 'Name', field: 'name', width: '25'}, 
			{label: 'Weight', field: (( item ) => ( item.weight != 1 ) ? Number( item.weight ) : '1.0' ), width: '10'},
			{label: 'Direct', field: (( item ) => ( item.direct ) ? 'x' : '-' ), width: '10'},
			{label: 'Indirect', field: (( item ) => ( item.indirect ) ? 'x' : '-' ), width: '10'},
			{label: 'Engagement', field: (( item ) => ( item.engagement ) ? 'x' : '-' ), width: '10'}
		];

		/*
		const retrievalDepthMarkup = ( channel.model !== ChannelModelTypes.PREDICTIVE ) ? '' : 
			<div className='grid-cell default-100'>
				<label className='pad-cell-top'>Content Records to Return <Hint width='250px' position='right' content={retrievalDepthToolTip}/></label>
				<DropDownField 
					width='100%'
					itemsWidth='100%' 
					labelField='label' 
					items={retrievalDepths}
					selectedIndex={retrievalDepthDropDownIndex} 
					disabled={channel.locked} 
					changeHandler={( item ) => { this.#handleFieldChanged( 'retrievalDepth', item.value ); }}/>
				{depthInputMarkup}
			</div>;
		*/

		const engagementEventTypes = SphereAdminSession.eventTypes.filter( type => type.interactionType === EventInteractionTypes.ENGAGEMENT );
		const successEventTypes = SphereAdminSession.eventTypes.filter( type => ( type.interactionType === EventInteractionTypes.SUCCESS ) && type.id !== channel.engagementEventTypeId );

		const engagementEventType = SphereAdminSession.eventTypes.find( type => type.id == channel.engagementEventTypeId );
		const successEventType = SphereAdminSession.eventTypes.find( type => type.id == channel.successEventTypeId );
		const successEventName = ( channel.optimizationType === 0 ) ? 'Success' : 'Conversion';

		return <div ref={this.additionalFieldsContainerRef} className='grid'>
					<div className='grid-cell default-70'>
						<div ref={this.eventsContainerRef}  className='grid panel-cell'>
							<div className='grid-cell default-100'>
								<h3>Optimization <Hint width='250px' content={optimizeToolTip}/></h3>
							</div>
							<div className='grid-cell default-33'>
								<label className='pad-cell-top'>Optimize For <Hint width='250px' position='right' content={optimizeForToolTip}/></label>
								<DropDownField 
									width='100%'
									itemsWidth='100%' 
									labelField='label' 
									items={optimizationTypes}
									selectedIndex={channel.optimizationType} 
									disabled={channel.locked} 
									changeHandler={ this.#handleOptimizationTypeChange.bind( this )}/>
							</div>
							<div className='grid-cell default-33 pad-cell-left'>
								<label className='pad-cell-top'>Engagement Event <Hint width='250px' position='right' content={enagementEventToolTip}/></label>
								<DropDownField 
									width='100%'
									itemsWidth='100%' 
									labelField='name' 
									items={engagementEventTypes}
									selectedItem={engagementEventType}
									disabled={channel.locked} 
									changeHandler={( item ) => { this.#handleFieldChanged( 'engagementEventTypeId', item.id ); }}/>
							</div>
							<div className='grid-cell default-33 pad-cell-left'>
								<label className='pad-cell-top'><span>{successEventName}</span> Event <Hint width='250px' position='right' content={successEventToolTip}/></label>
								<DropDownField 
									width='100%'
									itemsWidth='100%' 
									labelField='name' 
									items={successEventTypes}
									selectedItem={successEventType}
									disabled={channel.locked} 
									changeHandler={( item ) => { this.#handleFieldChanged( 'successEventTypeId', item.id ); }}/>
							</div>
							
							{/* ------------------------------- 

							<div className='grid-cell default-100 align-right pad-cell-top'>
								<div className={'button '} onClick={ this.#handleAddEvent.bind( this ) }>Add</div>
								<div className={'button control-pad-left '} onClick={ this.#handleRemoveEvent.bind( this ) }>Remove</div>
							</div>
							<div className='grid-cell default-100'>
								<DataGrid 
									style={{ margin: '18px -18px -18px -18px'}}
									ref={this.eventsGridRef} 
									headers={eventHeaders} 
									data={channel.events} 
									idField='id' 
									showHeader={true}
									showHeaderIfEmpty={true} 
									checkColumn={true} 
									disabled={channel.locked} 
									maxBodyHeight='300px'
									noContent={noEventsContent}
									checkBoxChangeHandler={ this.#handleEventChecked.bind( this )}/>
							</div>
							*/}
						</div>
					</div>
					<div className='grid-cell default-30 pad-cell-left'>
						<div ref={this.additionalFieldsRef} className='grid panel-cell'>
							<div className='grid-cell default-100'>
								<h3>Configuration</h3>
							</div>
							<div className='grid-cell default-100'>
								<label className='pad-cell-top'>Training Frequency <Hint width='250px' content={frequencyToolTip} position='left'/></label>
								<InputField 
									value={channel.trainingFrequency} 
									maxLength='3' 
									pattern='[0-9]{1,3}' 
									disabled={channel.locked}
									style={{ width: '50px' }}
									onChange={( value ) => { this.#handleFieldChanged( 'trainingFrequency', value ); }}
								/>
							</div>
							{/* 
							<label className='pad-cell-top'>Return Type <Hint width='250px' position='right' content={returnTypeToolTip}/></label>
							<DropDownField 
								width='100%'
								itemsWidth='100%' 
								labelField='label' 
								items={returnTypes}
								selectedIndex={channel.returnType} 
								disabled={channel.locked} 
								changeHandler={( item ) => { this.#handleFieldChanged(  'returnType', item.value ); }}/>
							
							{retrievalDepthMarkup}
							*/}
						</div>
					</div>
				</div>;
	}

	// Editing Handlers

	/**
	 * Handles the click to unlock the channel to begin editing
	 */
	/*
	#handleUnlockChannel( e ) {
		e.preventDefault();
		// TODO: send http request to actually unlock the object in the DB
		SphereAdminSession.channel.locked = false;
		this.setState({});
	};
	*/

	/**
	 * Handles changes to the input fields, invalidating the Channel object
	 */
	#handleFieldChanged( field, value ){
		// console.info( field, value );
		if( field === 'name' && !hasEditedId && SphereAdminSession.currentState !== AdminStates.ADMIN_CHANNELS_EDIT ){
			const regex = /[^a-zA-Z0-9_-]/ig;
			const id = value.replaceAll( regex, '-' ).toLowerCase();
			SphereAdminSession.channel.id = id;
		} else if( field === 'id' ){
			hasEditedId = true;
		} else if( field === 'trainingFrequency' ){
			// TODO: enforce server side
			value = Math.min( value, MAX_TRAINING_FREQUENCY );
		}
		SphereAdminSession.channel[ field ] = value;
		this.#invalidate();
	};

	/**
	 * Handles changes to the selection of the Channel's model
	 */
	#handleModelChanged( model ) {
		const channel = SphereAdminSession.channel;
		if( model === 'Predictive' ) { // TODO: use constant, there's an enum in the services models
			// Setup the default attributes
			channel.optimizationType = 0; // event
			channel.predictiveAttributes = ChannelFactory.getDefaultPredictiveAttributes();
			channel.retrievalDepth = ChannelObjectRetrievalDepth.SINGLE;
			channel.returnType = ChannelObjectReturnTypes.VALUE;
		} else {
			channel.predictiveAttributes = null;
			channel.optimizationType = null;
			channel.optimizationType = null;
			channel.retrievalDepth = ChannelObjectRetrievalDepth.SINGLE;
			channel.returnType = ChannelObjectReturnTypes.VALUE;
		}

		channel.model = model.type;
		this.#invalidate();
	};

	/**
	 * Handles changes to the input fields, invalidating the Channel object
	 */
	#handleOptimizationTypeChange( type ){
		SphereAdminSession.channel.optimizationType = type.value;
		this.#invalidate();
	};

	// Content

	/**
	 * Handles the click to add content
	 */
	#handleAddContent(){
		if( !SphereAdminSession.channel.locked ){
			const contentModal = <ContentSearchModal addHandler={ this.#handleContentAdded.bind( this )}/>;
			const openModal = new OpenModalCommand( 'Add Content', contentModal, '60%', false );
			openModal.execute();
		}
	};

	/**
	 * Handles content added
	 */
	#handleContentAdded( selectedContent ) {
		// TODO: remove duplicates
		SphereAdminSession.channel.content = SphereAdminSession.channel.content.concat( selectedContent );
		needsModelRebuild = true;
		this.#invalidate();
	};

	/**
	 * Handles a click on the remove content button
	 */
	#handleRemoveContent() {
		if( !SphereAdminSession.channel.locked && this.state.selectedContent.length > 0 ){
			const alert = <Alert content='Are you sure you want to remove the content from the Channel?' okHandler={ this.#handleConfirmRemoveContent.bind( this ) }/>;
			const openModal= new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		}
	};

	/**
	 * Opens the Predictive Attribute editor modal
	 */
	#handleConfirmRemoveContent() {
		SphereAdminSession.channel.content = SphereAdminSession.channel.content.filter(( item ) => {
			return !this.state.selectedContent.includes( item );
		});

		this.contentGridRef.current.unCheckAll();
		this.state.selectedContent = [];
		needsModelRebuild = true;
		this.#invalidate();
		
	};

	/**
	 * Handles clicks oncheckboxes in the data grid
	 */
	#handleContentChecked( dataGrid ){
		this.state.selectedContent = dataGrid.getChecked();
		this.#invalidate();
	}

	/**
	 * Handles clicks on items in the Channel list
	 */
	/* TODO: bring this back as a modal
	#handleEditContent( content ) {
		const hasChanged = SphereAdminSession.channel.status === ModifiableObject.MODIFIED || SphereAdminSession.channel.status === ModifiableObject.CREATED;
		if( hasChanged ){
			const alert = <Alert content='You have unsaved changes, are you sure you want to exit?' okHandler={ this.#handleCancelConfirm.bind( this ) }/>;
			const openModal= new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		} else {
			const editContent = new EditContentCommand( content.id );
			editContent.execute();
		}
	};
	*/

	// Control

	/**
	 * Handles the click to add content
	 */
	#handleAddControl(){
		if( !SphereAdminSession.channel.locked ){
			const contentModal = <ContentSearchModal singleSelect={ true } addHandler={ this.#handleControlAdded.bind( this )}/>;
			const openModal = new OpenModalCommand( 'Add Control', contentModal, '60%', false );
			openModal.execute();
		}
	};

	/**
	 * Handles content added 
	 */
	#handleControlAdded( selectedContent ) {
		SphereAdminSession.channel.control = selectedContent;
		this.#invalidate();
	};

	/**
	 * Handles a click on the remove content button
	 */
	#handleRemoveControl() {
		if( !SphereAdminSession.channel.locked && SphereAdminSession.channel.control != null ){
			const alert = <Alert content='Are you sure you want to remove the control from the Channel?' okHandler={ this.#handleConfirmRemoveControl.bind( this ) }/>;
			const openModal= new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		}
	};

	/**
	 * Opens the Predictive Attribute editor modal
	 */
	#handleConfirmRemoveControl(){
		SphereAdminSession.channel.control = null;
		this.#invalidate();
	};

	/**
	 * Handles changes to the controls selection distribution
	 */
	#handleControlDistributionChange( value ){
		SphereAdminSession.channel.controlWeight = value;
		this.#invalidate();
	}

	//

	/**
	 * Handles a click on the preview button
	 */
	/*
	#handlePreview() {
		// TODO: channel preview
		console.info( 'PREVIEW' );
		var previewModal = <PreviewChannelModal channel={SphereAdminSession.channel}/>;
		var openModal = new OpenModalCommand( null, previewModal, '90%', false );
		openModal.execute();
	};
	*/

	/**
	 * Handles a click on the save button, saves the Channel 
	 */
	#handleSave() {

		// TODO: this could all be in a command?
		// TODO: analytics metrics are being sent with the channel object, they should be stripped in the request

		const channel = SphereAdminSession.channel;

		if( SphereAdminSession.channel.controlWeight === '' ) SphereAdminSession.channel.controlWeight = 0;
		const validateChannel = new ValidateChannelCommand( channel );
		validateChannel.execute();

		const isValid = validateChannel.isValid();
		const invalidFields = validateChannel.getInvalidFields();
		
		if( isValid ){
			if( needsModelRebuild ){ // TODO: and this is not the first time saving
			//	var alert = <Alert content='These changes require a rebuild of the machine learning model, this can have unintended consequences, are you sure you want to continue saving?' okHandler={ this.#saveChannel.bind( this ) }/>;
			//	var openModal= new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			//	openModal.execute();
				//console.info( 'needsModelRebuild' );
			} //else {
				this.#saveChannel();
			//}
		} else {
			
			const invalidFieldsElements = [];

			for( var i = 0; i < invalidFields.length; i++ ) {
				invalidFieldsElements.push( <li key={i}>{invalidFields[ i ]}</li> );
			}

			const content = <div className='alert'>
				The Channel cannot be saved. The following fields are invalid or incomplete:
				<ul>{invalidFieldsElements}</ul>
			</div>;

			const openModal = new OpenModalCommand( 'Invalid Channel', content, '500px', true );
			openModal.execute();
		}
	};

	/**
	 * Handles completion of the save channel reques
	 */
	#saveChannel(){
		SphereAdminSession.loading = true;
		const saveChannel = new SaveChannelRequest( SphereAdminSession.channel, true ); //needsModelRebuild );
		saveChannel.execute(( command ) => { this.#handleSaveComplete( command ) });
	}

	/**
	 * Handles completion of the save channel reques
	 */
	#handleSaveComplete( command ){
		SphereAdminSession.loading = false;
		if( SphereAdminSession.currentState === AdminStates.ADMIN_CHANNELS_CREATE ){
			const editChannel = new EditChannelCommand( SphereAdminSession.channel.id );
			editChannel.execute();
		} else {
			this.setState({ invalidated: false });
		}
	}

	/**
	 * Handles a click on the cancel button
	 */
	#handleCancel() {
		const hasChanged = SphereAdminSession.channel.status === ModifiableObject.MODIFIED || SphereAdminSession.channel.status === ModifiableObject.CREATED;
		if( hasChanged ){
			const alert = <Alert content='You have unsaved changes, are you sure you want to exit?' okHandler={ this.#handleCancelConfirm.bind( this ) }/>;
			const openModal= new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		} else {
			const setState = new SetStateCommand( AdminStates.ADMIN_CHANNELS_BROWSE );
			setState.execute();
		}
	};

	/**
	 * Handles a confirmation to cancel changes
	 */
	#handleCancelConfirm() {
		const setState = new SetStateCommand( AdminStates.ADMIN_CHANNELS_BROWSE );
		setState.execute();
	};

	// 

	/**
	 * Handles window resizes events
	 */
	#handleWindowResizes( e ){
		this.#resizePanels();
	}

}

//

export default ChannelEditor;
