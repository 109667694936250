import React from 'react';

var ClockIcon = function( props ) {
	return (
		<svg width='52' height='52' viewBox='0 0 52 52'>
			<g className='nc-icon-wrapper' stroke='none' fill='#ffffff'>
				<path d='M23.99 4C12.94 4 4 12.95 4 24s8.94 20 19.99 20C35.04 44 44 35.05 44 24S35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16zm1-26h-3v12l10.49 6.3L34 29.84l-9-5.34z'/>
			</g>
		</svg>
	);
};

export default ClockIcon;