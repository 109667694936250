/*
 * (c) Verra Technology Corporation
 */

import SphereAdminServices from '../../model/SphereAdminServices';
import SphereAdminSession from '../../model/SphereAdminSession';
import ServiceRequestCommand from '../ServiceRequestCommand';

/**
 * Retrieves updated object counts for the account
 */
class RetrieveObjectCountsRequest extends ServiceRequestCommand {

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
	getService() {
		return SphereAdminServices.RETRIEVE_OBJECT_COUNTS;
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		// console.info( 'RetrieveObjectCountsRequest::handleComplete', response );
		if( response.success ) {
			SphereAdminSession.selectedAccount.objectCounts = response.result;
		}
	}
 
}

export default RetrieveObjectCountsRequest;
