/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';

/**
 * Takes analytics data from the analytics services API and populates a ChannelEventMetrics
 * model object with the data.
 */
class PopulateChannelEventMetricsModelCommand extends Command {
	
	/**
	 * Constant used to flag the incoming analytics data as engagement event data
	 */
	static DATA_TYPE_ENGAGEMENT = 'DATA_TYPE_ENGAGEMENT';

	/**
	 * Constant used to flag the incoming analytics data as conversion event data
	 */
	static DATA_TYPE_CONVERSION = 'DATA_TYPE_CONVERSION';

	//

	/**
	 * The ChannelEventMetrics model object getting populated with data
	 */
	#channelEventMetrics

	/**
	 * The analytics event data from the analytics service used to populate the model object
	 */
	#analyticsData;

	/**
	 * Whether or not analytics data is for conversion events
	 */
	#config;

	//
	
	/**
	 * Constructs the command
	 * @param channelEventMetrics The ChannelEventMetrics model object to populate
	 * @param analyticsData The analytics event data from the analytics service
	 * @param config Configuration object that determines what kind of data is in the analytics data and how to 
	 * populate the model. The config object follows the following format:
	 * 
	 * 	{
	 * 		dataType: DATA_TYPE_ENGAGEMENT | DATA_TYPE_CONVERSION,
	 * 		fields: { 'count': 'count', 'total': 'revenue', 'average': 'aov', ... } // a mapping of analytics data fields to metrics properties
	 * 		engagementEvent: engagement-event-id // optional, only applicable for DATA_TYPE_CONVERSION data
	 * 	}
	 * 
	 */
	constructor( channelEventMetrics, analyticsData, config ){
		super();
		// console.info( 'PopulateChannelEventMetricsModelCommand', channelEventMetrics, analyticsData, config );
		this.#channelEventMetrics = channelEventMetrics;
		this.#analyticsData = analyticsData;
		this.#config = config;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		this.#analyticsData.forEach( event => {
			// create the metrics object
			const metrics = {};
			Object.keys( this.#config.fields ).forEach( fieldName => {
				metrics[ this.#config.fields[ fieldName ]] = event[ fieldName ];
			});

			if( this.#config.dataType === PopulateChannelEventMetricsModelCommand.DATA_TYPE_ENGAGEMENT ){
				this.#channelEventMetrics.updateEventMetric( event.type, event.contentSelectionType, event.contentId, metrics );
			} else if( this.#config.dataType === PopulateChannelEventMetricsModelCommand.DATA_TYPE_CONVERSION ){
				this.#channelEventMetrics.updateConversionEventMetric( this.#config.engagementEvent, event.type, event.contentSelectionType, event.contentId, metrics );
			}
		});

		// now that all the data is populated, perform additional calculations
		this.#channelEventMetrics.calculateMetrics(); 
	}
	
}

//

export default PopulateChannelEventMetricsModelCommand;
