/*
 * (c) Verra Technology Corporation
 */

import PublishableObject from '../../../model/PublishableObject.mjs';
import SphereAdminServices from '../../model/SphereAdminServices';
import SphereAdminSession from '../../model/SphereAdminSession';
import ServiceRequestCommand from '../ServiceRequestCommand';

/**
 * Publishes an Optimization
 */
class PublishOptimizationRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param optimization The Optimization to publish
	 */
	constructor( optimization ){
		super();
		this.optimization = optimization;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.PUBLISH_OPTIMIZATION;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		// We only pass the optimization ID to allow publishing from the OptimizationsPanel which does not 
		// have the full Optimization Object
		return { accountId: this.optimization.accountId, optimizationId: this.optimization.id };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		if( response.success ) {
			this.optimization.status = PublishableObject.PUBLISHED; // TODO: should this come from the DB?
			// update the optimization in the list of optimizations if it exists
			SphereAdminSession.optimizations.every( optimization => {
				// console.info( optimization.id, this.optimization.id, optimization );
				if( optimization.id === this.optimization.id ) {
					optimization.status = PublishableObject.PUBLISHED;
					return false;
				}
				return true;
			});
		}
		//console.info( 'PublishOptimizationRequest::handleComplete content', this.optimization );
	}
 
}

export default PublishOptimizationRequest;
