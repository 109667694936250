/*
 * (c) Verra Technology Corporation
 */

import ChannelContentSelectionTypes from "./ChannelContentSelectionTypes";
import ChannelEventMetrics from "./ChannelEventMetrics";
import jstat from 'jstat';

//

const PRIOR = 0.5;
const SAMPLES = 5000;

const zeroArray = function(length) {
	var arr = [], i = length;
	while (i--) {
		arr[i] = 0;
	}
	return arr;
};

//

/**
 * Represents metrics for a specific conversion event, for a Channel
 */
class ChannelConversionEventMetrics extends ChannelEventMetrics {

	/**
	 * The engagement event metric correlated to this conversion
	 */
	#engagementEventMetric;

	// All Conversion Metrics 

	/**
	 * The conversion rate for this event
	 */
	cvr = 0;

	/**
	 * The conversion specifically for the content, excluding the control
	 */
	contentCvr = 0;

	/**
	 * jstat beta object for calculating probablities
	 */
	beta;

	/**
	 * The probablity that the combined content will beat the control
	 */
	contentSuccessProbability;

	/**
	 * The probablity that the control will beat the combined content
	 */
	controlSuccessProbability;

	// Place Order Specific Metrics

	/**
	 * Total revenue for the order
	 */
	revenue;

	/**
	 * The average order value associated with the event.
	 */
	aov;

	/**
	 * Revenue per engagement
	 */
	rpe;

	//

	/**
	 * Constructs a new ChannelEventMetrics model object. 
	 * @param channel The Channel the metrics are for
	 * @param engagementEventMetric The engagement event metric correlated to this conversion
	 * @param eventType The event type object in which the metrics apply
	 */
	constructor( channel, engagementEventMetric, eventType ){
		super( channel, eventType );
		this.#engagementEventMetric = engagementEventMetric;
		
	}

	//

	/**
	 * Updates metrics values for an event type, selection type, and content
	 * @param type The event type ID
	 * @param selectionType The selection type, 0 for control or 1 for content
	 * @param contentId The content ID
	 * @param metrics An object with the metrics to update, { count: 5, order-value: 100, ... }
	 */
	updateEventMetric( type, selectionType, contentId, metrics ){
		// if( type === 'place-order' ) console.info( 'update', type, selectionType, contentId, metrics );
		super.updateEventMetric( type, selectionType, contentId, metrics );
		if( type === this._eventType.id ){
			// report += 'conversion updateEventMetric', type, selectionType, contentId );
			const contentMetric = this.getContentMetricBySelectionType( contentId, selectionType );
			const engagementContentMetric = this.#engagementEventMetric.getContentMetricBySelectionType( contentId, selectionType );
			
			if( metrics.count != null ){
				contentMetric.cvr = ( engagementContentMetric.count > 0 ) ? contentMetric.count / engagementContentMetric.count : 0;
				contentMetric.beta = jstat.beta( contentMetric.count + PRIOR, engagementContentMetric.count - contentMetric.count + PRIOR );
				// if( engagementContentMetric.count > 0 ) contentMetric.error = Math.sqrt(( contentMetric.cvr * ( 1 - contentMetric.cvr ) / engagementContentMetric.count ));

				this.cvr = ( this.#engagementEventMetric.total > 0 ) ? this.total / this.#engagementEventMetric.total : 0;

				if( selectionType === ChannelContentSelectionTypes.CONTENT ){
					this.contentCvr = ( this.#engagementEventMetric.contentTotal > 0 ) ? this.contentTotal / this.#engagementEventMetric.contentTotal : 0;
					this.beta = jstat.beta( this.contentTotal + PRIOR, this.#engagementEventMetric.contentTotal - this.contentTotal + PRIOR );
				}
			}

			if( metrics.aov != null ){
				contentMetric.aov = metrics.aov;
			}

			if( metrics.revenue != null ){
				if( this.revenue == null ) this.revenue = 0;
				if( contentMetric.revenue == null ) contentMetric.revenue = 0;

				this.revenue += metrics.revenue;

				contentMetric.revenue = metrics.revenue;

				contentMetric.rpe = metrics.revenue / engagementContentMetric.count;
				this.rpe = this.revenue / this.#engagementEventMetric.total;
			}
		}
	}

	// Post data population calculations

	/**
	 * Calculates metrics after the data has been populated
	 */
	calculateMetrics(){
		this.#udpateAverageOrderValues();
		this.#updateProbabilities();
		this.#updateRevenuePotentials();
	}

	/**
	 * Updates winner probabilities
	 */
	#udpateAverageOrderValues(){
		let totalAov = 0;
		let contentIds = Object.keys( this._content );

		contentIds.forEach(( contentId ) => {
			let contentMetric = this._content[ contentId ];
			totalAov += contentMetric.aov;
		});

		totalAov += this._control.aov;
		this.aov = Math.round( totalAov / ( contentIds.length + 1 ));
	}

	/**
	 * Updates winner probabilities
	 */
	#updateProbabilities(){
		// we only update if there are actual conversions
		if( this.cvr > 0 ){
			this.#updateContentControlProbabilities();
			this.#updateAllContentProbabilities();
		}
	}

	/**
	 * Updates probabilities for all of the content combined vs the control
	 */
	#updateContentControlProbabilities(){
		// we only update if there are actual conversions
		if( this.cvr > 0 ){
			const counts = zeroArray( 2 );
			
			let value;
			let beta;
			let x;
			let index;
			let i, j;
			let probability

			for( i = 0; i < SAMPLES; i++ ){
				value = 0; 
				for ( let j = 0; j < counts.length; j++ ){
					beta = ( j === 0 ) ? this.beta : this._control.beta;
					if( beta != null ){
						x = beta.sample();
						if ( x > value ){
							index = j;
							value = x;
						} 
					}
				}
				counts[index]++;
			}

			for ( j = 0; j < counts.length; j++ ){
				probability = counts[ j ] / ( SAMPLES / 100 );
				if( j === 0 ){
					this.contentSuccessProbability = probability
				} else {
					this.controlSuccessProbability = probability;
				}
			}
		}
	}

	/**
	 * Updates probabilities for all content and the control
	 */
	#updateAllContentProbabilities(){
		// we only update if there are actual conversions
		if( this.cvr > 0 ){
			const contentKeys = Object.keys( this._content );
			const counts = zeroArray( contentKeys.length ); // TODO: temporarily removing control + 1 ); // we add one for the control
			let value;
			let contentMetric;
			let x;
			let index;
			let i, j;
			for( i = 0; i < SAMPLES; i++ ){
				value = 0; 
				// for ( let j = 0; j < contentKeys.length + 1; j++ ){
				for ( let j = 0; j < contentKeys.length; j++ ){
					// contentMetric = ( j < contentKeys.length ) ? this._content[ contentKeys[ j ] ] : this._control;
					contentMetric = this._content[ contentKeys[ j ]];
					if( contentMetric.beta != null ){
						x = contentMetric.beta.sample();
						if ( x > value ){
							index = j;
							value = x;
						} 
					}
				}
				counts[index]++;
			}
			for ( j = 0; j < contentKeys.length + 1; j++ ){
				contentMetric = ( j < contentKeys.length ) ? this._content[ contentKeys[ j ] ] : this._control;
				contentMetric.successProbability = counts[ j ] / ( SAMPLES / 100 );
			}
		}
	}

	/**
	 * Updates revenue potential values for all content metrics
	 */
	#updateRevenuePotentials(){
		Object.keys( this._content ).forEach( contentId => {
			let contentMetric = this._content[ contentId ];
			this.#updateRevenuePotentialForContent( contentMetric );
		});
		//Object.keys( this._control ).forEach( contentId => {
			this.#updateRevenuePotentialForContent( this._control );
		//});
	}

	/**
	 * Updates revenue potential values for all content metrics
	 */
	#updateRevenuePotentialForContent( contentMetric ){
		contentMetric.rpcvr = this.#engagementEventMetric.total * contentMetric.cvr * this.aov;
		contentMetric.rpcvrd = contentMetric.rpcvr - this.revenue;

		contentMetric.rpaov = this.total * contentMetric.aov;
		contentMetric.rpaovd = contentMetric.rpaov - this.revenue;

		contentMetric.rpcvraov = this.#engagementEventMetric.total * contentMetric.cvr * contentMetric.aov;
		contentMetric.rpcvraovd = contentMetric.rpcvraov - this.revenue;
	}

	//

	/**
	 * 
	 */
	buildReport(){
		let report = '';
		// report += ' -------------------------------------  \n';
		// report += this._eventType.name + ' \n';
		// report += 'Engagement Event, Conversion Event, CVR, AOV, Revenue, Revenue Per ' + this._eventType.name + ' \n';
		report += `${this._channel.name},Channel,${this.#engagementEventMetric._eventType.name},${this._eventType.name},,${this.total},${this.cvr},${this.#getMetricAsCurrency( this.aov )},${this.#getMetricAsCurrency( this.revenue )},${this.#getMetricAsCurrency( this.rpe )},,,,,,\n`;
		// report += ' ------------------------------------- \n';
		// report += 'Content \n';
		// report += ' ------------------------------------- \n';
		// report += 'Conversion Event, Content, Volume, CVR, AOV, Revenue, Revenue Per Engagement, Revenue Potential (CVR/AOV), Revenue Potential (CVR/AOV) Delta, Revenue Potential (AOV), Revenue Potential (AOV) \n';
		Object.keys( this._content ).forEach( contentId => {
			report += this._content[ contentId ].buildReport( this.#engagementEventMetric._eventType.name, true, false);
		});
		report += this._control.buildReport( this.#engagementEventMetric._eventType.name, true, true );
		return report;
	}

	// TODO: create util 
	#getMetricAsCurrency( metric ){
		const formatConfig = { 
			style: 'currency',
			currency: 'USD',
			maximumFractionDigits: 2, 
			minimumFractionDigits: 2 
		};
		return `"${Intl.NumberFormat( 'en-US', formatConfig ).format( metric * 0.01 )}"`;
	}

}

//

export default ChannelConversionEventMetrics;