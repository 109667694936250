/*
 * (c) Verra Technology Corporation
 */

import jstat from "jstat";

/**
 * Provides utility functions for statistics, in case that was obvious
 */
class StatisticsUtil {

	/**
	 * Calculates the minimum sample size required to reach statistical significance
	 * @param {*} baselineCvr 
	 * @param {*} mde 
	 * @param {*} significanceLevel 
	 * @param {*} power 
	 * @returns sample size
	 */
	static getMinimumSampleSize( baselineCvr, mde, significanceLevel = 0.95, power = 0.8 ) {
		const alpha = 1 - significanceLevel;
		const delta = mde * baselineCvr; 

		if (baselineCvr > 0.5) {
			baselineCvr = 1.0 - baselineCvr;
		}

		const a2 = jstat.normal.inv( 1.0 - alpha / 2, 0, 1 );
		const beta = jstat.normal.inv( power, 0, 1 );
	
		const sd1 = Math.sqrt( 2 * baselineCvr * ( 1.0 - baselineCvr ));
		const sd2 = Math.sqrt( baselineCvr * ( 1.0 - baselineCvr ) + ( baselineCvr + delta ) * ( 1.0 - baselineCvr - delta ));
	
		return Math.round(( a2 * sd1 + beta * sd2 ) * ( a2 * sd1 + beta * sd2 ) / ( delta * delta ));
	}
	
}

//

export default StatisticsUtil;