/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import OpenModalCommand from '../commands/OpenModalCommand';
import SetStateCommand from '../commands/SetStateCommand';
import Alert from '../components/controls/Alert';
import SiteEditor from '../components/editors/SiteEditor';
import AdminStates from '../model/AdminStates';
import SphereAdminSession from '../model/SphereAdminSession';

//

/**
 * The EditSitePanel contains the UI for editing Sites
 * NOTE: the 
 */
class EditSitePanel extends Component {

	/**
	 * Constructs the Component
	 */
	constructor(){
		super();
		SphereAdminSession.site = null;
		const siteUrl = SphereAdminSession.stateParameters.siteUrl;
		const sites = SphereAdminSession.sites;
		for( let i = 0; i < sites.length; i++ ){
			const site = sites[ i ];
			const url = site.url.split( '.' ).join( '-' ).toLowerCase();
			if( url === siteUrl ) {
				SphereAdminSession.site = site;
				break;
			}
		}

		if( SphereAdminSession.site == null ){
			const setState = new SetStateCommand( AdminStates.ADMIN_CONFIGURATION_SITES );
			setState.execute();

			const alert = <Alert content='Site does not exist' showCancelBtn={false}/>;
			const openModal = new OpenModalCommand( '', alert, '500px', true );
			openModal.execute();
		}
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		return ( SphereAdminSession.site != null ) ? <SiteEditor/> : '';
	}

}

//

export default EditSitePanel;
