/*
 * (c) Verra Technology Corporation
 */

import ChannelEngagementEventMetrics from "./ChannelEngagementEventMetrics";

/*

{
	'view': {
		'total': 130,
		'content': {
			'total': 100,
			'content-id-1': {
				'count': 75
			},
			'content-id-2': {
				'count': 25
			}
		},
		'control': {
			'total': 30,
			'content-id-control': {
				'count': 30
			}
		},
		'conversions': {
			'place-order': {
				'total': 10,
				'content': {
					'total': 8,
					'revenue': '25000',
					'aov'
					'content-id-1': {
						'count': 5
					},
					'content-id-2': {
						'count': 3
					}
				},
				'control': {
					'total': 2,
					'content-id-control': {
						'count': 2
					}
				}
			}
		}
	},
	
}

*/

/**
 * Represents Channel analytics data for a Channel. The ChannelMetrics object 
 */
class ChannelMetrics {

	/**
	 * The Channel in which the metrics are for
	 */
	#channel;

	/**
	 * The engagement event types represented by the metrics object
	 */
	#engagementEventTypes;

	/**
	 * The conversion event types represented by the metrics object
	 */
	#conversionEventTypes

	/**
	 * A map of ChannelEngagementEventMetrics objects keyed by the event ID 
	 */
	#engagements;

	//

	/**
	 * Constructs a new ChannelMetrics model object. 
	 * @param channel The Channel object in which the metrics apply
	 * @param engagementEventTypes The engagement events in which to track for the Channel
	 * @param conversionEventTypes The conversion events in which to track for the Channel
	 */
	constructor( channel, engagementEventTypes, conversionEventTypes ){
		this.#channel = channel;
		this.#engagementEventTypes = engagementEventTypes;
		this.#conversionEventTypes = conversionEventTypes;
		this.#buildInitialEngagementMetrics();
	}

	//

	/**
	 * Builds the default metrics structure
	 * @param engagementEventTypes The engagement events in which to track for the Channel
	 * @param conversionEventTypes The conversion events in which to track for the Channel
	 */
	#buildInitialEngagementMetrics(){
		this.#engagements = {};
		this.#engagementEventTypes.forEach( engagementEvent => {
			this.#engagements[ engagementEvent.id ] = new ChannelEngagementEventMetrics( this.#channel, engagementEvent, this.#conversionEventTypes );
		});
	}

	//

	/**
	 * Rebuilds the initial metrics structure with empty values
	 */
	resetMetrics(){
		this.#buildInitialEngagementMetrics();
	}

	/**
	 * Rebuilds the initial metrics structure with empty values
	 */
	resetConversionMetricsForEvent( engagementEventTypeId, conversionEventTypeId ){
		this.#engagements[ engagementEventTypeId ].resetConversionMetricsForEvent( conversionEventTypeId );
	}

	/**
	 * Updates metrics values for an event type, selection type, and content
	 * @param type The event type ID
	 * @param selectionType The selection type, 0 for control or 1 for content
	 * @param contentId The content ID
	 * @param metrics An object with the metrics to update, { count: 5, order-value: 100, ... }
	 */
	updateEventMetric( type, selectionType, contentId, metrics ){
		Object.keys( this.#engagements ).forEach( engagementEventId => {
			this.#engagements[ engagementEventId ].updateEventMetric( type, selectionType, contentId, metrics );
		});
	}
	
	/**
	 * Updates metrics values for an event type, selection type, and content
	 * @param engagementEventTypeId The engagement event type id correlated with the conversion event
	 * @param conversionEventTypeId The conversion event type id
	 * @param selectionType The selection type, 0 for control or 1 for content
	 * @param contentId The content ID
	 * @param metrics An object with the metrics to update, { count: 5, order-value: 100, ... }
	 */
	updateConversionEventMetric( engagementEventTypeId, conversionEventTypeId, selectionType, contentId, metrics ){
		this.#engagements[ engagementEventTypeId ].updateConversionEventMetric( conversionEventTypeId, selectionType, contentId, metrics );
	}

	//

	/**
	 * Calculates metrics after the data has been populated
	 */
	calculateMetrics(){
		Object.keys( this.#engagements ).forEach( engagementEventId => {
			this.#engagements[ engagementEventId ].calculateMetrics();
		});
	}

	//

	/**
	 * @return The metric for a specific event
	 */
	getEngagementEventMetric( eventTypeId ){
		return this.#engagements[ eventTypeId ];
	}

	/**
	 * @return The metrics for a specific event
	 */
	getConversionEventMetric( engagementEventTypeId, conversionEventTypeId ){
		return this.#engagements[ engagementEventTypeId ].getConversionEventMetric( conversionEventTypeId );
	}

	/**
	 * @return The content metrics for a specific event
	 */
	getEngagementContentEventMetric( contentId, eventTypeId ){
		return this.#engagements[ eventTypeId ].getContentEventMetric( contentId );
	}

	/**
	 * @return All content and control metrics for the conversion metric
	 */
	getAllConversionContentEventMetrics( engagementEventTypeId, conversionEventTypeId ){
		return this.#engagements[ engagementEventTypeId ].getAllConversionContentEventMetrics( conversionEventTypeId );
	}

	/**
	 * @return The content metrics for a specific event
	 */
	getConversionContentEventMetric( contentId, engagementEventTypeId, conversionEventTypeId ){
		return this.#engagements[ engagementEventTypeId ].getConversionContentEventMetric( contentId, conversionEventTypeId );
	}

	/**
	 * @return The control metrics for a specific event
	 */
	getEngagementControlEventMetric( eventTypeId ){
		return this.#engagements[ eventTypeId ].getControlEventMetric();
	}

	/**
	 * @return The control metrics for a specific event
	 */
	getConversionControlEventMetric( engagementEventTypeId, conversionEventTypeId ){
		return this.#engagements[ engagementEventTypeId ].getConversionControlEventMetric( conversionEventTypeId );
	}

	//

	/**
	 * 
	 */
	buildReport(){
		let report = 'Channel,Object,Engagement Event,Conversion Event,Content,Volume,CVR,AOV,Revenue,Revenue Per Engagement,Revenue Potential (CVR),Revenue Potential (CVR) Delta,Revenue Potential (AOV),Revenue Potential (AOV),Revenue Potential (CVR/AOV),Revenue Potential (CVR/AOV) Delta\n';
		// report += ' ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| ';
		Object.keys( this.#engagements ).forEach( engagementEventId => {
			report += this.#engagements[ engagementEventId ].buildReport();
		});
		// report += ' ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| ';
		console.info( report );
	}

}

//

export default ChannelMetrics;