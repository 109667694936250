/*
 * (c) Verra Technology Corporation
 */

import Optimization from "../../model/Optimization";
import SphereAdminSession from "../model/SphereAdminSession";

/**
 * Factory class for creating Optimization model objects
 */
const OptimizationFactory = {

	/**
	 * Creates a new, empty Channel
	 * @return Optimization
	 */	
	createOptimization: function() {
		const optimization = new Optimization();
		optimization.accountId = SphereAdminSession.selectedAccount.id;
		if( SphereAdminSession.sites.length === 1 ) optimization.siteId = SphereAdminSession.sites[ 0 ].id;
		return optimization;
	}
	
};

export default OptimizationFactory;
