/*
 * (c) Verra Technology Corporation
 */

import ChannelContentEventMetrics from "./ChannelContentEventMetrics";
import ChannelContentSelectionTypes from "./ChannelContentSelectionTypes";
import ChannelModelTypes from "./ChannelModelTypes";

/**
 * Abstract class for representing metrics for a specific channel event.
 */
class ChannelEventMetrics {

	// Protected Members

	/**
	 * The Channel in which the metrics are for
	 */
	_channel;

	/**
	 * The event type object in which the metrics apply
	 */
	_eventType;

	/**
	 * Represents the event metrics by individual content
	 */
	_content;

	/**
	 * Represents the event metrics by the control or individual control content IDs
	 */
	_control;

	//

	/**
	 * The total number of times this event has occurred
	 */
	total = 0;

	/**
	 * The aggregate totals for all content
	 */
	contentTotal = 0;

	//

	/**
	 * Constructs a new ChannelEventMetrics model object. 
	 * @param channel The Channel the metrics are for
	 * @param eventType The event type object in which the metrics apply
	 */
	constructor( channel, eventType ){
		this.id = Math.round( Math.random() * 9999 );
		this._channel = channel;
		this._eventType = eventType;
		this._content = {};
		this._control = {};
		this._buildInitialContentMetrics();
		this._buildInitialControlMetrics(); 
	}

	//

	/** Adds a new event type to the metrics
	 * @param content The content object in which to add a metrics
	 */
	_buildInitialContentMetrics(){
		this._channel.content.forEach( content => {
			this._content[ content.id ] = new ChannelContentEventMetrics( this._channel, content, this._eventType );
		});
   	}

	/** Adds a new event type to the metrics
	 * @param content The content object in which to add a metrics
	 */
	_buildInitialControlMetrics(){
		if( this._channel.controlId != null ){
			// use only the content matching the controlId
			const controlContent = this._channel.content.filter( content => content.id === this._channel.controlId );
			// this._control[ this._channel.controlId ] = new ChannelContentEventMetrics( this._channel, controlContent, this._eventType );
			this._control = new ChannelContentEventMetrics( this._channel, controlContent, this._eventType );
		} else if( this._channel.model === ChannelModelTypes.PREDICTIVE ){
			this._control = new ChannelContentEventMetrics( this._channel, null, this._eventType );
			// use all of the the content for the control
			/*
			this._channel.content.forEach( content => {
				this._control[ content.id ] = new ChannelContentEventMetrics( this._channel, content, this._eventType );
			});
			*/
		}
   	}

	//

	/**
	 * Rebuilds the initial metrics structure with empty values
	 */
	rebuildMetrics(){
		this._buildInitialContentMetrics();
		this._buildInitialControlMetrics(); 
	}

	/**
	 * Updates metrics values for an event type, selection type, and content
	 * @param type The event type ID
	 * @param selectionType The selection type, 0 for control or 1 for content
	 * @param contentId The content ID
	 * @param metrics An object with the metrics to update, { count: 5, order-value: 100, ... }
	 */
	updateEventMetric( type, selectionType, contentId, metrics ){
		// if the event type matches this event, update values
		if( type === this._eventType.id ){
			const contentMetric = this.getContentMetricBySelectionType( contentId, selectionType );
			if( contentMetric != null ){
				Object.keys( metrics ).forEach( metricKey => {
					// on count, update the total
					if( metricKey === 'count' ){
						this.total += metrics[ metricKey ];
						contentMetric[ metricKey ] += metrics[ metricKey ];
						if( selectionType === ChannelContentSelectionTypes.CONTENT ) this.contentTotal += metrics[ metricKey ];
					}
				});
			}
		}
	}

	/**
	 * Retrieves a content metric for this event by content ID and selection type
	 * @param contentId The content ID
	 */
	getContentMetricBySelectionType( contentId, selectionType ){
		return ( selectionType === 1 ) ? this._content[ contentId ] : this._control;
	}

	//

	/**
	 * @return The metric for a specific event
	 */
	getContentEventMetric( contentId ){
		return this._content[ contentId ];
	}

	/**
	 * @return The control metric for a specific event
	 */
	getControlEventMetric(){
		return this._control;
	}

	//

}

//

export default ChannelEventMetrics;