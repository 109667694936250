/*
 * (c) Verra Technology Corporation
 */

/*
	'Custom',
	'View History',
	'Click Stream',
	'Event Stream', // TODO: cool idea but, what if the events are very verbose, like mouse move or something like that?
	'Season',
	'Date', 
	'Month',
	'Day of Week',
	'Time',
	'Hour',
	'Time of Day',
	'Postal Code', // TODO: codes outside of the US
	'Region',
	'Weather',
	'Session Length',
	'Cart Size',
	'Cart Value',
	'AOV'
*/

/**
 * Represents available Predictive Attributes
 * Defines preconfigured PredictiveAttribute type names
 * TODO: handle these names as reserved
 * TODO: create in DB?
 * TODO: saved custom types under the account
 * TODO: attribute weights
 */
const PredictiveAttributeTypes = [
	{
		label: 'Date & Time',
		items: [
			{ name: 'Hour', encodingType: 1, characters: '0123456789', inputLength: 2, dataLayerVariable: 'time.hour' },
			{ name: 'Weekday', encodingType: 1, characters: '0123456789', inputLength: 1, dataLayerVariable: 'time.weekday' }, // TODO: this can be 0-6 for characters, yeah?
			{ name: 'Day of Month', encodingType: 1, characters: '0123456789', inputLength: 2, dataLayerVariable: 'time.date' },
			{ name: 'Month', encodingType: 1, characters: '0123456789', inputLength: 2, dataLayerVariable: 'time.month' }
		]
	},
	{
		label: 'Location',
		items: [
			// TODO: test lat and long
			{ name: 'Lat & Long', encodingType: 1, characters: '0123456789-', inputLength: 14, dataLayerVariable: 'location.latlong' },
			{ name: 'Postal Code', encodingType: 1, characters: '0123456789', inputLength: 5, dataLayerVariable: 'location.zip' },
			{ name: 'City', encodingType: 2, characters: '0abcdefghijklmnopqrstuvwxyz', inputLength: 20, dataLayerVariable: 'location.city' },
			{ name: 'State', encodingType: 1, characters: '0abcdefghijklmnopqrstuvwxyz', inputLength: 2, dataLayerVariable: 'location.state' },
			{ name: 'Country', encodingType: 1, characters: '0abcdefghijklmnopqrstuvwxyz', inputLength: 2, dataLayerVariable: 'location.country' }
			// { name: 'Language', encodingType: 1, characters: 'abcdefghijklmnopqrstuvwxyz', inputLength: 2, dataLayerVariable: 'location.language' }
		]
	},
	{
		label: 'Context',
		items: [ 
			//{ name: 'Current Site', encodingType: 2, characters: '0123456789', inputLength: 9, dataLayerVariable: 'context.site' },
			{ name: 'Current Page', encodingType: 2, characters: '0123456789', inputLength: 9, dataLayerVariable: 'context.href' },
			{ name: 'Referrer', encodingType: 2, characters: '0123456789', inputLength: 9, dataLayerVariable: 'context.referrer' },
			{ name: 'User ID', encodingType: 1, characters: '0123456789', inputLength: 20, dataLayerVariable: 'verra-uid' },
			{ name: 'Session ID', encodingType: 1, characters: '0123456789', inputLength: 20, dataLayerVariable: 'verra-sid' }
		]
	},
	{
		label: 'Device',
		items: [ 
			{ name: 'Screen Resolution', encodingType: 2, characters: '0123456789', inputLength: 10, dataLayerVariable: 'device.resolution' },
		]
	},
	{
		label: 'History',
		items: [

			/**
			 * Up to 2000 pages each with an ID of a max 2000 characters
			 * The value 4,7,12,15 is encoded as 00010010000100100000...0
			 */
			{ name: 'Pages Viewed', encodingType: 4, inputLength: 2000, dataLayerVariable: 'history.pages-viewed' },
			
			//{ name: 'Channels Viewed', encodingType: 1, characters: '0123456789', inputLength: 5, dataLayerVariable: 'history.channels-viewed' },
			//{ name: 'Channel Content Viewed', encodingType: 1, characters: '0123456789', inputLength: 5, dataLayerVariable: 'history.channel-content-viewed' }
		]
	},
	{
		label: 'Other',
		items: [
			{ name: 'Custom', encodingType: 1, characters: '', inputLength: 0, dataLayerVariable: '' }
		]
	}

	// Device, Platform, etc
	// Products Purchased
	// Custom

];

//

export default PredictiveAttributeTypes;