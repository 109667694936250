/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';
import ModifiableObject from '../../../model/ModifiableObject.mjs';

/**
 * Saves a Channel
 */
class SaveChannelRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param channel The Channel to save
	 * @param rebuildModel Whether or not to rebuild the ML model
	 */
	constructor( channel, rebuildModel ){
		super();
		this.channel = channel;
		this.rebuildModel = rebuildModel;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.SAVE_CHANNEL;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { channel: this.channel, rebuildModel: this.rebuildModel };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		var response = this.getResponse();
		//console.info( 'SearchContentRequest::handleComplete', response );
		if( response.success ) {
			SphereAdminSession.channelContentList = response.result;
			this.channel.status = ModifiableObject.SAVED;
		}
	}
 
}

export default SaveChannelRequest;
