/*
 * (c) Verra Technology Corporation
 */

/**
 * Represents a model object that will have it's modified state tracked. 
 * @see PublishableObject for additional status states
 */
 class ModifiableObject {

	// Modified States

	/**
	 * Represents a new, unmodified, unsaved object. This state only exists on the client.
	 */
	static CREATED = 0;

	/**
	 * Represents a modifed object that has not been saved yet. This state only exists on the client.
	 */
	static MODIFIED = 1;

	/**
	 * Represents an object that has been saved
	 */
	static SAVED = 2;

	/**
	 * Represents an object that has been saved
	 */
	static LOCKED = 4;

	//

	/**
	 * The current publish state of the object
	 */
	status = 0; // CREATED

}

//

export default ModifiableObject;