/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';
import Loader from '../controls/Loader';

//

//

/**
 * Modal used for the loading state
 */
class LoadingModal extends Component {

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return (
			<div id='modal' className='modal'>
				<div className='background'></div>
				<div className='loader'><Loader/></div>
			</div> 
		)
	}

}

//

export default LoadingModal;