/*
 * (c) Verra Technology Corporation
 */


/**
 * Represents a modifications made as part of an experience
 */
class ExperienceModification {

	/**
	 * Defines the structure of the Channel class. Used with ObjectUtil.CreateFromDefinition
	 */
	static definition = {
		type: ExperienceModification
	}

	/**
	 * The type of change that was made
	 */
	type = null;

	/**
	 * The path of the original element that is being modified, removed, added, or the path to where a new element 
	 * is being added
	 */
	path = null;

	/**
	 * The value representing the change. This could be text, markup, js, etc. depending on the type of change
	 */
	value = null;

	/**
	 * The original value, or set of values for query selectors with multiple elements, before the change. This 
	 * could be the original text or markup or it could be null depending on the type
	 */
	original = null;

	// Admin only fields

	currentPath = null;
	
	/**
	 * Determines whether the modification is visible in the editor
	 */
	enabled = true;

}

//

export default ExperienceModification;