/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import CreateSiteCommand from '../commands/CreateSiteCommand';
import EditSiteCommand from '../commands/EditSiteCommand';
import OpenModalCommand from '../commands/OpenModalCommand';
import Alert from '../components/controls/Alert';
import DataGrid from '../components/controls/DataGrid';
import SphereAdminSession from '../model/SphereAdminSession';
import DeleteSitesRequest from '../requests/sites/DeleteSitesRequest';

//

/**
 * The ContentPanel contains the administration UI for content
 */
class SitesPanel extends Component {

	/**
	 * Constructs the ChannelPanel.
	 */
	constructor() {
		super();
		this.state = { sitesSelected: false };
		this.sitesGrid = React.createRef();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		const sites = SphereAdminSession.sites; 
		const deleteDisabled = ( this.state.sitesSelected ) ? '' : ' disabled';
		const emptySitesMarkup = <div>You do not have any Sites. <button className='link-button' onClick={this.#handleCreateSite.bind( this )}>Create Site</button></div>;
		const headers = [ 
			//{label: 'Account', field: 'accountName', width: '15'}, 
			{ label: 'Name', field: 'name', width: '25' }, 
			{ label: 'URL', field: 'url', width: '35' },
			{ label: 'Client ID', field: 'clientId', width: '30', supressClick: true }
		];

		return ( 
			<div className='sites-panel'>
				<div className='grid'>
					<h2>Sites</h2>
					<div className='grid-cell'>
						<div className='button' onClick={this.#handleCreateSite.bind( this )}>Create</div>
					</div>
					<div className='grid-cell pad-cell-left'>
						<button className={'button' + deleteDisabled} onClick={this.#handleDelete.bind( this )}>Delete</button>
					</div>
				</div>
				<div className='pad-cell-top'>
					<DataGrid
						ref={this.sitesGrid}
						headers={headers} 
						data={sites} 
						idField='url' 
						checkColumn={true} 
						statusIndicator={true} 
						statusField='status'
						noContent={emptySitesMarkup}
						rowClickHandler={this.#handleSiteListItemClick.bind( this )}
						checkBoxChangeHandler={this.#handleGridCheckBoxSelect.bind( this )}/>
				</div>
			</div>
		);
	}

	// Private

	/**
	 * Handles the click to create a new site
	 */
	#handleCreateSite( e ){
		const createSite = new CreateSiteCommand();
		createSite.execute();
	}

	/**
	 * Handles the click to edit a site
	 */
	#handleSiteListItemClick( site ){
		const editSite = new EditSiteCommand( site.url );
		editSite.execute();
	}

	/**
	 * Handles clicks on a checkbox in the Content grid
	 */
	#handleGridCheckBoxSelect( grid ){
		const selected = grid.getChecked();
		this.setState({ sitesSelected: ( selected.length > 0 )});
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleDelete( e ){
		if( this.state.sitesSelected ){
			const alert = <Alert content='Are you sure you want to delete these Sites?' okHandler={ this.#handleConfirmDelete.bind( this ) }/>;
			const openModal= new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		}
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleConfirmDelete( e ){
		SphereAdminSession.loading = true;
		const selectedSites = this.sitesGrid.current.getChecked();
		const siteIds = selectedSites.map( site => site.id );
		const deleteSites = new DeleteSitesRequest( siteIds );
		deleteSites.execute( command  => this.#handleDeleteComplete( command ));
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleDeleteComplete( command ){
		SphereAdminSession.loading = false;
		this.sitesGrid.current.unCheckAll();
		this.setState({}); // force a reload of the grid
	}

}

//

export default SitesPanel;
