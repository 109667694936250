/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import ModifiableObject from '../../../model/ModifiableObject.mjs';
import AdminStates from '../../model/AdminStates';
import SphereAdminSession from '../../model/SphereAdminSession';

import CreateExperienceCommand from '../../commands/CreateExperienceCommand';
import EditExperienceCommand from '../../commands/EditExperienceCommand';
import EditOptimizationCommand from '../../commands/EditOptimizationCommand';
import OpenModalCommand from '../../commands/OpenModalCommand';
import SetStateCommand from '../../commands/SetStateCommand';
import ValidatorCommand from '../../commands/ValidatorCommand';
import SaveExperienceRequest from '../../requests//experiences/SaveExperienceRequest';
import AddExperienceToOptimizationRequest from '../../requests/optimizations/AddExperienceToOptimizationRequest';

import Alert from '../controls/Alert';
import DropDownField from '../controls/DropDownField';
import Hint from '../controls/Hint';
import InputField from '../controls/InputField';

import ExperienceModificationType from '../../../model/ExperienceModificationType';
import ElementUtil from '../../../util/ElementUtil';
import CreateExperienceModificationCommand from '../../commands/CreateExperienceModificationCommand';
import SaveExperienceToStorageCommand from '../../commands/SaveExperienceToStorageCommand';
import ObjectStatusMap from '../../model/ObjectStatusMap';
import ExperienceEditorControls from '../experiences/ExperienceEditorControls';
import ModificationEditor from '../experiences/ModificationEditor';
import ModificationsList from '../experiences/ModificationsList';
import SiteEditor from '../experiences/SiteEditor';

//

/**
 * Provides UI for creating and editing experiences
 */
class ExperienceEditor extends Component {

	#controlsRef;
	#siteEditorRef;
	#resizeModifications;
	#upListener;
	#moveListener;
	#resizeEditor;
	#startDragX;
	#startWidth;
	#startDragY;
	#startHeight;

	//

	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();

		// console.info( 'ExperienceEditor', SphereAdminSession.optimization, SphereAdminSession.experience );

		this.state = { 
			frameLoaded: false,
			primaryElement: null,
			selectedElements: null,
			querySelector: null,
			showModificationsList: true,
			modificationsWidth: 350,
			editorHeight: 200,
			modificationsEnabled: false,
			selectedModification: null,
			deletedModification: null,
		};

		// UI

		this.#controlsRef = React.createRef();
		this.#siteEditorRef = React.createRef();
		
		// Events
		
		this.#upListener = this.#stopResize.bind( this );
		this.#moveListener = this.#resize.bind( this );

		window.addEventListener( 'resize', this.#handleWindowResize.bind( this ) );
		setTimeout( () => { this.#handleWindowResize(); }, 1000 ); // force a resize
	}

	/**
	 * Handles the mounting of the component
	 */
	componentDidUpdate() {
		// console.info( 'componentDidUpdate' );
		if( this.state.deletedModification != null ) this.#handleFinishRemoveModification();
		this.#handleWindowResize();
	}
	
	// Rendering 

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		let markup = '';
		if( SphereAdminSession.experience != null ) {
			markup = this.#getExperienceEditorMarkup();
		} else {
			markup = this.#getNoExperienceMarkup();
		}
		return markup;
	}

	/**
	 * @return the full Experience Editor markup
	 */
	#getExperienceEditorMarkup() { 
		const sites = SphereAdminSession.sites;
		const selectedSite = sites.find( site => site.id.toString() === SphereAdminSession.experience.siteId.toString() );

		// const editorConfig = this.modificationTypeConfig[ this.state.editingMode ];
		// const editorRenderer = ( editorConfig != null ) ? editorConfig.render : null;

		return ( 
			<div className='content-panel experience-editor no-select'>
				{ this.#getHeaderMarkup() }
				{ this.#getPrimaryFieldsMarkup() }
				<ExperienceEditorControls
					ref={ this.#controlsRef }
					primaryElement={ this.state.primaryElement }
					selectedElements={ this.state.selectedElements }
					selectedModification={ this.state.selectedModification }
					modificationsEnabled={ this.state.modificationsEnabled }
					editingMode={ this.state.editingMode }
					querySelector={ this.state.querySelector }
					togglePreviewHandler={ this.#handleTogglePreview.bind( this )}
					toggleModificationsListHandler={ this.#handleToggleModificationsList.bind( this )}
					elementNavigationHandler={ this.#handleElementNavigation.bind( this )}
					createModificationHandler={ this.#handleCreateModification.bind( this )}
					elementPathChangedHandler={ this.#handleElementPathChanged.bind( this )}/>
				<div style={{ display: ( this.state.frameLoaded ) ? 'flex' : 'none', alignItems: 'stretch', justifyContent: 'stretch', flexShrink: 0 }}>
					{ this.state.showModificationsList && 
						<ModificationsList
							width={ this.state.modificationsWidth }
							selectedModification={ this.state.selectedModification } 
							selectModificationHandler={ this.#handleModificationSelected.bind( this )}
							togglePreviewHandler={ this.#handleToggleModificationPreview.bind( this )}
							removeModificationHandler={ this.#handleRemoveModification.bind( this )}/> 
					}
					{ this.state.showModificationsList && 
						<div 
							className='vertical-resize-handle no-select' 
							style={{ alignSelf: 'center' }}
							onMouseDown={ this.#startModificationsListResize.bind( this )}></div>
					}
					<div style={{ flexGrow: 1, overflow: 'auto' }}>
						{/* { editorRenderer != null && editorRenderer.apply( this ) } */ }
						{/* { editorRenderer != null && <hr className='resize-handle no-select' onMouseDown={ this.#startEditorResize.bind( this )}/> } */}
						<ModificationEditor 
							height={ this.state.editorHeight }
							primaryElement={ this.state.primaryElement }
							selectedElements={ this.state.selectedElements }
							selectedModification={ this.state.selectedModification }
							modificationChangedHandler={ this.#handleModificationChanged.bind( this )}/> 
						<hr className='resize-handle no-select' onMouseDown={ this.#startEditorResize.bind( this )}/>
						<SiteEditor
							ref={ this.#siteEditorRef }
							selectedSite={ selectedSite }
							selectedModification={ this.state.selectedModification }
							modificationsEnabled={ this.state.modificationsEnabled }
							loadedHandler={ this.#handleSiteExperienceLoaded.bind( this ) }
							elementClickedHandler={ this.#handleElementClicked.bind( this )}
							elementsSelectedHandler={ this.#handleElementsSelected.bind( this )}
							modificationPathChangedHandler={ this.#handleModificationPathChanged.bind( this )}/>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Gets the markup for the header section
	 */
	#getHeaderMarkup() {
		const experience = SphereAdminSession.experience;
		const isLocked = ( experience?.status === ModifiableObject.LOCKED );
		const isEditing = ( SphereAdminSession.currentState === AdminStates.ADMIN_EXPERIENCES_EDIT);
		const title = ( isEditing ) ? 'Edit Experience' : 'Create Experience';

		const isFromOptimization = ( SphereAdminSession.optimizationId != null ); // ( SphereAdminSession.currentState === AdminStates.ADMIN_EXPERIENCES_CREATE_FOR_OPTIMIZATION );
		// const breadcrumb = ( isFromOptimization ) ? 
		// 		<div className='breadcrumb'><a href='/optimization/create/'>{ SphereAdminSession.optimization.name }</a> / { experience.name }</div> : 
		// 		<div className='breadcrumb'><a href='/experiences/'>Experiences</a> / { experience.name }</div>;

		const breadcrumb = <div className='breadcrumb'><a href='/experiences/'>Experiences</a> / { experience?.name }</div>;

		const saveDisabled = ( experience?.status === ModifiableObject.SAVED );
		const saveButtonsDisabledClass = ( saveDisabled ) ? ' disabled' : '';

		return 	<div>
					<div className='grid'>
						<div className='grid-cell default-50'>
							<h2>{title}</h2>
							{breadcrumb}
						</div>
						{ experience != null &&
							<div className='grid-cell default-50 align-right header-actions'>
								{ !isFromOptimization && !isLocked &&
									<button
										className={ 'primary-button control-pad-left' + saveButtonsDisabledClass }
										disabled={ saveDisabled }
										style={{ width: '80px' }}
										onClick={ this.#handleSave.bind( this )}>
											Save
									</button>
								}
								{ isFromOptimization &&
									<button
										className={ 'primary-button control-pad-left' }
										style={{ width: '200px' }}
										onClick={ this.#handleSaveAndAdd.bind( this )}>
											Add to Optimization
									</button>
								}
								{ isFromOptimization &&
									<button
										className={ 'button control-pad-left' + saveButtonsDisabledClass }
										disabled={ saveDisabled }
										style={{ width: '80px' }}
										onClick={ this.#handleSave.bind( this )}>
											Save
									</button>
								}
								<button className={ 'button control-pad-left' } style={{ width: '80px' }} onClick={ this.#handleCancel.bind( this )}>Cancel</button>
							</div>
						}
					</div>
					{ experience != null && isLocked && 
						<div className='panel-cell'>The Experience is in use by a published optimization</div>
					}
				</div>;
	}

	/**
	 * Gets the primary fields markup
	 */
	#getPrimaryFieldsMarkup() {
		const experience = SphereAdminSession.experience;

		const sites = SphereAdminSession.sites;
		const selectedSite = sites.find( site => site.id.toString() === experience.siteId.toString() );

		const isLocked = ( experience.status === ModifiableObject.LOCKED );

		const nameToolTip = 'User friendly name for the Experience.';
		const status = ObjectStatusMap[ experience.status ];
		const siteTooltip = 'The site in which the changes will apply';

		return (
			<div>
				<div className='panel-cell primary-fields'>
					<div style={{ flexBasis: 0, flexGrow: 3, position: 'relative' }}>
						<div className={ 'status-indicatator ' + status }></div>
						<label>Name <Hint width='250px' content={ nameToolTip }/></label>
						<InputField 
							value={ experience.name } 
							maxLength='256' 
							disabled={ isLocked } 
							onChange={( value ) => { this.#handleFieldChanged( 'name', value ); }}/>
					</div>
					{ sites.length > 1 &&
						<div style={{ flexBasis: 0, flexGrow: 1 }}>
							<label>Site <Hint width='225px' content={ siteTooltip }/></label>
							<DropDownField 
								labelField='name' 
								items={ SphereAdminSession.sites }
								selectedItem={ selectedSite }
								disabled={ isLocked } 
								changeHandler={ this.#handleSiteSelected.bind( this )}/>
						</div>
					}
				</div>
			</div>
		);
	}

	/**
	 * @return The markup for when no Experience can be found
	 */
	#getNoExperienceMarkup() {
		return ( 
			<div className='content-panel experience-editor no-select'>
				{ this.#getHeaderMarkup() }
				<div className='panel-cell grid-cell default-100' style={{ textAlign: 'center' }}>
					Experience could not be found. <button className='link-button' onClick={ this.#handleCreateExperience.bind( this )}>Create Experience</button>
				</div>
			</div>
		);
	}

	// Resizing

	/**
	 * Begins the resize of the editor
	 */
	#startModificationsListResize( e ) {
		this.#resizeModifications = true;
		this.#resizeEditor = false;
		this.#startDragX = e.clientX;
		this.#startWidth = this.state.modificationsWidth;
		document.addEventListener( 'mouseup', this.#upListener );
		document.addEventListener( 'mousemove', this.#moveListener );
	}

	/**
	 * Begins the resize of the editor
	 */
	#startEditorResize( e ) {
		this.#resizeModifications = false;
		this.#resizeEditor = true;
		this.#startDragY = e.clientY;
		this.#startHeight = this.state.editorHeight;
		document.addEventListener( 'mouseup', this.#upListener );
		document.addEventListener( 'mousemove', this.#moveListener );
	}

	/**
	 * Ends the resize of the editor
	 */
	#stopResize( e ) {
		this.#resizeModifications = false;
		this.#resizeEditor = false;
		document.removeEventListener( 'mouseup', this.#upListener );
		document.removeEventListener( 'mousemove', this.#moveListener );
	}

	/**
	 * Handles the mouse move event, resizing the editor
	 */
	#resize( e ) {
		let state;
		if( this.#resizeModifications ) {
			state = { modificationsWidth: e.clientX - this.#startDragX + this.#startWidth }
		} else if( this.#resizeEditor ) {
			state = { editorHeight: e.clientY - this.#startDragY + this.#startHeight }
		}
		this.setState( state );
	}

	/**
	 * Handles window resize events
	 */
	#handleWindowResize() {
		const siteEditor = this.#siteEditorRef.current;
		if( siteEditor != null ) {
			const clientHeight = window.innerHeight;
			const controls = this.#controlsRef.current;
			const controlsRect = ( controls != null ) ? controls.getBoundingClientRect() : { y: 0 };
			const siteEditorRect = siteEditor.getBoundingClientRect();
			const height = clientHeight - siteEditorRect.y + controlsRect.y - 39;
			siteEditor.setHeight( height );
		}
	}

	// UI Handlers

	/**
	 * Handles a selection of a site from the Sites drop down
	 */
	#handleSiteSelected( site ) {
		// console.info( 'handleSiteSelected', site );
		SphereAdminSession.experience.siteId = site.id;
		// this.#navigateToPage( site, this.state.path );
	}

	/**
	 * Handles changes to the input fields, invalidating the Channel object
	 */
	#handleFieldChanged( field, value ) {
		// console.info( field, value );
		SphereAdminSession.experience.status = ModifiableObject.MODIFIED;
		SphereAdminSession.experience[ field ] = value;
		this.setState({}); // force a redraw
	}

	// Control Handlers

	/**
	 * Handles the toggle to enable or disable previewing
	 */
	#handleTogglePreview() {
		// console.info( 'handleTogglePreview' );
		const modificationsEnabled = !this.state.modificationsEnabled;
		const modifications = SphereAdminSession.experience.modifications;
		if( modificationsEnabled ) {
			for( let i = 0; i < modifications.length; i++ ) {
				this.#handleToggleModificationPreview( modifications[ i ], modificationsEnabled );
			}
		} else {
			for( let i = modifications.length - 1; i >= 0; i-- ) {
				this.#handleToggleModificationPreview( modifications[ i ], modificationsEnabled );
			}
			this.#siteEditorRef.current.selectElements([]);
		}
		this.setState({ modificationsEnabled, selectedModification: null, querySelector: null });
	}

	/**
	 * Handles the toggle to enable or disable previewing
	 */
	#handleToggleModificationPreview( modification, enabled ) {
		modification.enabled = enabled;
		this.#siteEditorRef.current.applyModificationChange( modification, enabled, false );
		if( modification === this.state.selectedModification ) {
			this.setState({ selectedModification: null, querySelectory: null });
			this.#siteEditorRef.current.selectElements([]);
		}
	}

	/**
	 * Handles the toggle to hide or show the modifications list
	 */
	#handleToggleModificationsList() {
		this.setState({ showModificationsList: !this.state.showModificationsList });
	}

	/**
	 * Navigates the selected content to a specifc element
	 */
	#handleElementNavigation( element ) {
		const querySelector = ElementUtil.getQuerySelectorPath( element );
		this.setState({ querySelector, selectedModification: null });
		this.#siteEditorRef.current.selectElements([ element ]);
	}
	 
	/**
	 * Handles the create modification callback from the ExperienceEditorControls
	 */
	#handleCreateModification( type ) {
		const isGlobalMod = ( type === ExperienceModificationType.CSS || type === ExperienceModificationType.JS );
		if( this.state.selectedModification == null || ( this.state.selectedModification.type !== type && isGlobalMod )) {
			let path;
			if( type === ExperienceModificationType.CSS ) {
				path = 'verra-global-css';
			} else if ( type === ExperienceModificationType.JS ) {
				path = 'verra-global-js';
			} else {
				path = this.state.querySelector;
			}

			const create = new CreateExperienceModificationCommand( type, path, this.state.selectedElements );	
			const modification = create.execute();
			if( modification != null ) {
				modification.selected = true;
				this.setState({ selectedModification: modification });
				this.#siteEditorRef.current.applyModificationChange( modification, true, true, true );
			}
		} else {
			this.setState({ selectedModification: null, querySelector: null });
			this.#siteEditorRef.current.selectElements([]);
		}
	}

	/**
	 * Handles user input changes to the element path in the controls component
	 */
	#handleElementPathChanged( path ) {
		// NOTE: this is fragile, the setState call here /has/ to occur after selecting elements because the call back handler
		// for select elements updates the querySelector in the state to match the primary element and we want it to match what 
		// the user provided in the input field
		this.#siteEditorRef.current.selectElementsByPath( path );
		this.setState({ querySelector: path });
	}

	// Modification List Handlers

	/**
	 * Handle the click to select a modification in the modifications list
	 */
	#handleModificationSelected( modification ) {
		if ( this.state.modificationsEnabled && modification.enabled ) {
			if( modification !== this.state.selectedModification ) {
				// console.info( 'handleModificationSelected' );
				// console.info( modification.currentPath );
				this.#siteEditorRef.current.selectModificationElements( modification );
				this.setState({ selectedModification: modification, querySelector: modification.currentPath });
			} else {
				this.#siteEditorRef.current.selectElements([]);
				this.setState({ selectedModification: null, querySelector: null });
			}
		}
	}

	/**
	 * Handles removing a modification
	 */
	#handleRemoveModification( modification ) {
		if( this.state.modificationsEnabled ) {
			this.state.deletedModification = modification;
			this.#handleTogglePreview();
		}
	}

	/**
	 * Handles removing a modification
	 */
	#handleFinishRemoveModification() {
		const experience = SphereAdminSession.experience;
		experience.modifications = experience.modifications.filter( mod => mod !== this.state.deletedModification );
		this.state.deletedModification = null;
		this.#handleTogglePreview();
	}

	// Modification Editor Handlers

	/**
	 * Handles changes to the selected modification
	 */
	#handleModificationChanged() {
		SphereAdminSession.experience.status = ModifiableObject.MODIFIED;
		this.#siteEditorRef.current.applyModificationChange( this.state.selectedModification, true, true );
		this.setState({}); // redraw
	}

	// Site Experience Editor Handler
	
	/**
	 * Handles the load event from site experience editor
	 */
	#handleSiteExperienceLoaded() {
		this.#handleTogglePreview();
		this.setState({ frameLoaded: true });
	}

	/**
	 * Handles the element selection callback from the SiteEditor
	 */
	#handleElementClicked( element ) {
		const querySelector = ElementUtil.getQuerySelectorPath( element );
		this.setState({ querySelector, selectedModification: null });
	}

	/**
	 * Handles the element selection callback from the SiteEditor
	 */
	#handleElementsSelected( primaryElement, selectedElements ) {
		this.setState({ primaryElement, selectedElements });
	}

	/**
	 * Handles the element selection callback from the SiteEditor
	 */
	#handleModificationPathChanged( modification ) {
		this.setState({ querySelector: modification.currentPath });
	}

	// Save

	/**
	 * Saves the experience to session storage
	 */
	#saveToStorage() {
		const save = new SaveExperienceToStorageCommand( SphereAdminSession.experience );
		save.execute();
	}

	/**
	 * Handles the click to save the Experience
	 */
	#handleSave() {
		this.#save( this.#handleSaveComplete.bind( this ));
	}

	/**
	 * Handles the click to save the Experience and then add it to an Optimization
	 */
	#handleSaveAndAdd() {
		// console.info( 'handleSaveAndAdd' );
		this.#save( this.#addToOptimization.bind( this ));
	}

	/**
	 * Performs the save operation
	 */
	#save( saveCompleteHandler ) {
		// console.info( 'save' );
		const fields = { 
			id: ValidatorCommand.isNotNullOrEmpty, 
			name: ValidatorCommand.isNotNullOrEmpty 
		};

		const validateSite = new ValidatorCommand( SphereAdminSession.experience, fields );
		const isValid = validateSite.execute();

		if( isValid ) {
			// console.info( 'valid' );
			SphereAdminSession.loading = true;
			const saveExperience = new SaveExperienceRequest( SphereAdminSession.experience );
			saveExperience.execute( saveCompleteHandler );
		} else {
			
			const invalidFields = validateSite.getInvalidFields();
			const invalidFieldsElements = [];
	
			var i = 0;
			invalidFields.forEach( field => {
				invalidFieldsElements.push( <li key={ i++ }>{ field }</li> );
			});
	
			const content = <div className='alert'>
				The Experience cannot be saved. The following fields are invalid or incomplete:
				<ul>{ invalidFieldsElements }</ul>
			</div>;
	
			const openModal = new OpenModalCommand( 'Invalid Experience', content, '500px', true );
			openModal.execute();
		}
	}

	/**
	 * Handles completion of the save channel reques
	 */
	#handleSaveComplete( command ) {
		SphereAdminSession.loading = false;
		if( command.response.success ) {
			this.#saveToStorage();
			if( SphereAdminSession.currentState === AdminStates.ADMIN_EXPERIENCES_CREATE ) {
				const editExperience = new EditExperienceCommand( SphereAdminSession.experience.id );
				editExperience.execute();
			} else {
				this.setState({}); // redraw
			}
		}
	}

	/**
	 * Executes the request to add the Experience to an Optimization
	 */
	#addToOptimization() {
		// console.info( 'addToOptimization' );
		const addExperience = new AddExperienceToOptimizationRequest( SphereAdminSession.optimizationId, SphereAdminSession.experience );
		addExperience.execute( this.#handleAddToOptimizationComplete.bind( this ));
	}

	/**
	 * Executes the request to add the Experience to an Optimization
	 */
	#handleAddToOptimizationComplete() {
		// console.info( 'handleAddToOptimizationComplete' );
		const editOptimization = new EditOptimizationCommand( SphereAdminSession.optimizationId );
		editOptimization.execute();
	}

	/**
	 * Handles a click on the cancel button
	 */
	#handleCancel() {
		const hasChanged = SphereAdminSession.experience.status === ModifiableObject.MODIFIED || SphereAdminSession.experience.status === ModifiableObject.CREATED;
		if( hasChanged ){
			const alert = <Alert content='You have unsaved changes, are you sure you want to exit?' okHandler={ this.#handleCancelConfirm.bind( this ) }/>;
			const openModal = new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		} else {
			const isFromOptimization = ( SphereAdminSession.optimizationId != null );
			if( isFromOptimization ) {
				const editOptimization = new EditOptimizationCommand( SphereAdminSession.optimizationId );
				editOptimization.execute();
			} else {
				const setState = new SetStateCommand( AdminStates.ADMIN_EXPERIENCES );
				setState.execute();
			}
		}
	};

	/**
	 * Handles a confirmation to cancel changes
	 */
	#handleCancelConfirm() {
		const isFromOptimization = ( SphereAdminSession.optimizationId != null );
		if( isFromOptimization ) {
			const editOptimization = new EditOptimizationCommand( SphereAdminSession.optimizationId );
			editOptimization.execute();
		} else {
			const setState = new SetStateCommand( AdminStates.ADMIN_EXPERIENCES );
			setState.execute();
		}
	};

	//

	/**
	 * Handles the click to create a new Experience. Only applicable if the Experience cannot be found
	 */
	#handleCreateExperience() {
		const create = new CreateExperienceCommand();
		create.execute();
	}

}

//

export default ExperienceEditor;
