/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminServices from '../../model/SphereAdminServices';
import ChannelContentSelectionTypes from '../../model/ChannelContentSelectionTypes';

/**
 * Retrieves impression event counts based on time increment and period
 */
class RetrieveChannelEngagementMetricsRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 */
	 constructor( accountId, channelId, engagementEvents, timeIncrement, startTime, endTime ){
		super();
		this.accountId = accountId;
		this.channelId = channelId;
		this.engagementEvents = engagementEvents;
		this.timeIncrement = timeIncrement;
		this.startTime = startTime;
		this.endTime = endTime;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getService() {
		return SphereAdminServices.RETRIEVE_CHANNEL_ENGAGEMENT_METRICS;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { 
			accountId: this.accountId, 
			channelId: this.channelId, 
			engagementEvents: this.engagementEvents.join( ',' ), 
			timeIncrement: this.timeIncrement, 
			startTime: this.startTime.getTime(), 
			endTime: this.endTime.getTime() 
		};
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		// console.info( 'RetrieveChannelImpressionMetricsRequest::handleComplete', response );
		this.events = {};
		if( response.success ){
			const controlMap = {};
			const totalsMap = {};
			response.result.forEach( event => {
				// console.info( event.type, event.count, event.time, new Date( Number( event.time )) );
				let x = new Date( Number( event.time ));
				if( this.events[ event.type ] == null ) this.events[ event.type ] = { total: [], content: {}, control: [] };
				if( event.contentSelectionType === ChannelContentSelectionTypes.CONTENT ){
					if( this.events[ event.type ].content[ event.contentId ] == null ) this.events[ event.type ].content[ event.contentId ] = [];
					this.events[ event.type ].content[ event.contentId ].push( { x, y: event.count, contentId: event.contentId } );
				} else { // ChannelContentSelectionTypes.CONTROL
					if( controlMap[ event.type ] == null ) controlMap[ event.type ] = {};
					if( controlMap[ event.type ][ event.time ] == null ){
						controlMap[ event.type ][ event.time ] = { x, y: 0, control: true };
						this.events[ event.type ].control.push( controlMap[ event.type ][ event.time ] );
					}
					controlMap[ event.type ][ event.time ].y += event.count;
				}
				
				if( totalsMap[ event.type ] == null ) totalsMap[ event.type ] = {};
				if( totalsMap[ event.type ][ event.time ] == null ){
					totalsMap[ event.type ][ event.time ] = { x, y: 0 };
					this.events[ event.type ].total.push( totalsMap[ event.type ][ event.time ] );
				}
				totalsMap[ event.type ][ event.time ].y += event.count;
			});
		}

		// console.info( 'events', this.events );
	}
 
	//

	/**
	 * @return The event counts for the Channel
	 */
	getEvents(){
		return this.events;
	}
 
}

//

export default RetrieveChannelEngagementMetricsRequest;
