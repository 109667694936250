/*
 * (c) Verra Technology Corporation
 */

/**
 * Utility for formating strings and numbers
 */
class FormatUtil {
	
	/**
	 * @return a number formatted as a currency
	 */
	static getValueAsCurrency( value ){
		const formatConfig = { 
			style: 'currency',
			currency: 'USD',
			maximumFractionDigits: 0, 
			minimumFractionDigits: 0,
		};
		if( value > 9999 ) formatConfig.notation = 'compact';
		return Intl.NumberFormat( 'en-US', formatConfig ).format( value );
	}
 
	/**
	 * @return A metric that has been rounded to the nearest thousandth, millionth, etc.
	 */
	static roundMetric( value ) {
		return Intl.NumberFormat( 'en-US', { notation: "compact", maximumFractionDigits: 1 } ).format( value );
	}

}

//

export default FormatUtil;
	