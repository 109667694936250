/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';

//

/**
 * Object validation Command, can be used as is or extended
 */
class ValidatorCommand extends Command {

	// Validation functions

	/**
	 * Validates a string, checking to see if it's null or empty.
	 */
	static isNotNullOrEmpty( value ) {
		var valid = ( value != null );
		if( valid ) valid = ( value.toString().split( ' ' ).join( '' ) !== '' );
		return valid;
	}

	//
	
	/**
	 * Constructs the Command
	 * @param obj The object to validate
	 * @param fields An object representing the fields of the object to validate and the 
	 */
	constructor( obj, fields ) {
		super();
		this.obj = obj;
		this.fields = fields;
		this.valid = false;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		
		this.valid = true;
		this.invalidFields = [];

		for( var fieldName in this.fields ) {
			var validationFunc = this.fields[ fieldName ];
			var fieldValid = validationFunc( this.obj[ fieldName ]);
			if( !fieldValid ) {
				this.valid = false;
				this.invalidFields.push( fieldName );
			}
		}
		
		return this.valid;
	}

	/**
	 * @return whether or not the Channel is valid
	 */
	isValid() {
		return this.valid;
	}
	
	/**
	 * @return whether or not the Channel is valid
	 */
	getInvalidFields() {
		return this.invalidFields;
	}
	
}

//

export default ValidatorCommand;
