/*
 * (c) Verra Technology Corporation
 */

import ModifiableObject from '../../../model/ModifiableObject.mjs';
import SphereAdminServices from '../../model/SphereAdminServices.js';
import SphereAdminSession from '../../model/SphereAdminSession.js';
import RetrieveObjectCountsRequest from '../account/RetrieveObjectCountsRequest.js';
import ServiceRequestCommand from '../ServiceRequestCommand.js';

/**
 * Saves an Metric
 */
class SaveMetricRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param metric The Metric to save
	 */
	constructor( metric ){
		super();
		this.metric = metric;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.SAVE_METRIC
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { metric: this.metric };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		if( response.success ) {
			this.metric.status = ModifiableObject.SAVED; // TODO: should this come from the DB?
			this.metric.id = response.result;
			SphereAdminSession.metrics.push( this.metric );

			// update the object counts
			const retrieveObjectCounts = new RetrieveObjectCountsRequest();
			retrieveObjectCounts.execute();
		}
		//console.info( 'SaveContentRequest::handleComplete content', this.content );
	}
 
}

export default SaveMetricRequest;
