/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import RetrieveContentRequest from '../requests//content/RetrieveContentRequest';
import SetStateCommand from '../commands/SetStateCommand';
import Alert from '../components/controls/Alert';
import OpenModalCommand from '../commands/OpenModalCommand';
import AdminStates from '../model/AdminStates';
import ContentEditor from '../components/editors/ContentEditor';
import SphereAdminSession from '../model/SphereAdminSession';

//

/**
 * The EditContentPanel contains the administration UI for editing
 */
class EditContentPanel extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();

		this.editorRef = React.createRef();

		const contentId = SphereAdminSession.stateParameters.contentId;
		if( SphereAdminSession.content == null || ( SphereAdminSession.content != null && SphereAdminSession.content.id !== contentId )){
			SphereAdminSession.content = null;
			SphereAdminSession.loading = true;
			const retrieveContent = new RetrieveContentRequest( SphereAdminSession.selectedAccount.id, SphereAdminSession.stateParameters.contentId );
			retrieveContent.execute(( command ) => { this.#handleContentRetrieved( command ); } );
		}
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render(){
		// console.info( 'EditContentPanel::render' );
		const jsx = ( SphereAdminSession.content !== null ) ? <ContentEditor ref={this.editorRef}/> : '';
		return jsx;
	}

	/**
	 * Handles retrieval of the Content
	 */
	#handleContentRetrieved( command ){
		// console.info( 'handleContentRetrieved', SphereAdminSession.content );
		SphereAdminSession.loading = false;
		this.setState({ invalidated: true });
		if( SphereAdminSession.content != null ){
			if( this.editorRef.current != null ) this.editorRef.current.resizeContentContainers();
		} else {
			const setState = new SetStateCommand( AdminStates.ADMIN_CONTENT_BROWSE );
			setState.execute();

			const alert = <Alert content='Content does not exist' showCancelBtn={false}/>;
			const openModal = new OpenModalCommand( '', alert, '500px', true );
			openModal.execute();
		}
	}
}

//

export default EditContentPanel;
