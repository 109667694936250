/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import Optimization from '../../model/Optimization';
import PublishableObject from '../../model/PublishableObject.mjs';
import CreateOptimizationCommand from '../commands/CreateOptimizationCommand';
import EditOptimizationCommand from '../commands/EditOptimizationCommand';
import OpenModalCommand from '../commands/OpenModalCommand';
import ViewOptimizationCommand from '../commands/ViewOptimizationCommand';
import Alert from '../components/controls/Alert';
import ItemListPanel from '../components/controls/ItemListPanel';
import SphereAdminServices from '../model/SphereAdminServices';
import SphereAdminSession from '../model/SphereAdminSession';

//

/**
 * Defines the model type labels
 */
const modelTypes = {
	'1': 'A/B',
	'5': 'Personalization',
	'4': 'Predictive'
};

//

/**
 * Displays the list of Optimizations created within the account
 */
class OptimizationsPanel extends Component {

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		const noItemsContent = <div>No Optimizations Found. <button className='link-button' onClick={ this.#handleCreateOptimization.bind( this )}>Create Optimization</button></div>;
		const headers = [ 
			{ label: 'Name', field: 'name', width: '50' }, 
			{ label: 'Type', field: ( item ) => modelTypes[ item.type ], width: '20' },
			{ label: 'Last Modified', field: ( item ) => new Date( item.modifiedDate ).toLocaleDateString(), width: '20' }
		];
		
		const markup = ( 
			<div className='optimizations-panel'>
				<div className='grid'>
					<h2 className='grid-cell default-100'>Optimizations</h2>
				</div>
				<ItemListPanel 
					headers={ headers }
					showControls={ true } 
					retrieveOnLoad={ true } 
					noItemsContent={ noItemsContent }
					createCommand={ CreateOptimizationCommand }
					listEndPoint={ SphereAdminServices.RETRIEVE_OPTIMIZATIONS }
					searchEndPoint={ SphereAdminServices.SEARCH_OPTIMIZATIONS }
					deleteEndPoint={ SphereAdminServices.DELETE_OPTIMIZATIONS }
					retrievedHandler={ this.#handleItemsRetrieved.bind( this )} 
					clickHandler={ this.#handleListItemClick.bind( this )}
					deleteMessageHandler={ this.#handleDeleteComplete.bind( this )}/>
			</div>
		);

		return markup;
	}

	/**
	 * Handles the click to create a new item
	 */
	#handleCreateOptimization( e ){
		const create = new CreateOptimizationCommand();
		create.execute();
	}

	/**
	 * Handles the items retrieved event from the ItemListPanel
	 */
	#handleItemsRetrieved( itemsList ) {
		SphereAdminSession.optimizations = itemsList.items;
	}

	/**
	 * Handles clicks on items in the content list
	 */
	#handleListItemClick( item ) {
		if( item.status === PublishableObject.PUBLISHED || item.status === Optimization.COMPLETE ) {
			const viewOptimization = new ViewOptimizationCommand( item.id );
			viewOptimization.execute();
		} else {
			const editOptimization = new EditOptimizationCommand( item.id );
			editOptimization.execute();
		}
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleDeleteComplete( itemsInUse ) {
		if( itemsInUse.length > 0 ){
			const inUseMarkup = itemsInUse.map( item => <li key={ item.name }>{ item.name }</li> );
			const alertContent = <div>The following Optimizations are in a published state and cannot be deleted.<ul>{ inUseMarkup }</ul></div>;
			const alert = <Alert content={ alertContent } showCancelBtn={ false }/>;
			const openModal = new OpenModalCommand( 'Could not delete Optimizations', alert, '500px', true );
			openModal.execute();
		}
	}
}

//

export default OptimizationsPanel;
