import React from 'react';

const RemoveIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg height={size} width={size} viewBox="0 -960 960 960" >
			<g className='nc-icon-wrapper' stroke='none' fill={color}>
			 <path d="M175-433v-94h611v94H175Z"/>
			</g>
		</svg>
	);
};

export default RemoveIcon;