import React from 'react';

var LaptopIcon = function( props ) {
	const width = ( props.width != null ) ? props.width : '48';
	const height = ( props.width != null ) ? props.width : '48';
	return (
		<svg width={width} height={height} viewBox="0 0 48 48">
			<g className="nc-icon-wrapper" fill="#ffffff">
				<path d="M40 36c2.2 0 4-1.8 4-4V12c0-2.2-1.8-4-4-4H8c-2.2 0-4 1.8-4 4v20c0 2.2 1.8 4 4 4H0v4h48v-4h-8zM8 12h32v20H8V12z"/>
			</g>
		</svg>
	);
};

export default LaptopIcon;