/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import SphereAdminSession from '../model/SphereAdminSession';
import LaptopIcon from '../icons/LaptopIcon';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import LayersIcon from '../icons/LayersIcon';
//import SphereAdminConfig from '../model/SphereAdminConfig';

//

/**
 * The administration dashboard
 */
class PrimaryNavigationItem extends Component {
	
	/**
	 * Constructs the component
	 */
	constructor( props ) {
		super( props );
		this.open = false;
		this.element = React.createRef();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const panel = this.props.panel;
		const hasSubNav = ( panel.states != null && panel.states.length > 0 );

		const clickHandler = (( e ) => { handleClick.apply( this, [ e, false, hasSubNav ]); });
		const subItemClickHandler = (( e ) => { handleClick.apply( this, [ e, true, false ]); });
		
		let subNav = null;
		
		if( hasSubNav ) {
			//this.subNavId = panel.state + '-subnav';
			const subNavId = panel.state + '-subnav';
			//var subNavStyle = ( isSubNavVisible.apply( this ) ) ? { display: 'block' } : { display: 'none' };
			const subNavStyle = ( this.open ) ? { display: 'block' } : { display: 'none' };
			const subNavItems = [];

			for( let i = 0; i < panel.states.length; i++ ) {
				let subPanel = panel.states[ i ];
				if( subPanel.isNavigationItem ){
					let selected = ( subPanel.state === SphereAdminSession.currentState );
					let className = ( selected ) ? 'selected' : '';
					subNavItems.push( <a href={subPanel.path} onClick={subItemClickHandler} className={className} data-panel-state={subPanel.state} key={subPanel.state}>{subPanel.label}</a> );
				}
			}
			subNav = <div id={subNavId} className='primary-sub-nav' style={subNavStyle}>{subNavItems}</div>;
		}
		
		const itemClassName = ( this.props.selected ) ? 'primary-nav-item selected' : 'primary-nav-item';

		return ( 
			<div ref={this.element}>
				<a 
					href={panel.path} 
					className={itemClassName} 
					onClick={clickHandler} 
					data-panel-state={panel.state}>
						<span className='label'>{panel.label}</span>
						<span className='icon'>{this.props.icon}</span>
				</a> 
				{subNav}
			</div>
		);

	}

	/**
	 * Toggles the open and close state of the panel
	 */
	hasSubNav() {
		return this.props.panel.states != null;
	}

	/**
	 * Toggles the open and close state of the panel
	 */
	toggleOpenClose() {
		this.open = !this.open;
		//const element = document.getElementById( 'nav-item-' + this.props.panel.id );
	
		if( this.element.current != null ){
			const subNav = this.element.current.querySelector( '.primary-sub-nav' );
			subNav.style.display = ( this.open ) ? 'block' : 'none';
		}
	}

}

// Private

/**
 * Handles clicks on the nav item
 */
var handleClick = function( e, isSubNavItem, hasSubNavItems ) {
	this.props.clickHandler( e, this, isSubNavItem, hasSubNavItems );
}

//

export default PrimaryNavigationItem;
