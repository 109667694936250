/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';
import CloseModalCommand from '../../commands/CloseModalCommand';
import SphereAdminSession from '../../model/SphereAdminSession';

//

// Some ugly hardcoding in here but trying to move fast at this point, easy enough to clean up later

//

const plans = {
	verra_starter: {
		index: 0,
		label: 'Starter',
		blurb: 'Get started with Verra',
		price: 'Free',
		features: [
			'50k Monthly Sessions',
			'1 Site',
			'Documentation',
		],
		callouts: {
			optimizations: '10 Optimizations',
			optimizations_ab: '10 A/B Tests',
			optimizations_personalization: '10 Personalizations',
			optimizations_predictive: '0 Predictive Optimizations',
			published_optimizations: '1 Published Optimization',
			optimization_audiences: '2 Audiences per Optimization',
			optimization_metrics: '5 Metrics per Optimization',
			optimization_experiences: '3 Experiences per Optimization',
			experiences: '30 Experiences',
			experience_modifications: '10 Modifications per Experience',
			audiences: '5 Audiences',
			audience_rules: '3 Audience Rules',
			metrics: '10 Metrics',
			sites: '1 Site',
		},
	},
	verra_test: {
		index: 1,
		label: 'Test',
		blurb: 'Get started with Verra',
		price: '$109.00/mo',
		salePrice: '$98.00/mo',
		subscribeYearly: 'https://verra.ai/sign-up?verra_test_yearly',
		subscribeMonthly: 'https://verra.ai/sign-up?verra_test_monthly',
		features: [
			'150k Monthly Sessions',
			'1 Site',
			'Email Support',
		],
		callouts: {
			optimizations: 'Unlimited Optimizations',
			optimizations_ab: 'Unlimited A/B Tests',
			optimizations_personalization: 'Unlimited Personalizations',
			optimizations_predictive: '0 Predictive Optimizations',
			published_optimizations: '1 Published Optimization',
			published_optimizations: '5 Published Optimizations',
			optimization_audiences: '5 Audiences per Optimization',
			optimization_metrics: 'Unlimited Metrics per Optimization',
			optimization_experiences: '10 Experiences per Optimization',
			experiences: 'Unlimited Experiences',
			experience_modifications: '10 Modifications per Experience',
			audiences: 'Unlimited Audiences',
			audience_rules: '10 Audience Rules',
			metrics: 'Unlimited Metrics',
			sites: '1 Site',
		},
	},
	verra_grow: {
		index: 2,
		label: 'Grow',
		blurb: 'For growing businesses',
		price: '$381.00/mo',
		salePrice: '$343.00/mo',
		subscribeYearly: 'https://verra.ai/sign-up?verra_grow_yearly',
		subscribeMonthly: 'https://verra.ai/sign-up?verra_grow_monthly',
		features: [
			'350k Monthly Sessions',
			'2 Sites',
			'Phone Support',
		],
		callouts: {
			optimizations: 'Unlimited Optimizations',
			optimizations_ab: 'Unlimited A/B Tests',
			optimizations_personalization: 'Unlimited Personalizations',
			optimizations_predictive: '0 Predictive Optimizations',
			published_optimizations: '15 Published Optimizations',
			optimization_audiences: 'Unlimited Audiences per Optimization',
			optimization_experiences: 'Unlimited Experiences per Optimization',
			experiences: 'Unlimited Experiences',
			experience_modifications: '10 Modifications per Experience',
			audiences: 'Unlimited Audiences',
			audience_rules: '10 Audience Rules',
			metrics: 'Unlimited Metrics',
			sites: '2 Sites',
		},
	},
	verra_optimize: {
		index: 3,
		label: 'Optimize',
		blurb: 'Enterprise',
		price: 'Contact for Pricing',
		subscribeYearly: 'https://verra.ai/demo',
		subscribeMonthly: 'https://verra.ai/demo',
		features: [
			'Tailored Monthly Sessions',
			'Tailored Sites',
			'Dedicated Support',
		],
		callouts: {
			optimizations: 'Unlimited Optimizations',
			optimizations_ab: 'Unlimited A/B Tests',
			optimizations_personalization: 'Unlimited Personalizations',
			optimizations_predictive: 'Unlimited Predictive Optimizations',
			published_optimizations: 'Unlimited Published Optimizations',
			experiences: 'Unlimited Experiences',
			experience_modifications: 'Unlimited Modifications per Experience',
			audiences: 'Unlimited Audiences',
			audience_rules: 'Unlimited Audience Rules',
			metrics: 'Unlimited Metrics',
			sites: 'Unlimited Sites',
		},
	},
};

const planOrder = [ plans.verra_starter, plans.verra_test, plans.verra_grow, plans.verra_optimize ];

//

/**
 * Modal used to up sell the customer when they've hit a permissions limit
 */
class UpSellModal extends Component {

	/**
	'* Constructs the Component
	*/
	constructor( props ) {
		super( props );
	}
	
	/**
	* Renders the component
	* @see react docs
	*/
	render() {
		return (
			<div className='dialog'>
				<p>{ this.props.message }</p>
				<p>Upgrade your account and keep optimizing!</p>
				{ this.#getUpSellContent() }
				<div className='grid-cell default-100' style={{ padding: '20px 0 0 0', textAlign: 'center' }}>
					<div className='button control-pad-left' style={{ width: '120px' }} onClick={ this.#handleCancel.bind( this )}>Maybe Later</div>
				</div>
			</div>
		);
	}

	/**
	 * The content for the upsell
	 */
	#getUpSellContent() {
		// SphereAdminSession.selectedAccount.subscription = 'verra_test_yearly';
		const plan = SphereAdminSession.selectedAccount.subscription.replace( '_yearly', '' ).replace( '_monthly', '' );
		const currentPlan = plans[ plan ];
		
		let nextPlanIndex;
		if( plan === 'verra_starter' && this.props.callout === 'sites' ) {
			nextPlanIndex = currentPlan.index + 2;
		} else if( plan === 'verra_test' && this.props.callout === 'audience_rules' ) {
			nextPlanIndex = currentPlan.index + 2;
		} else if( this.props.callout === 'optimizations_predictive' ) {
			nextPlanIndex = 3;
		} else if( this.props.callout === 'experience_modifications' ) {
			nextPlanIndex = 3;
		} else {
			nextPlanIndex = currentPlan.index + 1;
		}
		
		const recommendedPlan = planOrder[ nextPlanIndex ];

		return (
			<div>
				<div style={{ display: 'flex', gap: 13 }}>
					{ this.#getPlanTileMarkup( currentPlan, 'current' ) }
					{ this.#getPlanTileMarkup( recommendedPlan, 'recommended' ) }
				</div>
				<div style={{ textAlign: 'center', marginTop: 10 }}>
					<a href='https://verra.ai/pricing' target='_blank' style={{ textAlign: 'center', fontSize: '0.8em', color: '#cccccc' }}>See and compare all plans</a>
				</div>
			</div>
		);
	}

	/**
	 * @return the tile markup for a plan
	 */
	#getPlanTileMarkup( plan, type ) {
		const label = ( type === 'current' ) ? 'Current Plan' : 'Recommended';
		const features = [];
		if( plan.callouts[ this.props.callout ] != null ) features.push( <li className='highlight'>{ plan.callouts[ this.props.callout ] }</li> );
		plan.features.forEach( ( feature, index ) => {
			features.push( <li key={ index }>{ feature }</li> );
		});
		return (
			<div key={ type } className={ `product-item-content ${ type }`}>
				<div className='plan-label'>{ label }</div>
				<div className='plan-details'>
					<h4>{ plan.label }</h4>
					<p>{ plan.blurb }</p>
					{ ( plan.salePrice == null || type === 'current' ) && 
						<div className='price'>
							<span className='normal'>{ plan.price }</span>
							<span>&nbsp;</span>
						</div>
					}
					{ ( plan.salePrice != null && type !== 'current' ) && 
						<div className='price'>
							<span className='strike'>{ plan.price }</span>
							<span className='sale'>{ plan.salePrice }</span>
						</div>
					}
					<p className='price-disclaimer'>&nbsp;</p>
					<ul>
						{ this.props.highlight === 'optimizations' && <li className='highlight'>Unlimited Optimizations</li> }
						{ features }
					</ul>
					{ type !== 'current' && 
						<div className="product-item-footer">
							<a href={ plan.subscribeYearly } target='_blank' className="primary-button">Subscribe</a>
							<div className='subscribe-secondary'>
								or <a href={ plan.subscribeMonthly } target='_blank' style={{ color: '#62ebff', fontWeight: '500' }}>subscribe monthly</a> at $155/mo
							</div>
						</div>
					}
				</div>
			</div>
		);
	}

	//

	/**
	 * Closes the Modal
	 */
	#handleCancel( e ) {
		const closeModal = new CloseModalCommand();
		closeModal.execute();
	}

}

export default UpSellModal;