/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

/**
 * Accordion Component
 */
class Accordion extends Component {
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		var itemsMarkup = [];

		for( var i = 0; i < this.props.items.length; i++ ){
			var item = this.props.items[ i ];
			var subItemsMarkup = [];

			for( var j = 0; j < item.items.length; j++ ){
				let subItem = item.items[ j ];
				var itemSelected = ( this.props.selectedHeaderIndex === i && this.props.selectedItemIndex === j ) ? ' selected' : '';
				var clickHandler = ( e ) => { this.#handleItemClick( e, subItem ); };
				subItemsMarkup.push( 
					<button 
						key={j}
						className={ 'accordion-list-item' + itemSelected } 
						onClick={ clickHandler }>{ subItem[ this.props.itemLabel ] }</button> 
				);
			};

			var contentDisplay = ( this.props.selectedHeaderIndex === i ) ? ' selected' : '';
			itemsMarkup.push(
				<div key={i} className='accordion-section'>
					<button className='accordion-header' onClick={ this.#handleHeaderClick.bind( this ) }>{ item[ this.props.headerLabel ] }</button>
					<div className={ 'accordion-list-content' + contentDisplay }>
						{subItemsMarkup}
					</div>
				</div>
			);
		};
		return ( 
			<div className='accordion'>
				{itemsMarkup}
			</div>
		);
	}
	
	//

	/**
	 * Handles clicks on an accoridion header
	 */
	#handleHeaderClick( e ){
		var header = e.target;
		var content = header.closest( '.accordion-section' ).querySelector( '.accordion-list-content' );
		var openContent = header.closest( '.accordion' ).querySelector( '.accordion-list-content.selected' );

		if( openContent != null ) {
			openContent.classList.remove( 'selected' );
		}

		if( content !== openContent ) {
			content.classList.add( 'selected' );
		}
	}

	/**
	 * Handles clicks on an accoridion header
	 */
	#handleItemClick( e, item ){
		if( this.props.stickyItemSelect ){
			var itemElement = e.target;
			if( this.selectedItem != null ) {
				this.selectedItem.classList.remove( 'selected' );
			}
			if( itemElement !== this.selectedItem ) {
				itemElement.classList.add( 'selected' );
				this.selectedItem = itemElement;
			} else {
				this.selectedItem = null;
			}
		}
		if( this.props.itemClickHandler != null ) this.props.itemClickHandler( item );	
	}

}

export default Accordion;
