/*
 * (c) Verra Technology Corporation
 */

import PublishableObject from "./PublishableObject.mjs";

/**
 * Represents Content
 */
class Content extends PublishableObject {

	// accountId
	// id
	// status
	// name
	// body

}

//

export default Content;