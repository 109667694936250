/*
 * (c) Verra Technology Corporation
 */

import CodeEditor from '@uiw/react-textarea-code-editor';
import React, { Component } from 'react';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import EditSiteCommand from '../../commands/EditSiteCommand';
import OpenModalCommand from '../../commands/OpenModalCommand';
import SetStateCommand from '../../commands/SetStateCommand';
import ValidatorCommand from '../../commands/ValidatorCommand';
import AdminStates from '../../model/AdminStates';
import ObjectStatusMap from '../../model/ObjectStatusMap';
import SphereAdminSession from '../../model/SphereAdminSession';
import SaveSiteRequest from '../../requests/sites/SaveSiteRequest';
import Alert from '../controls/Alert';
import Hint from '../controls/Hint';
import InputField from '../controls/InputField';

//

/**
 * Possible Site types
 */
const siteTypes = [
	{ name: 'Production', value: 1 },
	{ name: 'Development', value: 2 }
];

//

/**
 * The SiteEditor contains the UI for creating and editing Sites
 */
class SiteEditor extends Component {
	
	/**
	 * Constructs the panel.
	 */
	 constructor() {
		super();
		this.state = { invalidated: false };
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const jsx = this.#getSiteEditorMarkup();
		return jsx;
	}

	// Private

	/**
	 * Invalidates the state of the panel
	 */
	#invalidate() {
		this.setState({ invalidated: true });
	}

	// Markup

	/**
	 * @return the markup used to edit / create a Site
	 */
	#getSiteEditorMarkup(){
		// TODO: change state after save
		const isEditing = ( SphereAdminSession.currentState === AdminStates.ADMIN_CONFIGURATION_SITES_EDIT );
		const title = ( isEditing ) ? 'Edit Site' : 'Create Site';
		const saveDisabled = ( SphereAdminSession.site.status === ModifiableObject.SAVED || SphereAdminSession.site.status === ModifiableObject.LOCKED );
		const saveButtonsDisabledClass = ( saveDisabled ) ? ' disabled' : '';

		return 	<div className='content-panel'>
					<div className='grid'>
						<div className='grid-cell default-50'>
							<h2>{title}</h2>
							<div className='breadcrumb'>
								<a href='/configuration/sites/' className='breadcrumb'>Sites</a> / {SphereAdminSession.site.name}
							</div>
						</div>
						<div className='grid-cell default-50 align-right header-actions'>
							<button className={'primary-button control-pad-left' + saveButtonsDisabledClass} disabled={saveDisabled} style={{width: '90px'}} onClick={this.#handleSave.bind( this )}>Save</button>
							<button className={'button control-pad-left'} style={{width: '80px'}} onClick={ this.#handleCancel.bind( this )}>Cancel</button>
						</div>
					</div>
					{this.#getPrimaryFieldsMarkup()}
					{this.#getScondaryFieldsMarkup()}
				</div>;
	}

	/**
	 * @return The markup for displaying name and url fields
	 */
	#getPrimaryFieldsMarkup(){
		const site = SphereAdminSession.site;

		const nameToolTip = 'A user friendly name for the site';
		const urlToolTip = 'The URL of the site, for example: mysite.com.';
		// const typeToolTip = 'The type of site, production or development. This can only be changed on creation.';

		const status = ObjectStatusMap[ site.status ];
		const statusIndicatorElement = <div className={'status-indicatator ' + status}></div>;

		// let typeMarkup;
		// if( isEditing ){
		// 	typeMarkup = <InputField value={siteTypes[ site.type - 1 ].name} maxLength='256' readOnly={true}/>
		// } else {
		// 	typeMarkup = <DropDownField 
		// 		width='100%'
		// 		itemsWidth='100%' 
		// 		items={siteTypes}
		// 		labelField="name"
		// 		selectedIndex={site.type - 1}
		// 		changeHandler={( item ) => { this.#handleFieldChanged( 'type', item.value ); }}/>
		// }

		return <div className='panel-cell primary-fields'>
					<div style={{ flexBasis: 0, flexGrow: 3, position: 'relative' }}>
						{statusIndicatorElement}
						<label>Name <Hint width='250px' content={nameToolTip}/></label>
						<InputField value={site.name} maxLength='256' onChange={( value ) => { this.#handleFieldChanged( 'name', value ); }}/>
					</div>
					<div style={{ flexBasis: 0, flexGrow: 3 }}>
						<label>URL <Hint width='250px' content={urlToolTip}/></label>
						<InputField value={site.url} maxLength='256' onChange={( value ) => { this.#handleFieldChanged( 'url', value ); }}/>
					</div>
					{/* <div style={{ flexBasis: 0, flexGrow: 3 }}>
						<label>Type <Hint width='250px' content={typeToolTip} position='left'/></label>
						{typeMarkup}
					</div>  */}
				</div>;
	}

	/**
	 * @return The markup for displaying secondary fields
	 */
	#getScondaryFieldsMarkup(){
		const site = SphereAdminSession.site;
		const dataLayerToolTop = 'Specifies the name of the JavaScript data layer object on the site.';
		const clientIdToolTip = 'The unique client ID for the site.';
		const tagToolTip = 'The snippet of HTML required to enable Verra on the site.';
		const tag = `<link rel="preconnect" href="https://client-services.verra.ai" crossorigin>\n<script>window.VerraClientConfig={clientId:'${ site.clientId }'};</script>\n<script src="https://client-services.verra.ai/verra.js"></script>`;
		
		return (
			<div>
				<div className='panel-cell primary-fields' style={{ alignItems: 'stretch' }}>
					<div style={{ flexBasis: 0, flexGrow: 1 }}>
						<div>
							<label>Data Layer <Hint width='250px' content={ dataLayerToolTop }/></label>
							<InputField value={site.dataLayer} maxLength='256' onChange={( value ) => { this.#handleFieldChanged( 'dataLayer', value ); }}/>
						</div>
						{ site.clientId != null &&
							<div style={{ marginTop: 13 }}>
								<label>Client ID <Hint width='250px' content={ clientIdToolTip }/></label>
								<InputField className='monospace' readOnly={ true } value={ site.clientId } maxLength='256'/>
							</div>
						}
					</div>
					{ site.clientId != null &&
						<div style={{ display: 'flex', flexDirection: 'column', flexBasis: 0, flexGrow: 1 }}>
							<label>HTML Tag <Hint width='250px' content={ tagToolTip }/></label>
							<div style={{ flexGrow: 2, border: '1px solid #555555' }}>
								<CodeEditor
									value={ tag }
									language='html'
									data-color-mode='dark'
									padding={ 15 }
									wrap='off'
									style={{
										height: '100%',
										fontSize: 12,
										backgroundColor: '#1b1b1b',
										fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
									}}
								/>
							</div>
						</div>
					}
				</div>
			</div>
		);
	}

	// Field Handlers

	/**
	 * Handles changes to the input fields, invalidating the Channel object
	 */
	 #handleFieldChanged( field, value ) {
		SphereAdminSession.site[ field ] = value;
		SphereAdminSession.site.status = ModifiableObject.MODIFIED;
		this.#invalidate();
	};

	/**
	 * Handles a click on the save button, saves the Channel 
	 */
	#handleSave() {

		// TODO: this could all be in a command?
		// TODO: validate the rest of the Site?
		//console.info( 'EditSitePanel::handleSave', SphereAdminSession.site );

		const fields = { name: ValidatorCommand.isNotNullOrEmpty, url: ValidatorCommand.isNotNullOrEmpty };
		const validateSite = new ValidatorCommand( SphereAdminSession.site, fields );
		const isValid = validateSite.execute();
		let openModal;
		let alert;

		//console.info( 'isValid', isValid );
		//console.info( 'invalidFields', validateSite.getInvalidFields() );

		if( isValid ){
			// ensure a site with this URL does not already exist
			const sites = SphereAdminSession.sites;
			let hasUniqueUrl = true;
			for( let i = 0; i < sites.length; i++ ){
				const site = sites[ i ];
				// console.info( SphereAdminSession.site.id, SphereAdminSession.site.url, site.id, site.url );
				if( SphereAdminSession.site.url === site.url && SphereAdminSession.site.id != site.id ){
					hasUniqueUrl = false;
					break;
				}
			}

			if( hasUniqueUrl ){
				SphereAdminSession.loading = true;
				const saveSite = new SaveSiteRequest( SphereAdminSession.site );
				saveSite.execute(( command ) => { this.#handleSaveComplete( command ); });
			} else {
				alert = <Alert content='The Site cannot be saved. A site with this URL already exists.' showCancelBtn={false}/>;
				openModal = new OpenModalCommand( 'Invalid Site', alert, '500px', true );
				openModal.execute();
			}
			
		} else {
			
			const invalidFields = validateSite.getInvalidFields();
			const invalidFieldsElements = [];
	
			for( let i = 0; i < invalidFields.length; i++ ) {
				invalidFieldsElements.push( <li key={i}>{invalidFields[ i ]}</li> );
			}
	
			const content = <div>
				The Site cannot be saved. The following fields are invalid or incomplete:
				<ul>{invalidFieldsElements}</ul>
			</div>;
	
			alert = <Alert content={content} showCancelBtn={false}/>;
			openModal = new OpenModalCommand( 'Invalid Site', alert, '500px', true );
			openModal.execute();
		}
		
	}

	/**
	 * Handles completion of the save channel reques
	 */
	#handleSaveComplete( command ){
		//console.info( 'EditSitePanel::handleSaveComplete site', SphereAdminSession.site );
		SphereAdminSession.loading = false;
		if( command?.response?.success ) {
			if( SphereAdminSession.currentState === AdminStates.ADMIN_CONFIGURATION_SITES_CREATE ) {
				const editSite = new EditSiteCommand( SphereAdminSession.site.url );
				editSite.execute();
			} else {
				this.#invalidate();
			}
		}
	}

	/**
	 * Handles a click on the cancel button
	 */
	 #handleCancel() {
		const hasChanged = SphereAdminSession.site.status === ModifiableObject.MODIFIED || SphereAdminSession.site.status === ModifiableObject.CREATED;
		if( hasChanged ){
			const alert = <Alert content='You have unsaved changes, are you sure you want to exit?' okHandler={ this.#handleCancelConfirm.bind( this ) }/>;
			const openModal = new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		} else {
			var setState = new SetStateCommand( AdminStates.ADMIN_CONFIGURATION_SITES );
			setState.execute();
		}
	};

	/**
	 * Handles a confirmation to cancel changes
	 */
	#handleCancelConfirm() {
		const setState = new SetStateCommand( AdminStates.ADMIN_CONFIGURATION_SITES );
		setState.execute();
	};

}

//

export default SiteEditor;
