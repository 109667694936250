/*
 * (c) Verra Technology Corporation
 */

import CSSPropertyType from "./CSSPropertyType";

/**
 * All of the available CSS properties by group
 */
const CSSProperties = {

	'align-content': { type: CSSPropertyType.TEXT },
	'align-items': { type: CSSPropertyType.TEXT },
	'align-self': { type: CSSPropertyType.TEXT },
	'alignment-adjust': { type: CSSPropertyType.TEXT },
	'alignment-baseline': { type: CSSPropertyType.TEXT },
	'all': { type: CSSPropertyType.TEXT },
	'alt': { type: CSSPropertyType.TEXT },
	'animation': { type: CSSPropertyType.TEXT },
	'animation-delay': { type: CSSPropertyType.TEXT },
	'animation-direction': { type: CSSPropertyType.TEXT },
	'animation-duration': { type: CSSPropertyType.TEXT },
	'animation-fill-mode': { type: CSSPropertyType.TEXT },
	'animation-iteration-count': { type: CSSPropertyType.TEXT },
	'animation-name': { type: CSSPropertyType.TEXT },
	'animation-play-state': { type: CSSPropertyType.TEXT },
	'animation-timing-function': { type: CSSPropertyType.TEXT },
	'azimuth': { type: CSSPropertyType.TEXT },

	'backface-visibility': { type: CSSPropertyType.TEXT },
	'background': { type: CSSPropertyType.TEXT },
	'background-attachment': { type: CSSPropertyType.TEXT },
	'background-clip': { type: CSSPropertyType.TEXT },
	'background-color': { type: CSSPropertyType.COLOR },
	'background-image': { type: CSSPropertyType.TEXT },
	'background-origin': { type: CSSPropertyType.TEXT },
	'background-position': { type: CSSPropertyType.TEXT },
	'background-repeat': { type: CSSPropertyType.TEXT },
	'background-size': { type: CSSPropertyType.TEXT },
	'background-blend-mode': { type: CSSPropertyType.TEXT },
	'baseline-shift': { type: CSSPropertyType.TEXT },
	'bleed': { type: CSSPropertyType.TEXT },
	'bookmark-label': { type: CSSPropertyType.TEXT },
	'bookmark-level': { type: CSSPropertyType.TEXT },
	'bookmark-state': { type: CSSPropertyType.TEXT },
	'border': { type: CSSPropertyType.TEXT },
	'border-color': { type: CSSPropertyType.COLOR },
	'border-style': { type: CSSPropertyType.TEXT },
	'border-width': { type: CSSPropertyType.TEXT },
	'border-bottom': { type: CSSPropertyType.TEXT },
	'border-bottom-color': { type: CSSPropertyType.COLOR },
	'border-bottom-style': { type: CSSPropertyType.TEXT },
	'border-bottom-width': { type: CSSPropertyType.TEXT },
	'border-left': { type: CSSPropertyType.TEXT },
	'border-left-color': { type: CSSPropertyType.COLOR },
	'border-left-style': { type: CSSPropertyType.TEXT },
	'border-left-width': { type: CSSPropertyType.TEXT },
	'border-right': { type: CSSPropertyType.TEXT },
	'border-right-color': { type: CSSPropertyType.COLOR },
	'border-right-style': { type: CSSPropertyType.TEXT },
	'border-right-width': { type: CSSPropertyType.TEXT },
	'border-top': { type: CSSPropertyType.TEXT },
	'border-top-color': { type: CSSPropertyType.COLOR },
	'border-top-style': { type: CSSPropertyType.TEXT },
	'border-top-width': { type: CSSPropertyType.TEXT },
	'border-collapse': { type: CSSPropertyType.TEXT },
	'border-image': { type: CSSPropertyType.TEXT },
	'border-image-outset': { type: CSSPropertyType.TEXT },
	'border-image-repeat': { type: CSSPropertyType.TEXT },
	'border-image-slice': { type: CSSPropertyType.TEXT },
	'border-image-source': { type: CSSPropertyType.TEXT },
	'border-image-width': { type: CSSPropertyType.TEXT },
	'border-radius': { type: CSSPropertyType.TEXT },
	'border-bottom-left-radius': { type: CSSPropertyType.TEXT },
	'border-bottom-right-radius': { type: CSSPropertyType.TEXT },
	'border-top-left-radius': { type: CSSPropertyType.TEXT },
	'border-top-right-radius': { type: CSSPropertyType.TEXT },
	'border-spacing': { type: CSSPropertyType.TEXT },
	'bottom': { type: CSSPropertyType.TEXT },
	'box-decoration-break': { type: CSSPropertyType.TEXT },
	'box-shadow': { type: CSSPropertyType.TEXT },
	'box-sizing': { type: CSSPropertyType.TEXT },
	'box-snap': { type: CSSPropertyType.TEXT },
	'break-after': { type: CSSPropertyType.TEXT },
	'break-before': { type: CSSPropertyType.TEXT },
	'break-inside': { type: CSSPropertyType.TEXT },
	'buffered-rendering': { type: CSSPropertyType.TEXT },

	'caption-side': { type: CSSPropertyType.TEXT },
	'clear': { type: CSSPropertyType.TEXT },
	'clear-side': { type: CSSPropertyType.TEXT },
	'clip': { type: CSSPropertyType.TEXT },
	'clip-path': { type: CSSPropertyType.TEXT },
	'clip-rule': { type: CSSPropertyType.TEXT },
	'color': { type: CSSPropertyType.COLOR },
	'color-adjust': { type: CSSPropertyType.TEXT },
	'color-correction': { type: CSSPropertyType.TEXT },
	'color-interpolation': { type: CSSPropertyType.TEXT },
	'color-interpolation-filters': { type: CSSPropertyType.TEXT },
	'color-profile': { type: CSSPropertyType.TEXT },
	'color-rendering': { type: CSSPropertyType.TEXT },
	'column-fill': { type: CSSPropertyType.TEXT },
	'column-gap': { type: CSSPropertyType.TEXT },
	'column-rule': { type: CSSPropertyType.TEXT },
	'column-rule-color': { type: CSSPropertyType.COLOR },
	'column-rule-style': { type: CSSPropertyType.TEXT },
	'column-rule-width': { type: CSSPropertyType.TEXT },
	'column-span': { type: CSSPropertyType.TEXT },
	'columns': { type: CSSPropertyType.TEXT },
	'column-count': { type: CSSPropertyType.TEXT },
	'column-width': { type: CSSPropertyType.TEXT },
	'contain': { type: CSSPropertyType.TEXT },
	'content': { type: CSSPropertyType.TEXT },
	'counter-increment': { type: CSSPropertyType.TEXT },
	'counter-reset': { type: CSSPropertyType.TEXT },
	'counter-set': { type: CSSPropertyType.TEXT },
	'cue': { type: CSSPropertyType.TEXT },
	'cue-after': { type: CSSPropertyType.TEXT },
	'cue-before': { type: CSSPropertyType.TEXT },
	'cursor': { type: CSSPropertyType.TEXT },

	'direction': { type: CSSPropertyType.TEXT },
	'display': { 
		type: CSSPropertyType.OPTION, 
		options: [
			'block', 
			'inline', 
			'inline-block', 
			'flex', 
			'inline-flex', 
			'grid', 
			'inline-grid', 
			'flow-root', 
			'none', 
			'contents', 
			'block flex', 
			'block flow', 
			'block flow-root', 
			'block grid', 
			'inline flex', 
			'inline flow', 
			'inline flow-root', 
			'inline grid', 
			'table', 
			'table-row', 
			'list-item', 
			'inherit', 
			'initial', 
			'revert', 
			'revert-layer', 
			'unset'
		] 
	},
	'display-inside': { type: CSSPropertyType.TEXT },
	'display-outside': { type: CSSPropertyType.TEXT },
	'display-extras': { type: CSSPropertyType.TEXT },
	'display-box': { type: CSSPropertyType.TEXT },
	'dominant-baseline': { type: CSSPropertyType.TEXT },

	'elevation': { type: CSSPropertyType.TEXT },
	'empty-cells': { type: CSSPropertyType.TEXT },
	'enable-background': { type: CSSPropertyType.TEXT },

	'fill': { type: CSSPropertyType.TEXT },
	'fill-opacity': { type: CSSPropertyType.TEXT },
	'fill-rule': { type: CSSPropertyType.TEXT },
	'filter': { type: CSSPropertyType.TEXT },
	'float': { type: CSSPropertyType.TEXT },
	'float-defer-column': { type: CSSPropertyType.TEXT },
	'float-defer-page': { type: CSSPropertyType.TEXT },
	'float-offset': { type: CSSPropertyType.TEXT },
	'float-wrap': { type: CSSPropertyType.TEXT },
	'flow-into': { type: CSSPropertyType.TEXT },
	'flow-from': { type: CSSPropertyType.TEXT },
	'flex': { type: CSSPropertyType.TEXT },
	'flex-basis': { type: CSSPropertyType.TEXT },
	'flex-grow': { type: CSSPropertyType.TEXT },
	'flex-shrink': { type: CSSPropertyType.TEXT },
	'flex-flow': { type: CSSPropertyType.TEXT },
	'flex-direction': { type: CSSPropertyType.TEXT },
	'flex-wrap': { type: CSSPropertyType.TEXT },
	'flood-color': { type: CSSPropertyType.COLOR },
	'flood-opacity': { type: CSSPropertyType.TEXT },
	'font': { type: CSSPropertyType.TEXT },
	'font-family': { type: CSSPropertyType.TEXT },
	'font-size': { type: CSSPropertyType.TEXT },
	'font-stretch': { type: CSSPropertyType.TEXT },
	'font-style': { type: CSSPropertyType.TEXT },
	'font-weight': { type: CSSPropertyType.TEXT },
	'font-feature-settings': { type: CSSPropertyType.TEXT },
	'font-kerning': { type: CSSPropertyType.TEXT },
	'font-language-override': { type: CSSPropertyType.TEXT },
	'font-size-adjust': { type: CSSPropertyType.TEXT },
	'font-synthesis': { type: CSSPropertyType.TEXT },
	'font-variant': { type: CSSPropertyType.TEXT },
	'font-variant-alternates': { type: CSSPropertyType.TEXT },
	'font-variant-caps': { type: CSSPropertyType.TEXT },
	'font-variant-east-asian': { type: CSSPropertyType.TEXT },
	'font-variant-ligatures': { type: CSSPropertyType.TEXT },
	'font-variant-numeric': { type: CSSPropertyType.TEXT },
	'font-variant-position': { type: CSSPropertyType.TEXT },
	'footnote-policy': { type: CSSPropertyType.TEXT },

	'glyph-orientation-horizontal': { type: CSSPropertyType.TEXT },
	'glyph-orientation-vertical': { type: CSSPropertyType.TEXT },
	'grid': { type: CSSPropertyType.TEXT },
	'grid-auto-flow': { type: CSSPropertyType.TEXT },
	'grid-auto-columns': { type: CSSPropertyType.TEXT },
	'grid-auto-rows': { type: CSSPropertyType.TEXT },
	'grid-template': { type: CSSPropertyType.TEXT },
	'grid-template-areas': { type: CSSPropertyType.TEXT },
	'grid-template-columns': { type: CSSPropertyType.TEXT },
	'grid-template-rows': { type: CSSPropertyType.TEXT },
	'grid-area': { type: CSSPropertyType.TEXT },
	'grid-column': { type: CSSPropertyType.TEXT },
	'grid-column-start': { type: CSSPropertyType.TEXT },
	'grid-column-end': { type: CSSPropertyType.TEXT },
	'grid-row': { type: CSSPropertyType.TEXT },
	'grid-row-start': { type: CSSPropertyType.TEXT },
	'grid-row-end': { type: CSSPropertyType.TEXT },

	'hanging-punctuation': { type: CSSPropertyType.TEXT },
	'height': { type: CSSPropertyType.TEXT },
	'hyphenate-character': { type: CSSPropertyType.TEXT },
	'hyphenate-limit-chars': { type: CSSPropertyType.TEXT },
	'hyphenate-limit-last': { type: CSSPropertyType.TEXT },
	'hyphenate-limit-lines': { type: CSSPropertyType.TEXT },
	'hyphenate-limit-zone': { type: CSSPropertyType.TEXT },
	'hyphens': { type: CSSPropertyType.TEXT },

	'icon': { type: CSSPropertyType.TEXT },
	'image-orientation': { type: CSSPropertyType.TEXT },
	'image-resolution': { type: CSSPropertyType.TEXT },
	'image-rendering': { type: CSSPropertyType.TEXT },
	'ime': { type: CSSPropertyType.TEXT },
	'ime-align': { type: CSSPropertyType.TEXT },
	'ime-mode': { type: CSSPropertyType.TEXT },
	'ime-offset': { type: CSSPropertyType.TEXT },
	'ime-width': { type: CSSPropertyType.TEXT },
	'initial-letters': { type: CSSPropertyType.TEXT },
	'inline-box-align': { type: CSSPropertyType.TEXT },
	'isolation': { type: CSSPropertyType.TEXT },

	'justify-content': { type: CSSPropertyType.TEXT },
	'justify-items': { type: CSSPropertyType.TEXT },
	'justify-self': { type: CSSPropertyType.TEXT },

	'kerning': { type: CSSPropertyType.TEXT },

	'left': { type: CSSPropertyType.TEXT },
	'letter-spacing': { type: CSSPropertyType.TEXT },
	'lighting-color': { type: CSSPropertyType.COLOR },
	'line-box-contain': { type: CSSPropertyType.TEXT },
	'line-break': { type: CSSPropertyType.TEXT },
	'line-grid': { type: CSSPropertyType.TEXT },
	'line-height': { type: CSSPropertyType.TEXT },
	'line-slack': { type: CSSPropertyType.TEXT },
	'line-snap': { type: CSSPropertyType.TEXT },
	'list-style': { type: CSSPropertyType.TEXT },
	'list-style-image': { type: CSSPropertyType.TEXT },
	'list-style-position': { type: CSSPropertyType.TEXT },
	'list-style-type': { type: CSSPropertyType.TEXT },

	'margin': { type: CSSPropertyType.TEXT },
	'margin-bottom': { type: CSSPropertyType.TEXT },
	'margin-left': { type: CSSPropertyType.TEXT },
	'margin-right': { type: CSSPropertyType.TEXT },
	'margin-top': { type: CSSPropertyType.TEXT },
	'marker': { type: CSSPropertyType.TEXT },
	'marker-end': { type: CSSPropertyType.TEXT },
	'marker-mid': { type: CSSPropertyType.TEXT },
	'marker-pattern': { type: CSSPropertyType.TEXT },
	'marker-segment': { type: CSSPropertyType.TEXT },
	'marker-start': { type: CSSPropertyType.TEXT },
	'marker-knockout-left': { type: CSSPropertyType.TEXT },
	'marker-knockout-right': { type: CSSPropertyType.TEXT },
	'marker-side': { type: CSSPropertyType.TEXT },
	'marks': { type: CSSPropertyType.TEXT },
	'marquee-direction': { type: CSSPropertyType.TEXT },
	'marquee-play-count': { type: CSSPropertyType.TEXT },
	'marquee-speed': { type: CSSPropertyType.TEXT },
	'marquee-style': { type: CSSPropertyType.TEXT },
	'mask': { type: CSSPropertyType.TEXT },
	'mask-image': { type: CSSPropertyType.TEXT },
	'mask-repeat': { type: CSSPropertyType.TEXT },
	'mask-position': { type: CSSPropertyType.TEXT },
	'mask-clip': { type: CSSPropertyType.TEXT },
	'mask-origin': { type: CSSPropertyType.TEXT },
	'mask-size': { type: CSSPropertyType.TEXT },
	'mask-box': { type: CSSPropertyType.TEXT },
	'mask-box-outset': { type: CSSPropertyType.TEXT },
	'mask-box-repeat': { type: CSSPropertyType.TEXT },
	'mask-box-slice': { type: CSSPropertyType.TEXT },
	'mask-box-source': { type: CSSPropertyType.TEXT },
	'mask-box-width': { type: CSSPropertyType.TEXT },
	'mask-type': { type: CSSPropertyType.TEXT },
	'max-height': { type: CSSPropertyType.TEXT },
	'max-lines': { type: CSSPropertyType.TEXT },
	'max-width': { type: CSSPropertyType.TEXT },
	'min-height': { type: CSSPropertyType.TEXT },
	'min-width': { type: CSSPropertyType.TEXT },
	'mix-blend-mode': { type: CSSPropertyType.TEXT },

	'nav-down': { type: CSSPropertyType.TEXT },
	'nav-index': { type: CSSPropertyType.TEXT },
	'nav-left': { type: CSSPropertyType.TEXT },
	'nav-right': { type: CSSPropertyType.TEXT },
	'nav-up': { type: CSSPropertyType.TEXT },

	'object-fit': { type: CSSPropertyType.TEXT },
	'object-position': { type: CSSPropertyType.TEXT },
	'offset-after': { type: CSSPropertyType.TEXT },
	'offset-before': { type: CSSPropertyType.TEXT },
	'offset-end': { type: CSSPropertyType.TEXT },
	'offset-start': { type: CSSPropertyType.TEXT },
	'opacity': { type: CSSPropertyType.TEXT },
	'order': { type: CSSPropertyType.TEXT },
	'orphans': { type: CSSPropertyType.TEXT },
	'outline': { type: CSSPropertyType.TEXT },
	'outline-color': { type: CSSPropertyType.COLOR },
	'outline-style': { type: CSSPropertyType.TEXT },
	'outline-width': { type: CSSPropertyType.TEXT },
	'outline-offset': { type: CSSPropertyType.TEXT },
	'overflow': { type: CSSPropertyType.TEXT },
	'overflow-x': { type: CSSPropertyType.TEXT },
	'overflow-y': { type: CSSPropertyType.TEXT },
	'overflow-style': { type: CSSPropertyType.TEXT },
	'overflow-wrap': { type: CSSPropertyType.TEXT },

	'padding': { type: CSSPropertyType.TEXT },
	'padding-bottom': { type: CSSPropertyType.TEXT },
	'padding-left': { type: CSSPropertyType.TEXT },
	'padding-right': { type: CSSPropertyType.TEXT },
	'padding-top': { type: CSSPropertyType.TEXT },
	'page': { type: CSSPropertyType.TEXT },
	'page-break-after': { type: CSSPropertyType.TEXT },
	'page-break-before': { type: CSSPropertyType.TEXT },
	'page-break-inside': { type: CSSPropertyType.TEXT },
	'paint-order': { type: CSSPropertyType.TEXT },
	'pause': { type: CSSPropertyType.TEXT },
	'pause-after': { type: CSSPropertyType.TEXT },
	'pause-before': { type: CSSPropertyType.TEXT },
	'perspective': { type: CSSPropertyType.TEXT },
	'perspective-origin': { type: CSSPropertyType.TEXT },
	'pitch': { type: CSSPropertyType.TEXT },
	'pitch-range': { type: CSSPropertyType.TEXT },
	'play-during': { type: CSSPropertyType.TEXT },
	'pointer-events': { type: CSSPropertyType.TEXT },
	'position': { type: CSSPropertyType.TEXT },

	'quotes': { type: CSSPropertyType.TEXT },

	'region-fragment': { type: CSSPropertyType.TEXT },
	'resize': { type: CSSPropertyType.TEXT },
	'rest': { type: CSSPropertyType.TEXT },
	'rest-after': { type: CSSPropertyType.TEXT },
	'rest-before': { type: CSSPropertyType.TEXT },
	'richness': { type: CSSPropertyType.TEXT },
	'right': { type: CSSPropertyType.TEXT },
	'ruby-align': { type: CSSPropertyType.TEXT },
	'ruby-merge': { type: CSSPropertyType.TEXT },
	'ruby-position': { type: CSSPropertyType.TEXT },

	'scroll-behavior': { type: CSSPropertyType.TEXT },
	'scroll-snap-coordinate': { type: CSSPropertyType.TEXT },
	'scroll-snap-destination': { type: CSSPropertyType.TEXT },
	'scroll-snap-points-x': { type: CSSPropertyType.TEXT },
	'scroll-snap-points-y': { type: CSSPropertyType.TEXT },
	'scroll-snap-type': { type: CSSPropertyType.TEXT },
	'shape-image-threshold': { type: CSSPropertyType.TEXT },
	'shape-inside': { type: CSSPropertyType.TEXT },
	'shape-margin': { type: CSSPropertyType.TEXT },
	'shape-outside': { type: CSSPropertyType.TEXT },
	'shape-padding': { type: CSSPropertyType.TEXT },
	'shape-rendering': { type: CSSPropertyType.TEXT },
	'size': { type: CSSPropertyType.TEXT },
	'speak': { type: CSSPropertyType.TEXT },
	'speak-as': { type: CSSPropertyType.TEXT },
	'speak-header': { type: CSSPropertyType.TEXT },
	'speak-numeral': { type: CSSPropertyType.TEXT },
	'speak-punctuation': { type: CSSPropertyType.TEXT },
	'speech-rate': { type: CSSPropertyType.TEXT },
	'stop-color': { type: CSSPropertyType.COLOR },
	'stop-opacity': { type: CSSPropertyType.TEXT },
	'stress': { type: CSSPropertyType.TEXT },
	'string-set': { type: CSSPropertyType.TEXT },
	'stroke': { type: CSSPropertyType.TEXT },
	'stroke-dasharray': { type: CSSPropertyType.TEXT },
	'stroke-dashoffset': { type: CSSPropertyType.TEXT },
	'stroke-linecap': { type: CSSPropertyType.TEXT },
	'stroke-linejoin': { type: CSSPropertyType.TEXT },
	'stroke-miterlimit': { type: CSSPropertyType.TEXT },
	'stroke-opacity': { type: CSSPropertyType.TEXT },
	'stroke-width': { type: CSSPropertyType.TEXT },

	'tab-size': { type: CSSPropertyType.TEXT },
	'table-layout': { type: CSSPropertyType.TEXT },
	'text-align': { type: CSSPropertyType.TEXT },
	'text-align-all': { type: CSSPropertyType.TEXT },
	'text-align-last': { type: CSSPropertyType.TEXT },
	'text-anchor': { type: CSSPropertyType.TEXT },
	'text-combine-upright': { type: CSSPropertyType.TEXT },
	'text-decoration': { type: CSSPropertyType.TEXT },
	'text-decoration-color': { type: CSSPropertyType.COLOR },
	'text-decoration-line': { type: CSSPropertyType.TEXT },
	'text-decoration-style': { type: CSSPropertyType.TEXT },
	'text-decoration-skip': { type: CSSPropertyType.TEXT },
	'text-emphasis': { type: CSSPropertyType.TEXT },
	'text-emphasis-color': { type: CSSPropertyType.COLOR },
	'text-emphasis-style': { type: CSSPropertyType.TEXT },
	'text-emphasis-position': { type: CSSPropertyType.TEXT },
	'text-emphasis-skip': { type: CSSPropertyType.TEXT },
	'text-height': { type: CSSPropertyType.TEXT },
	'text-indent': { type: CSSPropertyType.TEXT },
	'text-justify': { type: CSSPropertyType.TEXT },
	'text-orientation': { type: CSSPropertyType.TEXT },
	'text-overflow': { type: CSSPropertyType.TEXT },
	'text-rendering': { type: CSSPropertyType.TEXT },
	'text-shadow': { type: CSSPropertyType.TEXT },
	'text-size-adjust': { type: CSSPropertyType.TEXT },
	'text-space-collapse': { type: CSSPropertyType.TEXT },
	'text-spacing': { type: CSSPropertyType.TEXT },
	'text-transform': { type: CSSPropertyType.TEXT },
	'text-underline-position': { type: CSSPropertyType.TEXT },
	'text-wrap': { type: CSSPropertyType.TEXT },
	'top': { type: CSSPropertyType.TEXT },
	'touch-action': { type: CSSPropertyType.TEXT },
	'transform': { type: CSSPropertyType.TEXT },
	'transform-box': { type: CSSPropertyType.TEXT },
	'transform-origin': { type: CSSPropertyType.TEXT },
	'transform-style': { type: CSSPropertyType.TEXT },
	'transition': { type: CSSPropertyType.TEXT },
	'transition-delay': { type: CSSPropertyType.TEXT },
	'transition-duration': { type: CSSPropertyType.TEXT },
	'transition-property': { type: CSSPropertyType.TEXT },
	'transition-timing-function':  { type: CSSPropertyType.TEXT },

	'unicode-bidi': { type: CSSPropertyType.TEXT },

	'vector-effect': { type: CSSPropertyType.TEXT },
	'vertical-align': { type: CSSPropertyType.TEXT },
	'visibility': { type: CSSPropertyType.TEXT },
	'voice-balance': { type: CSSPropertyType.TEXT },
	'voice-duration': { type: CSSPropertyType.TEXT },
	'voice-family': { type: CSSPropertyType.TEXT },
	'voice-pitch': { type: CSSPropertyType.TEXT },
	'voice-range': { type: CSSPropertyType.TEXT },
	'voice-rate': { type: CSSPropertyType.TEXT },
	'voice-stress': { type: CSSPropertyType.TEXT },
	'voice-volumn': { type: CSSPropertyType.TEXT },
	'volume': { type: CSSPropertyType.TEXT },

	'white-space': { type: CSSPropertyType.TEXT },
	'widows': { type: CSSPropertyType.TEXT },
	'width': { type: CSSPropertyType.TEXT },
	'will-change': { type: CSSPropertyType.TEXT },
	'word-break': { type: CSSPropertyType.TEXT },
	'word-spacing': { type: CSSPropertyType.TEXT },
	'word-wrap': { type: CSSPropertyType.TEXT },
	'wrap-flow': { type: CSSPropertyType.TEXT },
	'wrap-through': { type: CSSPropertyType.TEXT },
	'writing-mode': { type: CSSPropertyType.TEXT },

	'z-index': { type: CSSPropertyType.TEXT },

	// 	'display': {
	// 		type: CSSPropertyType.OPTION,
	// 		
	// 	},
 	// 	'position': {
	// 		type: CSSPropertyType.OPTION,
	// 		options: [
	// 			'static', 
	// 			'relative', 
	// 			'absolute', 
	// 			'fixed', 
	// 			'sticky', 
	// 			'inherit', 
	// 			'initial', 
	// 			'revert', 
	// 			'revert-layer', 
	// 			'unset'
	// 		] 
	// 	},

	// 	'top': {type: CSSPropertyType.TEXT },
	// 	'right': {type: CSSPropertyType.TEXT },
	// 	'bottom': {type: CSSPropertyType.TEXT },
	// 	'left': {type: CSSPropertyType.TEXT },
	// 	'float': {
	// 		type: CSSPropertyType.OPTION,
	// 		options: [
	// 			'left', 
	// 			'right', 
	// 			'none', 
	// 			'inline-start', 
	// 			'inline-end', 
	// 			'inherit', 
	// 			'initial', 
	// 			'revert', 
	// 			'revert-layer', 
	// 			'unset'
	// 		]
	// 	},
	// 	'clear': {
	// 		type: CSSPropertyType.TEXT,
	// 		options: [
	// 			'none', 
	// 			'left', 
	// 			'right', 
	// 			'both', 
	// 			'inline-start', 
	// 			'inline-end', 
	// 			'inherit', 
	// 			'initial', 
	// 			'revert', 
	// 			'revert-layer', 
	// 			'unset'
	// 		]
	// 	},
	// 	'z-index': {type: CSSPropertyType.TEXT },
	// 		'overflow': {
	// 		type: CSSPropertyType.OPTION,
	// 		options: [
	// 			'visible', 
	// 			'hidden', 
	// 			'clip', 
	// 			'scroll', 
	// 			'auto', 
	// 			'hidden visible', 
	// 			'inherit', 
	// 			'initial', 
	// 			'revert', 
	// 			'revert-layer', 
	// 			'unset'
	// 		] 
	// 	},
	// 	'overflow-x': {
	// 		type: CSSPropertyType.OPTION,
	// 		options: [
	// 			'visible', 
	// 			'hidden', 
	// 			'clip', 
	// 			'scroll', 
	// 			'auto', 
	// 			'hidden visible', 
	// 			'inherit', 
	// 			'initial', 
	// 			'revert', 
	// 			'revert-layer', 
	// 			'unset'
	// 		]  
	// 	},
	// 	'overflow-y': {
	// 		type: CSSPropertyType.OPTION,
	// 		options: [
	// 			'visible', 
	// 			'hidden', 
	// 			'clip', 
	// 			'scroll', 
	// 			'auto', 
	// 			'hidden visible', 
	// 			'inherit', 
	// 			'initial', 
	// 			'revert', 
	// 			'revert-layer', 
	// 			'unset'
	// 		]  
	// 	},
	// 	'resize': {
	// 		type: CSSPropertyType.OPTION,
	// 		options: [
	// 			'none', 
	// 			'both', 
	// 			'horizontal', 
	// 			'vertical', 
	// 			'block', 
	// 			'inline', 
	// 			'inherit', 
	// 			'initial', 
	// 			'revert', 
	// 			'revert-layer', 
	// 			'unset'
	// 		] 
	// 	},
	// 	'clip-path': {
	// 		type: CSSPropertyType.TEXT 
	// 	},
	// 	'visibility': {
	// 		type: CSSPropertyType.OPTION,
	// 		options: [
	// 			'visible', 
	// 			'hidden', 
	// 			'collapse',
	// 			'inherit', 
	// 			'initial', 
	// 			'revert', 
	// 			'revert-layer', 
	// 			'unset'
	// 		] 
	// 	},
	// 	'cursor': {
	// 		type: CSSPropertyType.TEXT 
	// 	},
	// 	'box-shadow': {
	// 		type: CSSPropertyType.TEXT 
	// 	},
	// 	'box-sizing': {
	// 		type: CSSPropertyType.OPTION,
	// 		options: [
	// 			'border-box', 
	// 			'content-box', 
	// 			'inherit', 
	// 			'initial', 
	// 			'revert', 
	// 			'revert-layer', 
	// 			'unset'
	// 		] 
	// 	},

	// 	'height': {type: CSSPropertyType.TEXT },
	// 	'max-height': {type: CSSPropertyType.TEXT },
	// 	'max-width': {type: CSSPropertyType.TEXT },
	// 	'min-height': {type: CSSPropertyType.TEXT },
	// 	'min-width': {type: CSSPropertyType.TEXT },
	// 	'width': {type: CSSPropertyType.TEXT }
	// },


	// { 
	// 	label: 'Margin & Padding',
	// 	properties: [
	// 		'margin': {type: CSSPropertyType.TEXT },
	// 		'margin-bottom': {type: CSSPropertyType.TEXT },
	// 		'margin-left': {type: CSSPropertyType.TEXT },
	// 		'margin-right': {type: CSSPropertyType.TEXT },
	// 		'margin-top': {type: CSSPropertyType.TEXT },
	// 		'padding': {type: CSSPropertyType.TEXT },
	// 		'padding-bottom': {type: CSSPropertyType.TEXT },
	// 		'padding-left': {type: CSSPropertyType.TEXT },
	// 		'padding-right': {type: CSSPropertyType.TEXT },
	// 		'padding-top': {type: CSSPropertyType.TEXT }
	// 	]
	// },

	
	// { 
	// 	label: 'Background',
	// 	properties: [
	// 		'background': {type: CSSPropertyType.TEXT },
	// 		{ label: 'background-attachment' , type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'background-clip': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'border-box', 
	// 				'padding-box', 
	// 				'content-box', 
	// 				'text', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			] 
	// 		},
	// 		'background-color': {type: CSSPropertyType.COLOR },
	// 		'background-image': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'background-origin': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'border-box', 
	// 				'padding-box', 
	// 				'content-box', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			] 
	// 		},
	// 		'background-position': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'background-repeat': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'repeat', 
	// 				'repeat-x', 
	// 				'repeat-y', 
	// 				'space', 
	// 				'round', 
	// 				'no-repeat', 
	// 				'repeat space', 
	// 				'repeat repeat', 
	// 				'round space', 
	// 				'no-repeat round', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			]
	// 		},
	// 		'background-size': {type: CSSPropertyType.TEXT }
	// 	]
	// },

	// { 
	// 	label: 'Border',
	// 	properties: [
	// 		'border': {type: CSSPropertyType.TEXT },
	// 		'border-bottom': {type: CSSPropertyType.TEXT },
	// 		'border-bottom-color': {type: CSSPropertyType.COLOR },
	// 		'border-bottom-left-radius': {type: CSSPropertyType.TEXT },
	// 		'border-bottom-right-radius': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'border-bottom-style': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'none', 
	// 				'hidden', 
	// 				'dotted', 
	// 				'dashed', 
	// 				'solid', 
	// 				'double', 
	// 				'groove', 
	// 				'ridge', 
	// 				'inset', 
	// 				'outset', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			] 
	// 		},
	// 		'border-bottom-width': {type: CSSPropertyType.TEXT },
	// 		'border-color': {type: CSSPropertyType.COLOR },
	// 		'border-image': {type: CSSPropertyType.TEXT },
	// 		'border-image-outset': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'border-image-repeat': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'stretch', 
	// 				'repeat', 
	// 				'round', 
	// 				'space', 
	// 				'round stretch', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			] 
	// 		},
	// 		'border-image-slice': {type: CSSPropertyType.TEXT },
	// 		'border-image-source': {type: CSSPropertyType.TEXT },
	// 		'border-image-width': {type: CSSPropertyType.TEXT },
	// 		'border-left': {type: CSSPropertyType.TEXT },
	// 		'border-left-color': {type: CSSPropertyType.COLOR },
	// 		{ 
	// 			'border-left-style': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'none', 
	// 				'hidden', 
	// 				'dotted', 
	// 				'dashed', 
	// 				'solid', 
	// 				'double', 
	// 				'groove', 
	// 				'ridge', 
	// 				'inset', 
	// 				'outset', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			]  
	// 		},
	// 		'border-left-width': {type: CSSPropertyType.TEXT },
	// 		'border-radius': {type: CSSPropertyType.TEXT },
	// 		'border-right': {type: CSSPropertyType.TEXT },
	// 		'border-right-color': {type: CSSPropertyType.COLOR },
	// 		{ 
	// 			'border-right-style': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'none', 
	// 				'hidden', 
	// 				'dotted', 
	// 				'dashed', 
	// 				'solid', 
	// 				'double', 
	// 				'groove', 
	// 				'ridge', 
	// 				'inset', 
	// 				'outset', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			]  
	// 		},
	// 		'border-right-width': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'border-style': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'none', 
	// 				'hidden', 
	// 				'dotted', 
	// 				'dashed', 
	// 				'solid', 
	// 				'double', 
	// 				'groove', 
	// 				'ridge', 
	// 				'inset', 
	// 				'outset', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			]  
	// 		},
	// 		'border-top': {type: CSSPropertyType.TEXT },
	// 		'border-top-color': {type: CSSPropertyType.COLOR },
	// 		'border-top-left-radius': {type: CSSPropertyType.TEXT },
	// 		'border-top-right-radius': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'border-top-style': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'none', 
	// 				'hidden', 
	// 				'dotted', 
	// 				'dashed', 
	// 				'solid', 
	// 				'double', 
	// 				'groove', 
	// 				'ridge', 
	// 				'inset', 
	// 				'outset', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			]  
	// 		},
	// 		'border-top-width': {type: CSSPropertyType.TEXT },
	// 		'border-width': {type: CSSPropertyType.TEXT },
	// 		'outline': {type: CSSPropertyType.TEXT },
	// 		'outline-color': {type: CSSPropertyType.COLOR },
	// 		'outline-offset': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'outline-style': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'none', 
	// 				'hidden', 
	// 				'dotted', 
	// 				'dashed', 
	// 				'solid', 
	// 				'double', 
	// 				'groove', 
	// 				'ridge', 
	// 				'inset', 
	// 				'outset', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			] 
	// 		},
	// 		'outline-width': {type: CSSPropertyType.TEXT }
	// 	]
	// },

	// { 
	// 	label: 'Text',
	// 	properties: [
	// 		'color': {type: CSSPropertyType.COLOR },
	// 		'font': {type: CSSPropertyType.TEXT },
	// 		'font-family': {type: CSSPropertyType.TEXT },
	// 		'font-size': {type: CSSPropertyType.TEXT },
	// 		'font-size-adjust': {type: CSSPropertyType.TEXT },
	// 		'font-stretch': {type: CSSPropertyType.TEXT },
	// 		'font-style': {type: CSSPropertyType.TEXT },
	// 		'font-variant': {type: CSSPropertyType.TEXT },
	// 		'font-weight': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'direction': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'ltr', 
	// 				'rtl',  
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			] 
	// 		},
	// 		'tab-size': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'text-align': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'start', 
	// 				'end', 
	// 				'left', 
	// 				'right', 
	// 				'center', 
	// 				'justify', 
	// 				'justify-all', 
	// 				'match-parent',  
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			] 
	// 		},
	// 		{ 
	// 			'text-align-last': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'auto',
	// 				'start', 
	// 				'end', 
	// 				'left', 
	// 				'right', 
	// 				'center', 
	// 				'justify',  
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			]
	// 		},
	// 		'text-decoration': {type: CSSPropertyType.TEXT },
	// 		'text-decoration-color': {type: CSSPropertyType.COLOR },
	// 		'text-decoration-line': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'text-decoration-style': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'none', 
	// 				'solid', 
	// 				'double', 
	// 				'dotted', 
	// 				'dashed', 
	// 				'wavy', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			]
	// 		},
	// 		'text-indent': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'text-justify': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'none', 
	// 				'auto', 
	// 				'inter-word', 
	// 				'inter-character', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			]
	// 		},
	// 		'text-overflow': {type: CSSPropertyType.TEXT },
	// 		'text-shadow': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'text-transform': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'none', 
	// 				'capitalize', 
	// 				'uppercase', 
	// 				'lowercase', 
	// 				'full-width', 
	// 				'full-size-kana',
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			] 
	// 		},
	// 		'line-height': {type: CSSPropertyType.TEXT },
	// 		'vertical-align': {type: CSSPropertyType.TEXT },
	// 		'letter-spacing': {type: CSSPropertyType.TEXT },
	// 		'word-spacing': {type: CSSPropertyType.TEXT },
	// 		'white-space': {type: CSSPropertyType.TEXT },
	// 		{ 
	// 			'word-break': {
	// 			type: CSSPropertyType.OPTION,
	// 			options: [
	// 				'normal', 
	// 				'break-all', 
	// 				'keep-all', 
	// 				'inherit', 
	// 				'initial', 
	// 				'revert', 
	// 				'revert-layer', 
	// 				'unset'
	// 			] 
	// 		},
	// 		'word-wrap': {type: CSSPropertyType.TEXT }
	// 	]
	// },


	// { 
	// 	label: 'Other',
	// 	properties: [

	// 	]
	// },

};

export default CSSProperties;