/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';
import AdminStates from '../../model/AdminStates';
import SetStateCommand from '../../commands/SetStateCommand';

 /**
  * Checks the user's authentication state
  */
 class VerifyAuthenticationRequest extends ServiceRequestCommand {

	/**
	 * Constructs the request
	 * @param updateState Determines if the request should update the application state upon completion. 
	 * The default is true but this can be overridden to allow the request to run independently
	 */
	constructor( updateState ){
		super();

		// check for the token in the URL, present when in site mode
		const params = new URLSearchParams( window.location.search );
		const siteMode = params.get( 'verra-id' );
		if( siteMode != null ) {
			SphereAdminSession.token = siteMode;
		}

		// console.info( 'VerifyAuthenticationRequest', SphereAdminSession.token );

		this.updateState = ( updateState === null || updateState === undefined ) ? true : updateState;
	}

	/**
	 * @return Whether or not the request requires an authenticated session. Default is true
	 */
	requiresAuth(){
		return true; // force the send of the bearer token
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
	getService(){
		return SphereAdminServices.VERIFY_AUTH;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams(){
		return { token: SphereAdminSession.token };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete(){
		// console.info( 'VerifyAuthenticationRequest::handleComplete', this.getResponse() );
		if( this.updateState ){
			var response = this.getResponse();
			var setState;
			if( response.success ) {
				setState = new SetStateCommand( AdminStates.AUTHENTICATED );
			} else {
				setState = new SetStateCommand( AdminStates.NOT_AUTHENTICATED );
			}
			setState.execute();
		}
		// else what?
	}
 
 }

 
 export default VerifyAuthenticationRequest;
 