/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';
import CloseModalCommand from '../../commands/CloseModalCommand';
import PositionModalCommand from '../../commands/PositionModalCommand';
import ContentSearch from '../ContentSearch';

//

const contentHeaders = [ 
	{label: 'ID', field: 'id', width: '35'}, 
	{label: 'Name', field: 'name', width: '35'}//, 
	//{label: 'Last Modified', field: 'attributes.date-modified', width: '15'}
];

//

/**
 * Modal used to search for and select content
 */
class ContentSearchModal extends Component {

	/**
	 * Constructs the Component
	 */
	constructor( props ) {
		super( props );
		this.selectedContent = [];
		this.state = {
			content: null,
			dataGridMaxBodyHeight: ( window.innerHeight - 300 ) + 'px'
		};

		this.contentSearch = React.createRef();

		window.addEventListener( 'resize', e => { 
			this.setState({ dataGridMaxBodyHeight: ( window.innerHeight - 300 ) + 'px' });
		});
	}

	/**
	 * Component mount
	 */
	componentDidMount() {
		this.#positionContent();
	}

	/**
	 * 
	 */
	componentDidUpdate() {
		this.#positionContent();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render(){
		const addBtn = ( !this.props.singleSelect ) ?  <div className='primary-button' style={{width: '90px'}} onClick={this.#handleAdd.bind( this )}>Add</div> : '';
		const rowClickHandler = ( this.props.singleSelect ) ? this.#handleContentRowClick.bind( this ) : null;
		return ( 
			<div className='dialog'>
				<ContentSearch 
					ref={this.contentSearch}
					showControls={false} 
					retrieveOnLoad={false} 
					singleSelect={this.props.singleSelect}
					maxGridHeight={this.state.dataGridMaxBodyHeight} 
					clickHandler={rowClickHandler} 
					componentUpdateHandler={this.componentDidUpdate.bind( this )}/>
				<div className='grid-cell default-100' style={{ padding: '20px 0 0 0', textAlign: 'center' }}>
					{addBtn}
					<div className='button control-pad-left' style={{width: '90px'}} onClick={this.#handleCancelEdit.bind( this )}>Cancel</div>
				</div>
			</div>
		);
	}

	//

	/**
	 * Vertically centers the content 
	 */
	#positionContent( e ){
		var positionModal = new PositionModalCommand();
		positionModal.execute();
	}

	// Handlers

	/**
	 * Handles a click on the add button
	 */
	#handleAdd( e ) {
		if( this.props.addHandler != null ) this.props.addHandler( this.contentSearch.current.getChecked() );
		const closeModal = new CloseModalCommand();
		closeModal.execute();
	};

	/**
	 * Handles a click on a row in the data grid
	 */
	#handleContentRowClick( content ) {
		if( this.props.addHandler != null ) this.props.addHandler( content );
		const closeModal = new CloseModalCommand();
		closeModal.execute();
	};

	/**
	 * Closes the modal
	 */
	#handleCancelEdit( e ) {
		const closeModal = new CloseModalCommand();
		closeModal.execute();
	};

}

//

export default ContentSearchModal;
