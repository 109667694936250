/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ExperienceEditor from '../components/editors/ExperienceEditor';
import SphereAdminSession from '../model/SphereAdminSession';
import RetrieveExperienceRequest from '../requests//experiences/RetrieveExperienceRequest';

//

/**
 * The EditExperiencePanel contains the administration UI for editing an Experience
 */
class EditExperiencePanel extends Component {

	/**
	 * Keeps track of the audience ID from the URL
	 */
	#experienceId;

	//
	
	/**
	 * Constructs the Component.
	 */
	constructor() {
		super();

		this.#experienceId = SphereAdminSession.stateParameters.experienceId;

		// console.info( 'on external site', ( window.location !== window.parent.location ));
		// console.info( 'this.#experienceId', this.#experienceId );

		// We are still on the admin site and have not been redirected to the chosen site for the Experience
		if( window.location === window.parent.location ) {
			// Check to see if the experienceId in the URL matches the Experience in the session. If it doesn't, 
			// and there are Experiences in the session, search the Experiences for a matching ID
			if( this.#experienceId !== SphereAdminSession.experience?.id && SphereAdminSession.experiences != null ) {
				// console.info( 'look for experience in session' );
				// reset the Experience in the session and go try to find a match
				SphereAdminSession.experience = null;
				this.#lookForExperienceInSession();
			} else { // if( SphereAdminSession.experience == null && SphereAdminSession.experiences == null || SphereAdminSession.experiences.length === 0 ) {
				// console.info( 'load experience' );
				// If there are no experiences in the session we have load the experience in order to get the 
				// siteId to be able to redirect to the site
				SphereAdminSession.experience = null;
				this.#retrieveExperience();
			} 

			// If this is an Experience that's been selected from the ExperiencesPanel, the experienceId and the 
			// url params will be null, the Experience will have a siteId. In this case we can go ahead
			// and redirect the user to the site associated with the Experience
			if( SphereAdminSession.experience != null ) {
				// console.info( 'SphereAdminSession.experience, navigate to site' );
				this.#redirectToSite();
			}
		} 
		// We are now on the site (different domain) that is assigned to the Experience. In this case, the url params
		// will have an experienceId and we'll use that to figure out where to get the Experience model object.
		else {
			// console.info( 'now on site', this.#experienceId );
			// Ensure that there is an experienceId coming from the url
			if( this.#experienceId != null ) {
				// first, let's try to pull the experience from storage

				// Check to see if there is an Experience model object in session storage. This will exist when the user
				// has loaded the site and then navigates to another page on the site while editing.
				const experienceData = window.sessionStorage.getItem( 'verra-site-mode-experience' );
				if( experienceData != null ) {
					// console.info( 'experience found in storage' );
					const experienceInStorage = JSON.parse( experienceData );
					// Ensure the Experience in storage matches the ID in the params
					if( experienceInStorage.id === this.#experienceId ) {
						// console.info( 'experience id in url matches storage' );
						// console.info( experienceInStorage );
						SphereAdminSession.experience = experienceInStorage;
					} else {
						// console.info( 'experience in storage doesnt match' );
						this.#retrieveExperience();
					}
				} 
				// we didn't find the experience in storage, let's go get it
				else {
					// console.info( 'experience not in storage retrieve experience' );
					this.#retrieveExperience();
				}
			}
		}

		this.state = { noResult: false };
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		let markup = ( window.location !== window.parent.location && ( SphereAdminSession.experience !== null || this.state.noResult )) ? <ExperienceEditor/> : '';
		return markup;
	}

	//

	/**
	 * Retrieves the experience 
	 */
	#retrieveExperience() {
		// console.info( 'retrieveExperience' );
		SphereAdminSession.loading = true;
		const retrieveExperience = new RetrieveExperienceRequest( this.#experienceId );
		retrieveExperience.execute(( command ) => { this.#handleExperienceRetrieved( command ); } );
	}

	/**
	 * Redirects the user to the site assigned to the Experience, loading Verra in site mode
	 */
	#redirectToSite() {
		const sites = SphereAdminSession.sites;
		const selectedSite = sites.find( site => site.id.toString() === SphereAdminSession.experience.siteId.toString() );
		const url = `https://${ selectedSite.url }/?verra-edit-mode=edit&verra-site-id=${ selectedSite.id }&verra-experience-id=${ this.#experienceId }&verra-id=${ SphereAdminSession.token }`;
		// console.info( 'redirectToSite', url );
		window.location.href = url;
	}

	/**
	 * Takes the Experience ID from the URL and tries to find the Experience in the array of Experiences in the session
	 */
	#lookForExperienceInSession() {
		const experience = SphereAdminSession.experiences.find(( experience ) => {
			return experience.id === this.#experienceId;
		});
		if( experience != null ) {
			// console.info( 'experience found in session' );
			SphereAdminSession.experience = experience;
		} else {
			// we need to load the experience
			// console.info( 'experience not found in session, load experience' );
			const retrieveExperience = new RetrieveExperienceRequest( this.#experienceId );
			retrieveExperience.execute(( command ) => { this.#handleExperienceRetrieved( command ); } );
		}
	}

	//

	/**
	 * Handles the response from the request to retrieve Channel
	 */
	#handleExperienceRetrieved( command ) {
		// console.info( 'handleExperienceRetrieved', SphereAdminSession.experience );
		SphereAdminSession.loading = false;
		if( SphereAdminSession.experience != null ) {
			if( window.location === window.parent.location ) {
				this.#redirectToSite();
			} else {
				// console.info( 'load editor after retrieve', SphereAdminSession.experience );
				this.setState({ noResult: SphereAdminSession.experience == null });
			}
		}
	}

}

//

export default EditExperiencePanel;
