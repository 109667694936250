/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import AudienceEditor from '../components/editors/AudienceEditor';
import SphereAdminSession from '../model/SphereAdminSession';
import RetrieveAudiencesListRequest from '../requests//audiences/RetrieveAudiencesListRequest';

//

/**
 * The EditAudiencePanel contains the administration UI for editing an Audience
 */
class EditAudiencePanel extends Component {

	/**
	 * Keeps track of the audience ID from the URL
	 */
	#audienceId;

	//
	
	/**
	 * Constructs the Component.
	 */
	constructor() {
		super();

		this.#audienceId = SphereAdminSession.stateParameters.audienceId;

		// make sure we've got a matching audience in the session
		if( SphereAdminSession.audience?.id != this.#audienceId && SphereAdminSession.audiences != null && SphereAdminSession.audiences.length > 0 ){
			this.#assignAudienceToSession();
		} else if( SphereAdminSession.audience == null && SphereAdminSession.audiences == null || SphereAdminSession.audiences.length === 0 ){
			// pull down the Audiences, we don't have them yet
			SphereAdminSession.loading = true;
			const retrieveAudiences = new RetrieveAudiencesListRequest();
			retrieveAudiences.execute(( command ) => { this.#handleAudiencesRetrieved( command ); } );
		}
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const jsx = ( SphereAdminSession.audience !== null ) ? <AudienceEditor modal={ false }/> : '';
		return jsx;
	}

	//

	/**
	 * Handles the response from the request to retrieve Channel
	 */
	#handleAudiencesRetrieved( command ) {
		this.#assignAudienceToSession();
		SphereAdminSession.loading = false;
		this.setState({}); // redraw
	};

	/**
	 * Takes the Audience ID from the URL, finds the Audience in the array of Audiences, assigns it to the session
	 */
	#assignAudienceToSession() {
		const audience = SphereAdminSession.audiences.find(( audience ) => {
			return audience.id === this.#audienceId;
		});
		SphereAdminSession.audience = audience;
	}

}

//

export default EditAudiencePanel;
