/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';
import Optimization from '../../../model/Optimization';
import ObjectUtil from '../../../util/ObjectUtil.mjs';

/**
 * Retrieves an Optimization by ID
 */
class RetrieveOptimizationRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param optimizationId The ID of the Optimization
	 */
	constructor( optimizationId ) {
		super();
		this.optimizationId = optimizationId;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getService() {
		return SphereAdminServices.RETRIEVE_OPTIMIZATION + this.optimizationId;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		SphereAdminSession.optimization = null;
		const response = this.getResponse();
		// console.info( 'RetrieveOptimizationRequest::handleComplete', response );
		if( response.success && response.result != null ) {
			SphereAdminSession.optimization = ObjectUtil.createFromDefinition( response.result, Optimization.definition ); ;
		}
	}
 
}

export default RetrieveOptimizationRequest;
