/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import SphereAdminSession from '../model/SphereAdminSession';
import DropDownField from '../components/controls/DropDownField';
import InputField from '../components/controls/InputField';
import Hint from '../components/controls/Hint';
import PencilIcon from '../icons/PencilIcon';
import UploadIcon from '../icons/UploadIcon';
import TransferContentModal from '../components/modals/TransferContentModal';
import OpenModalCommand from '../commands/OpenModalCommand';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import CodeEditor from '@uiw/react-textarea-code-editor';
import ContentAccordion from '../components/controls/ContentAccordion';
import CodeIcon from '../icons/CodeIcon';
import CssIcon from '../icons/CssIcon';
import JavaScriptIcon from '../icons/JavaScriptIcon';
import HtmlIcon from '../icons/HtmlIcon';
import NotesIcon from '../icons/NotesIcon';
import MoveIcon from '../icons/MoveIcon';
import AddIcon from '../icons/AddIcon';
import RemoveIcon from '../icons/RemoveIcon';

console.info( new Date( 1690834941958 ));

//

/**
 * Provides UI for importing content
 * TODO: currently this only supports browsing the site and selecting content to import
 * TODO: duplicate content functionality
 */
class ImportContentPanel extends Component {

	/**
	 * Constructs the ContentPanel.
	 */
	constructor(){
		super();

		this.sitesDropDownRef = React.createRef();
		this.editorContainerRef = React.createRef();
		this.iFrameContainerRef = React.createRef();
		this.iFrameRef = React.createRef();
		this.codeEditorRef = React.createRef();

		this.upListener = this.#stopEditorResize.bind( this );
		this.moveListener = this.#resizeEditor.bind( this );

		this.state = { 
			selectedSite: null, 
			path: '', 
			selectedElement: null,
			editing: false,
			editorContent: '',
			editorHeight: 200
		};

		if( window.location !== window.parent.location ){
			window.addEventListener( 'message', this.#handleWindowMessage.bind( this ));
			window.parent.postMessage({ action: 'GET_SITE' }, '*' );
		}

		window.addEventListener( 'resize', this.#handleResize.bind( this ) );
		this.componentDidUpdate(); // force a resize
	}

	/**
	 * Handles the mounting of the component
	 */
	componentDidUpdate(){
		setTimeout( () => { this.#handleResize(); }, 200 );
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render(){

		const controls = [
			{
				label: 'Styles',
				content: <div>content styes</div>
			},
			{
				label: 'HTML',
				content: <div>content html</div>
			}
		];

		const editor = ( this.state.editing ) ? this.#getEditorMarkup() : null;
		const markup = <div className='content-panel experience-editor'>
			{this.#getHeaderMarkup()}
			{this.#getPrimaryFieldsMarkup()}
			{this.#getControlsMarkup()}
			{editor}
			{ this.state.editing && <hr className='resize-handle no-select' onMouseDown={ this.#startEditorResize.bind( this )}/> }
			<div style={{ display: 'flex', alignItems: 'stretch' }}>
				<div className='pad-cell-bottom' style={{ width: '300px' }}>
					<div className='panel-cell' style={{ height: '100%', padding: 0 }}>
						<ContentAccordion 
							headerLabel='label' 
							itemLabel='name' 
							itemsProp='items' 
							stickyItemSelect={true} 
							items={controls} 
							// selectedHeaderIndex={selectedHeader}
							// selectedItemIndex={selectedItem}
							// itemClickHandler={ this.#handleTypeSelected.bind( this ) }
							/>
					</div>
				</div>
				<div className='pad-cell-left' style={{ flexGrow: 2 }}>
					{this.#getFrameMarkup()}
				</div>
			</div>
		</div>;
		return markup;
	}

	// Markup

	/**
	 * Gets the markup for the header section
	 */
	#getHeaderMarkup(){
		return 	<div className='grid'>
					<div className='grid-cell default-50'>
						<h2>Import Content</h2>
						<div className='breadcrumb'>
						<a href='/content/'>Content</a> / Import
						</div>
					</div>
					<div className='grid-cell default-50 align-right header-actions'>
					</div>
				</div>;
	}

	/**
	 * Gets the primary fields markup
	 */
	#getPrimaryFieldsMarkup(){
		const sites = SphereAdminSession.sites; 
		const url = ( this.state.selectedSite != null ) ? this.state.selectedSite.url : '';
		const siteTooltip = 'The site to import content from';
		const urlToolTip = 'The URL of the site';
		const pathToolTip = 'The path to the page to import content from';
		const controlsDisabled = ''; //( selectedElement != null ) ? '' : ' disabled';

		return 	<div>
					<div className='grid panel-cell primary-fields'>
						<div className='grid-cell default-20'>
							<label>Site <Hint width='250px' content={siteTooltip}/></label>
							<DropDownField 
								ref={this.sitesDropDownRef}
								itemsWidth='100%'
								labelField='name' 
								items={sites}
								selectedItem={this.state.selectedSite}
								changeHandler={this.#handleSiteSelected.bind( this )}/>
						</div>
						<div className='grid-cell default-20 pad-cell-left'>
							<label>URL <Hint width='250px' content={urlToolTip}/></label>
							<InputField 
								value={url} 
								readOnly={true}/>
						</div>
						<div className='grid-cell default-20 pad-cell-left'>
							<label>Path <Hint width='250px' content={pathToolTip}/></label>
							<InputField 
								value={this.state.path} 
								maxLength='256' 
								onChange={( value ) => {  }}/>
						</div>
						<div className='grid-cell default-10 pad-cell-left'>
							<label>&nbsp;</label> {/* yikes ugly */}
							<button className={'button'} onClick={ this.#handleNavigateToPage.bind( this )}>Go</button>
						</div>
						<div className='grid-cell-right'>
							<label>&nbsp;</label> {/* yikes ugly */}
							<button className={'button icon-button' + controlsDisabled} onClick={this.#handleToggleEditor.bind( this )}><PencilIcon/></button>
							<button className={'button icon-button control-pad-left' + controlsDisabled} onClick={this.#handleImportContent.bind( this )}><UploadIcon/></button>
						</div>
					</div>
				</div>;
	}

	/**
	 * The markup for the experience controls
	 */
	#getControlsMarkup(){
		const elementSelected = ( this.state.selectedElement != null );
		const elementSelectedDisabled = ''; //( elementSelected ) ? '' : 'disabled';
		const prevDisabled = ( this.state.selectedElement != null && this.state.selectedElement.previousElementSibling != null ) ? '' : 'disabled';
		const nextDisabled = ( this.state.selectedElement != null && this.state.selectedElement.nextElementSibling != null ) ? '' : 'disabled';
		const upDisabled = ( this.state.selectedElement != null && this.state.selectedElement.parentElement != null ) ? '' : 'disabled';
		const downDisabled = ( this.state.selectedElement != null && this.state.selectedElement.firstElementChild != null ) ? '' : 'disabled';

		return <div className='panel-cell'>
			<button 
				className={'control-button ' + prevDisabled} 
				onClick={ e => this.#handleElementNavigation( this.state.selectedElement.previousElementSibling )}>
					<ArrowLeftIcon size='26' color='#ffffff'/>
			</button>
			<button 
				className={'control-button control-pad-left ' + nextDisabled}
				onClick={ e => this.#handleElementNavigation( this.state.selectedElement.nextElementSibling )}>
					<ArrowRightIcon size='26' color='#ffffff'/>
			</button>
			<button 
				className={'control-button control-pad-left ' + upDisabled}
				onClick={ e => this.#handleElementNavigation( this.state.selectedElement.parentElement )}>
					<ArrowUpIcon size='26' color='#ffffff'/>
			</button>
			<button 
				className={'control-button control-pad-left ' + downDisabled}
				onClick={ e => this.#handleElementNavigation( this.state.selectedElement.firstElementChild )}>
					<ArrowDownIcon size='26' color='#ffffff'/>
			</button>
			
			<span className='control-pad-left'></span>

			<button 
				className={'control-button control-pad-left ' + elementSelectedDisabled}
				onClick={ e => this.#handleElementNavigation( this.state.selectedElement.firstElementChild )}>
					<NotesIcon size='26' color='#ffffff'/>
			</button>
			<button 
				className={'control-button control-pad-left ' + elementSelectedDisabled}
				onClick={ e => this.#handleElementNavigation( this.state.selectedElement.firstElementChild )}>
					<CssIcon size='26' color='#ffffff'/>
			</button>
			<button 
				className={'control-button control-pad-left ' + elementSelectedDisabled}
				onClick={ e => this.#handleElementNavigation( this.state.selectedElement.firstElementChild )}>
					<CodeIcon size='26' color='#ffffff'/>
			</button>

			<span className='control-pad-left'></span>

			<button 
				className={'control-button control-pad-left ' + elementSelectedDisabled}
				onClick={ e => this.#handleElementNavigation( this.state.selectedElement.firstElementChild )}>
					<MoveIcon size='26' color='#ffffff'/>
			</button>
			<button 
				className={'control-button control-pad-left ' + elementSelectedDisabled}
				onClick={ e => this.#handleElementNavigation( this.state.selectedElement.firstElementChild )}>
					<AddIcon size='26' color='#ffffff'/>
			</button>
			<button 
				className={'control-button control-pad-left ' + elementSelectedDisabled}
				onClick={ e => this.#handleElementNavigation( this.state.selectedElement.firstElementChild )}>
					<RemoveIcon size='26' color='#ffffff'/>
			</button>
			
			<span className='control-pad-left'></span>

			<button 
				className={'control-button control-pad-left ' + elementSelectedDisabled}
				onClick={ e => this.#handleElementNavigation( this.state.selectedElement.firstElementChild )}>
					<JavaScriptIcon size='26' color='#ffffff'/>
			</button>
			<button 
				className={'control-button control-pad-left ' + elementSelectedDisabled}
				onClick={ e => this.#handleElementNavigation( this.state.selectedElement.firstElementChild )}>
					<HtmlIcon size='26' color='#ffffff'/>
			</button>
		</div>;
	}

	/**
	 * @return The markup for displaying secondary fields
	 */
	#getEditorMarkup(){
		const prevDisabled = ( this.state.selectedElement != null && this.state.selectedElement.previousElementSibling != null ) ? '' : 'disabled';
		const nextDisabled = ( this.state.selectedElement != null && this.state.selectedElement.nextElementSibling != null ) ? '' : 'disabled';
		const upDisabled = ( this.state.selectedElement != null && this.state.selectedElement.parentElement != null ) ? '' : 'disabled';
		const downDisabled = ( this.state.selectedElement != null && this.state.selectedElement.firstElementChild != null ) ? '' : 'disabled';
		return 	<div ref={this.editorContainerRef} className='grid panel-cell no-select'>
					<div className='grid-cell default-100 control-pad-bottom'>
						<button 
							className={'button icon-button ' + prevDisabled} 
							onClick={ e => this.#handleElementNavigation( this.state.selectedElement.previousElementSibling )}>
								<ArrowLeftIcon size='18'/>
						</button>
						<button 
							className={'button icon-button control-pad-left ' + nextDisabled}
							onClick={ e => this.#handleElementNavigation( this.state.selectedElement.nextElementSibling )}>
								<ArrowRightIcon size='18'/>
						</button>
						<button 
							className={'button icon-button control-pad-left ' + upDisabled}
							onClick={ e => this.#handleElementNavigation( this.state.selectedElement.parentElement )}>
							<ArrowUpIcon size='18'/>
						</button>
						<button 
							className={'button icon-button control-pad-left ' + downDisabled}
							onClick={ e => this.#handleElementNavigation( this.state.selectedElement.firstElementChild )}>
							<ArrowDownIcon size='18'/>
						</button>
					</div>
					<div className='grid-cell-right default-100' style={{ height: `${this.state.editorHeight}px`, backgroundColor: "#1b1b1b", overflowY: 'auto' }}>
						<CodeEditor
							ref={this.codeEditorRef} 
							value={this.state.editorContent}
							language="html"
							// placeholder="Optional JavaScript (do not include the script tag)"
							data-color-mode="dark"
							onChange={( e ) => { this.#handleCodeChanged( e.target.value ); }}
							padding={15}
							style={{
								fontSize: 12,
								backgroundColor: "#1b1b1b",
								fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
							}}
						/>
					</div>
				</div>;
	}

	/**
	 * @return The markup for displaying secondary fields
	 */
	#getFrameMarkup(){
		const site = SphereAdminSession.site;
		return 	<div ref={this.iFrameContainerRef} className='panel-cell pad-cell-left no-select'>
						<iframe 
							id='verraEditorIframe'
							ref={this.iFrameRef} 
							title='Site Preview' 
							width='100%' 
							style={{ height: '80vh' }} 
							onLoad={ this.#handleIFrameLoaded.bind( this )}
							onMouseUp={ this.upListener }
							onMouseOut={ this.#stopEditorResize.bind( this )}/>
				</div>;
	}

	// Editor Resizing
	
	/**
	 * Begins the resize of the editor
	 */
	#startEditorResize( e ){
		this.startDragY = e.clientY;
		this.startHeight = this.state.editorHeight;
		document.addEventListener( 'mouseup', this.upListener );
		document.addEventListener( 'mousemove', this.moveListener );
	}

	/**
	 * Ends the resize of the editor
	 */
	#stopEditorResize( e ){
		document.removeEventListener( 'mouseup', this.upListener );
		document.removeEventListener( 'mousemove', this.moveListener );
	}

	/**
	 * Handles the mouse move event, resizing the editor
	 */
	#resizeEditor( e ){
		this.setState({ editorHeight: e.clientY - this.startDragY + this.startHeight })
	}

	// HTML Navigation

	/**
	 * Navigates the selected content to a specifc element
	 */
	#handleElementNavigation( element ){
		if( element != null ) this.#selectElement( element );
	}

	// Post Message Handlers

	/**
	 * Handles iframe post message events
	 */
	#handleWindowMessage( event ){
		//console.info( 'handleWindowMessage', event.data.siteId );
		if( event.data.action === 'SET_SITE' && this.iFrameRef.current != null ){
			this.#setSite( event.data.siteId, event.data.siteContent );
		}
	}

	/**
	 * Handles 
	 */
	#setSite( siteId, siteContent ){
		this.iFrameRef.current.contentWindow.document.open();
		this.iFrameRef.current.contentWindow.document.write( siteContent );
		this.iFrameRef.current.contentWindow.document.close();
		
		const sites = SphereAdminSession.sites;
		let selectedSite = null;
		sites.forEach(( site ) => {
			if( site.id.toString() === siteId.toString() ) selectedSite = site;
		});

		this.setState({ selectedSite });
	}

	// UI Handles
	
	/**
	 * Handles a selection of a site from the Sites drop down
	 */
	#handleSiteSelected( site ){
		// console.info( 'handleSiteSelected', site );
		this.setState({ selectedSite: site });
	}

	/**
	 * Handles a selection of a site from the Sites drop down
	 */
	#handleNavigateToPage(){
		// console.info( 'handleNavigateToPage', this.state.selectedSite, this.state.path );
		const site = this.state.selectedSite;
		if( site != null ){
			const url = `https://${site.url}/${this.state.path}?verra-site-mode=${site.id}&verra-id=${SphereAdminSession.token}`;
			if( window.location === window.parent.location ){
				window.location.href = url;
			} else {
				window.parent.postMessage({ action: 'NAVIGATE', url: url }, '*' );
			}
			
		}
	}

	/**
	 * Handles a selection of a site from the Sites drop down
	 */
	#handleToggleEditor(){
		this.setState({ editing: !this.state.editing });
	}

	/**
	 * Handles a click on the button to upload content
	 */
	#handleImportContent(){
		this.state.selectedElement.classList.remove( 'verra-selected' );
		this.state.selectedElement.classList.remove( 'verra-highlighted' );
		const content = ( this.state.selectedElement.nodeType === 3 ) ? this.state.selectedElement.nodeValue : this.state.selectedElement.outerHTML;
		// console.info( content );
		const contentModal = <TransferContentModal
								contentId={this.state.selectedElement.id}
								content={content}
								closeHandler={this.#handleModalDone}/>;
		const openModal = new OpenModalCommand( 'Import Content', contentModal, '350px', false );
		openModal.execute();
	}

	/**
	 * Closes the Predictive Attribute editor modal
	 */
	#handleModalDone( e ) {
		console.info( 'handleModalDone' );
	}

	// Code Editing

	/**
	 * Handles all clicks within the document
	 */
	#handleCodeChanged( value ){
		const editorContent = value; //.trim();

		const template = document.createElement('template');
		template.innerHTML = editorContent;

		const newElement = template.content.firstChild;

		// this.#compareDocumentFragments( this.originalSelectedElement, newElement );

		newElement.classList.add( 'verra-selected' );

		this.state.selectedElement.replaceWith( newElement );

		///*
		const path = this.#getDomPath( newElement );
		console.info( path );

		const selector = path.join( ' ' );
		console.info( selector );

		const iframe = this.iFrameRef.current.contentWindow.document;
		console.info( iframe );
		console.info( iframe.querySelector( selector ));

		const js = `
			const t = document.createElement('template');
			t.innerHTML = '${ this.#getEditorContent( newElement ).split( '\'' ).join( '&apos;' )}';
			const e = document.querySelector('${selector}');
			e.replaceWith(t.content.firstChild);`;

		console.info( js );
		//*/
		
		this.state.selectedElement = newElement;
		this.state.editorContent = editorContent;
	}

	/**
	 * 
	 */
	#getDomPath(el) {
		var stack = [];
		while ( el.parentNode != null ) {
			// console.log(el.nodeName);
			var sibCount = 0;
			var sibIndex = 0;
			for ( var i = 0; i < el.parentNode.childNodes.length; i++ ) {
				var sib = el.parentNode.childNodes[i];
				 if ( sib.nodeName == el.nodeName ) {
					if ( sib === el ) {
						sibIndex = sibCount;
					}
					sibCount++;
				 }
			}
			if ( el.hasAttribute('id') && el.id != '' ) {
				 stack.unshift(el.nodeName.toLowerCase() + '#' + el.id);
			} else if ( sibCount > 1 ) {
				stack.unshift(el.nodeName.toLowerCase() + ':nth-child(' + ( sibIndex + 1 ) + ')');
			} else {
				stack.unshift(el.nodeName.toLowerCase());
			}
			el = el.parentNode;
		}
	
		return stack.slice(1); // removes the html element
	}

	/**
	 * 
	 */
	#compareDocumentFragments( fragment1, fragment2 ){

		// console.info( fragment1.outerHTML );
		// console.info( fragment2.outerHTML );

		const clone1 = fragment1.cloneNode( false );
		const clone2 = fragment2.cloneNode( false );

		console.info( 'isEqualNode', fragment1.isEqualNode( fragment2 ));
		console.info( 'clones equal', clone1.isEqualNode( clone2 ));



		/*
		const nodeTypeChanged = this.originalSelectedElement.nodeName != template.content.firstChild.nodeName;


		console.info( 'nodeTypeChanged', nodeTypeChanged );
		console.info( newElement.nodeName );
		console.info( newElement.nodeType );

		if( newElement.nodeType !== 3 ){
			console.info( newElement.getAttributeNames() );
		}
		*/
	}

	// iframe events

	/**
	 * Handles the load event from the iframe
	 */
	#handleIFrameLoaded( event ){
		const iframe = this.iFrameRef.current;

		iframe.contentWindow.document.body.addEventListener( 'mouseover', this.#handleMouseOver.bind( this ));
		iframe.contentWindow.document.body.addEventListener( 'mouseout', this.#handleMouseOut.bind( this ));
		iframe.contentWindow.document.body.addEventListener( 'click', this.#handleDocumentClick.bind( this ), { capture: true });

		const iframeStyles = document.createElement( 'style' );
		iframeStyles.innerText = 
		`*, *:hover {
			cursor: default;
		}
		#admin-bar-iframe {
			display: none;
		}
		.verra-highlighted { 
			border: 1px solid red;
		}
		.verra-selected { 
			border: 1px solid #1edffd;
		}`;
		iframe.contentWindow.document.head.appendChild( iframeStyles );
	}

	/**
	 * Handles all clicks within the document
	 */
	#handleMouseOver( e ){
		//console.info( 'over', e.target );
		//if( this.editorMode === 0 ){
			//const isControls = ( e.target == this.controls || e.target.closest( '.verra-controls' ) != null );
			//if( !isControls )
			 e.target.classList.add( 'verra-highlighted' );
		//}
	}

	/**
	 * Handles all clicks within the document
	 */
	#handleMouseOut( e ){
		//console.info( 'out', e );
		//if( this.editorMode === 0 ){
			e.target.classList.remove( 'verra-highlighted' );
		//}
	}

	/**
	 * Handles all clicks within the document
	 */
	#handleDocumentClick( e ){
		this.#selectElement( e.target );

		const a = e.target.closest( 'a' );
		if( a != null ){
			e.preventDefault();
			e.stopPropagation();
			//console.info( window.location.href );
			//const origin = this.state.selectedSite.url;
			//const modeParam = `verra-site-mode=${this.state.selectedSite.id}`;
			//const params = ( a.href.indexOf( '?' ) === -1 ) ? `?${modeParam}` : `&${modeParam}`;
			//const url = 'https://' + origin + a.href.split( window.location.origin )[ 1 ] + params;
			this.setState({ path: a.href.split( window.location.origin )[ 1 ] });
			//console.info( url );
			//window.parent.postMessage({ action: 'NAVIGATE', url: url }, '*' );
		}
	}

	/**
	 * Selects an element for editing and/or import
	 */
	#selectElement( targetElement ){

		const selectChild = ( this.state.selectedElement === targetElement );
		const element = ( selectChild ) ? targetElement.firstChild : targetElement;

		const deselect = ( this.state.selectedElement != null && this.state.selectedElement.classList != null && ( !selectChild || element.nodeType !== 3 ));
		const deselectParent = ( this.state.selectedElement != null && this.state.selectedElement.parentNode != null && this.state.selectedElement.parentNode.classList != null );

		if( deselect ) this.state.selectedElement.classList.remove( 'verra-selected' );
		if( deselectParent ) this.state.selectedElement.parentNode.classList.remove( 'verra-selected' );
		if( element.classList != null ) element.classList.add( 'verra-selected' );

		const editorContent = this.#getEditorContent( element );

		const template = document.createElement('template');
		template.innerHTML = editorContent;
		this.originalSelectedElement = template.content.firstChild;

		this.setState({ selectedElement: element, editorContent });
	}

	/**
	 * @return The editor content based on the provided element
	 */
	#getEditorContent( element ){
		let editorContent = ( element != null ) ? (( element.nodeType === 3 ) ? element.nodeValue : element.outerHTML ) : '';
		editorContent = editorContent.replaceAll( /(\s*verra-highlighted\s*)|(\s*verra-selected\s*)/g, '' );
		editorContent = editorContent.replaceAll( /(\s*class=""\s*)/g, '' );
		return editorContent;
	}

	//

	/**
	 * Handles window resize events
	 */
	#handleResize(){
		this.#resizeContentContainers();
	}

	/**
	 * Resizes the edit and preview containers
	 */
	#resizeContentContainers(){
		const iFrameContainer = this.iFrameContainerRef.current;
		if( iFrameContainer != null ){
			const clientHeight = window.innerHeight;
			const editorContainer = this.editorContainerRef.current;
			const editorRect = ( editorContainer != null ) ? editorContainer.getBoundingClientRect() : { height: 0 };
			const frameRect = iFrameContainer.getBoundingClientRect();
			// const height = clientHeight - frameRect.y - 13;
			const height = clientHeight - editorRect.height - 13;
			// console.info( 'resizeContentContainers', frameRect, height );
			iFrameContainer.style.maxHeight = height + 'px';
			iFrameContainer.style.height = height + 'px';
			this.iFrameRef.current.style.height = ( height - 36 ) + 'px';
		}
	}


}

//

export default ImportContentPanel;
