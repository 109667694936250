/*
 * (c) Verra Technology Corporation
 */


/**
 * Abstract Command class
 */
class Command {

	/**
	 * Executes the function
	 * TODO: consider returning value
	 */
	execute() {
		return this.handleExecute();
	}

	/**
	 * Template method. Handles execution of the Command.
	 */
	handleExecute() {
		// override
		return null;
	}
	
}

export default Command;
