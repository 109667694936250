/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SphereAdminSession from '../model/SphereAdminSession';
import SetStateCommand from './SetStateCommand';

/**
 * Intiates the edit metric action in the admin
 */
class EditMetricCommand extends Command {
	
	/**
	 * Constructs the command.
	 * @param metricId the ID metric to edit
	 */
	constructor( metricId ) {
		super();
		this.metricId = metricId;
	}
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		SphereAdminSession.metric = this.metric;
		const setState = new SetStateCommand( AdminStates.ADMIN_METRICS_EDIT, { metricId: this.metricId });
		setState.execute();
	}
	
}

export default EditMetricCommand;
