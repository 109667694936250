/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AudienceRuleCombinators from '../../model/AudienceRuleCombinators';
import UpSellModal from '../components/modals/UpSellModal';
import AudienceFactory from '../managers/AudienceFactory';
import PermissionsManager from '../managers/PermissionsManager';
import Permissions from '../model/Permissions';
import SphereAdminSession from '../model/SphereAdminSession';
import OpenModalCommand from './OpenModalCommand';

/**
 * Adds a rule to an audience
 */
class AddRuleToAudienceCommand extends Command {
	
	#startGroup;

	//

	/**
	 * Constructs the AddRuleToAudienceCommand
	 * @param startGroup whether or not the rule should start a new group
	 */
	constructor( startGroup ) {
		super();
		this.#startGroup = startGroup;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const audience = SphereAdminSession.audience;
		const rules = audience.rules;
		const hasPermission = PermissionsManager.hasPermissionForMoreItems( Permissions.AUDIENCE_RULES_MAX, rules );

		if( hasPermission.allowed ) {
			let groupId;
			if( this.#startGroup ){
				groupId = rules[ rules.length - 1 ].groupId + 1;
			} else {
				groupId = ( rules.length === 0 ) ? 0 : rules[ rules.length - 1 ].groupId;
			}
			const rule = AudienceFactory.createAudienceRule( audience );
			rule.groupId = groupId;
			rule.combinator = AudienceRuleCombinators.AND;
		} else {
			const msg = <p style={{ margin: 0 }}>You've reached your maximum number of allowed rules per Audience. Your account only allows { hasPermission.permitted }.</p>;
			const upSell = <UpSellModal message={ msg } callout='audience_rules'/>;
			const openModal = new OpenModalCommand(  'Limit Reached', upSell, '600px', true );
			openModal.execute();
		}
	}
	
}

//

export default AddRuleToAudienceCommand;
