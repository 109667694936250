/*
 * (c) Verra Technology Corporation
 */

/*

TODO: MVP: support all time values or remove
SELECT date_format(now(),'%Y-%m'); -- round to the month
SELECT date_format(now(),'%Y-%m-%d'); -- round to the day
SELECT date_format(now(),'%Y-%m-%d %H'); -- round to the hour
SELECT date_format(now(),'%Y-%m-%d %H:%i'); -- round to the minute

*/

/**
 * Contants use to control time parameters in performance metrics queries
 */
const MetricsTimeValues = {

	/**
	 * Time increments by the minute
	 */
	INCREMENT_MINUTE: 'MINUTE',

	/**
	 * Time increments by the hour
	 */
	INCREMENT_HOUR: 'HOUR',

	/**
	 * Time increments by the day
	 */
	INCREMENT_DAY: 'DAY',

	/**
	 * Time increments by the week
	 */
	INCREMENT_WEEK: 'WEEK',

	/**
	 * Time increments by the month
	 */
	INCREMENT_MONTH: 'MONTH',

	/**
	 * Time increments by the quarter
	 */
	INCREMENT_QUARTER: 'QUARTER',

	/**
	 * Time increments by the year
	 */
	INCREMENT_YEAR: 'YEAR',

	// Time Ranges

	/**
	 * Time RANGE for the current day
	 */
	RANGE_CURRENT_DAY: 'RANGE_CURRENT_DAY',

	/**
	 * Time RANGE for the current week
	 */
	RANGE_CURRENT_WEEK: 'RANGE_CURRENT_WEEK',

	/**
	 * Time RANGE for the current day
	 */
	RANGE_CURRENT_MONTH: 'RANGE_CURRENT_MONTH',

	/**
	 * Time RANGE for the current quarter
	 */
	RANGE_CURRENT_QUARTER: 'RANGE_CURRENT_QUARTER',

	/**
	 * Time RANGE for the current year
	 */
	RANGE_CURRENT_YEAR: 'RANGE_CURRENT_YEAR',

	/**
	 * Time RANGE for the LAST day
	 */
	RANGE_LAST_DAY: 'RANGE_LAST_DAY',

	/**
	 * Time RANGE for the LAST week
	 */
	RANGE_LAST_WEEK: 'RANGE_LAST_WEEK',

	/**
	 * Time range for the last month
	 */
	RANGE_LAST_MONTH: 'RANGE_LAST_MONTH',

	/**
	 * Time RANGE for the LAST quarter
	 */
	RANGE_LAST_QUARTER: 'RANGE_LAST_QUARTER',

	/**
	 * Time RANGE for the LAST year
	 */
	RANGE_LAST_YEAR: 'RANGE_LAST_YEAR'

}

export default MetricsTimeValues;
