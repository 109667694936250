/*
 * (c) Verra Technology Corporation
 */

/**
 * Provides utility functions for objects
 */
 class ObjectUtil {

	/**
	 * Creates a new object using a provided definition. The definition specifies classes that will 
	 * be instantiated and populated with the values in the object. It's like Object.assign but works 
	 * with nested properties and collections.
	 */
	static createFromDefinition( object, definition ) {
		if( object instanceof Array ){
			var instances = [];
			for( let i = 0; i < object.length; i++ ){
				instances.push( ObjectUtil.createFromDefinition( object[ i ], definition ));
			}
			return instances;
		} else {
			const instance = ( definition.collection ) ? {} : new definition.type();
			for( let key in object ) {
				if(( definition.properties != null && definition.properties[ key ] != null ) || definition.collection ) {
					const propertiesDefinition = ( definition.collection ) ? definition.type.definition : definition.properties[ key ]; // this is ugly, needs a refactor
					instance[ key ] = ObjectUtil.createFromDefinition( object[ key ], propertiesDefinition );
				} else {
					instance[ key ] = object[ key ];
				}
			}
			return instance;
		}
	}

}

//

export default ObjectUtil;