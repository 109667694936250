/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';

import ObjectUtil from '../../../util/ObjectUtil.mjs';
import Channel from '../../../model/Channel';

/**
 * Retrieves a specific piece of content by ID
 */
class RetrieveChannelRequest extends ServiceRequestCommand {

	/**
	 * Constructs the request
	 */
	constructor( channelId ){
		super();
		this.channelId = channelId;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
	getService(){
		return SphereAdminServices.RETRIEVE_CHANNEL + this.channelId;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams(){
		return { accountId: SphereAdminSession.selectedAccount.id };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete(){
		SphereAdminSession.channel = null;
		var response = this.getResponse();
		//console.info( 'RetrieveChannelRequest::handleComplete', response );
		if( response.success ) {
			var channel = ObjectUtil.createFromDefinition( response.result, Channel.definition );
			//console.info( 'channel', channel );
			SphereAdminSession.channel = channel;
		}
	}
 
}

export default RetrieveChannelRequest;
