import React from 'react';

const CheckIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg height={ size } width={ size } viewBox="0 -960 960 960" fill={ color }>
			<path d="M382-208 122-468l90-90 170 170 366-366 90 90-456 456Z"/>
		</svg>
	);
};

export default CheckIcon;