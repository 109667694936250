/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';

/**
 * Retrieves a specific piece of content by ID
 */
class RetrieveContentRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param accountId The account in which the Content lives
	 * @param contentId The ID of the Content
	 */
	constructor( accountId, contentId ){
		super();
		//console.info( 'RetrieveContentRequest', accountId, contentId );
		this.accountId = accountId;
		this.contentId = contentId;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getService(){
		return SphereAdminServices.RETRIEVE_CONTENT + this.contentId;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams(){
		return { accountId: this.accountId };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		SphereAdminSession.content = null;
		var response = this.getResponse();
		//console.info( 'RetrieveContentRequest::handleComplete', response );
		if( response.success ) {
			SphereAdminSession.content = response.result;
		}
	}
 
}

export default RetrieveContentRequest;
