/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';
import AddAudiencesToOptimiztionCommand from '../../commands/AddAudiencesToOptimiztionCommand';
import CloseModalCommand from '../../commands/CloseModalCommand';
import AudienceFactory from '../../managers/AudienceFactory';
import SphereAdminSession from '../../model/SphereAdminSession';
import AudienceEditor from '../editors/AudienceEditor';

//

/**
 * Modal used to select an Audience
 */
class CreateAudienceModal extends Component {

	/**
	'* Constructs the Component
	*/
	constructor( props ) {
		super( props );
		this.audienceEditor = React.createRef();
		SphereAdminSession.audience = AudienceFactory.createAudience();
	}
	
	/**
	* Renders the component
	* @see react docs
	*/
	render() {
		return (
			<div className='dialog'>
				<AudienceEditor ref={ this.audienceEditor } modal={ true } saveHandler={ this.#handleSaveComplete.bind( this )}/>
				<div className='grid-cell default-100' style={{ padding: '20px 0 0 0', textAlign: 'center' }}>
					<div className='primary-button' style={{width: '90px'}} onClick={this.#handleSave.bind( this )}>Save</div>
					<div className='button control-pad-left' style={{width: '90px'}} onClick={this.#handleCancel.bind( this )}>Cancel</div>
				</div>
			</div>
		);
	}

	/**
	 * Saves the audience and closes the modal
	 */
	#handleSave( e ) {
		this.audienceEditor.current.save();
	}

	/**
	 * Saves the audience and closes the modal
	 */
	#handleSaveComplete( audience ) {
		// SphereAdminSession.optimization.audiences.push( audience );
		const addAudiences = new AddAudiencesToOptimiztionCommand([ audience ]);
		addAudiences.execute();

		const closeModal = new CloseModalCommand();
		closeModal.execute();
		this.props.createdHandler();
	}

	/**
	 * Closes the Modal
	 */
	#handleCancel( e ) {
		const closeModal = new CloseModalCommand();
		closeModal.execute();
	}

}

export default CreateAudienceModal;