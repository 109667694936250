/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import SphereAdminConfig from '../model/SphereAdminConfig';
import AdminStates from '../model/AdminStates';
import SetStateCommand from './SetStateCommand';
import RetrieveUserRequest from '../requests/account/RetrieveUserRequest';
import SphereAdminSession from '../model/SphereAdminSession.js';
import RetrieveSitesRequest from '../requests/sites/RetrieveSitesRequest.js';
import RetrieveEventTypesRequest from '../requests/events/RetrieveEventTypesRequest.js';


/**
 * Initializes the administration suite
 */
class InitializeAdminCommand extends Command {
	
	/**
	 * Handles execution of the Command
	 */
	handleExecute() {
		//console.info( 'InitializeAdminCommand::handleExecute', SphereAdminSession.user );
		// if user is null, get the user. this will happen if the page is refreshed but the user's jwt is still valid
		if( SphereAdminSession.user == null ){
			var retrieveUser = new RetrieveUserRequest();
			retrieveUser.execute(() => { this.#handleUserLoaded( this ); });
		} else {
			this.#loadSites();
		}
	}

	// Private
	
	/**
	 * Handles loading of the user from the service
	 */
	#handleUserLoaded( e ) {
		if( SphereAdminSession.user != null ){
			this.#loadSites();
		} else {
			SphereAdminSession.signInError = 'Could not find user';
			var setState = new SetStateCommand( AdminStates.AUTHENTICATION_FAILED ); // TODO: should be initialization failed
			setState.execute();
		}
	};

	/**
	 * Loads the configuration file
	 */
	#loadSites() {
		// TODO: this needs to be called again when switching accounts, refactor to init account command
		var retrieveSites = new RetrieveSitesRequest();
		retrieveSites.execute(( command ) => { this.#handleSitesLoaded( command ); } );
	};

	/**
	 * Handles loading of the user from the service
	 */
	#handleSitesLoaded( e ) {
		if( SphereAdminSession.sites != null ){
			this.#loadEventTypes();
		} else {
			SphereAdminSession.signInError = 'Could not find any sites for this user';
			var setState = new SetStateCommand( AdminStates.AUTHENTICATION_FAILED ); // TODO: should be initialization failed
			setState.execute();
		}
	};

	/**
	 * Loads the configuration file
	 */
	#loadEventTypes() {
		// TODO: this needs to be called again when switching accounts, refactor to init account command
		var revieveEventTypes = new RetrieveEventTypesRequest();
		revieveEventTypes.execute(( command ) => { this.#handleEventTypesLoaded( command ); } );
	};

	/**
	 * Handles loading of the user from the service
	 */
	#handleEventTypesLoaded( e ) {
		this.#loadConfig();
	};

	/**
	 * Loads the configuration file
	 */
	#loadConfig() {
		var request = new XMLHttpRequest();
		request.onreadystatechange = ( e ) => { handleConfigLoaded.apply( this, [ e ]); };
		request.open( 'GET', SphereAdminConfig.configDataUrl );
		request.send();
		// this.#loadStopWords();
	};

	/**
	 * Loads stop words
	 */
	/*
	 #loadStopWords() {
		var request = new XMLHttpRequest();
		request.onreadystatechange = ( e ) => { handleStopWordsLoaded.apply( this, [ e ]); };
		request.open( 'GET', '/data/stop_words_english.json' );
		request.send(); 
	};
	*/

}

// Private - TODO: refactor these to be class members

/**
 * Handles the ready state change event on the XMLHttpRequest 
 */
var handleConfigLoaded = function( e ) {
	// TODO: support both components and commands
	// console.info( 'handleConfigLoaded' );
	var request = e.target;
	if( request.readyState === 4 && request.status === 200 ) {
		var configData = JSON.parse( request.responseText ); // TODO: config data should come from the DB and based on role
		
		// console.info( 'handleConfigLoaded', configData );

		// create a straight map of the config data
		for( var key in configData ) {
			SphereAdminConfig[ key ] = configData[ key ];
		}

		// determine what state we should be in
		var state = null;
		var stateParameters = {};
		var pathname = window.location.pathname;

		// create a map of the states for lookup
		parseStates.apply( this, [ configData.states, pathname ]);

		if( SphereAdminConfig.pathMap[ pathname ] != null ){ // we have an exact path match
			state = SphereAdminConfig.pathMap[ pathname ];
		} else { // try to find a match
			for( var path in SphereAdminConfig.pathMap ){
				
				var replacementMatches = path.match( /(.*?)({.*?})/g );

				if( replacementMatches != null ){

					var regexStr = path.replace( /(.*?)({.*?})/g, '($1)(.*)' );
					var regex = new RegExp( regexStr, 'g' );
					var match = regex.exec( pathname );

					// do we have a full match?
					if( match != null ) {

						// pull the matched state from the pathMap
						state = SphereAdminConfig.pathMap[ path ];

						// populate the state params with the values in the pathname
						var matchIndex = 2;
						for( var i = 0; i < replacementMatches.length; i++ ){
							var replacementMatch = replacementMatches[ i ];
							var paramId = replacementMatch.match( /{(.*?)}/ )[ 1 ];
							var paramValue = match[ matchIndex ];
							stateParameters[ paramId ] = paramValue;
							matchIndex += 2;
						}
						break;
					}
				}
			}
		}

		// if we didn't find a state matching the pathname, set it to the default 
		if( state == null ) state = AdminStates.INITIALIZED;

		var setState = new SetStateCommand( state, stateParameters );
		setState.execute();
	}
	
	// TODO: handle error state
};

/**
 * Handles parsing of the panel data included in the config file, creating a look up 
 * hash map of panels by their states. It also creates a parent child relationship 
 * between the states.
 */
var parseStates = function( states ) {
	if( SphereAdminConfig.statesMap == null ) SphereAdminConfig.statesMap = {};
	if( SphereAdminConfig.pathMap == null ) SphereAdminConfig.pathMap = {};

	for( let i = 0; i < states.length; i++ ) {
		let state = states[ i ];
		SphereAdminConfig.statesMap[ state.state ] = state;
		if( state.path != null ) SphereAdminConfig.pathMap[ state.path ] = state.state;
		if( state.states != null ) parseStates.apply( this, [ state.states ]);
	}
}

/**
 * 
 */
/*
var handleStopWordsLoaded = function( e ){
	var request = e.target;
	if( request.readyState === 4 && request.status === 200 ) {
		var stopWords = JSON.parse( request.responseText ); //
		SphereAdminSession.stopWords = stopWords;
	}
}
*/

//

export default InitializeAdminCommand;