/*
 * (c) Verra Technology Corporation
 */

import Experience from "../../model/Experience";
import SphereAdminSession from "../model/SphereAdminSession";

/**
 * Factory class for creating Optimization model objects
 */
const ExperienceFactory = {

	/**
	 * Creates a new, empty Experience
	 * @param id Optionally creates and Experience with a specific ID
	 * @param siteId Optionally creates an Experience with a specific site assignment
	 * @return Experience
	 */	
	createExperience: function( id, siteId ) {
		const experience = new Experience();
		experience.accountId = SphereAdminSession.selectedAccount.id;
		experience.id = ( id != null ) ? id : ExperienceFactory.createExperienceId();
		experience.__n = 1; // this is used to flag an experience as new experience that has not yet been saved, ties into permissions, it's hacky
		if( siteId != null ) experience.siteId = siteId;
		return experience;
	},

	/**
	 * Creates an ID for an Experience
	 * @return The ID
	 */	
	createExperienceId: function() {
		return window.crypto.randomUUID();
	}

};

export default ExperienceFactory;
