/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';

//

/**
 * The basic fields in the object to check and the validation method to check them with
 */
var fields = {
	'id': validateId,
	'name': isNotNullOrEmpty, 
	'model': isNotNullOrEmpty
};

//

/**
 * Evaluates the Channel verifying if it contains all of the necessary data to be complete
 * TODO: this should really tie into generic form validation
 */
class ValidateChannelCommand extends Command {
	
	/**
	 * Constructs the Command
	 * @param channel The Channel object to validate
	 */
	constructor( channel ) {
		super();
		this.channel = channel;
		this.valid = false;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		
		this.valid = true;
		this.invalidFields = [];

		for( var fieldName in fields ) {
			var validationFunc = fields[ fieldName ];
			var fieldValid = validationFunc( this.channel[ fieldName ]);
			if( !fieldValid ) {
				this.valid = false;
				this.invalidFields.push( fieldName );
			}
		}
		
	}

	/**
	 * @return whether or not the Channel is valid
	 */
	isValid() {
		return this.valid;
	}
	
	/**
	 * @return whether or not the Channel is valid
	 */
	getInvalidFields() {
		return this.invalidFields;
	}
	
}

/**
 * Validates the ID
 */
function validateId( value ) {
	var valid = isNotNullOrEmpty( value );
	if( valid ) {
		var match = value.match( /^[A-z0-9_.-]+$/ ); // TODO: this should pull from the input's pattern or be shared in some way
		valid = ( match != null && match.length === 1 );
	}
	return valid;
}

/**
 * Validates a string, checking to see if it's null or empty.
 */
function isNotNullOrEmpty( value ) {
	var valid = ( value != null );
	if( valid ) valid = ( value.toString().split( ' ' ).join( '' ) !== '' );
	return valid;
}

//

export default ValidateChannelCommand;
