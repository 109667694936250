import React from 'react';

var ArrowUpIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg width={size} height={size} viewBox='0 0 24 24'>
			<g className='nc-icon-wrapper' stroke='none' fill={color}>
				<path d='M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z'/>
			</g>
		</svg>
	);
};

export default ArrowUpIcon;