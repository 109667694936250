/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminServices from '../../model/SphereAdminServices';

/**
 * Makes a request to reset a password
 */
class ForgotPasswordRequest extends ServiceRequestCommand {


	/**
	 * Constructs the request
	 */
	 constructor( email ){
		super();
		this.email = email;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
	getService() {
		return SphereAdminServices.FORGOT_PASSWORD;
	}

	/**
	 * @return Whether or not the request requires an authenticated session. Default is true
	 */
	requiresAuth() {
		return false;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { email: this.email };
	}

	//

	/**
	 * @return whether or not the request was successful
	 */
	getSuccess(){
		return this.getResponse().success;
	}
 
}

//

export default ForgotPasswordRequest;
