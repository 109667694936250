import React from 'react';

var LayersIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 52;
	return (
		<svg height={ size } width={ size } viewBox="0 0 52 52">
			<g strokeLinecap="square" strokeWidth="2" fill="none" stroke="#ffffff" strokeLinejoin="miter" className="nc-icon-wrapper" strokeMiterlimit="10">
				<polyline points="41,22.2 44,24 24,36 4,24 7.333,22 " stroke="#ffffff"></polyline>
				<polyline points="40.67,32.002 44,34 24,46 4,34 7.333,32 " stroke="#ffffff"></polyline>
				<polygon points="4,14 24,2 44,14 24,26 "></polygon>
			</g>
		</svg>
	);
};

export default LayersIcon;