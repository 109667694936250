import React from 'react';

var CancelIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 18;
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={ size } height={ size } viewBox='0 0 24 24'>
			<g className='nc-icon-wrapper' stroke='none' fill='#1EDFFD'>
				<path d='M9 1C4.58 1 1 4.58 1 9s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm4 10.87L11.87 13 9 10.13 6.13 13 5 11.87 7.87 9 5 6.13 6.13 5 9 7.87 11.87 5 13 6.13 10.13 9 13 11.87z'/>
			</g>
		</svg>
	);
};

export default CancelIcon;