/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';

import CloseModalCommand from '../../commands/CloseModalCommand';

import InputField from '../controls/InputField';
import Hint from '../controls/Hint';
import SaveEventTypeRequest from '../../requests/events/SaveEventTypeRequest';
import SphereAdminSession from '../../model/SphereAdminSession';
import Alert from '../controls/Alert';
import OpenModalCommand from '../../commands/OpenModalCommand';

//

/**
 * Manages the creation of AnalyticsEventTypes
 */
class CreateEventTypeModal extends Component {

	/**
	'* Constructs the Component
	*/
	constructor( props ) {
		super( props );

		var eventType = { accountId: SphereAdminSession.selectedAccount.id, readOnly: 0 };

		this.state = {
			eventType: eventType
		};
	}
	
	/**
	* Renders the component
	* @see react docs
	*/
	render() {

		var eventType = this.state.eventType;
		var idToolTip = 'Uniquely identifies the Event Type. Values can only contain letters, numbers, underscores, dashes, and periods';
		var nameToolTip = 'User friendly name for the Event Type.';

		return ( 
			<div className='dialog'>
				<div className='grid panel-cell'>
					<div className='grid-cell default-100 '>
						<label>Name <Hint width='250px' content={nameToolTip}/></label>
						<InputField 
							value={eventType.name}
							maxLength='256' 
							onChange={( value ) => { this.#handleFieldChanged( 'name', value ); }}/>
					</div>
					<div className='grid-cell default-100 pad-cell-top'>
						<label>ID <Hint width='250px' content={idToolTip}/></label>
						<InputField 
							value={eventType.id}
							maxLength='256' 
							pattern='[A-z0-9_.-]+' 
							onChange={( value ) => { this.#handleFieldChanged( 'id', value ); }}/>
					</div>
					<div className='grid-cell default-100' style={{ padding: '20px 0 0 0', textAlign: 'center' }}>
						<div className='primary-button' style={{width: '90px'}} onClick={this.#handleSave.bind( this )}>Save</div>
						<div className='button control-pad-left' style={{width: '90px'}} onClick={this.#handleCancelEdit.bind( this )}>Cancel</div>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Handles changes to the input fields, invalidating the Channel object
	 */
	 #handleFieldChanged( field, value ) {
		var eventType = this.state.eventType;
		eventType[ field ] = value;
		this.setState({ eventType: eventType });
	};

	/**
	 * Closes the Predictive Attribute editor modal
	 */
	#handleSave( e ) {
		const eventTypes = SphereAdminSession.eventTypes; 
		const hasEvent = ( eventTypes.find(( eventType => eventType.id === this.state.eventType.id )) != null );
		if( hasEvent ){
			const alert = <Alert content='An event with this ID already exists' showCancelBtn={false}/>;
			const openModal = new OpenModalCommand( 'Could not create Event Type', alert, '500px', true );
			openModal.execute();
		} else {
			const saveEventType = new SaveEventTypeRequest( this.state.eventType );
			saveEventType.execute(( command ) => { this.#handleEventTypeSaved( command )});
		}
	}

	/**
	 * Handles completion of the SaveEventTypeCommand
	 */
	#handleEventTypeSaved( commmand ){
		this.props.saveHandler( this.state.eventType );
		var closeModal = new CloseModalCommand();
		closeModal.execute();
	}

	/**
	 * Closes the Predictive Attribute editor modal
	 */
	#handleCancelEdit( e ) {
		var closeModal = new CloseModalCommand();
		closeModal.execute();
	}

}

export default CreateEventTypeModal;