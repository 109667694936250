import React from 'react';

const ChartIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	return (
		<svg viewBox="0 -960 960 960" height={ size } width={ size } fill="#ffffff"
			><path d="M200-120q-33 0-56.5-23.5T120-200v-640h80v640h640v80H200Zm40-120v-360h160v360H240Zm200 0v-560h160v560H440Zm200 0v-200h160v200H640Z"/>
		</svg>
	);
};

export default ChartIcon;