import React from 'react';

var CalendarIcon = function( props ) {
	return (
		<svg version='1.1' x='0px' y='0px' viewBox='0 0 32 32' width='48' height='48'>
			<g className='nc-icon-wrapper' stroke='none' fill='#111111'>
				<path fill='#ffffff' d='M31,3h-5V1c0-0.553-0.448-1-1-1h-3c-0.552,0-1,0.447-1,1v2H11V1c0-0.553-0.448-1-1-1H7C6.448,0,6,0.447,6,1 v2H1C0.448,3,0,3.447,0,4v27c0,0.553,0.448,1,1,1h30c0.552,0,1-0.447,1-1V4C32,3.447,31.552,3,31,3z M21,5h5v2h-5V5z M6,5h5v2H6V5z M30,30H2V12h28V30z'/>
			</g>
		</svg>
	);
};

export default CalendarIcon;