/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import CreateAudienceModal from '../components/modals/CreateAudienceModal';
import UpSellModal from '../components/modals/UpSellModal';
import PermissionsManager from '../managers/PermissionsManager';
import AdminStates from '../model/AdminStates';
import Permissions from '../model/Permissions';
import OpenModalCommand from './OpenModalCommand';
import SetStateCommand from './SetStateCommand';

/**
 * Sets the state to create audience
 */
class CreateAudienceCommand extends Command {
	
	#modal;
	#createdHandler;
	
	//

	/**
	 * Constructs the CreateAudienceCommand
	 * @param modal Whether or not the creation is done as a modal or not
	 */
	constructor( modal = false, createdHandler ) {
		super();
		this.#modal = modal;
		this.#createdHandler = createdHandler;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const hasPermission = PermissionsManager.hasPermissionForMore( Permissions.AUDIENCES_MAX );
		if( hasPermission.allowed ) {
			if( this.#modal ) {
				const createAudienceModal = <CreateAudienceModal createdHandler={ this.#createdHandler }/>;
				const openModal = new OpenModalCommand( 'Create Audience', createAudienceModal, '70%', false );
				openModal.execute();
			} else {
				const setState = new SetStateCommand( AdminStates.ADMIN_AUDIENCES_CREATE );
				setState.execute();
			}
		} else {
			const msg = <p style={{ margin: 0 }}>You've reached your maximum number of allowed Audiences. Your account only allows { hasPermission.permitted }.</p>;
			const upSell = <UpSellModal message={ msg } callout='audiences'/>;
			const openModal = new OpenModalCommand(  'Limit Reached', upSell, '600px', true );
			openModal.execute();
		}
	}
	
}

//

export default CreateAudienceCommand;
