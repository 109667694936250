/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import SerializeExperienceCommand from './SerializeExperienceCommand';

/**
 * Saves an Experience to local storage
 */
class SaveExperienceToStorageCommand extends Command {
	
	#experience;

	//

	/**
	 * Constructs the Command
	 * @param experience The Experience object to save
	 */
	constructor( experience ) {
		super();
		this.#experience = experience;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		// create the object to store
		const serialize = new SerializeExperienceCommand( this.#experience );
		const expToStore = serialize.execute();
		const experienceData = JSON.stringify( expToStore );
		window.sessionStorage.setItem( 'verra-site-mode-experience', experienceData );
	}
	
}

export default SaveExperienceToStorageCommand;
