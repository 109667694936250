/*
 * (c) Verra Technology Corporation
 */

/**
 * Specifies constants that represent the various states of the SphereAdmin
 * TODO: should states be dynamic?
 */
const AdminStates = {

	// Constants	
	
	/**
	 * SSO needs to be inititialized before attempting to sign the user in
	 */
	AUTH_NOT_INITIALIZED: 'AUTH_NOT_INITIALIZED',

	/**
	 * The user has not been authenticated.
	 */
	NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
		
	/**
	 * The user's attempt to sign in has failed
	 */
	AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
	
	/**
	 * The user is registering an account
	 */
	REGISTERING: 'REGISTERING',
	
	/**
	 * The user's attempt to register had failed
	 */
	REGISTRATION_FAILED: 'REGISTRATION_FAILED',
	
	/**
	 * The user is creating an account as a part of registration
	 */
	REGISTERING_CREATING_ACCOUNT: 'REGISTERING_CREATING_ACCOUNT',
	
	/**
	 * The user is resetting their password
	 */
	RESET_PASSWORD: 'RESET_PASSWORD',
	 
	/**
	 * The user has authenticated.
	 */
	AUTHENTICATED: 'AUTHENTICATED',
	
	//

	/**
	 * The user has been authenticated and the admin initialized.
	 */
	INITIALIZED: 'INITIALIZED',

	/**
	 * The iframe state is used to enter the admin and send commands via an iframe
	 */
	IFRAME: 'IFRAME',

	// Optimizations

	/**
	 * The create optimization state
	 */
	ADMIN_OPTIMIZATIONS: 'ADMIN_OPTIMIZATIONS',

	/**
	 * The create optimization state
	 */
	ADMIN_OPTIMIZATIONS_CREATE: 'ADMIN_OPTIMIZATIONS_CREATE',

	/**
	 * The edit optimization state
	 */
	ADMIN_OPTIMIZATIONS_EDIT: 'ADMIN_OPTIMIZATIONS_EDIT',

	/**
	 * The view optimization state
	 */
	ADMIN_OPTIMIZATIONS_VIEW: 'ADMIN_OPTIMIZATIONS_VIEW',

	/**
	 * State for locating an element
	 */
	ADMIN_OPTIMIZATIONS_LOCATE_ELEMENT: 'ADMIN_OPTIMIZATIONS_LOCATE_ELEMENT',

	// Experiences

	/**
	 * The experiences state
	 */
	ADMIN_EXPERIENCES: 'ADMIN_EXPERIENCES',

	/**
	 * The create experience state
	 */
	ADMIN_EXPERIENCES_CREATE: 'ADMIN_EXPERIENCES_CREATE',
	ADMIN_EXPERIENCES_CREATE_FOR_OPTIMIZATION: 'ADMIN_EXPERIENCES_CREATE_FOR_OPTIMIZATION',

	/**
	 * The edit experience state
	 */
	ADMIN_EXPERIENCES_EDIT: 'ADMIN_EXPERIENCES_EDIT',

	// Audiences

	/**
	 * The audiences state
	 */
	ADMIN_AUDIENCES: 'ADMIN_AUDIENCES',

	/**
	 * The create Audience state
	 */
	ADMIN_AUDIENCES_CREATE: 'ADMIN_AUDIENCES_CREATE',

	/**
	 * The edit Audience state
	 */
	ADMIN_AUDIENCES_EDIT: 'ADMIN_AUDIENCES_EDIT',

	// Metrics

	/**
	 * The metrics state
	 */
	ADMIN_METRICS: 'ADMIN_METRICS',

	/**
	 * The create metric state
	 */
	ADMIN_METRICS_CREATE: 'ADMIN_METRICS_CREATE',

	/**
	 * The edit metric state
	 */
	ADMIN_METRICS_EDIT: 'ADMIN_METRICS_EDIT',

	// Content

	/**
	 * The user has selected / is viewing the content panel.
	 */
	ADMIN_CONTENT: 'ADMIN_CONTENT',
	
	/**
	 * The user is creating new Content
	 */
	ADMIN_CONTENT_CREATE: 'ADMIN_CONTENT_CREATE',
	
	/**
	 * The user has selected / is viewing the content import panel.
	 */
	ADMIN_CONTENT_IMPORT: 'ADMIN_CONTENT_IMPORT',
	
	/**
	 * The user has selected / is viewing the edit/create content panel.
	 */
	ADMIN_CONTENT_EDIT: 'ADMIN_CONTENT_EDIT',
	
	/**
	 * The user has selected / is viewing the content panel.
	 */
	ADMIN_CONTENT_BROWSE: 'ADMIN_CONTENT_BROWSE',

	/**
	 * The user has selected / is viewing the content panel.
	 */
	ADMIN_CONTENT_IMPORT: 'ADMIN_CONTENT_IMPORT',
	
	//

	/**
	 * The channels state
	 */
	ADMIN_CHANNELS: 'ADMIN_CHANNELS',

	/**
	 * The user is browsing the list of Channels
	 */
	ADMIN_CHANNELS_BROWSE: 'ADMIN_CHANNELS_BROWSE',

	/**
	 * The user is viewing a Channel
	 */
	ADMIN_CHANNELS_VIEW: 'ADMIN_CHANNELS_VIEW',
	
	/**
	 * The user is creating a Channel
	 */
	ADMIN_CHANNELS_CREATE: 'ADMIN_CHANNELS_CREATE',
	
	/**
	 * The user is editing a Channel
	 */
	ADMIN_CHANNELS_EDIT: 'ADMIN_CHANNELS_EDIT',
	
	//

	/**
	 * The configuration state
	 */
	ADMIN_CONFIGURATION: 'ADMIN_CONFIGURATION',

	/**
	 * The user profile section
	 */
	ADMIN_CONFIGURATION_PROFILE: 'ADMIN_CONFIGURATION_PROFILE',

	/**
	 * The user has selected / is viewing the sites panel.
	 */
	ADMIN_CONFIGURATION_SITES: 'ADMIN_CONFIGURATION_SITES',
	
	/**
	 * The user is creating a Site
	 */
	ADMIN_CONFIGURATION_SITES_CREATE: 'ADMIN_CONFIGURATION_SITES_CREATE',
	
	/**
	 * The user is editing a Site
	 */
	ADMIN_CONFIGURATION_SITES_EDIT: 'ADMIN_CONFIGURATION_SITES_EDIT',
	
	/**
	 * The user is viewing Events
	 */
	ADMIN_CONFIGURATION_EVENTS: 'ADMIN_CONFIGURATION_EVENTS',
	
	/**
	 * The user navigating to the subscription management page
	 */
	ADMIN_CONFIGURATION_SUBSCRIPTION: 'ADMIN_CONFIGURATION_SUBSCRIPTION',
	
	//

	/**
	 * The user is viewing documentation
	 */
	ADMIN_DOCUMENTATION: 'ADMIN_DOCUMENTATION',

	/**
	 * The user is viewing Events
	 */
	ADMIN_DOCUMENTATION_ONBOARD: 'ADMIN_DOCUMENTATION_ONBOARD',
	
};

//

export default AdminStates;
