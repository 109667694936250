/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import ItemListPanel from '../components/controls/ItemListPanel';
import SphereAdminServices from '../model/SphereAdminServices';
import CreateExperienceCommand from '../commands/CreateExperienceCommand';
import EditExperienceCommand from '../commands/EditExperienceCommand';
import SphereAdminSession from '../model/SphereAdminSession';

//

/**
 * Displays the list of Experiences created within the account
 */
class ExperiencesPanel extends Component {

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		const noItemsContent = <div>No experiences found. <button className='link-button' onClick={ this.#handleCreateExperience.bind( this )}>Create Experience</button></div>;
		const headers = [ 
			{ label: 'Name', field: 'name', width: '95' }, 
		];
		const markup = ( 
			<div className='experiences-panel'>
				<div className='grid'>
					<h2 className='grid-cell default-100'>Experiences</h2>
				</div>
				<ItemListPanel 
					headers={ headers }
					showControls={ true } 
					retrieveOnLoad={ true } 
					noItemsContent={ noItemsContent }
					createCommand={ CreateExperienceCommand }
					listEndPoint={ SphereAdminServices.RETRIEVE_EXPERIENCES }
					searchEndPoint={ SphereAdminServices.SEARCH_EXPERIENCES }
					deleteEndPoint={ SphereAdminServices.DELETE_EXPERIENCES }
					retrievedHandler={ this.#handleItemsRetrieved.bind( this )} 
					clickHandler={ this.#handleListItemClick.bind( this )}/>
			</div>
		);

		return markup;
	}

	/**
	 * Handles the click to create a new item
	 */
	#handleCreateExperience( e ){
		const create = new CreateExperienceCommand();
		create.execute();
	}

	/**
	 * Handles the items retrieved event from the ItemListPanel
	 */
	#handleItemsRetrieved( itemsList ) {
		SphereAdminSession.experiences = itemsList.items;
		this.setState({});
	}

	/**
	 * Handles clicks on items in the content list
	 */
	#handleListItemClick( item ) {
		const editExperience = new EditExperienceCommand( item.id );
		editExperience.execute();
	}

}

//

export default ExperiencesPanel;
