/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand.js';
import SphereAdminSession from '../../model/SphereAdminSession.js';
import SphereAdminServices from '../../model/SphereAdminServices.js';

/**
 * Retrieves the sites the user has permission to work with
 */
class RetrieveContentListRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 */
	 constructor( accountId, pageStart, pageSize ){
		super();
		this.accountId = accountId;
		this.pageStart = pageStart;
		this.pageSize = pageSize;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getService() {
		return SphereAdminServices.RETRIEVE_CONTENT_LIST;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: this.accountId, pageStart: this.pageStart, pageSize: this.pageSize };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		var response = this.getResponse();
		//console.info( 'RetrieveContentListRequest::handleComplete', response );
		if( response.success ) {
			SphereAdminSession.contentList = response.result;
		}
	}

}

export default RetrieveContentListRequest;
