/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand.js';
import SphereAdminSession from '../../model/SphereAdminSession.js';
import SphereAdminServices from '../../model/SphereAdminServices.js';

/**
 * Deletes Content
 */
class DeleteContentRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param contentIds An array of Content IDs to delete
	 */
	constructor( contentIds ){
		super();
		this.contentIds = contentIds;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.DELETE_CONTENT;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id, contentIds: this.contentIds };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		var response = this.getResponse();
		//console.info( 'DeleteContentRequest::handleComplete', response );
		this.contentInUse = ( response.success ) ? response.result : [];
	}
 
	//

	/**
	 * @return Any Content that could not be deleted because it's in use by a Channel
	 */
	getContentInUse(){
		return this.contentInUse;
	}

}

export default DeleteContentRequest;
