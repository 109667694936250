/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import SearchContentRequest from '../requests//content/SearchContentRequest';
import EditContentCommand from '../commands/EditContentCommand';
import DataGrid from '../components/controls/DataGrid';
import InputField from '../components/controls/InputField';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import CreateContentCommand from '../commands/CreateContentCommand';
import Loader from '../components/controls/Loader';
import Alert from '../components/controls/Alert';
import OpenModalCommand from '../commands/OpenModalCommand';
import DeleteContentRequest from '../requests//content/DeleteContentRequest';
import RetrieveContentListRequest from '../requests//content/RetrieveContentListRequest';
import SphereAdminSession from '../model/SphereAdminSession';

/**
 * Component that renders a Content Search UI
 */
class ContentSearch extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor( props ) {
		super( props );
		
		this.state = {
			loading: false,
			hasSearched: false,
			searching: false,
			contentSelected: false,
			contentList: { pageStart: 0, pageSize: 20, count: 0, content: [] }
		};

		this.searchField = React.createRef();
		this.contentGrid = React.createRef();
	}

	/*
	getSnapshotBeforeUpdate( prevProps, prevState ) {
		console.info( 'getSnapshotBeforeUpdate' );
		console.info( prevProps );
		console.info( prevState );
	}
	*/

	/**
	 * Handles component update events
	 * @see react docs
	 */
	componentDidMount(){
		// TODO performing the retrieve here fixed a react error related to setting state before mounting, consider using componentDidlMount
		// for other components as well
		if( this.props.retrieveOnLoad ) this.#retrieveContent( this.state.contentList.pageStart, this.state.contentList.pageSize );
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render(){
		const contentList = this.state.contentList;
		const content = contentList.content;

		const pageStart = contentList.pageStart + 1;
		const pageEnd = Math.min( contentList.pageStart + contentList.pageSize, contentList.count );

		const hasPages = contentList.count > contentList.pageSize;
		const hasPrevPages = ( hasPages && contentList.pageStart > 0 );
		const hasNextPages = ( hasPages && pageEnd < contentList.count );

		const prevDisabled = ( hasPrevPages ) ? '' : ' disabled';
		const nextDisabled = ( hasNextPages ) ? '' : ' disabled';

		let createBtn = '';
		let deleteBtn = '';

		if( this.props.showControls ){
			const deleteDisabled = ( this.state.contentSelected ) ? '' : ' disabled';
			createBtn = <div className='grid-cell'>
				<button className='button' onClick={this.#handleCreateContent.bind( this )}>Create</button>
			</div>;
			deleteBtn = <div className='grid-cell pad-cell-left'>
				<button className={'button' + deleteDisabled} onClick={this.#handleDelete.bind( this )}>Delete</button>
			</div>;
 		}

		const searchInputPadding = ( this.props.showControls ) ? 'pad-cell-left' : '';
		const showCheckColumn = ( !this.props.singleSelect || this.props.singleSelect == null );

		const headers = [ 
			{label: 'Name', field: 'name', width: '30'}, 
			{label: 'ID', field: 'id', width: '50'}, 
			{label: 'Last Modified', field: 'attributes.date-modified', width: '15'}
		];

		const emptyContentMarkup = ( this.state.searching ) ? <Loader/> : 
			( !this.state.hasSearched ) ? '' : 
			<div>No Content Found. <button className='link-button' onClick={this.#handleCreateContent.bind( this )}>Create Content</button></div>;

		return (
			<div>
				<div className='grid'>
					{createBtn}
					{deleteBtn}
					<form onSubmit={this.#handleSearch.bind( this )}>
						<div className={'grid-cell default-40 ' + searchInputPadding}>
							<InputField id='content-search-input' placeholder='Search' ref={this.searchField}/>
						</div>
						<div className='grid-cell'>
							<button className='button icon-button control-pad-left' onClick={this.#handleSearch.bind( this )}>
								<ArrowRightIcon/>
							</button>
						</div>
					</form>
					<div className='grid-cell-right pad-cell-left'>
						<span className='results-count'>{pageStart} - {pageEnd} of {contentList.count}</span>
						<button className={'button icon-button control-pad-left' + prevDisabled} onClick={this.#handlePagePrev.bind( this )}>
							<ArrowLeftIcon/>
						</button>
						<button className={'button icon-button control-pad-left' + nextDisabled} onClick={this.#handlePageNext.bind( this )}>
							<ArrowRightIcon/>
						</button>
					</div>
				</div>
				<div className='pad-cell-top'>
					<DataGrid 
						ref={this.contentGrid}
						headers={headers} 
						data={content} 
						idField='id' 
						checkColumn={showCheckColumn} 
						statusIndicator={true} 
						statusField='status' 
						maxBodyHeight={this.props.maxGridHeight}
						noContent={emptyContentMarkup}
						rowClickHandler={this.props.clickHandler}
						checkBoxChangeHandler={this.#handleGridCheckBoxSelect.bind( this )}/>
				</div>
			</div>
		);
	}

	/**
	 * Handles component update events
	 * @see react docs
	 */
	componentDidUpdate(){
		if( this.props.componentUpdateHandler != null ) this.props.componentUpdateHandler();
	}

	//

	/**
	 * Retrieves content
	 */
	#retrieveContent( pageStart, pageSize ){
		if( this.contentGrid.current != null ) this.contentGrid.current.unCheckAll();
		this.setState({ loading: true, searching: false, contentSelected: false  });
		var retrieveContentList = new RetrieveContentListRequest( SphereAdminSession.selectedAccount.id, pageStart, pageSize );
		retrieveContentList.execute(( command ) => { this.#handleContentRetrieved( command ); } );
	}

	/**
	 * Searches content
	 */
	#searchContent( phrase, pageStart, pageSize ){
		if( this.contentGrid.current != null ) this.contentGrid.current.unCheckAll();
		this.setState({ loading: true, searching: true, contentSelected: false });
		var searchContent = new SearchContentRequest( phrase, pageStart, pageSize );
		searchContent.execute(( command ) => { this.#handleContentRetrieved( command ); } );
	}

	/**
	 * Handles the response from the request to retrieve content
	 */
	#handleContentRetrieved( command ){
		var contentList = ( this.state.searching ) ? SphereAdminSession.searchedContentList : SphereAdminSession.contentList;
		this.setState({ searching: false, hasSearched: true, loading: false, contentList });
	};

	/**
	 * Handles the click to create a new site
	 */
	#handleCreateContent( e ){
		var createContent = new CreateContentCommand();
		createContent.execute();
	}

	/**
	 * Handles clicks on a checkbox in the Content grid
	 */
	#handleGridCheckBoxSelect( grid ){
		var selected = grid.getChecked();
		this.setState({ contentSelected: ( selected.length > 0 )});
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleDelete( e ){
		if( this.state.contentSelected ){
			var alert = <Alert content='Are you sure you want to delete this Content?' okHandler={ this.#handleConfirmDelete.bind( this ) }/>;
			var openModal= new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		}
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleConfirmDelete( e ){
		SphereAdminSession.loading = true;
		var selecteContent = this.contentGrid.current.getChecked();
		var contentIds = selecteContent.map( content => content.id ).join( ',' );
		var deleteContent = new DeleteContentRequest( contentIds );
		deleteContent.execute( command  => this.#handleDeleteComplete( command ));
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleDeleteComplete( command ){
		SphereAdminSession.loading = false;
		var contentInUse = command.getContentInUse();
		if( contentInUse.length > 0 ){
			var inUseMarkup = contentInUse.map( content => <li key={content.contentId}>{content.contentId} is used by the Channel <a href={'/channels/edit/' + content.channelId + '/'}>{content.channelId}</a></li> );
			var alertContent = <div>The following content could not be deleted because it is in use by a Channel <ul>{inUseMarkup}</ul></div>;
			var alert = <Alert content={alertContent} showCancelBtn={false}/>;
			var openModal= new OpenModalCommand( 'Cound not delete Content', alert, '500px', true );
			openModal.execute();
		}

		// some of the content was delete, reload the grid
		var selectedContent = this.contentGrid.current.getChecked();
		if( selectedContent.length !== contentInUse.length ){
			if( this.state.searching ){
				var phrase = this.searchField.current.getValue();
				this.#searchContent( phrase, this.state.contentList.pageStart, this.state.contentList.pageSize );
			} else {
				this.#retrieveContent( this.state.contentList.pageStart, this.state.contentList.pageSize );
			}
		}
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleSearch( e ){
		e.preventDefault();
		var phrase = this.searchField.current.getValue();
		if( phrase.split( ' ').join( '' ) === '' ){
			this.#retrieveContent( 0, this.state.contentList.pageSize );
		} else {
			this.#searchContent( phrase, 0, this.state.contentList.pageSize );
		}
	}

	/**
	 * Handles the click to create a new site
	 */
	#handlePagePrev( e ){
		var btn = e.target.closest( 'button' );
		if( !btn.classList.contains( 'disabled' )){
			if( this.state.searching ){
				var phrase = this.searchField.current.getValue();
				this.#searchContent( phrase, this.state.contentList.pageStart - this.state.contentList.pageSize, this.state.contentList.pageSize );
			} else {
				this.#retrieveContent( this.state.contentList.pageStart - this.state.contentList.pageSize, this.state.contentList.pageSize );
			}
		}
	}

	/**
	 * Handles the click to create a new site
	 */
	#handlePageNext( e ){
		var btn = e.target.closest( 'button' );
		if( !btn.classList.contains( 'disabled' )){
			if( this.state.searching ){
				var phrase = this.searchField.current.getValue();
				this.#searchContent( phrase, this.state.contentList.pageStart + this.state.contentList.pageSize, this.state.contentList.pageSize );
			} else {
				this.#retrieveContent( this.state.contentList.pageStart + this.state.contentList.pageSize, this.state.contentList.pageSize );
			}
		}
	}

	//

	/**
	 * @return the elements selected
	 */
	getSelected(){
		return this.contentGrid.current.getChecked();
	}

}

//

export default ContentSearch;
