/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';

//

/**
 * Loading animation
 */
class Loader extends Component {

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return ( 
			<div className="folding">
				<div className="sk-cube1 sk-cube"></div>
				<div className="sk-cube2 sk-cube"></div>
				<div className="sk-cube4 sk-cube"></div>
				<div className="sk-cube3 sk-cube"></div>
			</div>
		);
	}

}

//

export default Loader;