/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';

import CloseModalCommand from '../../commands/CloseModalCommand';

// import DropDownField from '../controls/DropDownField';
import InputField from '../controls/InputField';
import Hint from '../controls/Hint';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import PredictiveAttribute from '../../../model/PredictiveAttribute.mjs'
import Accordion from '../controls/Accordion';
import PredictiveAttributeTypes from '../../model/PredictiveAttributeTypes';
import PositionModalCommand from '../../commands/PositionModalCommand';

//

/**
 * Predefined attribute types
 * TODO: extract enum
 */
var encodingTypes = [ 

	/**
	 * Input is encoded as a one hot vector using the properties defined in the the attribute
	 */
	{ name: 'One Hot', typeId: 1 },
	
	/**
	 * First looks up the value in the ValueIdMap table and then performs a one hot encoding
	 */
	//{ name: 'One Hot ID Look Up', typeId: 2 },

	/**
	 * Input is provided as a collection, each item is one hot encoded per the properties defined in the attribute
	 */
	//{ name: 'One Hot Collection', typeId: 3 }

	/**
	 * Input is provided as a collection, each item is one hot encoded based on its numeric value, example:
	 * The value 4,7,12,15 with an attribute length of 20, is encoded as 00010010000100100000
	 */
	//{ name: 'One Hot Numeric Collection', typeId: 4 }
];

/**
 * Provides an attribute name mapping for quick look up of predefined attributes
 */
var attributeLookUpMap = {};

//

/**
 * Manages the editing of a Predictive Attribute
 * TODO: add validation
 */
class PredictiveAttributeModal extends Component {

	/**
	'* Constructs the Component
	*/
	constructor( props ) {
		super( props );

		// Build the look up map
		for( var i = 0; i < PredictiveAttributeTypes.length; i++ ){
			var group = PredictiveAttributeTypes[ i ];
			for( var j = 0; j < group.items.length; j++ ){
				var item = group.items[ j ];
				attributeLookUpMap[ item.name ] = { groupIndex: i, itemIndex: j };
			}
		};

		// clone the attribute, we don't want to modify the original
		var attribute = new PredictiveAttribute();
		attribute.name = props.attribute.name;
		attribute.status = props.attribute.status; // is this in use?
		attribute.encodingType = props.attribute.encodingType;
		attribute.characters = props.attribute.characters;
		attribute.inputLength = props.attribute.inputLength;
		attribute.collectionLength = props.attribute.collectionLength;
		attribute.dataLayerVariable = props.attribute.dataLayerVariable;

		var isCustom = (( attribute.name === 'Custom' || attributeLookUpMap[ attribute.name ] == null ) && attribute.name != null );

		this.state = {
			attribute: attribute,
			isCustom: isCustom
		};
	}

	/**
	 * Handles the update to props
	 */
	componentWillReceiveProps( nextProps ) {
		if( nextProps.attribute !== this.props.attribute ) {
			this.setState({ 
				attribute: { 
					name: nextProps.attribute.name, 
					encodingType: nextProps.attribute.encodingType, 
					characters: nextProps.attribute.characters,
					inputLength: nextProps.attribute.inputLength,
					collectionLength: nextProps.attribute.collectionLength,
					dataLayerVariable: nextProps.attribute.dataLayerVariable
				} 
			});
		}
	}
	
	/**
	* Renders the component
	* @see react docs
	*/
	render() {

		var attribute = this.state.attribute;

		// look for the attribute in our predefined set
		var selectedAttributeLookUp = attributeLookUpMap[ attribute.name ];
		var selectedHeader = ( selectedAttributeLookUp != null ) ? selectedAttributeLookUp.groupIndex : null;
		var selectedItem = ( selectedAttributeLookUp != null ) ? selectedAttributeLookUp.itemIndex : null;

		var additionalFieldsMarkup = ( this.state.isCustom ) ? this.#getAdditionalFieldsMarkup() : '';
		var saveLabel = ( attribute.status === ModifiableObject.CREATED || attribute.status == null ) ? 'Add' : 'Update';

		// console.info( 'status', attribute.status );

		return ( 
			<div className='dialog'>
				<div className='grid panel-cell'>
					<div className='grid-cell default-100'>
						<label>Type <Hint width='250px' content='help text'/></label>
						<Accordion 
							headerLabel='label' 
							itemLabel='name' 
							itemsProp='items' 
							stickyItemSelect={true} 
							items={PredictiveAttributeTypes} 
							selectedHeaderIndex={selectedHeader}
							selectedItemIndex={selectedItem}
							itemClickHandler={ this.#handleTypeSelected.bind( this ) }/>
					</div>
					{additionalFieldsMarkup}
					<div className='grid-cell default-100 pad-cell-top'>
						<label>Data Layer Variable <Hint width='250px' content='help text'/></label>
						<InputField 
							value={this.state.attribute.dataLayerVariable} 
							maxLength='256' 
							pattern='[A-z0-9_.-]+'
							onChange={( value ) => { this.#handleFieldChanged( 'dataLayerVariable', value ); }}/>
					</div>
					<div className='grid-cell default-100' style={{ padding: '20px 0 0 0', textAlign: 'center' }}>
						<div className='primary-button' style={{width: '90px'}} onClick={handleSave.bind( this )}>{saveLabel}</div>
						<div className='button control-pad-left' style={{width: '90px'}} onClick={handleCancelEdit.bind( this )}>Cancel</div>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * 
	 */
	componentDidUpdate(){
		var positionModal = new PositionModalCommand();
		positionModal.execute();
	}

	// Markup

	/**
	 * @returns Markup for additional fields when editing custom predictive attributes
	 */
	#getAdditionalFieldsMarkup(){
		return <div>
			<div className='grid-cell default-100 pad-cell-top'>
				<label>Name <Hint width='250px' content='help text'/></label>
				<InputField 
					value={this.state.attribute.name} 
					maxLength='256' 
					pattern='[A-z0-9_.-]+'
					onChange={( value ) => { this.#handleFieldChanged( 'name', value ); }}/>
			</div>
			{/*
			<div className='grid-cell default-100 pad-cell-top'>
				<label>Encoding Type <Hint width='250px' content='help text'/></label>
				<DropDownField 
					itemsWidth='100%' 
					labelField='name'
					selectedIndex={this.state.attribute.encodingType} 
					items={encodingTypes}
					changeHandler={( item ) => { this.#handleFieldChanged( 'name', item.typeId ); }}/>
			</div>
			*/}
			<div className='grid-cell default-100 pad-cell-top'>
				<label>Character Set <Hint width='250px' content='help text'/></label>
				<InputField 
					value={this.state.attribute.characters} 
					onChange={( value ) => { this.#handleFieldChanged( 'characters', value ); }}/>
			</div>
			<div className='grid-cell default-100 pad-cell-top'>
				<label>Input Length <Hint width='250px' content='help text'/></label>
				<InputField 
					value={this.state.attribute.inputLength} 
					pattern='[0-9]+'
					onChange={( value ) => { this.#handleFieldChanged( 'inputLength', value ); }}/>
			</div>
		</div>;
	}

	// Handlers

	/**
	 * Handles the selection of an attribute type from the Accordion
	 */
	#handleTypeSelected( attribute ){
		var isCustom = (( attribute.name === 'Custom' || attributeLookUpMap[ attribute.name ] == null ) && attribute.name != null );
		this.setState({  attribute, isCustom });
	};

	/**
	 * Handles changes to the input fields, invalidating the Channel object
	 */
	 #handleFieldChanged( field, value ) {
		var attribute = this.state.attribute;
		attribute[ field ] = value;
		this.setState({ attribute: attribute });
	};

}

// Markup

//

/**
 * Closes the Predictive Attribute editor modal
 */
function handleSave( e ) {
	// this.originalAttribute = this.state.attribute;
	// TODO: add check for duplicates?
	this.props.saveHandler( this.state.attribute );
	var closeModal = new CloseModalCommand();
	closeModal.execute();
};

/**
 * Closes the Predictive Attribute editor modal
 */
function handleCancelEdit( e ) {
	var closeModal = new CloseModalCommand();
	closeModal.execute();
};

//

export default PredictiveAttributeModal;