/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';

import CloseModalCommand from '../../commands/CloseModalCommand';

import DropDownField from '../controls/DropDownField';
import InputField from '../controls/InputField';
import Hint from '../controls/Hint';
import SphereAdminSession from '../../model/SphereAdminSession';
import RetrieveChannelsListRequest from '../../requests/channels/RetrieveChannelsListRequest';
import PositionModalCommand from '../../commands/PositionModalCommand';
import Loader from '../controls/Loader';
import Content from '../../../model/Content.mjs';
import ValidatorCommand from '../../commands/ValidatorCommand';
import SaveContentRequest from '../../requests//content/SaveContentRequest';
import RetrieveChannelRequest from '../../requests/channels/RetrieveChannelRequest';
import SaveChannelRequest from '../../requests/channels/SaveChannelRequest';

//

/**
 * Modal used to transfer content to Verra
 */
class TransferContentModal extends Component {

	/**
	'* Constructs the Component
	*/
	constructor( props ) {
		super( props );
		this.state = { loading: true, invalidFields: [] };

		this.content = new Content();
		this.content.status = 0;
		this.content.accountId = SphereAdminSession.selectedAccount.id;
		this.content.id = this.props.contentId;
		this.content.name = '';
		this.content.body = this.props.content;

		this.channelId = null;

		// TODO: add loader
		// TODO: this should be moved into a ViewChannelsListCommand
		var retrieveChannels = new RetrieveChannelsListRequest();
		retrieveChannels.execute(( command ) => { this.#handleChannelsRetrieved( command ); } );
	}
	
	/**
	* Renders the component
	* @see react docs
	*/
	render() {
		return ( this.state.loading ) ? <Loader/> : this.#getTransferMarkup();
	}

	/**
	 * The markup for the transfer content UI
	 */
	#getTransferMarkup(){

		var eventTypeToolTip = 'The type of Analytics Event to add as success criteria to the Channel';
		var weightToolTip = 'The weight specifies how important the Event is when training the model.';
		var directTip = 'Direct events are events that occur directly on or within the Channel, for example, a click.'
		var indirectTip = 'Indirect events are events that do not occur directly on the Channel. For example, add to cart, or purchase. When an indirect event is used for training, it will only be taken into account if it was preceeded by an engagement event with the Channel, i.e. click.';
		var engagementTip = 'Engagement events are events that occur directly on a Channel, clicks for example, that correlated with an indirect event. For example, if a user click on the Channel and then adds a product to the cart. The click would be an engagement event that correlates to the indirect add to cart event.';
		return (
			<div className='grid panel-cell'>
				<div className='grid-cell default-100 pad-cell-top'>
					<label>Content ID <Hint width='250px' content={weightToolTip}/></label>
					<InputField 
						value={this.props.contentId}
						pattern='[A-z0-9_.-]+' 
						invalid={this.state.invalidFields.includes( 'id' )}
						onChange={( value ) => { this.#handleFieldChanged( 'id', value ); }}/>
				</div>
				<div className='grid-cell default-100 pad-cell-top'>
					<label>Name <Hint width='250px' content={weightToolTip}/></label>
					<InputField 
						invalid={this.state.invalidFields.includes( 'name' )}
						onChange={( value ) => { this.#handleFieldChanged( 'name', value ); }}/>
				</div>
				<div className='grid-cell default-100 pad-cell-top'>
					<label>Add to Channel (optional) <Hint width='250px' content={eventTypeToolTip}/></label>
					<DropDownField 
						width='100%'
						itemsWidth='100%' 
						labelField='name' 
						items={SphereAdminSession.channels}
						changeHandler={( item ) => { this.#handleChannelSelected( item ); }}/>
				</div>
				<div className='grid-cell default-100' style={{ padding: '20px 0 0 0', textAlign: 'center' }}>
					<div className='primary-button' style={{width: '90px'}} onClick={this.#handleSave.bind( this )}>Transfer</div>
					<div className='button control-pad-left' style={{width: '90px'}} onClick={this.#handleCancel.bind( this )}>Cancel</div>
				</div>
			</div>
		);
	}

	//

	/**
	 * Handles the response from the request to retrieve Channel
	 */
	#handleChannelsRetrieved( command ) {
		this.setState({ loading: false, channels: SphereAdminSession.channels });
		var positionModal = new PositionModalCommand();
		positionModal.execute();
	};

	/**
	 * Handles changes to the input fields, invalidating the Channel object
	 */
	#handleFieldChanged( field, value ) {
		this.content[ field ] = value;
		var index = this.state.invalidFields.indexOf( field );
		if( index !== -1 && value.split( ' ' ).join( '' ) !== '' ) {
			this.state.invalidFields.splice( index, 1 );
			this.setState({ invalidFields: this.state.invalidFields });			
		}
	};

	/**
	 * Handles changes the Channel selection
	 */
	#handleChannelSelected( item ) {
		this.channelId = item.id;
	}

	//

	/**
	 * Closes the Predictive Attribute editor modal
	 */
	#handleSave( e ) {
		
		// reset the state
		this.state.invalidFields = [];

		var fields = { 
			id: ValidatorCommand.isNotNullOrEmpty, 
			name: ValidatorCommand.isNotNullOrEmpty 
		};

		var validateSite = new ValidatorCommand( this.content, fields );
		var isValid = validateSite.execute();

		//console.info( 'isValid', isValid );
		//console.info( 'invalidFields', validateSite.getInvalidFields() );

		if( isValid ){
			this.setState({ loading: true });
			var saveContent = new SaveContentRequest( this.content );
			saveContent.execute(( command ) => { this.#handleSaveContentComplete( command ); });
		} else {
			var invalidFields = validateSite.getInvalidFields();
			this.setState({ invalidFields: invalidFields });
		}

		//this.props.saveHandler();
		//var closeModal = new CloseModalCommand();
		//closeModal.execute();
	}

	/**
	 * Handles completion of the save Content request
	 */
	#handleSaveContentComplete( command ){
		console.info( 'TransferContentModal::handleSaveComplete content', SphereAdminSession.content );
		if( this.channelId != null ){
			// First, get the Channel
			var retrieveChannel = new RetrieveChannelRequest( this.channelId );
			retrieveChannel.execute(( command ) => { this.#handleChannelRetrieved( command ); });

			
		} else {
			this.props.closeHandler();
			var closeModal = new CloseModalCommand();
			closeModal.execute();
			// this.setState({ complete: true });
		}
	}

	/**
	 * Handles completion of the SaveContentToChannelRequest
	 */
	 #handleChannelRetrieved( command ){
		var channel = SphereAdminSession.channel;
		console.info( 'TransferContentModal::handleSaveToChannelComplete', channel );
		
		if( channel != null ){
			if( channel.content == null ) channel.content = [];
			channel.content.push( this.content );
			var saveChannel = new SaveChannelRequest( channel, true );
			saveChannel.execute(( command ) => { this.#handleSaveToChannelComplete( command ); });
		} 
		// TODO: else show error
	}

	/**
	 * Handles completion of the SaveContentToChannelRequest
	 */
	#handleSaveToChannelComplete( command ){
		console.info( 'TransferContentModal::handleSaveToChannelComplete' );
		this.props.closeHandler();
		var closeModal = new CloseModalCommand();
		closeModal.execute();
	}

	/**
	 * Closes the Modal
	 */
	#handleCancel( e ) {
		this.props.closeHandler();
		var closeModal = new CloseModalCommand();
		closeModal.execute();
	}

}

export default TransferContentModal;