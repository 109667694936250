/*
 * (c) Verra Technology Corporation
 */

import ModifiableObject from './ModifiableObject.mjs';

/**
 * Represents a metric 
 */
class Metric extends ModifiableObject {

	/**
	 * Defines the structure of the OptimizationMetric class. Used with ObjectUtil.CreateFromDefinition
	 */
	static definition = {
		type: Metric
	}

	// Properties

	/**
	 * The ID of the account in which the Optimization is associated
	 */
	accountId = null;

	/**
	 * The engagement event used to track performance
	 */
	engagementEventId; // = 'experience-load';
	
	/**
	 * How the engagement event is measured, either once per session or every occurance
	 */
	engagementEventFrequency = 1;
	
	/**
	 * A value for enagement events that require it such as element viewed or clicked
	 */
	engagementEventValue;
	
	/**
	 * The conversion event used to track performance
	 */
	conversionEventId;
	
	/**
	 * How the conversion event is measured, either once per session or every occurance
	 */
	conversionEventFrequency = 0;
	
	/**
	 * A value for conversion events that require it such as element viewed or clicked
	 */
	conversionEventValue;
	
	/**
	 * The conversion rate in which this metric currently performs without any changes to the experience
	 */
	baselineCvr;
	
	/**
	 * The minimum detectable effect is the amount of change to the conversion rate that you would like to detect
	 */
	mde;
	
}

//

export default Metric;