/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SphereAdminConfig from '../model/SphereAdminConfig';
import SphereAdminSession from '../model/SphereAdminSession';
import StateCommandMap from '../model/StateCommandMap';

/**
 * Command used to set the state of the admin
 */
class SetStateCommand extends Command {
	
	/**
	 * Constructs the command.
	 * @param state The new state.
	 * @param parameters Parameters for the state
	 * @param skipPush Whether or not to skip updating the history object
	 * @param clearModels Whether or not to clear out models in the session
	 */
	constructor( state, parameters, skipPush, clearModels ) {
		super();
		this.state = state;
		this.parameters = parameters;
		this.skipPush = ( skipPush != null ) ? skipPush : false;
		this.clearModels = ( clearModels != null ) ? clearModels : true;
	}
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		// console.info( 'SetStateCommand::handleExecute', this.state, this.skipPush );

		let mappedState = null;

		// if SphereAdminConfig.statesMap doesn't exist the config data has not been loaded yet
		if( SphereAdminConfig.statesMap != null ) {
			mappedState = SphereAdminConfig.statesMap[ this.state ];
			if( mappedState != null && mappedState.path != null ) {
			
				let path = mappedState.path;
				if( this.parameters != null ){
					for( var parameter in this.parameters ){
						if( path.indexOf( '{' + parameter + '}' ) !== -1 ) {
							path = path.split( '{' + parameter + '}' ).join( this.parameters[ parameter] );
						}
					}
				}

				// break out of the iframe if we're not in an experience editing state
				if( 
					this.state != AdminStates.ADMIN_EXPERIENCES_CREATE && 
					this.state != AdminStates.ADMIN_EXPERIENCES_EDIT && 
					this.state != AdminStates.ADMIN_OPTIMIZATIONS_LOCATE_ELEMENT && 
					window.location !== window.parent.location
				 ){
					window.parent.location.href = window.location.origin + path;
				} else if( !this.skipPush ) {
					// WARNING: calling pushState clears out the contents of window.location.search, we track it manually
					SphereAdminSession.locationSearch = window.location.search;
					window.history.pushState({ state: this.state, parameters: this.parameters }, '', path );
				}
				
			} else if( mappedState?.url != null ) {
				window.open( mappedState.url, '_blank').focus();
			} else if( mappedState?.command != null ) {
				const StateCommand = StateCommandMap[ mappedState.command ];
				if( StateCommand != null ) {
					const command = new StateCommand( this.parameters );
					command.execute();
				}
			}
		}

		if( AdminStates[ this.state ] == null ) throw new Error( 'Invalid state ' + this.state );

		if( this.clearModels ) SphereAdminSession.clearModelState();

		if( mappedState?.url == null && mappedState?.command == null ) {
			const currentState = SphereAdminSession.currentState;
			if( this.state !== currentState ) {
				SphereAdminSession.priorState = { state: currentState, parameters: this.parameters, skipPush: this.skipPush, clearModels: this.clearModels };
				SphereAdminSession.stateParameters = this.parameters;
				SphereAdminSession.adminInstance.setState({ currentState: this.state, modals: [] }); // TODO: move to set method in SphereAdminSession
			}
		}
	}
	
}

export default SetStateCommand;
