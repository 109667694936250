/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminServices from '../../model/SphereAdminServices';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import SphereAdminSession from '../../model/SphereAdminSession';

/**
 * Adds an Experience to an Optimization
 */
class AddExperienceToOptimizationRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param optimizationId The ID of the Optimization to add the Experience to
	 * @param experience The Experience to add
	 */
	constructor( optimizationId, experience ){
		super();
		this.optimizationId = optimizationId;
		this.experience = experience;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.ADD_EXPERIENCE_TO_OPTIMIZATION;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: this.experience.accountId, optimizationId: this.optimizationId, experienceId: this.experience.id };
	}

   /**
	* Handles completion of the command
	*/
	// handleComplete() {
		// const response = this.getResponse();
		// if( response.success ) {
		// 	// TODO: handle error
		// }
		//console.info( 'SaveContentRequest::handleComplete content', this.content );
	// }
 
}

export default AddExperienceToOptimizationRequest;
