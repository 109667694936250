/*
 * (c) Verra Technology Corporation
 */

/**
 * Defines data types for CSS properties
 */
const CSSPropertyType = {

	/**
	 * 
	 */
	TEXT: 0,

	/**
	 * 
	 */
	NUMERIC: 1,

	/**
	 * 
	 */
	COLOR: 2,

	/**
	 * 
	 */
	OPTION: 3

};

export default CSSPropertyType;