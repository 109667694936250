/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import CalendarIcon from '../icons/CalendarIcon';
import ClockIcon from '../icons/ClockIcon';
import PlaceIcon from '../icons/PlaceIcon';
import SettingsIcon from '../icons/SettingsIcon';
import CancelIcon from '../icons/CancelIcon';
import PageIcon from '../icons/PageIcon';
import UserIcon from '../icons/UserIcon';
import LayersIcon from '../icons/LayersIcon';
import WorldIcon from '../icons/WorldIcon';
import ScreenIcon from '../icons/ScreenIcon';
import LaptopIcon from '../icons/LaptopIcon';

//

/**
 * Maps Predictive Attribute Data Types to icons
 */
var predictiveAttributeDataTypeIconMap = {
	'Hour': <ClockIcon/>,
	'Weekday': <CalendarIcon/>,
	'Day of Month': <CalendarIcon/>,
	'Month': <CalendarIcon/>,

	'Lat & Long': <PlaceIcon/>,
	'Postal Code': <PlaceIcon/>,
	'City': <PlaceIcon/>,
	'State': <PlaceIcon/>,
	'Country': <WorldIcon/>,

	'Current Page': <PageIcon/>,
	'Referrer': <ScreenIcon/>,
	'User ID': <UserIcon/>,
	'Session ID': <LaptopIcon/>,

	'Screen Resolution': <LaptopIcon/>,

	'Pages Viewed': <LayersIcon/>
};

//

/**
 * PredictiveAttributeIcon Component
 */
class PredictiveAttributeIcon extends Component {
	
	/**
	 * Constructs the Component
	 */
	constructor() {
		super();
		this.state = {
			actionsVisible: false
		};
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		
		var style = ( this.props.style === undefined ) ? {} : this.props.style;
		var icon = predictiveAttributeDataTypeIconMap[ this.props.attribute.name ];
		if( icon == null ) icon = <SettingsIcon/>;

		let attribute = this.props.attribute;
		var editHandler = () => { this.props.editHandler( attribute ); };
		var removeHandler = ( e ) => { e.stopPropagation(); this.props.removeHandler( attribute ); };

		// TODO: handle long names

		return ( 
			<div 
				className='predictive-attribute-icon' 
				style={style} onMouseOver={handleRollOver.bind( this )} 
				onMouseOut={handleRollOut.bind( this )}
				onClick={editHandler}>
				<div className='icon'>
					{icon}
				</div>
				<div className='label'>{this.props.attribute.name}</div>
				<div 
					className='remove-button' 
					style={{ display: (( this.state.actionsVisible ) ? 'block' : 'none' )}}
					onClick={removeHandler}>
					<CancelIcon/>
				</div>
			</div>
		);
	}
	
}

//

/**
 * Handles the rollover of the icon, displays the actions
 */
function handleRollOver( e ) {
	this.setState({ actionsVisible: true });
}

/**
 * Handles the rollover of the icon, displays the actions
 */
function handleRollOut( e ) {
	this.setState({ actionsVisible: false });
}

//

export default PredictiveAttributeIcon;
