/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';

/**
 * Retrieves the sites the user has permission to work with
 */
class RetrieveChannelsListRequest extends ServiceRequestCommand {

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getService() {
		return SphereAdminServices.RETRIEVE_CHANNELS_LIST;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		var response = this.getResponse();
		//console.info( 'RetrieveChannelsRequest::handleComplete', response );
		if( response.success ) {
			SphereAdminSession.channels = this.getResponse().result;
		}
	}
 
}

export default RetrieveChannelsListRequest;
