/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ContentEditor from '../components/editors/ContentEditor';
import SphereAdminSession from '../model/SphereAdminSession';
import Content from '../../model/Content.mjs';

//

/**
 * The CreateContentPanel contains the administration UI for ceating Content
 */
class CreateContentPanel extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();

		// console.info( 'CreateContentPanel::ctor' );

		this.editorRef = React.createRef();

		// TODO: this should be a command or factory?
		const content = new Content();
		content.status = 0;
		content.accountId = SphereAdminSession.selectedAccount.id;
		content.id = '';
		content.name = '';
		content.body = '';
		content.properties = '';
		SphereAdminSession.content = content;
		
		setTimeout( () => { this.#resizeEditor(); }, 200 );	
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return <ContentEditor ref={this.editorRef}/>;
	}

	/**
	 * Resizes the edit and preview containers
	 */
	#resizeEditor(){
		// console.info( 'resizeEditor', this.editorRef );
		if( this.editorRef.current != null ) this.editorRef.current.resizeContentContainers();
	}

}

//

export default CreateContentPanel;
