import React from 'react';

var PencilIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 18;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg viewBox="0 0 24 24" width={size} height={size}>
			<g className="nc-icon-wrapper" strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill={color} stroke={color}>
				<line datacap="butt" datacolor="color-2" fill="none" strokeMiterlimit="10" x1="14.5" y1="4.5" x2="19.5" y2="9.5" strokeLinecap="butt"/>
				<line datacap="butt" datacolor="color-2" fill="none" strokeMiterlimit="10" x1="3" y1="16" x2="8" y2="21" strokeLinecap="butt"/>
				<path fill="none" stroke={color} strokeMiterlimit="10" d="M8,21l-6,1l1-6 L16.586,2.414c0.781-0.781,2.047-0.781,2.828,0l2.172,2.172c0.781,0.781,0.781,2.047,0,2.828L8,21z"/>
			</g>
		</svg>
	);
};

export default PencilIcon;