/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand.js';
import SphereAdminSession from '../../model/SphereAdminSession.js';
import SphereAdminServices from '../../model/SphereAdminServices.js';
import ModifiableObject from '../../../model/ModifiableObject.mjs';

/**
 * Saves an AnalyticsEventType
 */
class SaveEventTypeRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param eventType The AnalyticsEventType to save
	 */
	constructor( eventType ){
		super();
		this.eventType = eventType;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.SAVE_EVENT_TYPE;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { eventType: this.eventType };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		var response = this.getResponse();
		//console.info( 'SaveEventTypeRequest::handleComplete', response );
		if( response.success ) {
			this.eventType.status = ModifiableObject.SAVED; // TODO: should this come from the DB?
			SphereAdminSession.eventTypes.push( this.eventType );
			SphereAdminSession.eventTypesMap[ this.eventType.id ] = this.eventType;
		} // TODO: else handle error
	}
 
}

export default SaveEventTypeRequest;
