/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import SphereAdminSession from '../model/SphereAdminSession';
import ChannelEditor from '../components/editors/ChannelEditor';
import RetrieveChannelRequest from '../requests/channels/RetrieveChannelRequest';
import SetStateCommand from '../commands/SetStateCommand';
import Alert from '../components/controls/Alert';
import OpenModalCommand from '../commands/OpenModalCommand';
import AdminStates from '../model/AdminStates';

//

/**
 * The ContentPanel contains the administration UI for a Channel
 */
class EditChannelPanel extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();
		const channelId = SphereAdminSession.stateParameters.channelId;
		if( SphereAdminSession.channel == null || ( SphereAdminSession.channel != null && SphereAdminSession.channel.id !== channelId )){
			SphereAdminSession.channel = null;
			SphereAdminSession.loading = true;
			var retrieveChannel = new RetrieveChannelRequest( channelId );
			retrieveChannel.execute(( command ) => { this.#handleChannelRetrieved( command ); } );
		}
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		var jsx = ( SphereAdminSession.channel !== null ) ? <ChannelEditor showPublishBtn={true}/> : '';
		return jsx;
	}

	/**
	 * Handles the response from the request to retrieve the Channel
	 */
	 #handleChannelRetrieved( command ) {
		//console.info( 'handleChannelRetrieved', this.setState );
		// SphereAdminSession.channel.locked = true;
		SphereAdminSession.loading = false;
		if( SphereAdminSession.channel != null ){
			this.setState({ invalidated: true });
	 	} else {
			var setState = new SetStateCommand( AdminStates.ADMIN_CHANNELS_BROWSE );
			setState.execute();

			var alert = <Alert content='Channel does not exist' showCancelBtn={false}/>;
			var openModal = new OpenModalCommand( '', alert, '500px', true );
			openModal.execute();
		}
	};
}

//

export default EditChannelPanel;
