/*
 * (c) Verra Technology Corporation
 */

import Audience from "../../model/Audience";
import AudienceRule from "../../model/AudienceRule";
import SphereAdminSession from "../model/SphereAdminSession";

/**
 * Factory class for creating Audience model objects
 */
const AudienceFactory = {

	/**
	 * Creates a new, empty Audience
	 * @return Audience
	 */	
	createAudience: function(){
		const audience = new Audience();
		audience.accountId = SphereAdminSession.selectedAccount.id;
		return audience;
	},

	/**
	 * Creates a new AudienceRule from an audience type object
	 * @return AudienceRule
	 */	
	createAudienceRule: function( audience ){
		const rule = new AudienceRule();
		rule.accountId = SphereAdminSession.selectedAccount.id;
		rule.id = window.crypto.randomUUID();
		rule.position = audience.rules.length;
		audience.addRule( rule );
		return rule;
	}

};

export default AudienceFactory;
