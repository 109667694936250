import React from 'react';

const NotesIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg height={size} width={size} viewBox="0 -960 960 960" >
			<g className='nc-icon-wrapper' stroke='none' fill={color}>
				<path d="M95-203v-95h531v95H95Zm0-230v-94h771v94H95Zm0-229v-95h771v95H95Z"/>
			</g>
		</svg>
	);
};

export default NotesIcon;