/*
 * (c) Verra Technology Corporation
 */

import jstat from 'jstat';
import OptimizationEventAnalytics from "./OptimizationEventAnalytics";

//

const PRIOR = 0.5;
const SAMPLES = 5000;

const zeroArray = function(length) {
	var arr = [], i = length;
	while (i--) {
		arr[i] = 0;
	}
	return arr;
};

//

/**
 * Class for representing event analytics for a specific Optimization Metric.
 */
class OptimizationMetricAnalytics extends OptimizationEventAnalytics {

	// 

	/**
	 * The Metric
	 */
	#metric;

	/**
	 * The engagement event analytics associated with the Metric analytics
	 */
	#engagementEventAnalytics;

	// Conversion Metrics 

	/**
	 * The conversion rate for this event
	 */
	cvr = 0;

	/**
	 * jstat beta object for calculating probablities
	 */
	beta;

	// Place Order Specific Metrics

	/**
	 * Total revenue for the order
	 */
	revenue;

	/**
	 * The average order value associated with the event.
	 */
	aov;

	/**
	 * Revenue per engagement
	 */
	rpe;

	//

	/**
	 * Constructs a new OptimizationEventAnalytics model object. 
	 * @param optimization The Optimization the analytics are for
	 * @param 
	 */
	constructor( optimization, engagementEventAnalytics, metric ) {
		super( optimization, metric.conversionEventId );
		this.#engagementEventAnalytics = engagementEventAnalytics;
		this.#metric = metric;
	}

	//

	/**
	 * Calculates analytics for the Metric
	 */
	#calculateAnalytics( eventData ) {
		const metricProp = ( this.#metric.engagementEventFrequency === 0 ) ? 'count' : 'sessions';
		const engagementCount = this.#engagementEventAnalytics?.[ metricProp ] || 0;
		this.cvr = ( engagementCount > 0 ) ? this.count / engagementCount : 0;

		if( this.#metric.conversionEventId === 'place-order' ) {
			this.aov = eventData?.subtotal?.average || 0;
			this.revenue = eventData?.subtotal?.total || 0;
			this.rpe = ( engagementCount > 0 ) ? this.revenue / engagementCount : 0;
		}

		Object.keys( eventData.experiences ).forEach( experienceId => {
			const id = ( experienceId.indexOf( 'default:' ) === 0 ) ? 'default' : experienceId;
			const data = eventData.experiences[ id ];
			this.#calculateExperienceConversionRate( id );
			this.#calculateExperienceBeta( id );
			if( this.#metric.conversionEventId === 'place-order' ) this.#calculateOrderMetrics( id, data );
		});

		this.#updateExperienceProbabilities();
	}

	/**
	 * Calculates conversion rate
	 */
	#calculateExperienceConversionRate( experienceId ) {
		const metricProp = ( this.#metric.engagementEventFrequency === 0 ) ? 'count' : 'sessions';
		const engagementCount = this.#engagementEventAnalytics?._experiences?.[ experienceId ]?.[ metricProp ] || 0;
		if( engagementCount > 0 ) {
			const conversionCount = this?._experiences?.[ experienceId ]?.count || 0;
			this._experiences[ experienceId ].cvr = conversionCount / engagementCount;
		}
	}

	/**
	 * Calculates conversion rate
	 */
	#calculateExperienceBeta( experienceId ) {
		const metricProp = ( this.#metric.engagementEventFrequency === 0 ) ? 'count' : 'sessions';
		const experienceEngagementAnalytics = this.#engagementEventAnalytics?._experiences?.[ experienceId ];
		if( experienceEngagementAnalytics != null ) {
			const conversionExperienceAnalytics = this._experiences[ experienceId ];
			conversionExperienceAnalytics.beta = jstat.beta( conversionExperienceAnalytics.count + PRIOR, experienceEngagementAnalytics[ metricProp ] - conversionExperienceAnalytics.count + PRIOR );
		}
	}

	/**
	 * Calculates conversion rate
	 */
	#calculateOrderMetrics( experienceId, eventData ) {
		const metricProp = ( this.#metric.engagementEventFrequency === 0 ) ? 'count' : 'sessions';
		const engagementAnalytics = this.#engagementEventAnalytics?._experiences?.[ experienceId ];

		if( engagementAnalytics != null ) {
			const engagementExpCount = engagementAnalytics[ metricProp ];
			const expAnalytics = this._experiences[ experienceId ];

			expAnalytics.aov = eventData?.subtotal?.average || 0;
			expAnalytics.revenue = eventData?.subtotal?.total || 0;
			expAnalytics.rpe = expAnalytics.revenue / engagementExpCount;

			expAnalytics.rpcvr = this.#engagementEventAnalytics[ metricProp ] * expAnalytics.cvr * this.aov;
			expAnalytics.rpcvrd = expAnalytics.rpcvr - this.revenue;

			expAnalytics.rpaov = this.count * expAnalytics.aov;
			expAnalytics.rpaovd = expAnalytics.rpaov - this.revenue;

			expAnalytics.rpcvraov = this.#engagementEventAnalytics[ metricProp ] * expAnalytics.cvr * expAnalytics.aov;
			expAnalytics.rpcvraovd = expAnalytics.rpcvraov - this.revenue;
		}
	}

	/**
	 * Updates probabilities for all content and the control
	 */
	#updateExperienceProbabilities(){
		if( this.cvr > 0 ) {
			const experienceKeys = Object.keys( this._experiences );
			const counts = zeroArray( experienceKeys.length ); 
			let value;
			let experienceAnalytics;
			let x;
			let index;
			let i, j;
			for( i = 0; i < SAMPLES; i++ ) {
				value = 0; 
				for ( let j = 0; j < experienceKeys.length; j++ ) {
					experienceAnalytics = this._experiences[ experienceKeys[ j ]];
					if( experienceAnalytics.beta != null ) {
						x = experienceAnalytics.beta.sample();
						if ( x > value ) {
							index = j;
							value = x;
						} 
					}
				}
				counts[ index ]++;
			}

			for ( j = 0; j < experienceKeys.length; j++ ) {
				const key = experienceKeys[ j ];
				const id = ( key.indexOf( 'default:' ) === 0 ) ? 'default' : key;
				experienceAnalytics = this._experiences[ key ];
				experienceAnalytics.successProbability = counts[ j ] / ( SAMPLES / 100 );
			}
		}
	}

	//

	/**
	 * Updates the data for the analytics object
	 */
	updateAnalytics( eventData ) {
		super.updateAnalytics( eventData );
		this.#calculateAnalytics( eventData );
	}

}

//

export default OptimizationMetricAnalytics;