/*
 * (c) Verra Technology Corporation
 */


//

let _user = null;

/**
 * Manages the Sphere Administration application session / state
 */
const SphereAdminSession = { 
	
	/**
	 * An instance of the SphereAdmin component
	 */
	adminInstance: null,

	// State Management

	/**
	 * @return The prior state of the admin
	 * @see sphere.admin.model.AdminStates
	 */
	priorState: null,

	 /**
	  * @return The current state of the admin
	  * @see sphere.admin.model.AdminStates
	  */
	get currentState() {
		return SphereAdminSession.adminInstance.state.currentState;
	},
 
	 /**
	  * Any parameters that were provided when the state changed
	  */
	stateParameters: null,
 
	// User & Token

	/** 
	 * @return The auth token for the user
	 */
	get token() {
	   return window.localStorage.getItem( 'verra-id' );
	},

   /**
	* Sets the auth token
	*/
	set token( theToken ) {
		if( theToken != null ){
			window.localStorage.setItem( 'verra-id', theToken );
		} else {
			window.localStorage.removeItem( 'verra-id' );
		}
	},

	/**
	 * The ID of the active user
	 */
	userId: null,

	/**
	 * @return The user
	 */
	get user() {
		return _user;
	},

	/**
	 * Sets the user
	 */
	set user( theUser ) {
		_user = theUser;

		// update the accounts with default, empty, sites
		var accounts = theUser.accounts;
		// for( var i = 0; i < accounts.length; i++ ){
		// 	var account = accounts[ i ];
		// 	account.sites = [];
		// }
		
		SphereAdminSession.accounts = accounts;
		if( accounts.length > 0 ) SphereAdminSession.selectedAccount = accounts[ 0 ];
	},

	// UI State

	/**
	 * @return The of the onboarding experience. If false the customer has not onboarded or dismisssed the onboarding screen. 
	 */
	get hasOnboarded() {
		return window.localStorage.getItem( 'verra-onboarded' );
	},

	/**
	 * Sets the onboarded state
	 */
	set hasOnboarded( onboarded ) {
		return window.localStorage.setItem( 'verra-onboarded', onboarded );
	},

	/**
	 * The sign in error if a sign in has failed
	 */
	signInError: null,
	
	/**
	 * The register error if a registration has failed
	 */
	registrationError: null,

	/**
	 * Adds a Modal to the UI modal stack
	 */
	pushModal: function( modal ) {
		const modals = SphereAdminSession.adminInstance.state.modals;
		modals.push( modal );
		SphereAdminSession.adminInstance.setState({ modals });
	},
	
	/**
	 * Removes the last Modal from the UI modal stack
	 */
	popModal: function( modal ) {
		const modals = SphereAdminSession.adminInstance.state.modals;
		modals.pop();
		SphereAdminSession.adminInstance.setState({ modals });
	},
	
	/**
	 * Returns the top most modal
	 */
	get modal() {
		const modals = SphereAdminSession.adminInstance.state.modals;
		return modals[ modals.length - 1 ];
	},
	
	/** 
	 * @return The auth token for the user
	 */
	get loading() {
		return SphereAdminSession.adminInstance.state.loading;
	},
 
	/**
	 * Sets the auth token
	 */
	set loading( isLoading ) {
		SphereAdminSession.adminInstance.setState({ loading: isLoading });
	},
 
	/**
	 * @return Whether or not the nav should be in a minimized state
	 */
	get navMinimized(){
		let minimized = window.localStorage.getItem( 'verra-nav-minimized' );
		if( minimized == null ) minimized = true;
		return minimized;
	},

	/**
	 * Sets whether or not the nav should be in a minimized state
	 */
	set navMinimized( minimized ) {
		window.localStorage.setItem( 'verra-nav-minimized', minimized );
	},

	// Model Objects

	/**
	 * Clears any model objects that were created and/or retrieved for viewing
	 * and editing
	 */
	clearModelState: function(){
		SphereAdminSession.content = null;
		SphereAdminSession.channel = null;
		SphereAdminSession.optimization = null;
		SphereAdminSession.experience = null;
		SphereAdminSession.audience = null;
		SphereAdminSession.site = null;
	},

	/**
	 * An array of Accounts
	 */
	accounts: [],
	
	/**
	 * The active, selected account
	 * TODO: persist the selected account across sessions
	 */
	selectedAccount: null,
	
	/**
	 * The Sites available to the selected account
	 */
	sites: [],
	
	/**
	 * The AnalyticEventTypes available to the selected account
	 */
	eventTypes: [],
	
	/**
	 * A map of event type id to the event type object
	 */
	eventTypesMap: {},
	
	/**
	 * A list of Content objects that is displayed on the Content list pane
	 */
	contentList: {},

	/**
	 * The ID of the Content object being edited
	 * TODO: don't think this is needed, the content object below covers this
	 */
	// contentId: null,
	
	/**
	 * A Content object that is being edited or created
	 */
	content: {},
	
	/**
	 * The list of Channels for the account
	 */
	channels: null,

	/**
	 * A Channel object that is being edited or created
	 */
	channel: null,

	// Optimizations

	/**
	 * Whether or not the user is in the middle of editing/creating an optimization
	 */
	isEditingOptimization: false,

	/**
	 * The optimizations associated with the account
	 */
	optimizations: [],
	
	/**
	 * An Optimization object that is being edited or created
	 */
	optimization: null,
	
	/**
	 * The experiences associated with the account
	 */
	experiences: [],
	
	/**
	 * An Experience object that is being edited or created
	 */
	experience: null,
	
	/**
	 * The Audiences for the account
	 */
	audiences: [],
	
	/**
	 * An Audience object that is being edited or created
	 */
	audience: null,
	
	/**
	 * The Metrics for the account
	 */
	metrics: [],
	
	/**
	 * An Metric object that is being edited or created
	 */
	metric: null,

	//
	
	/**
	 * A Site object that is being edited or created
	 */
	site: null
	
}

export default SphereAdminSession;
