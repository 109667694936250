/*
 * (c) Verra Technology Corporation
 */

import React from 'react';
import Command from '../../commands/Command.mjs';
import Modal from '../components/modals/Modal';
import SphereAdminSession from '../model/SphereAdminSession';

/**
 * Command used to set the state of the admin
 */
class OpenModalCommand extends Command {
	
	/**
	 * Constructs the command.
	 * @param The new state.
	 */
	constructor( title, content, width, closeOnClick ){
		super();
		this.title = title;
		this.content = content;
		this.width = width;
		this.closeOnClick = closeOnClick;
	}
	
	/**
	 * Handles execution of the command
	 */
	handleExecute(){
		const modalRef = React.createRef();
		const modal = <Modal ref={ modalRef } key={ window.crypto.randomUUID() } closeOnClick={ this.closeOnClick } title={ this.title } content={ this.content } width={ this.width }/>;
		SphereAdminSession.pushModal( modal );
	}
	
}

export default OpenModalCommand;
