/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import SphereAdminSession from '../model/SphereAdminSession';
import SelectSiteModal from '../components/modals/SelectSiteModal';
import OpenModalCommand from '../commands/OpenModalCommand';
import EditOptimizationCommand from '../commands/EditOptimizationCommand';
import InputField from '../components/controls/InputField';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import AdminStates from '../model/AdminStates';
import SetStateCommand from '../commands/SetStateCommand';
import ElementUtil from '../../util/ElementUtil';

//

/**
 * The LocateElementPanel contains the UI to allow the user to select an element on a site. Used for events
 */
class LocateElementPanel extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();
		
		// We are still on the admin site and have not been redirected to the chosen site to locate the element
		if( window.location === window.parent.location ) {
			this.state = { onSite: false };
			// Check for a siteId in the session
			let site;
			if( SphereAdminSession.siteId != null ) {
				// grab the site
				site = SphereAdminSession.sites.find( site => site.id === SphereAdminSession.siteId );
			}

			if( site != null ) {
				this.#redirectToSite( site );
			} else {
				const selectSite = <SelectSiteModal selectHandler={ this.#handleSiteSelected.bind( this )} cancelHandler={ this.#handleCancelSelectSite.bind( this )}/>;
				const openModal = new OpenModalCommand( 'Select Site' , selectSite, 350, false );
				openModal.execute();
			}
		} 
		// We are now on the site (different domain) that is assigned to the Experience. In this case, the url params
		// will have an experienceId and we'll use that to figure out where to get the Experience model object.
		else {
			const params = new URLSearchParams( SphereAdminSession.locationSearch );
			SphereAdminSession.optimizationId = params.get( 'verra-optimization-id' );

			this.state = { 
				onSite: true,
				selectedSite: null, 
				path: '',
				querySelector: null,
				primaryElement: null,
				selectedElements: []
			};
			
			this.iFrameContainerRef = React.createRef();
			this.iFrameRef = React.createRef();
			this.hoverHighlight = React.createRef();
			this.selectedHighlightsContainer = React.createRef();

			if( window.location !== window.parent.location ){
				window.addEventListener( 'message', this.#handleWindowMessage.bind( this ));
				window.parent.postMessage({ action: 'GET_SITE' }, '*' );
			}

			window.addEventListener( 'resize', this.#handleResize.bind( this ) );
			setTimeout( () => { this.#handleResize(); }, 1000 );
		}
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return ( this.state.onSite ) ? this.#getLocateElementMarkup() : '';
	}

	/**
	 * @return The locate element UI markup
	 */
	#getLocateElementMarkup() {
		return 	<div className='content-panel locate-element-panel no-select'>
					{ this.#getHeaderMarkup() }
					{ this.#getControlsMarkup() }
					{ this.#getFrameMarkup() }
				</div>;
	}

	/**
	 * Gets the markup for the header section
	 */
	#getHeaderMarkup() {
		const breadcrumb = <div className='breadcrumb'><a href='/optimizations/'>Optimizations</a> / Locate Element</div>;
		const saveDisabled = ( this.state.selectedElements.length === 0 );
		const saveButtonsDisabledClass = ( saveDisabled ) ? ' disabled' : '';

		return 	<div className='grid'>
					<div className='grid-cell default-50'>
						<h2>Select Element</h2>
						{ breadcrumb }
					</div>
					<div className='grid-cell default-50 align-right header-actions'>
						<button 
							className={ 'primary-button control-pad-left' + saveButtonsDisabledClass } 
							disabled={ saveDisabled } 
							style={{ width: '80px' }}
							onClick={ this.#handleSelectElement.bind( this )}>Select</button>
						<button className={ 'button control-pad-left' } style={{ width: '80px' }} onClick={ this.#handleCancel.bind( this )}>Cancel</button>
					</div>
				</div>;
	}

	/**
	 * The markup for the experience controls
	 */
	#getControlsMarkup() {
		const selectedElements = this.state.selectedElements;
		const primaryElement = this.state.primaryElement;
		const isSingleElement = ( selectedElements?.length === 1 );

		const prevDisabled = ( isSingleElement && primaryElement.previousElementSibling != null ) ? '' : 'disabled';
		const nextDisabled = ( isSingleElement && primaryElement.nextElementSibling != null ) ? '' : 'disabled';
		const upDisabled = ( isSingleElement && primaryElement.parentElement != null ) ? '' : 'disabled';
		const downDisabled = ( isSingleElement && primaryElement.firstElementChild != null ) ? '' : 'disabled';

		return 	<div ref={ this.controlsContainerRef } className='panel-cell experience-editor-controls'>
					<button
						className={ 'control-button control-pad-left ' + prevDisabled }
						onClick={ e => this.#handleElementNavigation( primaryElement.previousElementSibling )}>
							<ArrowLeftIcon size='26' color='#ffffff'/>
					</button>
					<button
						className={ 'control-button control-pad-left ' + nextDisabled }
						onClick={ e => this.#handleElementNavigation( primaryElement.nextElementSibling )}>
							<ArrowRightIcon size='26' color='#ffffff'/>
					</button>
					<button
						className={ 'control-button control-pad-left ' + upDisabled }
						onClick={ e => this.#handleElementNavigation( primaryElement.parentElement )}>
							<ArrowUpIcon size='26' color='#ffffff'/>
					</button>
					<button
						className={ 'control-button control-pad-left control-pad-right ' + downDisabled }
						onClick={ e => this.#handleElementNavigation( primaryElement.firstElementChild )}>
							<ArrowDownIcon size='26' color='#ffffff'/>
					</button>

					<div id='selected-element-path'>
						<div 
							className='input' 
							contentEditable={ true }
							suppressContentEditableWarning={ true }
							onBlur={ e => this.#handleElementPathInputChanged( e.target.textContent ) }
							>
								{ this.state.querySelector }
							</div>
					</div>
				</div>;
	}

	/**
	 * @return The markup for displaying secondary fields
	 */
	#getFrameMarkup() {
		const hightlightDisplay = ( this.highlightedElement != null ) ? 'block' : 'none';
		const selectedDisplay = ( this.state.selectedElements?.length > 0 ) ? 'block' : 'none';

		const siteBaseUrl = ( this.state.selectedSite != null ) ? this.state.selectedSite.url : '';
		const path = siteBaseUrl + this.state.path;
		const frameDisplay = ( this.state.frameLoaded ) ? 'block' : 'none';

		return 	<div className='panel-cell pad-cell-left' style={{ display: frameDisplay }}>
					<div className='grid pad-cell-bottom'>
						<div className='grid-cell default-90'>
							<InputField value={ path } onChange={ value => this.state.path = value }/>
						</div>
						<div className='grid-cell default-10 pad-cell-left'>
							<button style={{ width: '100%' }} className={ 'button' } onClick={ this.#handleNavigateToPage.bind( this )}>Go</button>
						</div>
					</div>
					<div ref={ this.iFrameContainerRef } className='no-select' style={{ position: 'relative', overflow: 'hidden' }}>
						<div ref={ this.hoverHighlight } className='hover-highlight' style={{ display: hightlightDisplay }}></div>
						<div ref={ this.selectedHighlightsContainer } style={{ display: selectedDisplay }}></div>
						<iframe
							ref={ this.iFrameRef }
							width='100%'
							onLoad={ this.#handleIFrameLoaded.bind( this )}/>
					</div>
				</div>;
	}

	// Selection of Site in which to locate an element

	/**
	 * Handles a selection of a site from the Sites modal
	 */
	#handleSiteSelected( site ) {
		// console.info( 'handleSiteSelected', site );
		this.#redirectToSite( site )
	}

	/**
	 * Handles the cancelation of selecting a site
	 */
	#handleCancelSelectSite() {
		if( SphereAdminSession.optimization == null ) {
			const setState = new SetStateCommand( AdminStates.ADMIN_OPTIMIZATIONS );
			setState.execute();
		} else {
			const editOptimization = new EditOptimizationCommand( SphereAdminSession.optimization.id );
			editOptimization.execute();
		}
	}

	/**
	 * Redirects the user to the site assigned to the Experience, loading Verra in site mode
	 */
	#redirectToSite( site ) {
		const editMode = 'verra-edit-mode=locate';
		const verraId = `verra-id=${ SphereAdminSession.token }`;
		let url = `https://${ site.url }/?${ editMode }&${ verraId }&verra-optimization-id=${ SphereAdminSession.optimization.id }`;
		console.info( 'redirectToSite', url );
		window.location.href = url;

		// const editMode = 'verra-edit-mode=locate';
		// const siteId = `verra-site-id=${ site.id }`;
		// const experienceId = `verra-experience-id=${ ExperienceFactory.createExperienceId() }`;
		// const verraId = `verra-id=${ SphereAdminSession.token }`;
		// let url = `https://${ site.url }/?${ editMode }&${ siteId }&${ experienceId }&${ verraId }`;
		// if( SphereAdminSession.optimization != null ) url += `&verra-optimization-id=${ SphereAdminSession.optimization.id }`;
		// console.info( 'redirectToSite', url );
		// window.location.href = url;
	}

	// Post Message Handlers

	/**
	 * Handles iframe post message events
	 */
	#handleWindowMessage( event ) {
		// console.info( 'handleWindowMessage', event.data );
		if( event.data.action === 'SET_SITE' && this.iFrameRef.current != null ){
			this.#setSite( event.data.siteId, event.data.siteContent, event.data.path );
		}
	}

	/**
	 * Assigns the site content to the iframe
	 */
	#setSite( siteId, siteContent, path ) {
		this.iFrameRef.current.contentWindow.document.open();
		this.iFrameRef.current.contentWindow.document.write( siteContent );
		this.iFrameRef.current.contentWindow.document.close();

		// SphereAdminSession.experience.siteId = Number( siteId );
		const sites = SphereAdminSession.sites;
		const selectedSite = sites.find( site => site.id.toString() === siteId.toString() );
		this.setState({ path, selectedSite });
	}
	
	// iframe events

	/**
	 * Handles the load event from the iframe
	 */
	#handleIFrameLoaded( event ) {
		const iframe = this.iFrameRef.current;
		iframe.contentWindow.document.body.addEventListener( 'mouseover', this.#handleMouseOver.bind( this ));
		iframe.contentWindow.document.body.addEventListener( 'mouseout', this.#handleMouseOut.bind( this ));
		iframe.contentWindow.document.body.addEventListener( 'click', this.#handleDocumentClick.bind( this ), { capture: true });
		iframe.contentWindow.addEventListener( 'scroll', this.#handleIFrameScroll.bind( this ));
		iframe.contentWindow.addEventListener( 'resize', this.#handleIFrameResize.bind( this ));
		this.setState({ frameLoaded: true });
	}

	/**
	 * Handles mouse over events in the iframe
	 */
	#handleMouseOver( e ) {
		this.highlightedElement = e.target;
		const hoverHighlight = this.hoverHighlight.current
		hoverHighlight.style.display = 'block';
		this.#positionHighlight( hoverHighlight, this.highlightedElement );
	}

	/**
	 * Positions the element highlight
	 * @param highlight The highlight element, either for the moused over element or selected element
	 * @param elementToHighlight The element within the iframe to hightlight
	 */
	#positionHighlight( highlight, elementToHighlight ) {
		const elementRect = elementToHighlight.getBoundingClientRect();
		highlight.style.top = ( elementRect.y ) + 'px';
		highlight.style.left = ( elementRect.x ) + 'px';
		highlight.style.width = ( elementRect.width ) + 'px';
		highlight.style.height = ( elementRect.height ) + 'px';
	}

	/**
	 * Handles mouse out events in the iframe
	 */
	#handleMouseOut( e ) {
		const hoverHighlight = this.hoverHighlight.current;
		hoverHighlight.style.display = 'none';
		this.highlightedElement = null;
	}

	/**
	 * Handles all clicks within the document
	 */
	#handleDocumentClick( e ) {
		e.preventDefault();
		e.stopPropagation();

		const a = e.target.closest( 'a' );
		if( a != null ) this.state.path = a.href.split( window.location.origin )[ 1 ];

		this.state.selectedElements = null;
		this.state.querySelector = ElementUtil.getQuerySelectorPath( e.target );
		this.#selectElements([ e.target ]);
	}

	/**
	 * Selects an element for editing and/or import
	 */
	#selectElements( elements ) {
		this.selectedHighlightsContainer.current.innerHTML = '';

		let primaryElement;
		elements.forEach(( element, index ) => {
			if( index === 0 ) primaryElement = element;
			const highlight = document.createElement( 'div' );
			highlight.className = 'selected-highlight';
			this.selectedHighlightsContainer.current.appendChild( highlight );
			this.#positionHighlight( highlight, element ); 
		});

		const state = { primaryElement, selectedElements: elements };
		this.setState( state );
	}
	
	/**
	 * Handles a selection of a site from the Sites drop down
	 */
	#handleNavigateToPage() {
		const site = this.state.selectedSite;
		const url = `https://${ site.url }${ this.state.path }?verra-edit-mode=locate&verra-site-id=${ site.id }&verra-optimization-id=${ SphereAdminSession.optimizationId }&verra-id=${ SphereAdminSession.token }`;
		window.parent.postMessage({ action: 'NAVIGATE', url: url }, '*' );
	}

	/**
	 * Handles the scroll event from the iframe
	 */
	#handleIFrameScroll( e ) {
		this.#updateHighlightPositions();
	}

	/**
	 * Handles the resize event from the iframe
	 */
	#handleIFrameResize( e ) {
		this.#updateHighlightPositions();
	}

	/**
	 * Updates the highlight positions
	 */
	#updateHighlightPositions() {
		if( this.highlightedElement != null ) {
			this.#positionHighlight( this.hoverHighlight.current, this.highlightedElement );
		}
		if( this.state.selectedElements != null ){
			const highlights = document.querySelectorAll( '.selected-highlight' );
			highlights.forEach(( highlight, i ) => {
				this.#positionHighlight( highlight, this.state.selectedElements[ i ]);
			});
		}
	}

	//

	/**
	 * Handles window resize events
	 */
	#handleResize() {
		this.#resizeContentContainers();
	}

	/**
	 * Resizes the edit and preview containers
	 */
	#resizeContentContainers() {
		const iFrameContainer = this.iFrameContainerRef.current;
		if( iFrameContainer != null ){
			const clientHeight = window.innerHeight;
			const iFrameContainerRect = iFrameContainer.getBoundingClientRect();
			const height = clientHeight - iFrameContainerRect.y - 37;

			iFrameContainer.style.maxHeight = height + 'px';
			iFrameContainer.style.height = height + 'px';
			this.iFrameRef.current.style.height = height + 'px';
		}
	}

	//

	/**
	 * Navigates the selected content to a specifc element
	 */
	#handleElementNavigation( element ) {
		if( element != null ) {
			this.state.querySelector = ElementUtil.getQuerySelectorPath( element );
			this.#selectElements([ element ]);
		}
	}

	/**
	 * Handles the click to copy the selected elements path
	 */
	#handleElementPathInputChanged( value ) {
		const iFrame = this.iFrameRef.current;
		const elements = iFrame.contentWindow.document.querySelectorAll( value );
		this.state.querySelector = value;
		this.#selectElements( elements );
	}

	//

	/**
	 * Handles the click to select the element
	 */
	#handleSelectElement() {
		
	}

	/**
	 * Handles the cancellation to select the element
	 */
	#handleCancel() {
		const editOptimization = new EditOptimizationCommand( SphereAdminSession.optimizationId );
		editOptimization.execute();

	}

}

//

export default LocateElementPanel;
