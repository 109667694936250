/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SphereAdminSession from '../model/SphereAdminSession';
import SetStateCommand from './SetStateCommand';

/**
 * Intiates the edit or create content action in the admin
 */
class EditContentCommand extends Command {
	
	/**
	 * Constructs the command.
	 * @param contentId the ID of the content objdct ot edit or null if creating new content
	 */
	constructor( contentId ) {
		super();
		this.contentId = contentId;
		// TODO: consider - is it weird that this command doesn't also do the retrieval of the content?
	}
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		SphereAdminSession.contentId = this.contentId;
		var setState = new SetStateCommand( AdminStates.ADMIN_CONTENT_EDIT, { contentId: this.contentId });
		setState.execute();
	}
	
}

export default EditContentCommand;
