/*
 * (c) Verra Technology Corporation
 */

/**
 * Represents the available model types for an Optimization
 */
const OptimizationModelTypes = {
	
	/** 
	 * Standard, static AB test
	 */
	AB: 1,

	/** 
	 * Multivariate
	 */
	// MULTIVARIATE: 2,

	/** 
	 * Multi-armed bandit
	 */
	// MULTI_ARMED_BANDIT: 3,

	/**
	 * Predictive model using ML to determine experience
	 */
	PREDICTIVE: 4,

	/** 
	 * A personalized experience
	 */
	PERSONALIZATION: 5

};

export default OptimizationModelTypes;