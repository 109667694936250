/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import UpSellModal from '../components/modals/UpSellModal';
import PermissionsManager from '../managers/PermissionsManager';
import Permissions from '../model/Permissions';
import SphereAdminSession from '../model/SphereAdminSession';
import OpenModalCommand from './OpenModalCommand';

//

const typeMap = {
	1: Permissions.OPTIMIZATIONS_AB_MAX,
	5: Permissions.OPTIMIZATIONS_PERSONALIZATION_MAX,
	4: Permissions.OPTIMIZATIONS_PREDICTIVE_MAX 
};

const labelMap = {
	1: 'A/B Tests',
	5: 'Personalizations',
	4: 'Predictive Optimizations' 
};

const calloutMap = {
	1: 'optimizations_ab',
	5: 'optimizations_personalization',
	4: 'optimizations_predictive' 
};

//

/**
 * Initiates the view to create an Experience
 */
class SetOptimizationTypeCommand extends Command {
	
	#type;

	//

	/**
	 * Constructs the SetOptimizationTypeCommand
	 * @param type The Optimization type, see OptimizationModalTypes
	 */
	constructor( type ) {
		super();
		this.#type = type;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		// console.info( SphereAdminSession.user ); //[ Permissions.EXPERIENCES_MAX ]);
		const hasPermission = PermissionsManager.hasPermissionForMore( typeMap[ this.#type ] );
		if( hasPermission.allowed ) {
			const optimization = SphereAdminSession.optimization;
			optimization.type = this.#type;
		} else {
			const label = labelMap[ this.#type ];
			const msg = <p style={{ margin: 0 }}>You've reached your maximum number of allowed { label }. Your account only allows { hasPermission.permitted }.</p>;
			const upSell = <UpSellModal message={ msg } callout={ calloutMap[ this.#type ]}/>;
			const openModal = new OpenModalCommand(  'Limit Reached', upSell, '600px', true );
			openModal.execute();
		}
	}
	
}

//

export default SetOptimizationTypeCommand;
