/*
 * (c) Verra Technology Corporation
 */

import CodeEditor from '@uiw/react-textarea-code-editor';
import React, { Component } from 'react';
import ExperienceModificationType from '../../../model/ExperienceModificationType';
import CancelIcon from '../../icons/CancelIcon';
import CSSProperties from '../../model/CSSProperties';
import CSSPropertyType from '../../model/CSSPropertyType';
import ColorPicker from '../controls/ColorPicker';
import DropDownField from '../controls/DropDownField';
import Hint from '../controls/Hint';
import InputField from '../controls/InputField';
import StylePropertyPicker from '../controls/StylePropertyPicker';

//

/**
 * Component for editing modifications
 */
class ModificationEditor extends Component {
	
	#modificationTypeConfig;

	//

	/**
	 * Constructs the panel.
	 */
	 constructor() {
		super();
		this.state = {
			codeInEditorIsInvalid: false,
			invalidMarkup: null,
		};
		this.#buildEditorConfig();
	}

	/**
	 * Builds the configuration object used to render and manage the different modification types
	 */
	#buildEditorConfig() {
		// Maps modification types to configuration within the editor
		this.#modificationTypeConfig = {};

		this.#modificationTypeConfig[ ExperienceModificationType.CSS ] = {
			render: this.#getCodeEditorMarkup.bind( this ),
			language: 'css',
			getValue: this.#getModificationValue.bind( this ),
			handleChange: this.#handleValueChanged.bind( this )
		};
		this.#modificationTypeConfig[ ExperienceModificationType.JS ] = {
			render: this.#getCodeEditorMarkup.bind( this ),
			language: 'javascript',
			getValue: this.#getModificationValue.bind( this ),
			handleChange: this.#handleValueChanged.bind( this )
		};

		this.#modificationTypeConfig[ ExperienceModificationType.TEXT ] = {
			render: this.#getTextEditorMarkup.bind( this ),
			getValue: this.#getElementTextContent.bind( this ),
			handleChange: this.#handleValueChanged.bind( this ),
		};
		this.#modificationTypeConfig[ ExperienceModificationType.MARKUP ] = {
			render: this.#getCodeEditorMarkup.bind( this ),
			language: 'html',
			getValue: this.#getElementMarkupContent.bind( this ),
			handleChange: this.#handleMarkupChanged.bind( this ),
		};
		this.#modificationTypeConfig[ ExperienceModificationType.STYLES ] = {
			render: this.#getStylesEditorMarkup.bind( this ),
		};

		this.#modificationTypeConfig[ ExperienceModificationType.ADD_BEFORE ] = {
			render: this.#getCodeEditorMarkup.bind( this ),
			language: 'html',
			getValue: this.#getElementMarkupContent.bind( this ),
			handleChange: this.#handleMarkupChanged.bind( this ),
		};
		this.#modificationTypeConfig[ ExperienceModificationType.ADD_AFTER ] = {
			render: this.#getCodeEditorMarkup.bind( this ),
			language: 'html',
			getValue: this.#getElementMarkupContent.bind( this ),
			handleChange: this.#handleMarkupChanged.bind( this ),
		};

	}

	//

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const editorConfig = ( this.props.selectedModification != null ) ? this.#modificationTypeConfig[ this.props.selectedModification.type ] : null;
		return (
			<div>
				{ editorConfig != null && editorConfig.render( editorConfig ) }
			</div>
		);
	}

	// Rendering

	/**
	 * @return The markup for displaying the text editor
	 */
	#getTextEditorMarkup( editorConfig ) {
		// const editorConfig = this.#modificationTypeConfig[ this.state.editingMode ];
		return (
			<div 
				className='panel-cell no-select'>
				<div className={ 'experience-text-editor'} >
					<textarea
						value={ editorConfig.getValue() }
						onChange={ editorConfig.handleChange }
						style={{ height: this.props.height }}
					/>
				</div>
			</div>
		);
	}

	/**
	 * @return The markup for displaying the code editor
	 */
	#getCodeEditorMarkup( editorConfig ) {
		// const editorConfig = this.#modificationTypeConfig[ this.props.editingMode ];
		const invalidToolTip = 'The HTML is invalid and may not display as expected. If you would like to apply the code anyway, click this alert icon. Keep in mind this may have unexpected results.';
		return (
			<div className='panel-cell no-select' style={{ position: 'relative' }}>
				<div 
					className={ 'experience-code-editor ' }
					style={{ height: this.props.height, overflowX: 'auto', overflowY: 'auto', backgroundColor: '#1b1b1b', }}>
					<CodeEditor
						value={ editorConfig.getValue.apply( this ) }
						language={ editorConfig.language }
						data-color-mode='dark'
						onChange={ editorConfig.handleChange.bind( this ) }
						padding={ 15 }
						style={{
							minHeight: this.props.height,
							fontSize: 12,
							backgroundColor: '#1b1b1b',
							fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
						}}
					/>
				</div>
				{ this.state.codeInEditorIsInvalid && 
					<div style={{ position: 'absolute', left: 22, top: 28 }}>
						<button onClick={ this.#handleApplyInvalidMarkup.bind( this ) }>
							<Hint width='300px' error={ true } content={ invalidToolTip }/>
						</button>
					</div>
				}
				{/* <button style={{ width: '200px', margin: '13px 0 0 0' }} className={ 'button' } onClick={ editorConfig.handleChange.bind( this )}>Apply</button> */}
			</div>
		);
	}

	/**
	 * @return The markup for displaying style controls
	 */
	#getStylesEditorMarkup( editorConfig ) {
		const modification = this.props.selectedModification;

		const propertyControls = [];
		if( modification != null ) {
			Object.keys( modification.value ).forEach(( styleProp, index ) => {
				
				const value = modification.value[ styleProp ];
				const elementValueStatus = ( value == null || value === '' ) ? 'no-value' : '';
				const styleDefinition = CSSProperties[ styleProp ];
				let valueElement;

				if( styleDefinition.type === CSSPropertyType.OPTION ) {
					valueElement = <DropDownField 
										label='choose value'
										items={ styleDefinition.options } 
										selectedItem={ value } 
										hideBackground={ true } 
										hideButton={ true }
										labelAlignRight={ true }
										positionTop={ true }
										style={{ width: '100%' }}
										changeHandler={ value => this.#handleStyleChanged( styleProp, value )}/>;
				} else if( styleDefinition.type === CSSPropertyType.TEXT ) {
					valueElement = 	<InputField 
										className={ elementValueStatus }
										defaultValue={ value } 
										placeholder='enter value'
										style={{ height: 30, textAlign: 'right' }}
										onChange={ value => this.#handleStyleChanged( styleProp, value )}/>;
				} else if( styleDefinition.type === CSSPropertyType.COLOR ){
					valueElement = 	<div style={{ width: '100%', padding: '0 8px 0 0', textAlign: 'right' }}>
										<ColorPicker 
											color={ value }
											onChange={ value => this.#handleStyleChanged( styleProp, value )}/>
									</div>;
				}

				propertyControls.push( 
					<div key={ index++ } className={ `property-control` }>
						<label style={{ flexBasis: '33%' }}>{ styleProp }</label>
						{ valueElement }
						<button className='remove-button' onClick={ e => this.#handleRemoveStyleProperty( styleProp )}>
							<CancelIcon size={ 18 }/>
						</button>
					</div>
				); 
			});
		}

		propertyControls.push(
			<div key='property-picker' className={ `property-control` }>
				<StylePropertyPicker showLabel={ propertyControls.length === 0 } selectHandler={ this.#handleAddNewStyleProperty.bind( this )}/>
			</div>
		);

		return (
			<div className='pad-cell-top no-select'>
				<div className='experience-styles-editor' style={{ height: this.props.height }}>
					<div className={ `property-controls` } style={{ height: this.props.height }}>{ propertyControls }</div>
				</div>
			</div>
		);
	}

	// Modification value getters 

	/**
	 * @return the value of the selected modificaiton
	 */
	#getModificationValue() {
		return this.props.selectedModification.value;
	}

	/**
	 * @return The editor content based on the provided element
	 */
	#getElementTextContent() {
		const content = ( this.props.primaryElement != null ) ? this.props.primaryElement.textContent : '';
		return content;
	}

	/**
	 * @return The editor content based on the provided element
	 */
	#getElementMarkupContent() {
		const content = ( this.props.primaryElement != null ) ? (( this.props.primaryElement.nodeType === 3 ) ? this.props.primaryElement.nodeValue : this.props.primaryElement.outerHTML ) : '';
		return content;
	}

	// Modification Change Handlers and Methods

	/**
	 * Handles changes to the code editor, applies the change to the modification
	 */
	#handleValueChanged( e ) {
		const value = e.target.value;
		this.props.selectedModification.value = value;
		this.props.modificationChangedHandler();
	}

	/**
	 * Handles changes to a text element
	 */
	#handleMarkupChanged( e ) {
		const value = e.target.value;

		// validate the changes
		const parser = new DOMParser();
  		const doc = parser.parseFromString( value, 'text/xml' );
		let parseError = ( doc.documentElement.querySelector( 'parsererror' ) != null );

		// if( !parseError ) {
		// 	// need to detect if the path is changing and there is more than one element
		// 	// if so, the new path is going to break the selection of multiple elements
		// 	// and we should inform the user of this
		// 	const modification = this.props.selectedModification;

		// 	const newTemplate = document.createElement( 'template' );
		// 	newTemplate.innerHTML = value;
		// 	const newElement = newTemplate.content.firstChild;
				
		// 	const originalTemplate = document.createElement( 'template' );
		// 	originalTemplate.innerHTML = modification.value;
		// 	const originalElement = originalTemplate.content.firstChild;

		// 	const rootNodeMatch = ( newElement.nodeName === originalElement.nodeName );
		// 	const idMatch = ( newElement.id === originalElement.id );
		// 	const willChangePath = ( !rootNodeMatch || !idMatch );

		// 	console.info( 'value', modification.value, value );
		// 	console.info( 'nodeName', newElement.nodeName, originalElement.nodeName )
		// 	console.info( 'id', newElement.id, originalElement.id );

		// 	if( this.props.selectedElements.length > 1 && willChangePath ) {
		// 		console.info( 'CHANGE WILL IMPACT MULTIPLE ELEMENT SELECTION' );
		// 		// parseError = true;
		// 		// 	replaceAllowed = false;
		// 		// 	const content = 'The current modification applies to multiple elements on the page. Making this change will cause the modification to only apply to a single element. Are you sure you want to do this?';
		// 		// 	const alert = <Alert 
		// 		// 					content={ content }
		// 		// 					continueLabel='Yes'
		// 		// 					okHandler={ () => this.#handleProceedWithMarkupChange( modification, element, newElement ) }
		// 		// 					cancelHandler={ () => this.#handleUndoMarkupChange( modification ) }/>;
		// 		// 	const openModal = new OpenModalCommand( 'Breaking Change', alert, '500px', false );
		// 		// 	openModal.execute();
		// 	}
		// }

		// if valid, make the change
		if( !parseError ) {
			this.#applyMarkup( value );
			this.setState({ codeInEditorIsInvalid: false, invalidMarkup: null });
		} else {
			this.setState({ codeInEditorIsInvalid: parseError, invalidMarkup: value });
		}
	}

	/**
	 * Applies the markup to the modification and site editor
	 */
	#applyMarkup( value ) {
		this.props.selectedModification.value = value;
		this.props.modificationChangedHandler();
	}

	/**
	 * Forces the changes of invalid markup
	 */
	#handleApplyInvalidMarkup() {
		this.#applyMarkup( this.state.invalidMarkup );
		this.setState({ codeInEditorIsInvalid: false, invalidMarkup: null });
	}

	/**
	 * Select handler for the StylePropertyPicker
	 */
	#handleAddNewStyleProperty( property ) {
		if( this.props.selectedModification.value[ property ] == null ) {
			this.props.selectedModification.value[ property ] = null;
			this.setState({});
		}
	}

	/**
	 * Handles changes an element style from the styles editor.
	 * In the editor / preview mode, styles are tracked in individual style tags. When applied live, style 
	 * changes are all bundled into a single style tag, along with any global css changes.
	 */
	#handleStyleChanged( styleProp, value ) {
		const modification = this.props.selectedModification;
		const property = CSSProperties[ styleProp ];
		if( property.type === CSSPropertyType.COLOR ) value = `rgba(${ value.rgb.r },${ value.rgb.g },${ value.rgb.b },${ value.rgb.a })`;
		if( modification.value[ styleProp ] == null ) modification.value[ styleProp ] = {};
		modification.value[ styleProp ] = value;
		this.props.modificationChangedHandler();
	}

	/**
	 * Handles the click to remove a style property
	 */
	#handleRemoveStyleProperty( styleProp ) {
		const modification = this.props.selectedModification;
		delete modification.value[ styleProp ];
		this.props.modificationChangedHandler();
	}

}

//

export default ModificationEditor;
