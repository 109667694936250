/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import SphereAdminSession from '../model/SphereAdminSession';
import AudienceFactory from '../managers/AudienceFactory';
import AudienceEditor from '../components/editors/AudienceEditor';

//

/**
 * The CreateAudiencePanel contains the administration UI for creating a new Audience
 */
class CreateAudiencePanel extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();
		SphereAdminSession.audience = AudienceFactory.createAudience();
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return <AudienceEditor modal={ false }/>;
	}

}

//

export default CreateAudiencePanel;
