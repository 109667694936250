import React from 'react';

var WorldIcon = function( props ) {
	return (
		<svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48">
			<g className="nc-icon-wrapper" fill="#ffffff">
				<path dataColor="color-2" d="M41.047,25.049c-0.574-1.363-3.173-2.495-4.453-2.98l-1.294-1.182 c-0.062-0.057-0.132-0.106-0.207-0.146c-1.052-0.557-3.207-0.82-4.02-0.902c-0.474-0.282-1.528-0.879-2.247-1.059 c-0.897-0.226-2.744,0.309-3.765,0.646c-0.178,0.059-0.336,0.167-0.457,0.312c-0.971,1.173-3.229,4.072-3.229,5.638 c0,0.877,0.774,1.605,1.67,2.448c0.338,0.319,0.895,0.842,1.027,1.079c0.028,0.276,0.096,0.604,0.18,1.009 c0.159,0.761,0.489,2.346,0.158,2.859c-1.059,1.64-1.356,4.589-0.651,6.438c0.467,1.223,1.23,1.664,1.788,1.817 c0.219,0.061,0.444,0.088,0.675,0.088c1.525,0,3.29-1.214,4.872-2.302c0.675-0.464,1.373-0.945,1.741-1.089 c1.455-0.569,2.123-2.375,2.611-3.692c0.107-0.289,0.253-0.684,0.319-0.789c0.352-0.554,1.318-1.417,2.683-2.098 C40.069,30.334,41.783,26.799,41.047,25.049z"/> 
				<path fill="#ffffff" d="M24,1C11.318,1,1,11.318,1,24s10.318,23,23,23s23-10.318,23-23S36.682,1,24,1z M24,45 c-5.015,0-9.622-1.772-13.237-4.717l1.25-1.874c0.622-0.216,1.556-0.635,1.924-1.298c1.048-1.887-0.195-4.583-0.618-5.396 c-0.038-0.497-0.145-1.556-0.39-2.168c-0.306-0.763-1.425-1.458-2.219-1.871c-0.592-1.021-1.397-2.73-1.397-3.676 c0-1.331,1.035-1.583,1.545-1.64c1.681-0.29,2.713-1.451,3.152-2.065c0.814-0.253,2.307-0.833,2.858-1.799 c0.59-1.033,0.045-3.193-0.397-4.551c0.102-0.558,0.249-1.756-0.112-2.778c-0.367-1.039-1.259-1.848-1.702-2.2 c-0.244-0.552-0.845-1.795-1.673-2.812c2.283-1.414,4.849-2.399,7.6-2.852c-0.265,0.656-0.427,1.322-0.212,1.914 c0.266,0.731,1.176,1.27,2.103,1.643c-0.094,0.912-0.035,2.235,0.9,2.983c0.991,0.792,2.225,0.453,2.962,0.25 c0.007-0.001,0.014-0.003,0.021-0.005c0.078,0.633,0.115,1.726,0.08,2.753c-0.009,0.26,0.084,0.513,0.258,0.705 c0.21,0.232,2.116,2.277,4.196,2.569c0.113,0.016,0.227,0.023,0.342,0.023c1.899,0,4.04-2.059,4.819-2.881 c0.826-0.2,3.142-0.789,4.956-1.525C43.509,15.188,45,19.419,45,24C45,35.58,35.58,45,24,45z"/>
			</g>
		</svg>
	);
};

export default WorldIcon;