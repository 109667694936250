/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import ExperienceModificationType from '../../model/ExperienceModificationType';

/**
 * Serializes an Experience for saving
 */
class SerializeExperienceCommand extends Command {
	
	#experience;

	//

	/**
	 * Constructs the Command
	 * @param experience The Experience object to save
	 */
	constructor( experience ) {
		super();
		this.#experience = experience;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		// create the object to store
		const serializedExperience = {
			__n: this.#experience.__n,
			accountId: this.#experience.accountId,
			id: this.#experience.id,
			name: this.#experience.name,
			siteId: this.#experience.siteId,
			status: this.#experience.status,
			modifications: [],
		};

		this.#experience.modifications.forEach( modification => {
			const serializedModification = {
				type: modification.type,
				path: modification.path,
				value: modification.value,
				currentPath: modification.path,
				enabled: true,
			};
			serializedModification.original = ( modification.type === ExperienceModificationType.ADD_AFTER || modification.type === ExperienceModificationType.ADD_BEFORE ) ? null : modification.original;
			serializedExperience.modifications.push( serializedModification );
		});

		return serializedExperience;
	}
	
}

export default SerializeExperienceCommand;
