/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import SetStateCommand from '../commands/SetStateCommand';
import AdminStates from '../model/AdminStates';
import ReactMarkdown from 'react-markdown';
import ReactDom from 'react-dom';

//

/**
 * Verra documentation
 */
class DocumentationPanel extends Component {
	
	/**
	 * Constructs the Component
	 */
	constructor(){
		super();
		this.state = {
			document: '# Hello, *world*! yeah! ![The San Juan Mountains are beautiful!](/android-chrome-256x256.png "San Juan Mountains")'
		};
		//this.commonTasksRef = React.createRef();
		//this.otherTasksRef = React.createRef();
		//window.addEventListener( 'resize', this.#resizePanels.bind( this ));
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render(){

		return ( 
			<div className='content-panel'>
				<div className='grid'>
					<div className='grid-cell default-100'>
						<h2>Documentation</h2>
					</div>
					<div className='grid-cell default-30'>
						<div className='panel-cell'>
							<h3>General Concepts</h3>
							<ul>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CHANNELS_BROWSE )}>View Channels</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CHANNELS_CREATE )}>Create New Channel</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CONTENT_BROWSE )}>View Content</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CONTENT_CREATE )}>Create New Content</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CONFIGURATION_SITES )}>Manage Sites</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CONFIGURATION_EVENTS )}>Manage Events</button></li>
							</ul>
							<h3>Administration</h3>
							<ul>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CHANNELS_BROWSE )}>View Channels</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CHANNELS_CREATE )}>Create New Channel</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CONTENT_BROWSE )}>View Content</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CONTENT_CREATE )}>Create New Content</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CONFIGURATION_SITES )}>Manage Sites</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CONFIGURATION_EVENTS )}>Manage Events</button></li>
							</ul>
							<h3>Integration</h3>
							<ul>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CHANNELS_BROWSE )}>View Channels</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CHANNELS_CREATE )}>Create New Channel</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CONTENT_BROWSE )}>View Content</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CONTENT_CREATE )}>Create New Content</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CONFIGURATION_SITES )}>Manage Sites</button></li>
								<li><button className='link-button' onClick={() => this.#viewDocument( AdminStates.ADMIN_CONFIGURATION_EVENTS )}>Manage Events</button></li>
							</ul>
						</div>
					</div>
					<div className='grid-cell default-70 pad-cell-left'>
						<div className='panel-cell'>
							<ReactMarkdown>{this.state.document}</ReactMarkdown>
						</div>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Lifecycle method @see React
	 */
	//componentDidMount(){
	//	this.#resizePanels();
	//}

	//

	/**
	 * Handles clicks on the tasks buttons / links
	 */
	#viewDocument( state ){
		this.setState({ document: 'new doc' });
	}

}

export default DocumentationPanel;
