/*
 * (c) Verra Technology Corporation
 */

import PublishableObject from './PublishableObject.mjs';
import Content from './Content.mjs';
import PredictiveAttribute from './PredictiveAttribute.mjs';

/**
 * Represents Channel
 * TODO: add attributes and other properties
 * TODO: add model enum
 */
class Channel extends PublishableObject {

	/**
	 * Defines the structure of the Channel class. Used with ObjectUtil.CreateFromDefinition
	 */
	static definition = {
		type: Channel,
		properties: {
			content: {
				type: Content
			},
			predictiveAttributes: {
				type: PredictiveAttribute
			}
		}
	}

	// TODO: document these -- in particular, status
	
	// accountId : Integer
	// id : String
	// name : String
	// status : Integer
	// locked : Boolean
	// model : Integer
	// returnType : Integer
	// retrievalDepth : Integer
	// content : Array<Content>
	// attributes : Array<Attribute>
	// predictiveAttributes : Array<PredictiveAttribute>

	//

	/**
	 * Adds a PredictiveAttribute to the Channel
	 * @param attribute The PredictiveAttribute to add
	 */
	addPredictiveAttribute( attribute ){
		if( this.predictiveAttributes == null ) this.predictiveAttributes = [];
		this.predictiveAttributes.push( attribute );
	}

}

//

export default Channel;