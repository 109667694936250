/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ObjectStatusMap from '../../model/ObjectStatusMap';
import Checkbox from './Checkbox';

//

/**
 * InputField Component
 */
class DataGrid extends Component {
	
	/**
	'* Constructs the Component
	*/
	constructor( props ) {
		super( props );
		this.gridElement = React.createRef();
		// A mapping of data by id
		this.dataMap = {};
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {

		// TODO: little cleanup here would be good, refactor to methods
		
		var headers = this.props.headers;
		var data = this.props.data;
		var headerColumns = [];
		var dataRows = [];
		var headerContent = '';
		var gridContent = '';
		var idField = this.props.idField;
		var header, className, key;
		var rowClickHandler = ( e ) => { handleRowClick.apply( this, [ e ]);};
		var editHandler = ( e ) => { handleEdit.apply( this, [ e ]);};
		var noContent = ( this.props.noContent != null ) ? <div className='no-content'>{this.props.noContent}</div> : '';
		var showHeader = ( this.props.showHeader != null ) ? this.props.showHeader : true;
		var showHeaderIfEmpty = ( this.props.showHeaderIfEmpty != null ) ? this.showHeaderIfEmpty : true;
		var disabled = ( !this.props.disabled || this.props.disabled === undefined ) ? '' : ' disabled';
		var maxBodyHeight = ( this.props.maxBodyHeight != null ) ? this.props.maxBodyHeight : 'auto';
		var style = ( this.props.style != null ) ? this.props.style : {};
		const multiselect = ( this.props.multiselect != null ) ? this.props.multiselect : true;

		let hasCheck = false;
		let hasRadio = false;

		if( data != null && data.length > 0 ){

			var checkChange = ( e ) => { handleCheckboxChange.apply( this, [ e ]); };
			const radioName = Math.random();

			for( var j = 0; j < data.length; j++ ){
				var item = data[ j ];
	
				var dataId = getValue( idField, item );
				var status = ObjectStatusMap[getValue( this.props.statusField, item )];

				this.dataMap[ dataId ] = item;
				var bodyColumns = [];

				if( this.props.statusIndicator ) {
					bodyColumns.push( <div key='status-indicator' className={ 'status-indicatator ' + status }></div> );
				}

				// Checkbox

				let checkIsFunc = ( typeof this.props.checkColumn === 'function' );
				let showCheck = ( checkIsFunc ) ? this.props.checkColumn( item ) : this.props.checkColumn;
				if( !hasCheck ) hasCheck = showCheck;

				if( showCheck || checkIsFunc ){
					bodyColumns.push( 
						<div key={'check-column-' + j} className='grid-cell default-5 check-column'>
							{ showCheck ? <Checkbox disabled={this.props.disabled} changeHandler={checkChange}/> : <span>&nbsp;</span> }
						</div> 
					);
				}

				// Radio

				let radioIsFunc = ( typeof this.props.radioColumn === 'function' );
				let showRadio = ( radioIsFunc ) ? this.props.radioColumn( item ) : this.props.radioColumn;
				if( !hasRadio ) hasRadio = showRadio;

				if( showRadio || radioIsFunc ){
					const selected = ( item === this.props.selected );
					bodyColumns.push( 
						<div key={'radio-column-' + j} className='grid-cell default-5 radio-column'>
							{ showRadio ? <input type='radio' name={ radioName } disabled={this.props.disabled} checked={ selected } onClick={ this.#handleRadioChecked.bind( this )}/> : <span>&nbsp;</span> }
						</div> 
					);
				}

				if( this.props.editColumn ){
					bodyColumns.push( <div key={'edit-column-' + j} className='grid-cell default-5 edit-column' onClick={editHandler}>{this.props.editIcon}</div> );
				}
				
				for( var k = 0; k < headers.length; k++ ){
					header = headers[ k ];
					className =  'grid-cell default-' + header.width;
					var value = getValue( header.field, item );
					key = 'data-' + j + '-' + k;
					bodyColumns.push( <div key={key} className={className} data-suppress-click={header.supressClick}>{value}</div> );
				}
	
				dataRows.push( 
					<div key={'body-row-' + j} className='grid data-grid-body-row' data-id={dataId} onClick={rowClickHandler} >
						{bodyColumns}
					</div> 
				);

				gridContent = dataRows;
			}
		} else if( data != null && data.length === 0 ){
			gridContent = <div>{noContent}</div>;
		}

		if( this.props.checkColumn ){
			const headerCheckChange = ( e ) => { handleHeaderCheckboxChange.apply( this, [ e ]); };
			const headerCheck = <div key='header-check-column' className='grid-cell default-5 check-column'>
									{( hasCheck && multiselect ) ? <Checkbox disabled={ this.props.disabled } changeHandler={headerCheckChange}/> : <span>&nbsp;</span> }
								</div>;
			headerColumns.push( headerCheck );
		}

		if( this.props.radioColumn ) {
			const radioLabel = this.props.radioLabel;
			headerColumns.push( <div key='header-radio-column' className='grid-cell default-5'><span>{ radioLabel }</span></div> );
		}

		if( this.props.editColumn ){
			headerColumns.push( <div key='header-edit-column' className='grid-cell default-5'>&nbsp;</div> );
		}

		if( showHeader && (( showHeaderIfEmpty && data != null && data.length === 0 ) || ( data != null && data.length > 0 ))){
			
			for( var i = 0; i < headers.length; i++ ){
				header  = headers[ i ];
				className =  'grid-cell default-' + header.width;
				//if( i === headers.length - 1 ) className += ' align-right';
				key = 'header-' + i;
				headerColumns.push( <div key={key} className={className}>{header.label}</div> );
			}
			headerContent = <div className='grid data-grid-header'>
								{headerColumns}
							</div>;
		}
		
		return ( 
			<div ref={this.gridElement} className={'data-grid ' + disabled} style={style}>
				{headerContent}
				<div className='data-grid-body' style={{maxHeight: maxBodyHeight}}>
					{gridContent}
				</div>
			</div>
		);
	}

	//

	/**
	 * Handles clicks on the radio button
	 */
	#handleRadioChecked( e ) {
		e.stopPropagation();
		if( this.props.radioChangeHandler != null ) this.props.radioChangeHandler( this );
	}

	//
	
	/**
	 * @return the checked elements
	 */
	getChecked(){
		var checkboxes = this.gridElement.current.querySelectorAll( '.data-grid-body input[type=checkbox]' );
		var checked = [];
		for( var i = 0; i < checkboxes.length; i++ ){
			var checkbox = checkboxes[ i ];
			if( checkbox.checked ){
				var id = checkbox.closest( '.data-grid-body-row' ).getAttribute( 'data-id' );
				checked.push( this.dataMap[ id ] );
			}
		}
		return checked;
	}
	
	/**
	 * @return the selected elements
	 */
	getSelected() {
		const selectedRadio = this.gridElement.current.querySelector( '.data-grid-body input[type=radio]:checked' );
		const id = selectedRadio.closest( '.data-grid-body-row' ).getAttribute( 'data-id' );
		return id;
	}
	
	/**
	 * Unchecks all of the rows in the grid
	 */
	unCheckAll(){
		var checkboxes = this.gridElement.current.querySelectorAll( 'input[type=checkbox]' );
		for( var i = 0; i < checkboxes.length; i++ ){
			var checkbox = checkboxes[ i ];
			checkbox.checked = false;
		}
	}
	
}

//

/**
 * Gets a value from the data using the field property of the header. Field property values can exist as 
 * single referenes such as: 'name', 'date', etc or nested objects, for example: 'attributes.date-modified' or
 * they can be functions which provide a look up for the value
 */
var getValue = function( field, data ){
	if( typeof( field ) === 'string' ){
		var fields = field.split( '.' );
		return getNestedValue( fields, data );
	} else if( typeof( field ) === 'function' ){
		return field( data );
	}
};

/**
 * Gets a nested value in the form of object.object.value. For example: 'attributes.date-modified'
 */
var getNestedValue = function( fields, data ){
	var field = fields.shift();
	if( fields.length === 0 ){
		return data[ field ];
	} else {
		return getNestedValue( fields, data[ field ]);
	}
};

/**
 * Handles change events on the checkbox in the header row
 */
var handleHeaderCheckboxChange = function( e ){
	var isChecked = e.currentTarget.checked;
	var checkboxes = this.gridElement.current.querySelectorAll( '.data-grid-body input[type=checkbox]' );
	for( var i = 0; i < checkboxes.length; i++ ){
		var checkbox = checkboxes[ i ];
		checkbox.checked = isChecked;
	}

	if( this.props.checkBoxChangeHandler != null ) this.props.checkBoxChangeHandler( this );
};

/**
 * Handles change events on a checkbox
 */
var handleCheckboxChange = function( e ){
	e.stopPropagation();
	
	const multiselect = ( this.props.multiselect != null ) ? this.props.multiselect : true;
	if( !multiselect ) {
		const checkboxes = this.gridElement.current.querySelectorAll( '.data-grid-body input[type=checkbox]' );
		checkboxes.forEach( checkbox => {
			if( checkbox !== e.target ) checkbox.checked = false;
		});
	}

	if( this.props.checkBoxChangeHandler != null ) this.props.checkBoxChangeHandler( this );
};

/**
 * Handles clicks on a row in the body of the grid
 */
var handleRowClick = function( e ){
	e.stopPropagation();
	if( e.target.type !== 'checkbox' ) {
		if( this.props.rowClickHandler != null ) {
			var dataId = e.currentTarget.closest( '.data-grid-body-row' ).getAttribute( 'data-id' );
			var rowData = this.props.data.find( item => dataId === item[ this.props.idField ] );
			this.props.rowClickHandler( rowData );
		}
		if( this.props.checkOnRowClick ) {
			const checkbox = e.currentTarget.closest( '.data-grid-body-row' ).querySelector( 'input[type=checkbox]');
			if( checkbox != null ) checkbox.click();
		}
	}
};

/**
 * Handles clicks on the edit icon
 */
 var handleEdit = function( e ){
	if( this.props.editHandler != null ) {
		var dataId = e.currentTarget.closest( '.data-grid-body-row' ).getAttribute( 'data-id' );
		var rowData = this.props.data.find( item => dataId === item[ this.props.idField ] );
		this.props.editHandler( rowData );
	}
};


//

export default DataGrid;
