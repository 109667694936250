/*
 * (c) Verra Technology Corporation
 */

import { Component } from 'react';
import InputField from '../components/controls/InputField';
import Hint from '../components/controls/Hint';
import CreateEventTypeModal from '../components/modals/CreateEventTypeModal';
import OpenModalCommand from '../commands/OpenModalCommand';
import TransferContentModal from '../components/modals/TransferContentModal';

//

/**
 * Defines the state of the admin iframe
 */
const states = {

	/**
	 * 
	 */
	IDLE: 'IDLE'

};

/**
 * Defines the state of the admin iframe
 */
 const actions = {

	/**
	 * 
	 */
	TRANSFER_CONTENT: 'TRANSFER_CONTENT'

};

//

/**
 * Application class for the Sphere Administration suite
 */
class IFrame extends Component {
	
	/**
	 * Constructs the component
	 */
	constructor(){
		super(); 

		this.state = { action: states.IDLE };

		if( window.location !== window.parent.location ) {
			window.addEventListener( 'message', this.#handleWindowMessage.bind( this ));
			window.parent.postMessage({ state: this.state.action, display: false }, '*' );
		}
	}

	/**
	 * Renders the component
	 */
	render() {
		var jsx = '';

		// TODO: account selection

		//var display = false;
		// TODO: wildcard in postMessage call needs to be replaced with allowed domains
		//window.parent.postMessage({ state: this.state.action, display: display }, '*' );

		return jsx;
	}

	//

	/**
	 * Handles post message events from the containing iframe
	 */
	#handleWindowMessage( event ){
		console.info( '(Verra) handleWindowMessage', event.data );
		//this.setState({ action: event.data.action, params: event.data.params });

		if( event.data.action === actions.TRANSFER_CONTENT ){
			var content = <TransferContentModal
							contentId={event.data.params.contentId}
							content={event.data.params.content}
							closeHandler={this.#handleModalDone}/>;
			var openModal = new OpenModalCommand( 'Transfer Content', content, '350px', false );
			openModal.execute();
			window.parent.postMessage({ state: event.data.action, display: true }, '*' );
		}
	}

	//

	/**
	 * Closes the Predictive Attribute editor modal
	 */
	#handleModalDone( e ) {
		window.parent.postMessage({ state: actions.IDLE, display: false }, '*' );
	}

}

export default IFrame;
