import React from 'react';

var PerformanceIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : '24';
	return (
		<svg viewBox="0 -960 960 960" height={ size } width={ size } fill="#ffffff">
			<path d="M424.15-346.15q21.31 21.3 54.89 20.23 33.57-1.08 49.27-24.23l217.07-314.46-315.23 216.3q-23.53 15.7-25.42 48.27-1.88 32.58 19.42 53.89ZM480-780q57.08 0 106.96 14.96 49.89 14.96 96.35 45.27l-56.39 36.46q-34.15-18.15-70.8-27.42Q519.46-720 480-720q-133 0-226.5 93.5T160-400q0 42 11.5 83t32.5 77h552q23-38 33.5-79t10.5-85q0-36-8.88-72.88-8.89-36.89-27.43-70.04l36.46-56.39q29.62 48.54 44.43 97.12 14.8 48.58 15.42 100.96.61 54.31-12.23 102.65-12.85 48.35-38.69 94.58-7.93 13-22.31 20.5Q772.38-180 756-180H204q-16 0-30.19-7.69-14.19-7.7-22.89-21.85-22.92-40-36.92-87.23Q100-344 100-400q0-78.38 29.74-147.37 29.74-68.99 81.19-120.85 51.46-51.86 120.99-81.82Q401.46-780 480-780Zm4.31 295.69Z"/>
		</svg>
		// <svg height={height} width={width} viewBox="0 0 52 52">
		// 	<g strokeLinecap="square" strokeWidth="2" fill="none" stroke="#ffffff" strokeLinejoin="miter" className="nc-icon-wrapper" strokeMiterlimit="10">
		// 		<path data-cap="butt" d="M28,31h10.365 C39.402,28.882,40,26.512,40,24c0-8.822-7.178-16-16-16S8,15.178,8,24c0,2.512,0.598,4.882,1.635,7H20" strokeLinecap="butt" stroke="#ffffff"></path>
		// 		<line data-cap="butt" x1="22.654" y1="27.232" x2="19" y2="17" strokeLinecap="butt" stroke="#ffffff"></line>
		// 		<circle cx="24" cy="31" r="4"></circle> <circle cx="24" cy="24" r="22"></circle>
		// 	</g>
		// </svg>
	);
};

export default PerformanceIcon;