/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import SiteEditor from '../components/editors/SiteEditor.js';
import SiteFactory from '../managers/SiteFactory.js';
import SphereAdminSession from '../model/SphereAdminSession';

//

/**
 * The CreateSitePanel contains the UI for creating a Site
 */
class CreateSitePanel extends Component {
	
	/**
	 * Constructs the panel.
	 */
	 constructor() {
		super();
		this.#initNewSite();
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		return <SiteEditor/>;
	}

	// Private

	/**
	 * Initializes a new Channel to edit
	 */
	#initNewSite() {
		const site = SiteFactory.createSite();
		SphereAdminSession.site = site;	
	}

}

//

export default CreateSitePanel;
