/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ArrowDownIcon from '../../icons/ArrowDownIcon';

/**
 * InputField Component
 * TODO: this needs to be refactored to use select and option --- needs to be refactored simply because it's clunky, geez
 * 
 */
class DropDownField extends Component {
	
	/**
	 * Constructs the component
	 */
	constructor() {
		super();

		this.state = {
			itemsOpen: false
		};

		this.componentRef = React.createRef();
		this.labelRef = React.createRef();
		this.buttonRef = React.createRef();
		this.itemsListRef = React.createRef();

		this.mouseLeaveHandler = handleMouseLeave.bind( this );
		this.keyPressHandler = this.#handleKeyPress.bind( this );
		this.scrollHandler = this.#positionItems.bind( this );
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		var toggleOpenCloseHandler = ( e ) => { handleDropDownClick.apply( this, [ e ] ); };
		var itemClickHandler = ( e ) => { handleItemClick.apply( this, [ e ] ); };

		var disabled = ( !this.props.disabled || this.props.disabled === undefined ) ? '' : ' disabled';
		
		var className = ( this.props.className != null ) ? this.props.className : ''; 
		className += ' drop-down ' + disabled;

		var style = ( this.props.style != null ) ? this.props.style : {}; 
		var label = ( this.props.label !== undefined ) ? this.props.label : '';
		var width = ( this.props.width === undefined ) ? '100%' : this.props.width;

		var buttonStyle = { width: width };
		if( this.props.hideBackground ) buttonStyle.background = 'none';
		if( this.props.labelAlignRight ) buttonStyle.textAlign = 'right';

		var showButton = ( this.props.hideButton != null ) ? !this.props.hideButton : true;
		var labelPaddingRight = ( !showButton ) ? '0px' : null;

		var itemElements = [];
		let hasSelected = false;
		if( this.props.items != null ){
			for( var i = 0; i < this.props.items.length; i++ ){
				var item = this.props.items[ i ];
				var itemLabel = this.#getItemLabel( item );
				if( item === this.props.selectedItem || i === this.props.selectedIndex ) {
					label = itemLabel;
					hasSelected = true;
				}
				var itemElement = <li key={ i } onClick={ itemClickHandler } data-item-index={ i }>{ itemLabel }</li>;
				itemElements.push( itemElement );
			}
		}

		var hideLabel = ( this.props.hideLabel != null && this.props.hideLabel );
		var hasSelectionLabelClass = ( hasSelected ) ? ' item-selected' : '';
		var labelMarkup = ( hideLabel ) ? <span className='empty'></span> : <span ref={this.labelRef} className={ hasSelectionLabelClass } style={{ paddingRight: labelPaddingRight }}>{label}</span>;
		var dividerMarkup = ( hideLabel ) ? '' : <div className='divider' style={{ display: (this.props.hideBackground) ? 'none' : 'block' }}></div>;

		var itemsWidth = ( this.props.itemsWidth != null ) ? this.props.itemsWidth : 'auto';

		var markup = ( 
			<div className={className} style={style} ref={this.componentRef}>
				<div className='drop-down-button' style={ buttonStyle } onClick={toggleOpenCloseHandler} ref={this.buttonRef}>
					{ labelMarkup }
					{ showButton && dividerMarkup }
					{ showButton && 
						<div className='icon' >
							<ArrowDownIcon/>
						</div>
					}
				</div>
				<ul className='drop-down-items' ref={ this.itemsListRef } style={{ width: itemsWidth }}>
					{ itemElements }
				</ul>
			</div>
		);

		return markup;
	}

	/**
	 * @return The label for the item in the drop down
	 */
	#getItemLabel( item ){
		var label;
		if( this.props.labelField !== undefined ){
			label = item[ this.props.labelField ];
		} else if( this.props.labelFunction !== undefined && this.props.labelFunction instanceof Function ){
			label = this.props.labelFunction( item );
		} else {
			label = item;
		}
		return label;
	}

	/**
	 * Handles key press events
	 */
	#handleKeyPress( e ) {
		const index = this.props.items.findIndex( item => e.key === this.#getItemLabel( item ).substring( 0, 1 ));
		const element = this.itemsListRef.current.querySelector( `:nth-child(${ index + 1 })` );
		const rect = element.getBoundingClientRect();
		this.itemsListRef.current.scrollTop = rect.height * index;
	}

	/**
	 * Positions the DropDownField's items
	 */
	#positionItems() {
		const itemsList = this?.itemsListRef.current;
		if( itemsList != null ) {
			const itemsListRect = itemsList.getBoundingClientRect();
			const buttonRect = this.buttonRef.current.getBoundingClientRect();
			itemsList.style.top = ( buttonRect.y + buttonRect.height ) + 'px';
			if( !this.props.alignRight ) { 
				itemsList.style.left = buttonRect.x + 'px';
			} else {
				itemsList.style.left = ( buttonRect.left - ( itemsListRect.width - buttonRect.width )) + 'px';
			}
		}
	}

	//

	/**
	 * Opens the drop down
	 */
	open() {
		const itemsList = this?.itemsListRef.current;

		if( itemsList != null ) {
			itemsList.style.display = 'block';
			
			if( this.props.itemsWidth == null ) {
				const buttonRect = this.buttonRef.current.getBoundingClientRect();
				itemsList.style.width = buttonRect.width + 'px';
			}

			this.#positionItems();
			
			this.componentRef.current.addEventListener( 'mouseleave', this.mouseLeaveHandler );
			document.addEventListener( 'keypress', this.keyPressHandler );
			document.addEventListener( 'scroll', this.scrollHandler );

			this.setState({ itemsOpen: true });
		}
	}

	/**
	 * Closes the drop down
	 */
	close() {
		//window.document.removeEventListener( 'click', this.outsideClickHandler );
		const itemsList = this.itemsListRef.current;
		itemsList.style.display = 'none';
		this.componentRef.current.removeEventListener( 'mouseleave', this.mouseLeaveHandler );
		document.removeEventListener( 'keypress', this.keyPressHandler );
		document.removeEventListener( 'scroll', this.scrollHandler );
		this.setState({ itemsOpen: false });
	}

	/**
	 * Selects an item in the drop down
	 */
	selectItem( item ) {
		var itemLabel = this.#getItemLabel( item );
		this.labelRef.current.innerHTML = itemLabel;
	}
	
}

// Private

/**
 * Handles clicks on the drop down field, opens the items list
 */
var handleDropDownClick = function( e ){
	e.stopPropagation();
	e.preventDefault();
	//console.info( 'handleDropDownClick' );
	//console.info( e.target );
	if(( !this.props.disabled || this.props.disabled === undefined ) && !this.state.itemsOpen ){
		this.open();
	} else if( this.state.itemsOpen ) {
		this.close();
	}
};

/**
 * Handles clicks on an item in the drop down
 */
var handleItemClick = function( e ){
	e.stopPropagation();
	e.preventDefault();
	//console.info( 'handleItemClick' );
	//console.info( e.target );
	var selectedIndex = Number( e.currentTarget.getAttribute( 'data-item-index' ));
	var selectedItem = this.props.items[ selectedIndex ];
	this.selectItem( selectedItem )
	if( this.props.changeHandler != null ) this.props.changeHandler( selectedItem, selectedIndex );
	this.close();
};

/**
 * Handles clicks outside of the items list, closes the list
 */
/*
var handleOutsideClick = function( e ) {
	e.stopPropagation();
	e.preventDefault();
	//console.info( 'handleOutsideClick' );
	//console.info( e );
	if( e.target != this.refs.button ) this.close();
};
*/

/**
 * Handles the mouse leaving the component, closes the items list
 */
 var handleMouseLeave = function( e ) {
	e.stopPropagation();
	e.preventDefault();
	//console.info( 'handleMouseLeave' );
	this.close();
};

//

export default DropDownField;
