/*
 * (c) Verra Technology Corporation
 */

import ModifiableObject from '../../../model/ModifiableObject.mjs';
import SerializeExperienceCommand from '../../commands/SerializeExperienceCommand';
import SphereAdminServices from '../../model/SphereAdminServices';
import SphereAdminSession from '../../model/SphereAdminSession';
import RetrieveObjectCountsRequest from '../account/RetrieveObjectCountsRequest';
import ServiceRequestCommand from '../ServiceRequestCommand';

/**
 * Saves an Experience
 */
class SaveExperienceRequest extends ServiceRequestCommand {

	#experience;

	//

	/**
	 * Constructs the Request
	 * @param experience The Experience to save
	 */
	constructor( experience ) {
		super();
		this.#experience = experience;
		// TODO: TEMP - the account ID is not being retrieved from the DB when retrieving experiences so they have a null value
		this.#experience.accountId = SphereAdminSession.selectedAccount.id
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.SAVE_EXPERIENCE;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		const serialize = new SerializeExperienceCommand( this.#experience );
		const experienceToSave = serialize.execute();
		return { experience: experienceToSave };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		if( response.success ) {
			this.#experience.status = ModifiableObject.SAVED; // TODO: should this come from the DB?
			SphereAdminSession.experiences.push( this.#experience );

			// update the object counts
			const retrieveObjectCounts = new RetrieveObjectCountsRequest();
			retrieveObjectCounts.execute();
		}
		// console.info( 'SaveExperienceRequest::handleComplete content', this.#experience );
	}
 
}

export default SaveExperienceRequest;
