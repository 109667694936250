// https://github.com/facebook/create-react-app
// npm run start
// npm run start-https (for local https development)

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import SphereAdmin from './sphere/admin/SphereAdmin';

console.clear();

window.addEventListener( 'error', e => {
	// console.info( 'VerraAdmin::error', e );
});

ReactDOM.render(
	<SphereAdmin/>,
	document.getElementById( 'sphere' )
);
