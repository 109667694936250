import React from 'react';

var ScreenIcon = function( props ) {
	return (
		<svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48">
			<g className="nc-icon-wrapper" strokeLinecap="square" strokeLinejoin="miter" fill="#ffffff" stroke="#ffffff" strokeWidth="3">
				<line data-color="color-2" fill="none" strokeMiterlimit="10" x1="8" y1="10" x2="10" y2="10"/> 
				<line data-color="color-2" fill="none" strokeMiterlimit="10" x1="16" y1="10" x2="18" y2="10"/> 
				<line data-color="color-2" fill="none" strokeMiterlimit="10" x1="32" y1="10" x2="40" y2="10"/> 
				<line fill="none" stroke="#ffffff" strokeMiterlimit="10" x1="2" y1="16" x2="46" y2="16"/> 
				<rect x="2" y="4" fill="none" stroke="#ffffff" strokeMiterlimit="10" width="44" height="40"/>
			</g>
		</svg>
	);
};

export default ScreenIcon;