/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ErrorIcon from '../../icons/ErrorIcon';

/**
 * Hint Component. Renders a small question mark and tool tip to display contextual help 
 * content to the user
 */
class Hint extends Component {

	/**
	 * 
	 */
	constructor() {
		super();
		this.state = {
			top: 0,
			left: 0,
			toolTipOpen: false
		};
		
		this.componentRef = React.createRef();
		this.toolTipRef = React.createRef();
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {

		var style = { 
			display: (( this.state.toolTipOpen ) ? 'inline' : 'none' ), 
			width: this.props.width,
			top: this.state.top,
			left: this.state.left
		};

		let markup;
		if( this.props.error ) {
			markup =	<div ref={ this.componentRef } className='hint'>
							<div style={{ display: 'inline-block' }} onMouseMove={ handleOver.bind( this )} onMouseOut={ handleOut.bind( this ) }>
								<ErrorIcon size={ 16 } color='#ff2222'/>
							</div>
							<div ref={ this.toolTipRef } className='tool-tip' style={ style }>
								{ this.props.content }
							</div>
						</div>;
		} else {
			markup = 	<div ref={ this.componentRef } className='hint'>
							<span onMouseMove={ handleOver.bind( this )} onMouseOut={ handleOut.bind( this ) }>?</span>
							<div ref={ this.toolTipRef } className='tool-tip' style={ style }>
								{ this.props.content }
							</div>
						</div>;
		}

		return markup;

	}
}

//

/**
 * Handles mouse over events
 * TODO: consider mobile
 */
function handleOver( e ) {
	let width;
	if( this.props.width != null && this.props.width.includes( 'px' ) ) {
		width = this.props.width.replace( 'px', '' );
	} else {
		const rect = this.toolTipRef.current.getBoundingClientRect();
		width = rect.width;
	}
	const left = ( this.props.position === 'left' ) ? e.clientX - width - 20 : e.clientX + 20;
	this.setState({ toolTipOpen: true, top: e.clientY, left })
};

/**
 * Handles mouse over events
 * TODO: consider mobile
 */
function handleOut( e ) {
	this.setState({ toolTipOpen: false })
};

//

export default Hint;
