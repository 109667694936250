/*
 * (c) Verra Technology Corporation
 */

import Optimization from '../../../model/Optimization';
import SphereAdminServices from '../../model/SphereAdminServices';
import SphereAdminSession from '../../model/SphereAdminSession';
import ServiceRequestCommand from '../ServiceRequestCommand';

/**
 * Completes an Optimization
 */
class CompleteOptimizationRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param optimization The Optimization to save
	 */
	constructor( optimization ){
		super();
		this.optimization = optimization;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.COMPLETE_OPTIMIZATION;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: this.optimization.accountId, optimizationId: this.optimization.id };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		if( response.success ) {
			this.optimization.status = Optimization.COMPLETE; // TODO: should this come from the DB?
			SphereAdminSession.optimizations.push( this.optimization );
		}
		//console.info( 'CompleteContentRequest::handleComplete content', this.content );
	}
 
}

export default CompleteOptimizationRequest;
