/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';
import ObjectUtil from '../../../util/ObjectUtil.mjs';
import Audience from '../../../model/Audience';

/**
 * Retrieves the Audiences for the account
 */
class RetrieveAudiencesListRequest extends ServiceRequestCommand {

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getService() {
		return SphereAdminServices.RETRIEVE_AUDIENCES_LIST;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		// console.info( 'RetrieveAudiencesListRequest::handleComplete', response );
		if( response.success ) {
			SphereAdminSession.audiences = ObjectUtil.createFromDefinition( response.result, Audience.definition );
		}
	}
 
}

export default RetrieveAudiencesListRequest;
