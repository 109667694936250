/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import ExperienceModification from '../../model/ExperienceModification';
import ExperienceModificationType from '../../model/ExperienceModificationType';
import UpSellModal from '../components/modals/UpSellModal';
import PermissionsManager from '../managers/PermissionsManager';
import Permissions from '../model/Permissions';
import SphereAdminSession from '../model/SphereAdminSession';
import OpenModalCommand from './OpenModalCommand';

/**
 * Craetes a new modification to add to an Experience
 */
class CreateExperienceModificationCommand extends Command {
	
	#type;
	#path;
	#selectedElements;
	#getNewValueMap;
	#getOriginalValueMap;

	//

	/**
	 * Constructs the Command
	 * @param type The type of modification to add. See ExperienceModificationTypes
	 * @param path The query select path to the element(s) being modified
	 * @param selectedElements The elements selected in the site experience editor
	 */
	constructor( type, path, selectedElements ) {
		super();
		this.#type = type;
		this.#path = path;
		this.#selectedElements = selectedElements;

		this.#getNewValueMap = {};
		this.#getNewValueMap[ ExperienceModificationType.TEXT ] = this.#getNewTextValue.bind( this );
		this.#getNewValueMap[ ExperienceModificationType.MARKUP ] = this.#getNewMarkupValue.bind( this );
		this.#getNewValueMap[ ExperienceModificationType.STYLES ] = this.#getNewStylesValue.bind( this );
		this.#getNewValueMap[ ExperienceModificationType.ADD_BEFORE ] = this.#getNewAddedValue.bind( this );
		this.#getNewValueMap[ ExperienceModificationType.ADD_AFTER ] = this.#getNewAddedValue.bind( this );

		this.#getOriginalValueMap = {};
		this.#getOriginalValueMap[ ExperienceModificationType.TEXT ] = this.#getOriginalTextValue.bind( this );
		this.#getOriginalValueMap[ ExperienceModificationType.MARKUP ] = this.#getOriginaMarkupValue.bind( this );
		this.#getOriginalValueMap[ ExperienceModificationType.ADD_BEFORE ] = this.#getOriginaAddedValue.bind( this );
		this.#getOriginalValueMap[ ExperienceModificationType.ADD_AFTER ] = this.#getOriginaAddedValue.bind( this );
		this.#getOriginalValueMap[ ExperienceModificationType.REMOVE ] = this.#getOriginaRemovedValue.bind( this );
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		let modification;
		const experience = SphereAdminSession.experience;
		const modifications = experience.modifications;
		const hasPermission = PermissionsManager.hasPermissionForMoreItems( Permissions.EXPERIENCES_MODIFICATIONS_MAX, modifications );

		if( hasPermission.allowed ) {
			// first, let's look at the existing modifications to make sure one doesn't already exist
			for( let i = 0; i < modifications.length; i++ ) {
				const mod = modifications[ i ];
				if( mod.type === this.#type && mod.currentPath === this.#path ) {
					modification = mod;
					break;
				}
			}
			// otherwise, we create a new modification
			if( modification == null ) {
				modification = new ExperienceModification();
				modification.type = this.#type;
				modification.path = this.#path;
				modification.currentPath = this.#path;
				if( this.#getNewValueMap[ this.#type ] != null ) modification.value = this.#getNewValueMap[ this.#type ]();
				if( this.#getOriginalValueMap[ this.#type ] != null ) modification.original = this.#getOriginalValueMap[ this.#type ]();
				modifications.push( modification );
			}
		} else {
			const msg = <p style={{ margin: 0 }}>You've reached your maximum number of allowed modifications per Experience. Your account only allows { hasPermission.permitted }.</p>;
			const upSell = <UpSellModal message={ msg } callout='experience_modifications'/>;
			const openModal = new OpenModalCommand(  'Limit Reached', upSell, '600px', true );
			openModal.execute();
		}

		// console.info( modification );

		return modification;
	}

	// New value getters

	/**
	 * @return the original value for a text modification
	 */
	#getNewTextValue() {
		return this.#selectedElements[ 0 ].textContent;
	}
	
	/**
	 * @return the original value for a markup modification
	 */
	#getNewMarkupValue() {
		return this.#selectedElements[ 0 ].outerHTML;
	}

	/**
	 * @return the original value for a markup modification
	 */
	#getNewStylesValue() {
		return {};
	}

	/**
	 * @return the original value for a markup modification
	 */
	#getNewAddedValue() {
		return '<div>insert element here</div>';
	}

	// Original value getters
	
	/**
	 * @return the original value for a text modification
	 */
	#getOriginalTextValue() {
		const originals = [];
		this.#selectedElements.forEach( element => originals.push( element.textContent ));
		return originals;
	}
	
	/**
	 * @return the original value for a markup modification
	 */
	#getOriginaMarkupValue() {
		const originals = [];
		this.#selectedElements.forEach( element => originals.push( element.outerHTML ));
		return originals;
	}
	
	/**
	 * @return the original value for a markup modification
	 */
	#getOriginaAddedValue() {
		// keep track of the element relative to which we're adding before or after by 
		// putting it into the original value
		const originals = [];
		this.#selectedElements.forEach( element => originals.push( element ));
		return originals;
	}
	
	/**
	 * @return the original value for a markup modification
	 */
	#getOriginaRemovedValue() {
		// track the original display value of the element. In the admin the remove modification only 
		// hides the element by setting display to none. The original is used to restore it for preview
		const originals = [];
		this.#selectedElements.forEach( element => originals.push( element.style.display ));
		return originals;
	}
	
}

//

export default CreateExperienceModificationCommand;
