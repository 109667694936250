/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import SphereAdminSession from '../model/SphereAdminSession';
import MetricEditor from '../components/editors/MetricEditor';
import MetricFactory from '../managers/MetricFactory';

//

/**
 * The CreateMetricPanel contains the administration UI for creating a new Metric
 */
class CreateMetricPanel extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();
		SphereAdminSession.metric = MetricFactory.createMetric();
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return <MetricEditor modal={ false }/>;
	}

}

//

export default CreateMetricPanel;
