/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';

/**
 * Retrieves the currently logged in user's info
 */
class RetrieveUserRequest extends ServiceRequestCommand {

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
	getService() {
		return SphereAdminServices.RETRIEVE_USER;
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		var response = this.getResponse();
		//console.info( 'RetrieveUserRequest::handleComplete', response );
		if( response.success ) {
			SphereAdminSession.user = response.result;
		}
		// TODO: handle error
	}
 
}

export default RetrieveUserRequest;
