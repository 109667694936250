/*
 * (c) Verra Technology Corporation
 */

/**
 * Defines the available interaction types for an event (Event.interactionType)
 */
const EventInteractionTypes = {

	// 0: any, 1: engagement (view, click) only, 2: success (cart, order) only, 3: engagement and/or success

	/**
	 * Engagement events on a Channel (view, click)
	 */
	ENGAGEMENT: 1,

	/**
	 * Success events related to a Channel (add to cart, place order, etc)
	 */
	SUCCESS: 2,

};

export default EventInteractionTypes;