/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand.js';
import SphereAdminSession from '../../model/SphereAdminSession.js';
import SphereAdminServices from '../../model/SphereAdminServices.js';

/**
 * Searches for Content
 */
class SearchContentRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param phrase The phrase to search against
	 * @param pageStart The start index
	 * @param pageSize How many records to retrieve
	 */
	constructor( phrase, pageStart, pageSize ){
		super();
		this.phrase = phrase;
		this.pageStart = pageStart;
		this.pageSize = pageSize;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.SEARCH_CONTENT;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id, phrase: this.phrase, pageStart: this.pageStart, pageSize: this.pageSize };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		var response = this.getResponse();
		//console.info( 'SearchContentRequest::handleComplete', response );
		if( response.success ) {
			SphereAdminSession.searchedContentList = this.getResponse().result;
		}
	}
 
}

export default SearchContentRequest;
