/*
 * (c) Verra Technology Corporation
 */

import OptimizationExperienceAnalytics from "./OptimizationExperienceAnalytics";

//

/**
 * Class for representing event analytics for a specific Optimization event.
 */
class OptimizationEventAnalytics {

	// Protected Members

	/**
	 * The Optimization in which the metrics are for
	 */
	_optimization;

	/**
	 * The event type object in which the metrics apply
	 */
	_eventType;

	/**
	 * Represents the event metrics by individual Experiences
	 */
	_experiences;

	//

	/**
	 * The total number of times this event has occurred
	 */
	count = 0;

	/**
	 * The number of times this event has occurred counted once per session
	 */
	sessions = 0;

	/**
	 * The aggregate totals for all content -- this excludes the control I think?
	 */
	// contentTotal = 0;

	//

	/**
	 * Constructs a new OptimizationEventAnalytics model object. 
	 * @param optimization The Optimization the analytics are for
	 */
	constructor( optimization, eventType ) {
		this._eventType = eventType;
		this._experiences = {};
		optimization.experiences.forEach(( experience, index ) => {
			this._experiences[ experience.id ] = new OptimizationExperienceAnalytics( experience, eventType );
		});
	}

	/**
	 * Updates the data for the analytics object
	 */
	updateAnalytics( eventData ) {
		this.count = eventData.count;
		this.sessions = eventData.sessions;
		Object.keys( eventData.experiences ).forEach(( experienceId, index ) => {
			const experienceEventData = eventData.experiences[ experienceId ];
			const id = ( experienceId.indexOf( 'default:' ) === 0 ) ? 'default' : experienceId;
			this._experiences[ id ].updateAnalytics( experienceEventData );
		});
	}

}

//

export default OptimizationEventAnalytics;