/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ExperienceModificationType from '../../../model/ExperienceModificationType';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import AddIcon from '../../icons/AddIcon';
import CancelIcon from '../../icons/CancelIcon';
import CodeIcon from '../../icons/CodeIcon';
import CssIcon from '../../icons/CssIcon';
import JavaScriptIcon from '../../icons/JavaScriptIcon';
import MoveIcon from '../../icons/MoveIcon';
import NotesIcon from '../../icons/NotesIcon';
import RemoveIcon from '../../icons/RemoveIcon';
import TextColorIcon from '../../icons/TextColorIcon';
import VisibilityIcon from '../../icons/VisibilityIcon';
import VisibilityOffIcon from '../../icons/VisibilityOffIcon';
import SphereAdminSession from '../../model/SphereAdminSession';

//

/**
 * Defines labels for the modification types
 */
const modificationTypeIcons = {
	'0': <NotesIcon size='24' color='#ffffff'/>,
	'1': <CodeIcon size='24' color='#ffffff'/>,
	'2': <TextColorIcon size='24' color='#ffffff'/>,
	'3': <MoveIcon size='24' color='#ffffff'/>,
	'4': <AddIcon size='24' color='#ffffff'/>,
	'5': <RemoveIcon size='24' color='#ffffff'/>,
	'6': <CssIcon size='24' color='#ffffff'/>,
	'7': <JavaScriptIcon size='24' color='#ffffff'/>,
};

//

/**
 * Displays a list of modifications for an Experience
 */
class ModificationsList extends Component {
	
	/**
	 * Constructs the panel.
	 */
	 constructor() {
		super();
		this.state = { 
		};
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return this.#getModificationsListMarkup();
	}

	/**
	 * The markup for the experience controls
	 */
	#getModificationsListMarkup() {
		const experience = SphereAdminSession.experience;
		const isLocked = ( experience.status === ModifiableObject.LOCKED );
		const modifications = SphereAdminSession.experience.modifications; 
		const modsList = [];
		// Object.keys( modifications ).forEach( key => {
		modifications.forEach(( modification, index ) => {
			// const modification = modifications[ key ];
			const modificationSelected = ( modification === this.props.selectedModification );
			const itemHighlight = ( modificationSelected ) ? 'highlighted' : '';
			const value = ( modification.value != null && modification.type !== ExperienceModificationType.STYLES ) ? modification.value.toString() : '';
			modsList.push( 
				<div key={ index } className={ 'modification-item ' + itemHighlight } onClick={ () => this.#handleSelectModification( modification )}>
					<div>{ modificationTypeIcons[ modification.type ]} </div>
					<div style={{ flexGrow: 2 }}>{ ExperienceModificationType.MODIFICATION_TYPE_LABELS[ modification.type ]} </div>
					<button className='control-button view' onClick={ e => this.#handleTogglePreviewOfModification( e, modification )}>
						{ !modification.enabled && 
							<VisibilityIcon size={ 18 }/>
						}
						{ modification.enabled && 
							<VisibilityOffIcon size={ 18 }/>
						}
					</button>
					{ !isLocked && 
						<button className='control-button remove' onClick={ e => this.#handleRemoveModification( e, modification )}>
							<CancelIcon size={ 18 }/>
						</button>
					}
					{ modification.type !== ExperienceModificationType.CSS && modification.type !== ExperienceModificationType.JS && 
						<div className={ 'modification-path' }>{ value }</div>
					}
				</div>
			)
		});
		return (
			<div 
				className='panel-cell modifications-list' 
				style={{ maxWidth: this.props.width, minWidth: this.props.width, marginRight: 13, overflowY: 'auto' }}>
				{ modsList }
			</div>
		);
	}

	//

	
	/**
	 * Handles selecting the elements of a modification
	 */
	#handleSelectModification( modification ) {
		this.props.selectModificationHandler( modification );
	}

	/**
	 * Handles toggling the preview of a single modification
	 */
	#handleTogglePreviewOfModification( e, modification ) {
		e.stopPropagation();
		this.props.togglePreviewHandler( modification, !modification.enabled );
		this.setState({}); // redraw
   	}

	/**
	 * Handles removal of a modification
	 */
   	#handleRemoveModification( e, modification ) {
		e.stopPropagation();
		this.props.removeModificationHandler( modification );
		this.setState({}); // redraw
   	}


}

//

export default ModificationsList;
