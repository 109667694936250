/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import DropDownField from '../controls/DropDownField';
import EventInteractionTypes from '../../model/EventInteractionTypes';

//

/** 
 * The colors used for the chart
 */
const colors = [
	/*
	'#09608f',
	'#4964a5',
	'#8063af',
	'#b45da9',
	'#df5795',
	'#fc5c75',
	'#ff714f',
	'#ff911f'
	*/
	'#65c1b7',
	'#5ea6c5',
	'#5679c9',
	'#5c4fcd',
	'#8d47d1',
	'#c83fd6',
	'#da37a7',
	'#df2f61',
];

const otherColor = '#999999';
const controlColor = '#666666';
const emptyColor = '#333333';

/**
 * Identifies the various metric types
 */
const metricTypes = {
	BEST: { label: 'Probability of Performance' },
	CONVERSION: { label: 'Conversion Rate' },
	VOLUME: { label: 'Volume' },
	REVENUE: { label: 'Revenue' },
	REVENUE_PER_ENGAGEMENT: { label: 'Revenue Per Engagement' },
	AVERAGE_ORDER_VALUE: { label: 'Average Order Value' },
	REVENUE_POTENTIAL_CVR: { label: 'Revenue Potential (CVR)' },
	REVENUE_POTENTIAL_CVR_DELTA: { label: 'Revenue Potential Delta (CVR)' },
	REVENUE_POTENTIAL_CVRAOV: { label: 'Revenue Potential (CVR/AOV)' },
	REVENUE_POTENTIAL_CVRAOV_DELTA: { label: 'Revenue Potential Delta (CVR/AOV)' },
	REVENUE_POTENTIAL_AOV: { label: 'Revenue Potential (AOV)' },
	REVENUE_POTENTIAL_AOV_DELTA: { label: 'Revenue Potential Delta (AOV)' }
};

//

// TODO: replace all of the if( obj != null & obj[ prop ] != null ) code with optional chaining obj?.[ prop ]

//

/**
 * Renders analytics for Content within a Channel
 * 
 * IMPORTANT: when the ContentChart has the engagementEvent prop it is displaying conversion events.
 * The engagementEvent prop in this case is used to determine for what engagement event we're viewing
 * conversions for.
 */
class ContentChart extends Component {
	
	/**
	 * Constructs the component
	 */
	constructor(){
		super();
		this.state = { metricType: metricTypes.BEST };
	}

	/**
	 * Renders the component
	 * @see react docs
	 * TODO: this was cleaned up quite a but is still a little messy
	 */
	render(){
		const availableMetricTypes = this.#getMetricTypes();
		if( !availableMetricTypes.includes( this.state.metricType )) this.state.metricType = metricTypes.CONVERSION;

		const channel = this.props.channel;
		const chartData = this.#getChartData();
		const data = chartData.data;
		const totalCount = chartData.totalCount;
		const maxLabels = data.length + 1; // ( channel.controlId != null ) ? 6 : 7;

		const colorOffset = Math.max( 1, Math.round( colors.length / data.length ));
		const countRatio = 360 / totalCount;
		const labels = [];

		const gradOffset = 0; // data.length * 0.25;

		let gradient = 'conic-gradient( ';
		let lastGradientValue = 0;
		let color;
		let chartColor;
		let i;

		for( i = 0; i < data.length; i++ ){
			
			let item = data[ i ];
			let itemCount = Math.max( 0, item.count );

			if( item.content != null && item.content.color == null ){
				color = ( i < colors.length ) ? colors[ Math.min( i * colorOffset, colors.length - 1 ) ] : otherColor;
				item.content.color = color;
			} else if( item.content != null ){
				color = item.content.color;
			} else { 
				color = controlColor;
			}

			chartColor = ( totalCount > 0 ) ? color : emptyColor;

			let currentGradientValue = lastGradientValue + itemCount;
			let startDeg = ( totalCount > 0 ) ? Math.round( lastGradientValue * countRatio ) : Math.round( i / data.length * 360 );
			let endDeg = ( totalCount > 0 ) ?  Math.round( currentGradientValue * countRatio - gradOffset ) : Math.round( i / data.length * 360 ) + Math.round( 360 / data.length );
			lastGradientValue = currentGradientValue;

			gradient += `${chartColor} ${startDeg}deg ${endDeg}deg, `;
			
			labels.push( this.#getLabel( i, maxLabels, item, color ));
		}

		gradient += `${chartColor} 360deg 360deg )`;

		return ( 
			<div className='grid'>
				<div className='grid-cell default-33'>
					<h3>{this.props.label}</h3>
				</div>
				{ this.props.engagementEvent != null && 
					<div className={`grid-cell default-66 align-right`}>
						<DropDownField
							className='padless'
							itemsWidth='225px' 
							hideBackground={true} 
							labelAlignRight={true} 
							alignRight={true}
							style={{ display: 'inline-block' }}
							labelField='label' 
							items={ availableMetricTypes }
							selectedItem={ this.state.metricType }
							changeHandler={ this.#handleMetricTypeChange.bind( this )}
						/>
					</div>
				}
				<div className='grid-cell start-row default-100'>
					<div className='grid donut-chart pad-cell-top'>
						<div className='grid-cell default-35 default-suffix-5'>
							<div className="donut" style={{ background: gradient }}>
								<div className="hole"></div>
							</div>
						</div>
						<div className='grid-cell default-60' style={{}}>
							<ul className='grid'>
								{ labels }
								{/* this.#getControlLabel() */}
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
		//*/
	}

	//

	/**
	 * @return the data used to render the chart
	 */
	#getChartData(){
		const data = [];

		const channel = this.props.channel;
		const content = channel.content;
		const metrics = this.props.metrics;
		const isConversionView = ( this.props.engagementEvent != null );

		let contentMetricsCount = 0;
		let totalCount = 0;
		let metric;
		let name;

		for( let i = 0; i < content.length + 1; i++ ){

			if( i < content.length ){
				metric = ( !isConversionView ) ? 
						metrics.getEngagementContentEventMetric( content[ i ].id, this.props.event.id ) : 
						metrics.getConversionContentEventMetric( content[ i ].id, this.props.engagementEvent.id, this.props.event.id );
				name = content[ i ].name;
			} else {
				metric = ( this.props.engagementEvent == null ) ? 
						this.props.metrics.getEngagementControlEventMetric( this.props.event.id ) : 
						this.props.metrics.getConversionControlEventMetric( this.props.engagementEvent.id, this.props.event.id );
				name = ( channel.controlId != null ) ? 'Control' : 'Random Selection';
			}
			
			// console.info( 'getChartData', metric );
						
			if( this.props.engagementEvent == null || this.state.metricType === metricTypes.VOLUME ){
				contentMetricsCount = metric.count;
			} else if( this.state.metricType === metricTypes.BEST ){
				contentMetricsCount = metric.successProbability;
			} else if( this.state.metricType === metricTypes.CONVERSION ){
				contentMetricsCount = metric.cvr * 100;
			} else if( this.state.metricType === metricTypes.REVENUE ){
				contentMetricsCount = metric.revenue * 0.01;
			} else if( this.state.metricType === metricTypes.REVENUE_PER_ENGAGEMENT ){
				contentMetricsCount = metric.rpe * 0.01;
			} else if( this.state.metricType === metricTypes.AVERAGE_ORDER_VALUE ){
				contentMetricsCount = metric.aov * 0.01;
			} else if( this.state.metricType === metricTypes.REVENUE_POTENTIAL_CVR ){
				contentMetricsCount = metric.rpcvr * 0.01;
			} else if( this.state.metricType === metricTypes.REVENUE_POTENTIAL_CVR_DELTA ){
				contentMetricsCount = metric.rpcvrd * 0.01;
			} else if( this.state.metricType === metricTypes.REVENUE_POTENTIAL_AOV ){
				contentMetricsCount = metric.rpaov * 0.01;
			} else if( this.state.metricType === metricTypes.REVENUE_POTENTIAL_AOV_DELTA ){
				contentMetricsCount = metric.rpaovd * 0.01;
			} else if( this.state.metricType === metricTypes.REVENUE_POTENTIAL_CVRAOV ){
				contentMetricsCount = metric.rpcvraov * 0.01;
			} else if( this.state.metricType === metricTypes.REVENUE_POTENTIAL_CVRAOV_DELTA ){
				contentMetricsCount = metric.rpcvraovd * 0.01;
			}

			totalCount += Math.max( 0, contentMetricsCount );
			
			let chartObj = { name, count: contentMetricsCount, z: 360 / content.length * i, content: content[ i ] };
			data.push(chartObj);
		}

		data.sort(( a, b ) => { 
			return ( a.count > b.count ) ? -1 : 1;
		});

		return { data, totalCount };
	}

	/**
	 * @return the markup for labeling specific content
	 */
	#getLabel( index, maxLabels, item, color ){
		let label;
		let value;
		if( this.props.engagementEvent == null || this.state.metricType === metricTypes.VOLUME ){
			value = Intl.NumberFormat( 'en-US', { maximumFractionDigits: 1 } ).format( item.count );
		} else if( this.state.metricType === metricTypes.CONVERSION || this.state.metricType === metricTypes.BEST ){
			value = Intl.NumberFormat( 'en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 } ).format( item.count ) + '%';
		} else if( this.state.metricType === metricTypes.REVENUE ){
			const formatConfig = { 
				style: 'currency',
				currency: 'USD',
				notation: "compact",
				maximumFractionDigits: 1, 
				minimumFractionDigits: 1 
			};
			value = Intl.NumberFormat( 'en-US', formatConfig ).format( item.count );
		} else if( 
			this.state.metricType === metricTypes.REVENUE_PER_ENGAGEMENT || 
			this.state.metricType === metricTypes.AVERAGE_ORDER_VALUE || 
			this.state.metricType === metricTypes.REVENUE_POTENTIAL_CVR || this.state.metricType === metricTypes.REVENUE_POTENTIAL_CVR_DELTA || 
			this.state.metricType === metricTypes.REVENUE_POTENTIAL_AOV || this.state.metricType === metricTypes.REVENUE_POTENTIAL_AOV_DELTA || 
			this.state.metricType === metricTypes.REVENUE_POTENTIAL_CVRAOV || this.state.metricType === metricTypes.REVENUE_POTENTIAL_CVRAOV_DELTA
		){
				const formatConfig = { 
					style: 'currency',
					currency: 'USD',
					notation: "compact",
					maximumFractionDigits: 2, 
					minimumFractionDigits: 2 
				};
				value = Intl.NumberFormat( 'en-US', formatConfig ).format( item.count );
		}

		if( index < maxLabels ){
			label = <li key={ index } className='label'>
					<div className='name' style={{ color, filter: 'brightness(140%)' }}>{ item.name }</div>
					<div className='value'>{ value }</div> 
				</li>;
		} else if( index === maxLabels ){
			label = <li key={ index }>
					<div className='name'>...</div>
				</li>;
		}

		return label;
	}

	/**
	 * @return the markup for the labeling the control
	 */
	#getControlLabel(){
		const channel = this.props.channel;

		const metric = ( this.props.engagementEvent == null ) ? 
						this.props.metrics.getEngagementControlEventMetric( this.props.event.id ) : 
						this.props.metrics.getConversionControlEventMetric( this.props.engagementEvent.id, this.props.event.id );
		
		let controlLabel;

		if( metric != null ){

			let label = ( channel.controlId != null ) ? 'Control' : 'Random Selection';
			let value;
			
			if( this.props.engagementEvent == null || this.state.metricType === metricTypes.VOLUME ){
				value = Intl.NumberFormat( 'en-US', { maximumFractionDigits: 1 } ).format( metric.count );
			} else if( this.state.metricType === metricTypes.BEST ){
				value = Intl.NumberFormat( 'en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 } ).format( metric.successProbability ) + '%';
			} else if( this.state.metricType === metricTypes.CONVERSION ){
				value = Intl.NumberFormat( 'en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 } ).format( metric.cvr * 100 ) + '%';
			} else if( 
				this.state.metricType === metricTypes.REVENUE || 
				this.state.metricType === metricTypes.REVENUE_PER_ENGAGEMENT || 
				this.state.metricType === metricTypes.AVERAGE_ORDER_VALUE || 
				this.state.metricType === metricTypes.REVENUE_POTENTIAL_AOV || 
				this.state.metricType === metricTypes.REVENUE_POTENTIAL_AOV_DELTA || 
				this.state.metricType === metricTypes.REVENUE_POTENTIAL_CVRAOV || 
				this.state.metricType === metricTypes.REVENUE_POTENTIAL_CVRAOV_DELTA 
			){
				
				const formatConfig = { 
					style: 'currency',
					currency: 'USD',
					notation: "compact",
					maximumFractionDigits: 2, 
					minimumFractionDigits: 2 
				};

				let metricVal;
				if( this.state.metricType === metricTypes.REVENUE ){
					metricVal = metric.revenue;
				} else if( this.state.metricType === metricTypes.REVENUE_PER_ENGAGEMENT ){
					metricVal = metric.rpe;
				} else if( this.state.metricType === metricTypes.AVERAGE_ORDER_VALUE ){
					metricVal = metric.aov;
				} else if( this.state.metricType === metricTypes.REVENUE_POTENTIAL_CVRAOV ){
					metricVal = metric.rpcvr;
				} else if( this.state.metricType === metricTypes.REVENUE_POTENTIAL_CVRAOV_DELTA ){
					metricVal = metric.rpcvrd;
				} else if( this.state.metricType === metricTypes.REVENUE_POTENTIAL_AOV ){
					metricVal = metric.rpaov;
				} else if( this.state.metricType === metricTypes.REVENUE_POTENTIAL_AOV_DELTA ){
					metricVal = metric.rpaovd;
				} 

				value = Intl.NumberFormat( 'en-US', formatConfig ).format( metricVal * 0.01 );

			}

			controlLabel = <li key='control' className='label'>
				<div className='name'>{ label }</div>
				<div className='value'>{ value }</div> 
			</li>;
		}

		return controlLabel;
	}

	/**
	 * @return The various types of metrics to view, for example: conversion rate, volume, order value, etc.
	 * These values show up in the drop down
	 */
	#getMetricTypes(){
		const types = [ metricTypes.BEST, metricTypes.CONVERSION, metricTypes.VOLUME ];
		if( this.props.event.id === 'place-order' ){
			types.push( 
				metricTypes.REVENUE, metricTypes.REVENUE_PER_ENGAGEMENT,
				metricTypes.AVERAGE_ORDER_VALUE,

				// metricTypes.REVENUE_POTENTIAL_CVR,
				// metricTypes.REVENUE_POTENTIAL_AOV,
				// metricTypes.REVENUE_POTENTIAL_CVRAOV,
				
				metricTypes.REVENUE_POTENTIAL_CVR_DELTA,
				metricTypes.REVENUE_POTENTIAL_AOV_DELTA,
				metricTypes.REVENUE_POTENTIAL_CVRAOV_DELTA,
			);
		}
		return types;
	}

	// 

	/**
	 * Handles a selection of the metric type.
	 */
	#handleMetricTypeChange( type ){
		this.setState({ metricType: type });
	}		

}

export default ContentChart;
