import React from 'react';

const CssIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg height={size} width={size} viewBox="0 -960 960 960" >
			<g className='nc-icon-wrapper' stroke='none' fill={color}>
			<path d="M414-354q-18.4 0-29.2-10.8T374-394v-46h60v26h92v-47H414.291q-17.691 0-28.991-11.3T374-501.362v-64.276q0-18.762 10.8-29.562T414-606h132q18.4 0 29.2 10.8T586-566v46h-60v-26h-92v47h111.709q17.691 0 28.991 11.3t11.3 29.062v64.276q0 18.762-10.8 29.562T546-354H414Zm260 0q-18.4 0-29.2-10.8T634-394v-46h60v26h92v-47H674.291q-17.691 0-28.991-11.3T634-501.362v-64.276q0-18.762 10.8-29.562T674-606h132q18.4 0 29.2 10.8T846-566v46h-60v-26h-92v47h111.709q17.691 0 28.991 11.3t11.3 29.062v64.276q0 18.762-10.8 29.562T806-354H674Zm-520 0q-18.4 0-29.2-10.8T114-394v-172q0-18.4 10.8-29.2T154-606h132q18.4 0 29.2 10.8T326-566v52h-60v-32h-92v132h92v-32h60v51.719Q326-376 315.2-365T286-354H154Z"/>
			</g>
		</svg>
	);
};

export default CssIcon;