/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import MetricEditor from '../components/editors/MetricEditor';
import SphereAdminSession from '../model/SphereAdminSession';
import RetrieveMetricRequest from '../requests/metrics/RetrieveMetricRequest';

//

/**
 * The EditMetricPanel contains the administration UI for editing an Metric
 */
class EditMetricPanel extends Component {

	/**
	 * Keeps track of the metric ID from the URL
	 */
	#metricId;

	/**
	 * Constructs the Component.
	 */
	constructor() {
		super();

		this.#metricId = SphereAdminSession.stateParameters.metricId;

		// check to see if the Metric is already in the session
		if( SphereAdminSession.metric?.id != this.#metricId && SphereAdminSession.metrics != null && SphereAdminSession.metrics.length > 0 ){
			this.#lookForExperienceInSession();
		} else {
			// pull down the Metric, we don't have it
			SphereAdminSession.loading = true;
			const retrieveMetric = new RetrieveMetricRequest( this.#metricId );
			retrieveMetric.execute(( command ) => { this.#handleMetricRetrieved( command ); } );
		}
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const jsx = ( SphereAdminSession.metric != null ) ? <MetricEditor/> : '';
		return jsx;
	}

	//

	/**
	 * Takes the Experience ID from the URL and tries to find the Experience in the array of Experiences in the session
	 */
	#lookForExperienceInSession() {
		const metric = SphereAdminSession.metrics.find(( metric ) => {
			return metric.id === this.#metricId;
		});
		if( metric != null ) {
			SphereAdminSession.metric = metric;
		} else {
			// we need to load the metric
			SphereAdminSession.loading = true;
			const retrieveMetric = new RetrieveMetricRequest( this.#metricId );
			retrieveMetric.execute(( command ) => { this.#handleMetricRetrieved( command ); } );
		}
	}

	/**
	 * Handles the response from the request to retrieve Channel
	 */
	#handleMetricRetrieved( command ) {
		SphereAdminSession.loading = false;
		this.setState({}); // redraw
	};

}

//

export default EditMetricPanel;
