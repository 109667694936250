/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

/**
 * Content Accordion Component
 */
class ContentAccordion extends Component {
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const itemsMarkup = [];
		for( let i = 0; i < this.props.items.length; i++ ){
			let item = this.props.items[ i ];
			let contentDisplay = ( this.props.selectedHeaderIndex === i ) ? ' selected' : '';
			itemsMarkup.push(
				<div key={i} className='accordion-section'>
					<button className='accordion-header' onClick={ this.#handleHeaderClick.bind( this ) }>{ item[ this.props.headerLabel ] }</button>
					<div className={ 'accordion-content' + contentDisplay }>
						{item.content}
					</div>
				</div>
			);
		};
		return ( 
			<div className='content-accordion'>
				{itemsMarkup}
			</div>
		);
	}
	
	//

	/**
	 * Handles clicks on an accoridion header
	 */
	#handleHeaderClick( e ){
		const header = e.target;
		const content = header.closest( '.accordion-section' ).querySelector( '.accordion-content' );
		let openContent = header.closest( '.content-accordion' ).querySelector( '.accordion-content.selected' );

		if( openContent != null ) {
			openContent.classList.remove( 'selected' );
		}

		if( content !== openContent ) {
			content.classList.add( 'selected' );
		}
	}

}

export default ContentAccordion;
