/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import PublishableObject from '../../../model/PublishableObject.mjs';
import OpenModalCommand from '../../commands/OpenModalCommand';
import SetStateCommand from '../../commands/SetStateCommand';
import ValidatorCommand from '../../commands/ValidatorCommand';
import AdminStates from '../../model/AdminStates';
import SphereAdminSession from '../../model/SphereAdminSession';
import SaveContentRequest from '../../requests//content/SaveContentRequest';
import Alert from '../controls/Alert';
import Hint from '../controls/Hint';
import InputField from '../controls/InputField';
import TabComponent from '../controls/TabComponent';

import { Editor } from '@tinymce/tinymce-react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import ObjectStatusMap from '../../model/ObjectStatusMap';

//

/**
 * Keeps track of whether or not the ID has been edited
 */
let hasEditedId;

//

/**
 * The ContentEditor contains the administration UI for creating and editing content
 * TODO: previewing with a style sheet or some kind of in site preview would be super useful
 */
class ContentEditor extends Component {
	
	#hasInitialChange = false;

	//

	/**
	 * Constructs the ContentPanel.
	 */
	constructor(){
		super();

		// TODO: these can be private
		this.body = '';
		this.code = '';
		this.selectedTabIndex = 0;
		this.editContainer = React.createRef();
		//this.previewContainer = React.createRef();
		this.tinyMceEditor = React.createRef();
		this.tinyMceEditorContainer = React.createRef();
		
		// TODO: there is inconsistency across the Content, Sites, Channel editing panels in how the Model object is 
		// handled. Here we see it as part of the component state, in other panels it's only in the session
		this.state = {
			invalidated: false
		};

		window.addEventListener( 'resize', ( e ) => { this.#handleResize( e ); });
	}
	
	/**
	 * Handles the mounting of the component
	 */
	componentDidUpdate(){
		// console.info( 'EditContentPanel::componentDidUpdate' );
		setTimeout( () => { this.resizeContentContainers(); }, 200 );
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render(){
		// console.info( 'ContentEditor::render', this.body, this.code );
		
		const regex = /(.*?)(<script>)(.*?)(<\/script>)/ms;
		const match = SphereAdminSession.content.body.match( regex );
		if( match != null ){
			this.body = match[ 1 ];
			this.code = match[ 3 ];
		} else {
			this.body = SphereAdminSession.content.body;
		}

		const markup = ( SphereAdminSession.content == null ) ? '' : <div className='content-panel'>
			{this.#getHeaderMarkup()}
			{this.#getPrimaryFieldsMarkup()}
			{this.#getTabComponentMarkup()}
		</div>;
		
		const state = this.state; // TODO: clean up state mutation
		state.invalidated = false;
		
		return markup;
	}

	// Markup

	/**
	 * Gets the markup for the header section
	 */
	#getHeaderMarkup(){
		var content = SphereAdminSession.content;
		var saveDisabled = content.status === ModifiableObject.SAVED || content.locked;
		var saveButtonsDisabledClass = ( saveDisabled ) ? 'disabled' : '';

		let divider = '';

		return 	<div className='grid'>
					<div className='grid-cell default-50'>
						<h2>Edit Content</h2>
						<div className='breadcrumb'>
							<a href='/content/'>Content</a> / {content.name}
						</div>
					</div>
					<div className='grid-cell default-50 align-right header-actions'>
						<button className={'primary-button control-pad-left ' + saveButtonsDisabledClass} disabled={saveDisabled} style={{width: '90px'}} onClick={this.#handleSave.bind( this )}>Save</button>
						<button className={'button control-pad-left'} style={{width: '80px'}} onClick={ this.#handleCancel.bind( this )}>Cancel</button>
						{/* 
						<div className='control-pad-left' style={{display: 'inline-block', float: 'right'}}>
							<DropDownField 
								itemsWidth='150px' 
								alignRight={true} 
								hideLabel={true} 
								items={['Lock', 'Revert to Saved', 'Revert to Published']}
								disabled={content.locked} />
						</div>
						*/}
					</div>
				</div>;
	}

	/**
	 * Gets the primary fields markup
	 */
	#getPrimaryFieldsMarkup(){
		const content = SphereAdminSession.content;
		const status = ( content.locked ) ? 'locked' : ObjectStatusMap[ content.status ];
		const statusIndicatorElement = <div className={'status-indicatator ' + status}></div>;
		const idToolTip = 'Uniquely identifies the Content. Values can only contain letters, numbers, underscores, dashes, and periods. Cannot be changed after saving.';
		const nameToolTip = 'User friendly name fot the Content.';
		const propertiesTooltip = 'Comma separated list of properties (tags) to assign to the Content. These can later be used for search.';
		const idReadOnly = ( SphereAdminSession.currentState === AdminStates.ADMIN_CONTENT_EDIT );

		return 	<div className='grid panel-cell primary-fields'>
					<div className='grid-cell default-33'>
						{statusIndicatorElement}
						<label>Name <Hint width='250px' content={nameToolTip}/></label>
						<InputField value={content.name} onChange={( value ) => { this.#handleInputFieldChanged( 'name', value ); }}/>
					</div>
					<div className='grid-cell default-33 pad-cell-left'>
						<label>ID <Hint width='250px' content={idToolTip}/></label>
						<InputField 
							value={content.id} 
							pattern='[A-z0-9_.-]+' 
							readOnly={idReadOnly} 
							onChange={( value ) => { this.#handleInputFieldChanged( 'id', value ); }}/>
					</div>
					<div className='grid-cell default-33 pad-cell-left'>
						<label>Properties <Hint width='250px' content={propertiesTooltip}/></label>
						<InputField value={content.properties} onChange={( value ) => { this.#handleInputFieldChanged( 'properties', value ); }}/>
					</div>
				</div>;
	}

	/** 
	 * Gets the markup for the Content properties
	 */
	/*
	#getPropertiesMarkup(){

		var content = SphereAdminSession.content;
		var customPropertyElements = [];
		if( content.properties != null ){
			for( var i = 0; i < content.properties.length; i++ ){
				var property = content.properties[ i ];
				var element = <CustomPropertyInput key={'property-' + i} value={property}/>
				customPropertyElements.push( element );
			}
		}

		return 	<div className='grid pad-cell-top'>
					<div className='grid-cell default-50'>
						<div id='custom-properties-panel' className='properties-panel panel-cell'>
							<h3>Custom Properties</h3>
							<div className='grid'>
								{customPropertyElements}
								<NewCustomPropertyButton/>
							</div>
						</div>
					</div>
					<div className='grid-cell default-50 pad-cell-left'>
						<div id='discovered-properties-panel' className='panel-cell'>
							<h3>Discovered Properties</h3>
							<div className='button' style={{width: '120px'}}>Search Now</div>
						</div>
					</div>
				</div>;
	}
	*/

	/**
	 * Gets the tab component / preview and editor markup
	 */
	#getTabComponentMarkup(){
		// const content = SphereAdminSession.content;
		// console.info( 'getTabComponentMarkup', this.body );
		
		/*
		const editContent = 	<textarea 
									ref={this.editContainer} 
									className='code-editor' 
									defaultValue={content.scripts}
									onChange={( e ) => { this.#handleCodeChanged( e.target.value ); }}>
								</textarea>;
		*/

		const editContent = <CodeEditor
								ref={this.editContainer} 
								value={this.code}
								language="js"
								placeholder="Optional JavaScript (do not include the script tag)"
								data-color-mode="dark"
								onChange={( e ) => { this.#handleCodeChanged( e.target.value ); }}
								padding={15}
								style={{
									fontSize: 12,
									backgroundColor: "#1b1b1b",
									fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
								}}
							/>;

		/*
		const previewContent = <div ref={this.previewContainer}><iframe sandbox='allow-same-origin' title='Content Editor' id='content-editor-frame'/></div>;
		*/

		const htmlEditContent = <div ref={this.tinyMceEditorContainer}>
			<Editor
				tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
				onInit={(evt, editor) => this.tinyMceEditor.current = editor}
				initialValue={this.body}
				init={{
					height: '100%',
					menubar: 'edit view insert format tools table help',
					skin: "verra",
					promotion: false,
					branding: false,
					valid_children: '+body[style]',
					menu: {
						// file: { title: 'File', items: 'newdocument restoredraft | preview | export print | deleteallconversations' },
						edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
						view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments' },
						insert: { title: 'Insert', items: 'image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime' },
						format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
						tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code' }, // wordcount' },
						table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
						help: { title: 'Help', items: 'help' }
					},
					plugins: [
						'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
						'anchor', 'searchreplace', 'visualblocks', 'code',
						'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
					],
					toolbar: 'undo redo | blocks | ' +
					'bold italic forecolor | alignleft aligncenter ' +
					'alignright alignjustify | bullist numlist outdent indent | ' +
					'removeformat | link media quickimage',
					content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
				}}
				onEditorChange={( value, editor ) => { this.#handleEditorChanged( value ) }}
      		/>
		</div>;

		///*
		const tabs = [
			{ label: 'Content', content: htmlEditContent },
			{ label: 'Scripts', content: editContent },
			//{ label: 'Preview', content: previewContent }
		];
		//*/

		return 	<div id='content-editor' className='content-editor'>
					<TabComponent tabs={tabs} onSelect={( e ) => { this.#handleTabSelect( e ); }}/>
				</div>;
	}

	// Handlers

	/**
	 * Handles the response from the request to retrieve content
	 */
	/*
	#handleContentRetrieved ( command ){
		// console.info('handleContentRetrieved');
		SphereAdminSession.loading = false;
		if( SphereAdminSession.content != null ){
			const regex = /(.*?)(<script>)(.*?)(<\/script>)/ms;
			const match = SphereAdminSession.content.body.match( regex );
			if( match != null ){
				this.setState({ content: match[ 1 ], code: match[ 3 ] });
			} else {
				this.setState({ content: SphereAdminSession.content.body });
			}
	 	} else {
			var setState = new SetStateCommand( AdminStates.ADMIN_CONTENT_BROWSE );
			setState.execute();

			var alert = <Alert content='Content does not exist' showCancelBtn={false}/>;
			var openModal = new OpenModalCommand( '', alert, '500px', true );
			openModal.execute();
		}
	}
	*/

	/**
	 * Handles window resize events
	 */
	#handleResize(){
		this.resizeContentContainers();
	}

	/**
	 * Handles changes to the tab selection
	 */
	#handleTabSelect( index ){
		this.selectedTabIndex = index;
		this.#invalidate();
	}

	/**
	 * Handles a change to an input field
	 */
	#handleInputFieldChanged( property, value ){
		if( property === 'name' && !hasEditedId && SphereAdminSession.currentState !== AdminStates.ADMIN_CONTENT_EDIT ){
			const regex = /[^a-zA-Z0-9_-]/ig;
			const id = value.replaceAll( regex, '-' ).toLowerCase();
			SphereAdminSession.content.id = id;
		} else if( property === 'id' ){
			hasEditedId = true;
		}
		SphereAdminSession.content[ property ] = value;
		this.#invalidate();
	}

	/**
	 * Handles a change to an input field
	 */
	#handleEditorChanged( value ){
		// console.info( 'handleEditorChanged', value, SphereAdminSession.content.body );
		const content = SphereAdminSession.content;
		content.body = value;
		if( this.code !== null && this.code !== '' ) content.body += `<script>${this.code}</script>`;

		if( !this.#hasInitialChange ){
			this.#hasInitialChange = true;
			this.#invalidate();
		}
	}

	/**
	 * Handles a change to an input field
	 */
	#handleCodeChanged( value ){
		// console.info( 'handleCodeChanged', value );
		const content = SphereAdminSession.content;
		content.body = this.body;
		if( value !== null && value !== '' ) content.body += `<script>${value}</script>`;
	}

	/**
	 * Handles the click to 
	 */
	#handleSave(){
		// console.info( 'handleSave', SphereAdminSession.content );

		const fields = { 
			id: ValidatorCommand.isNotNullOrEmpty, 
			name: ValidatorCommand.isNotNullOrEmpty 
		};

		const validateSite = new ValidatorCommand( SphereAdminSession.content, fields );
		const isValid = validateSite.execute();

		// console.info( 'isValid', isValid );
		// console.info( 'invalidFields', validateSite.getInvalidFields() );

		if( isValid ){
			SphereAdminSession.loading = true;
			const saveContent = new SaveContentRequest( SphereAdminSession.content );
			saveContent.execute(( command ) => { this.#handleSaveComplete( command ); });
		} else {
			
			const invalidFields = validateSite.getInvalidFields();
			const invalidFieldsElements = [];
	
			for( let i = 0; i < invalidFields.length; i++ ) {
				invalidFieldsElements.push( <li key={i}>{invalidFields[ i ]}</li> );
			}
	
			const content = <div className='alert'>
				The Content cannot be saved. The following fields are invalid or incomplete:
				<ul>{invalidFieldsElements}</ul>
			</div>;
	
			const openModal = new OpenModalCommand( 'Invalid Content', content, '500px', true );
			openModal.execute();
		}
	}

	/**
	 * Handles completion of the save Content request
	 */
	#handleSaveComplete( command ){
		//console.info( 'EditContentPanel::handleSaveComplete content', SphereAdminSession.content );
		SphereAdminSession.loading = false;
		this.setState({ invalidated: true });
	}

	/**
	 * Handles a click on the cancel button
	 */
	 #handleCancel() {
		var hasChanged = SphereAdminSession.content.status === ModifiableObject.MODIFIED || SphereAdminSession.content.status === ModifiableObject.CREATED;
		if( hasChanged ){
			var alert = <Alert content='You have unsaved changes, are you sure you want to exit?' okHandler={ this.#handleCancelConfirm.bind( this ) }/>;
			var openModal= new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		} else {
			var setState = new SetStateCommand( AdminStates.ADMIN_CONTENT_BROWSE );
			setState.execute();
		}
	};

	/**
	 * Handles a confirmation to cancel changes
	 */
	#handleCancelConfirm() {
		var setState = new SetStateCommand( AdminStates.ADMIN_CONTENT_BROWSE );
		setState.execute();
	};

	//

	/**
	 * Resizes the edit and preview containers
	 */
	resizeContentContainers(){
		// console.info('resizeContentContainers');
		if( SphereAdminSession.content != null ){
			const clientHeight = window.innerHeight;

			const editContainer = this.editContainer.current;
			const tinyMceEditorContainer = this.tinyMceEditorContainer.current;

			const containerRect = document.getElementById( 'content-editor' ).getBoundingClientRect();
			if( containerRect != null ){
				const containerHeight = clientHeight - containerRect.y - 73;
			
				if( tinyMceEditorContainer != null ) tinyMceEditorContainer.style.height = containerHeight + 'px';
				if( editContainer != null ){
					editContainer.style.height = containerHeight + 'px';
					editContainer.parentNode.style.height = containerHeight + 'px';
				}
			}
		}
	}

	/**
	 * Resizes the panels to match in height
	 */
	/*
	#resizePanels( e ) {
		var customAttributesPanel = document.getElementById( 'custom-properties-panel' );
		var discoveredAttributesPanel = document.getElementById( 'discovered-properties-panel' );
		var customRect = customAttributesPanel.getBoundingClientRect();
		var discoveredRect = discoveredAttributesPanel.getBoundingClientRect();

		customAttributesPanel.style.height = null;
		discoveredAttributesPanel.style.height = null;

		if( customRect.height > discoveredRect.height ){
			discoveredAttributesPanel.style.height = customRect.height + 'px';
		} else {
			customAttributesPanel.style.height = discoveredRect.height + 'px';
		}
	}
	*/

	/**
	 * Populates the iframe which is used to display and edit content
	 */
	/*
	#populateContent(){
		if( SphereAdminSession.content != null ){
			var iframe = document.getElementById( 'content-editor-frame' );
			var html = editorHtml.split( '{content}' ).join( SphereAdminSession.content.body );
			
			// console.info( 'populate content', html );

			iframe.contentWindow.document.open();
			iframe.contentWindow.document.write( html );
			iframe.contentWindow.document.close();

			//iframe.src = 'data:text/html;charset=utf-8,' + encodeURI( html );	
		}
	}
	*/
	
 	/**
	 * Invalidates the state of the panel
	 */
    #invalidate() {
		SphereAdminSession.content.status = PublishableObject.MODIFIED;
		this.setState({ invalidated: true });
   }

}

//

export default ContentEditor;
