/*
 * (c) Verra Technology Corporation
 */

import ChannelConversionEventMetrics from "./ChannelConversionEventMetrics";
import ChannelEventMetrics from "./ChannelEventMetrics";

/**
 * Represents metrics for a specific engagement event, for a Channel
 */
class ChannelEngagementEventMetrics extends ChannelEventMetrics {

	//

	/**
	 * The conversion event types represented by the metrics object
	 */
	#conversionEventTypes;

	/**
	 * Represents the conversion events associated with this engagement event
	 */
	#conversions;

	//

	/**
	 * Constructs a new ChannelEventMetrics model object. 
	 * @param channel The Channel the metrics are for
	 * @param eventType The event type object in which the metrics apply
	 * @param conversionEventTypes The conversion events in which to track for the engagement event
	 */
	constructor( channel, eventType, conversionEventTypes ){
		super( channel, eventType )
		this.#conversionEventTypes = conversionEventTypes;
		this.#buildInitialConversionMetrics();
	}

	//

	/**
	 * Builds the default metrics structure
	 * @param conversionEventTypes The conversion events in which to track for the engagement event
	 */
	#buildInitialConversionMetrics(){
		this.#conversions = {};
		this.#conversionEventTypes.forEach( conversionEvent => {
			this.#conversions[ conversionEvent.id ] = new ChannelConversionEventMetrics( this._channel, this, conversionEvent );
		});
	}

	//

	/**
	 * Rebuilds the initial metrics structure with empty values
	 */
	resetConversionMetricsForEvent( eventType ){
		Object.keys( this.#conversions ).forEach( conversionEventId => {
			if( eventType === conversionEventId ){
				this.#conversions[ eventType ] = new ChannelConversionEventMetrics( this._channel, this, this.#conversions[ eventType ]._eventType );
			}
		});
	}

	/**
	 * Updates metrics values for an event type, selection type, and content
	 * @param type The event type ID
	 * @param selectionType The selection type, 0 for control or 1 for content
	 * @param contentId The content ID
	 * @param count The number of times the event occurred
	 */
	updateEventMetric( type, selectionType, contentId, count ){
		super.updateEventMetric( type, selectionType, contentId, count );
		// this.#conversions[ conversionEventTypeId ].updateEventMetric( type, selectionType, contentId, count );
	}

	/**
	 * Updates metrics values for an event type, selection type, and content
	 * @param conversionEventTypeId The conversion event type id
	 * @param selectionType The selection type, 0 for control or 1 for content
	 * @param contentId The content ID
	 * @param metrics An object with the metrics to update, { count: 5, order-value: 100, ... }
	 */
	updateConversionEventMetric( conversionEventTypeId, selectionType, contentId, metrics ){
		this.#conversions[ conversionEventTypeId ].updateEventMetric( conversionEventTypeId, selectionType, contentId, metrics );
	}

	//

	/**
	 * Calculates metrics after the data has been populated
	 */
	calculateMetrics(){
		Object.keys( this.#conversions ).forEach( conversionEventId => {
			this.#conversions[ conversionEventId ].calculateMetrics();
		});
	}

	//

	/**
	 * @return The metric for a specific event
	 */
	getConversionEventMetric( eventTypeId ){
		return this.#conversions[ eventTypeId ];
	}

	/**
	 * @return All content and control metrics for the conversion metric
	 */
	getAllConversionContentEventMetrics( conversionEventTypeId ){
		const conversionMetric = this.#conversions[ conversionEventTypeId ];
		return { content: conversionMetric._content, control: conversionMetric._control };
	}

	/**
	 * @return The content metrics for a specific event
	 */
	getConversionContentEventMetric( contentId, conversionEventTypeId ){
		return this.#conversions[ conversionEventTypeId ].getContentEventMetric( contentId );
	}

	/**
	 * @return The control metrics for a specific event
	 */
	getConversionControlEventMetric( eventTypeId ){
		return this.#conversions[ eventTypeId ].getControlEventMetric();
	}

	//

	/**
	 * 
	 */
	buildReport(){
		let report = '';
		// report += ' ============================= \n';
		// report += this._eventType.name + ' \n';
		// report += ' ============================= \n';
		// report += 'Conversions \n';
		// report += ' -------------------------------------  \n';
		report += `${this._channel.name},Channel,${this._eventType.name},,,${this.total},,,,,,,,,,\n`;
		Object.keys( this._content ).forEach( contentId => {
			report += this._content[ contentId ].buildReport( this._eventType.name, false, false );
		});
		report += this._control.buildReport( this._eventType.name, false, true );
		Object.keys( this.#conversions ).forEach( conversionEventId => {
			report += this.#conversions[ conversionEventId ].buildReport();
		});
		// report += ' =============================  \n';
		return report;
	}

}

//

export default ChannelEngagementEventMetrics;