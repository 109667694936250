
/*
 * (c) Verra Technology Corporation
 */

import MetricsTimeValues from "./MetricsTimeValues";

//

const timeIncrements = [
	{ label: 'Minute', value: MetricsTimeValues.INCREMENT_MINUTE },
	{ label: 'Hour', value: MetricsTimeValues.INCREMENT_HOUR },
	{ label: 'Day', value: MetricsTimeValues.INCREMENT_DAY },
	{ label: 'Week', value: MetricsTimeValues.INCREMENT_WEEK },
	{ label: 'Month', value: MetricsTimeValues.INCREMENT_MONTH }//,
	//{ label: 'Quarter', value: MetricsTimeValues.INCREMENT_QUARTER },
	//{ label: 'Year', value: MetricsTimeValues.INCREMENT_YEAR }
];

/**
 * Contants use to control chart time parameters
 */
 const ChartTimeValues = {

	/**
	 * Time periods available for the impressions chart
	 */
	timeIncrements: timeIncrements,

	/**
	 * Time ranges available for the impressions chart
	 */
	availableRanges: [
		
		// Days

		{ 
			label: 'Last 24 Hours', 
			value: MetricsTimeValues.RANGE_LAST_DAY,
			defaultIncrement: timeIncrements[ 1 ],
			increments: [ 
				timeIncrements[ 0 ], 
				timeIncrements[ 1 ] 
			]
		},
		
		{ 
			label: 'Current Day', 
			value: MetricsTimeValues.RANGE_CURRENT_DAY, 
			defaultIncrement: timeIncrements[ 1 ] ,
			increments: [ 
				timeIncrements[ 0 ], 
				timeIncrements[ 1 ]
			]
		},
		
		// Weeks

		{ 
			label: 'Last 7 Days', 
			value: MetricsTimeValues.RANGE_LAST_WEEK, 
			defaultIncrement: timeIncrements[ 1 ] ,
			increments: [ 
				timeIncrements[ 1 ], 
				timeIncrements[ 2 ] 
			]
		},

		{ 
			label: 'Current Week', 
			value: MetricsTimeValues.RANGE_CURRENT_WEEK,
			defaultIncrement: timeIncrements[ 2 ],
			increments: [ 
				timeIncrements[ 1 ], 
				timeIncrements[ 2 ] 
			]
		},

		// Months
		
		{ 
			label: 'Last 30 Days', 
			value: MetricsTimeValues.RANGE_LAST_MONTH,
			defaultIncrement: timeIncrements[ 2 ],
			increments: [ 
				timeIncrements[ 1 ], 
				timeIncrements[ 2 ], 
				timeIncrements[ 3 ] 
			]
		},

		{ 
			label: 'Current Month', 
			value: MetricsTimeValues.RANGE_CURRENT_MONTH,
			defaultIncrement: timeIncrements[ 2 ],
			increments: [ 
				timeIncrements[ 1 ], 
				timeIncrements[ 2 ], 
				timeIncrements[ 3 ] 
			]
		},

		// Quarters

		{ 
			label: 'Last 3 Months', 
			value: MetricsTimeValues.RANGE_LAST_QUARTER,
			defaultIncrement: timeIncrements[ 2 ],
			increments: [ 
				timeIncrements[ 1 ], 
				timeIncrements[ 2 ], 
				timeIncrements[ 3 ] 
			]
		},

		{ 
			label: 'Current Quarter', 
			value: MetricsTimeValues.RANGE_CURRENT_QUARTER,
			defaultIncrement: timeIncrements[ 2 ],
			increments: [ 
				timeIncrements[ 1 ], 
				timeIncrements[ 2 ], 
				timeIncrements[ 3 ] 
			]
		},

		// Years

		{ 
			label: 'Last Year to Date', 
			value: MetricsTimeValues.RANGE_LAST_YEAR,
			defaultIncrement: timeIncrements[ 2 ],
			increments: [ 
				timeIncrements[ 2 ], 
				timeIncrements[ 3 ],
				timeIncrements[ 4 ] 
			]
		},
		{ 
			label: 'Current Year', 
			value: MetricsTimeValues.RANGE_CURRENT_YEAR,
			defaultIncrement: timeIncrements[ 2 ],
			increments: [ 
				timeIncrements[ 2 ], 
				timeIncrements[ 3 ],
				timeIncrements[ 4 ] 
			]
		}
	],

	// Util

	/**
	 * @return the date range for the specified time RANGE
	 */
	 getDateRange: function( timeRange ){

		const now = new Date();
		let startDate;
		let endDate = now;

		switch( timeRange ){

			// Days

			case MetricsTimeValues.RANGE_CURRENT_DAY:
				startDate = new Date( now.getFullYear(), now.getMonth(), now.getDate() );
				endDate = new Date( now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds() - 1 );
				break;

			case MetricsTimeValues.RANGE_LAST_DAY:
				startDate = new Date( now );
				startDate.setDate( now.getDate() - 1 );
				break;

			// Weeks

			case MetricsTimeValues.RANGE_CURRENT_WEEK:
				startDate = new Date( now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() );
				endDate = new Date( startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 7 );
				endDate.setMilliseconds( endDate.getMilliseconds() - 1 );
				break;

			case MetricsTimeValues.RANGE_LAST_WEEK:
				startDate = new Date( now );
				startDate.setDate( now.getDate() - 7 );
				break;

			// Months

			case MetricsTimeValues.RANGE_LAST_MONTH:
				startDate = new Date( now );
				startDate.setMonth( now.getMonth() - 1 );
				break;

			case MetricsTimeValues.RANGE_CURRENT_MONTH:
				startDate = new Date( now.getFullYear(), now.getMonth() );
				endDate = new Date( startDate.getFullYear(), startDate.getMonth() + 1 );
				endDate.setMilliseconds( endDate.getMilliseconds() - 1 );
				break;

			// Quarters

			case MetricsTimeValues.RANGE_LAST_QUARTER:
				startDate = new Date( now );
				startDate.setMonth( now.getMonth() - 3 );
				break;

			case MetricsTimeValues.RANGE_CURRENT_QUARTER:
				var startMonth = Math.floor( now.getMonth() / 3 ) * 3;
				startDate = new Date( now.getFullYear(), startMonth );
				endDate = new Date( startDate.getFullYear(), startDate.getMonth() + 3 );
				endDate.setMilliseconds( endDate.getMilliseconds() - 1 );
				break;

			// Years

			case MetricsTimeValues.RANGE_LAST_YEAR:
				startDate = new Date( now );
				startDate.setFullYear( now.getFullYear() - 1 );
				break;

			case MetricsTimeValues.RANGE_CURRENT_YEAR:
				startDate = new Date( now.getFullYear(), 0 );
				endDate = new Date( startDate.getFullYear() + 1, 0 );
				endDate.setMilliseconds( endDate.getMilliseconds() - 1 );
				break;

			// Default - Last Week

			default:
				startDate = new Date( now );
				startDate.setDate( now.getDate() - 7 );
				break;

		}

		return { startDate, endDate };
	}

}

export default ChartTimeValues;