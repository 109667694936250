/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from './ServiceRequestCommand';
import SphereAdminSession from '../model/SphereAdminSession';
import SphereAdminServices from '../model/SphereAdminServices';

/**
 * Searches for Items in the database
 */
class SearchItemsRequest extends ServiceRequestCommand {
	
	/**
	 * Stores the result from the response object
	 */
	#result; 

	//

	/**
	 * Constructs the Request
	 * @param service The service endpoint to use for searching
	 * @param phrase The phrase to search against. This can be null
	 * @param pageStart The start index
	 * @param pageSize How many records to retrieve
	 */
	constructor( service, phrase, pageStart, pageSize ){
		super();
		this.service = service;
		this.phrase = phrase;
		this.pageStart = pageStart;
		this.pageSize = pageSize;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return this.service;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id, phrase: this.phrase, pageStart: this.pageStart, pageSize: this.pageSize };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		if( response.success ) {
			this.#result = response.result;
		}
	}
 
	//

	/**
	 * @return the result of the request
	 */
	getResult() {
		return this.#result;
	}
 
}

export default SearchItemsRequest;
