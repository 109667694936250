/*
 * (c) Verra Technology Corporation
 */

import PublishableObject from './PublishableObject.mjs';

/**
 * Represents a Site
 * TODO: add attributes and other properties
 * TODO: add model enum
 */
class Site extends PublishableObject {

	static TYPE_DEVELOPMENT = 1;
	static TYPE_PRODUCTION = 2;

	/**
	 * Defines the structure of the Site class. Used with ObjectUtil.CreateFromDefinition
	 */
	static definition = {
		type: Site,
		properties: {
			name: {
				type: String
			},
			url: {
				type: String
			}
		}
	}

	// accountId : Integer
	// id : String
	// name : String
	// url : String
	// status : Integer
	// type : Integer 

}

//

export default Site;