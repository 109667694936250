/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import SphereAdminSession from '../model/SphereAdminSession';
import InputField from '../components/controls/InputField';
import SignInUserRequest from '../requests/account/SignInUserRequest';
import AdminStates from '../model/AdminStates';
import RegisterUserRequest from '../requests/account/RegisterUserRequest';
import SetStateCommand from '../commands/SetStateCommand';
import ForgotPasswordRequest from '../requests/account/ForgotPasswordRequest';
import ResetPasswordRequest from '../requests/account/ResetPasswordRequest';
import Loader from '../components/controls/Loader';

//

/**
 * Application class for the Sphere Administration suite
 */
class SignIn extends Component {
	
	/**
	 * Constructs the component
	 */
	constructor(){
		super(); 
		this.state = { 
			loading: false, 
			errorMessage: null, 
			resetRequestComplete: false,
			email: '',
			pwd: '' 
		};
		this.regCodeInput = React.createRef();
		this.emailInput = React.createRef();
		this.pwdInput = React.createRef();
		this.pwd2Input = React.createRef();
	}

	/**
	 * Renders the component
	 */
	render() {
		
		// TODO: replace alt text strings with strings config
		var currentState = SphereAdminSession.currentState;

		var jsx = '';
		if( this.state.loading && currentState !== AdminStates.AUTHENTICATION_FAILED && currentState !== AdminStates.REGISTRATION_FAILED ){
			jsx = <Loader/>
		} else {
			this.state.loading = false;
			if( currentState === AdminStates.NOT_AUTHENTICATED || currentState === AdminStates.AUTHENTICATION_FAILED ){
				jsx = this.#getSignInMarkup();
			} else if( currentState === AdminStates.REGISTERING || currentState === AdminStates.REGISTRATION_FAILED ){
				jsx = this.#getRegisterMarkup();
			} else if( currentState === AdminStates.RESET_PASSWORD ){
				var pathName = window.location.pathname;
				var hasToken = ( pathName.includes( '/reset-password/' ) && pathName.length > new String( '/reset-password/' ).length );
				if( hasToken ){
					jsx = this.#getResetPwdMarkup();
				} else if( !this.state.resetRequestComplete ) {
					jsx = this.#getForgotPwdMarkup();
				} else {
					jsx = this.#getPendingResetMarkup();
				}
			}
		}

		return 	<div className="grid sign-in-container">
					<div className="grid-cell logo">
						{/* <img src="/sphere.png" width="120" alt="sphere"/> */}
						<span>verra</span>
					</div>
					<div className="grid-cell default-100">
						{jsx}
					</div>
				</div>;
	}
	
	// Private

	/**
	 * @return the markup for rendering the sign in form
	 */
	#getSignInMarkup(){
		var currentState = SphereAdminSession.currentState;
		var signInError = ( currentState === AdminStates.AUTHENTICATION_FAILED && SphereAdminSession.signInError !== null ) ? <div className='error'>{SphereAdminSession.signInError}</div> : '';
		return <div className='sign-in'>
			<form onSubmit={this.#handleSignIn.bind( this )}>
				<InputField
					ref={this.emailInput}
					placeholder='Email'
					maxLength='256' 
					autoComplete='email'
					type='email'
					value={this.state.email}
					pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"/>
				<InputField
					ref={this.pwdInput}
					type="password"
					placeholder='Password'
					maxLength='256' 
					value={this.state.password}
					autoComplete='current-password'/>
				<button className={'primary-button'} style={{width: '100%'}} onClick={this.#handleSignIn.bind( this )}>Sign In</button>
			</form>
			{signInError}
			<button className='link-button' onClick={this.#handleForgotPasswordClick.bind( this )}>Forgot Password</button>
		</div>;
	}

	/**
	 * @return the markup for rendering the register form
	 */
	#getRegisterMarkup(){
		const currentState = SphereAdminSession.currentState;
		const params = new URLSearchParams( window.location.search );
		const code = params.keys().next().value;
		const registerError = ( currentState === AdminStates.REGISTRATION_FAILED && SphereAdminSession.registrationError !== null ) ? <div className='error'>{SphereAdminSession.registrationError}</div> : '';

		// console.info( 'code', code );

		return <div className='sign-in'>
			<form onSubmit={this.#handleRegister.bind( this )}>
				<p>Enter the email you used to subscribe</p>
				<InputField
					ref={this.emailInput}
					placeholder='Email'
					maxLength='256' 
					pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"/>
				<p>Create a new password</p>
				<InputField
					ref={this.pwdInput}
					type="password"
					placeholder='Password'/>
				<InputField
					ref={this.pwd2Input}
					type="password"
					placeholder='Repeat Password'/>

				{/* 
				<p>Create a name for your new account</p>	
				<InputField
					placeholder='Account Name'
					maxLength='128' />
				*/}

				<InputField
					type='hidden'
					ref={this.regCodeInput}
					value={code}/>

				<button className={'primary-button'} style={{width: '100%'}}>Create Account</button>
			</form>
			{registerError}
			{/* <button className='link-button' onClick={this.#handleBackToSignIn.bind( this )}>Back</button>*/}
		</div>;
	}

	/**
	 * @return the markup for rendering the register form
	 */
	#getForgotPwdMarkup(){
		var error = ( this.state.errorMessage != null ) ? <div className='error'>{this.state.errorMessage}</div> : '';
		return <div className='sign-in'>
			<form onSubmit={this.#handleResetPassword.bind( this )}>
				<InputField
					ref={this.emailInput}
					placeholder='Email'
					maxLength='256' 
					autoComplete='email'
					type='email'
					pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"/>
				<button className={'primary-button'} style={{width: '100%'}} onClick={this.#handleResetPassword.bind( this )}>Reset Password</button>
			</form>
			{error}
			<button className='link-button' onClick={this.#handleBackToSignIn.bind( this )}>Back</button>
		</div>;
	}

	/**
	 * @return the markup for rendering the register form
	 */
	#getPendingResetMarkup(){
		var error = ( this.state.errorMessage != null ) ? <div className='error'>{this.state.errorMessage}</div> : '';
		return <div className='sign-in'>
			Use the link in the sent email to reset your password 
			<button className='link-button' onClick={this.#handleBackToSignIn.bind( this )}>Back</button>
		</div>;
	}

	/**
	 * @return the markup for rendering the register form
	 */
	#getResetPwdMarkup(){
		var error = ( this.state.errorMessage != null ) ? <div className='error'>{this.state.errorMessage}</div> : '';
		return <div className='sign-in'>
			<form onSubmit={this.#handleSetNewPassword.bind( this )}>
				<InputField
					ref={this.emailInput}
					placeholder='Email'
					maxLength='256' 
					pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"/>
				<InputField
					ref={this.pwdInput}
					type="password"
					placeholder='New Password'/>
				<InputField
					ref={this.pwd2Input}
					type="password"
					placeholder='Repeat New Password'/>
				<button className={'primary-button'} style={{width: '100%'}}>Set New Password</button>
			</form>
			{error}
			<button className='link-button' onClick={this.#handleBackToSignIn.bind( this )}>Back</button>
		</div>;
	}

	// Handlers

	/**
	 * Handles clicks on the sign in button.
	 */
	#handleSignIn( e ){
		e.preventDefault();
		this.setState({ loading: true, errorMessage: null });
		this.state.email = this.emailInput.current.state.value;
		this.state.password = this.pwdInput.current.state.value;
		var signInUser = new SignInUserRequest( this.state.email, this.state.password );
		signInUser.execute();
	}

	/**
	 * Handles clicks on the button to register
	 */
	/*
	#handleRegisterClick( e ){
		e.preventDefault();
		this.setState({ errorMessage: null });
		var setState = new SetStateCommand( AdminStates.REGISTERING );
		setState.execute();
	}
	*/

	/**
	 * Handles clicks on the sign in button.
	 */
	#handleRegister( e ){
		e.preventDefault();

		this.setState({ loading: true, errorMessage: null });

		var code = this.regCodeInput.current.state.value;
		var email = this.emailInput.current.state.value;
		var password = this.pwdInput.current.state.value;
		var password2 = this.pwd2Input.current.state.value;
		
		if( password === password2 ){
			var registerUser = new RegisterUserRequest( code, email, password );
			registerUser.execute();
		} else {
			// TODO: move this state into the component
			SphereAdminSession.registrationError = 'Passwords must match';
			var setState = new SetStateCommand( AdminStates.REGISTRATION_FAILED );
			setState.execute();
		}
	}

	/**
	 * Handles clicks to go back to the sign in screen from the register screen
	 */
	#handleBackToSignIn( e ){
		e.preventDefault();
		this.setState({ errorMessage: null });
		var setState = new SetStateCommand( AdminStates.NOT_AUTHENTICATED );
		setState.execute();
	}

	/**
	 * Handles clicks on the button to register
	 */
	#handleForgotPasswordClick( e ){
		e.preventDefault();
		this.setState({ errorMessage: null });
		var setState = new SetStateCommand( AdminStates.RESET_PASSWORD );
		setState.execute();
	}

	/**
	 * Handles the click to reset the password
	 */
	#handleResetPassword( e ){
		e.preventDefault();
		this.setState({ loading: true, errorMessage: null });
		var email = this.emailInput.current.state.value;
		var reset = new ForgotPasswordRequest( email );
		reset.execute(( command ) => { this.#handleForgotPasswordComplete( command )});
	}

	/**
	 * Handles completion of the ForgotPasswordRequest
	 */
	#handleForgotPasswordComplete( command ){
		if( command.getSuccess() ){
			this.setState({ loading: false, errorMessage: null, resetRequestComplete: true });
		} else {
			this.setState({ loading: false, errorMessage: command.getResponse().error });
		}
	}

	/**
	 * Handles the click to reset the password
	 */
	#handleSetNewPassword( e ){
		e.preventDefault();
		
		this.setState({ errorMessage: null });

		var email = this.emailInput.current.state.value;
		var password = this.pwdInput.current.state.value;
		var password2 = this.pwd2Input.current.state.value;
		var token = window.location.pathname.substring( window.location.pathname.lastIndexOf( '/' ) + 1 );

		if( password === password2 ){
			var resetPassword = new ResetPasswordRequest( email, password, token );
			resetPassword.execute(( command ) => { this.#handleResetPasswordComplete( command )});
		} else {
			this.setState({ errorMessage: 'Passwords do not match' });
		}
	}

	/**
	 * Handles completion of the ResetPasswordRequest
	 */
	 #handleResetPasswordComplete( command ){
		if( command.getSuccess() ){
			this.setState({ loading: false, errorMessage: null, resetRequestComplete: false });
			var setState = new SetStateCommand( AdminStates.NOT_AUTHENTICATED );
			setState.execute();
		} else {
			this.setState({ loading: false, errorMessage: command.getResponse().error });
		}
	}

}

//

export default SignIn;