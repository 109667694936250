/*
 * (c) Verra Technology Corporation
 */

import SphereAdminServices from '../../model/SphereAdminServices';
import SphereAdminSession from '../../model/SphereAdminSession';
import RetrieveObjectCountsRequest from '../account/RetrieveObjectCountsRequest';
import ServiceRequestCommand from '../ServiceRequestCommand';

/**
 * Deletes Channels
 */
class DeleteAudiencesRequest extends ServiceRequestCommand {

	#audienceIds;
	#audiencesInUse;

	//

	/**
	 * Constructs the Request
	 * @param #audienceIds An array of Audience IDs to delete
	 */
	constructor( audienceIds ){
		super();
		this.#audienceIds = audienceIds;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.DELETE_AUDIENCES;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id, audienceIds: this.#audienceIds };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		//console.info( 'DeleteAudiencesRequest::handleComplete', response );
		if( response.success ) {
			this.#audiencesInUse = response.result;
			SphereAdminSession.audiences = SphereAdminSession.audiences.filter(( audience ) => {
				const isInDeleteList = this.#audienceIds.includes( audience.id );
				const isInUsedList = ( this.#audiencesInUse.find( optimizationAudience => optimizationAudience.audienceId === audience.id ) != null );
				return !isInDeleteList || isInUsedList;
			});

			// update the object counts
			const retrieveObjectCounts = new RetrieveObjectCountsRequest();
			retrieveObjectCounts.execute();
		}
		
	}
 
	//

	/**
	 * @return Any Content that could not be deleted because it's in use by a Channel
	 */
	getAudiencesInUse(){
		return this.#audiencesInUse;
	}

}

export default DeleteAudiencesRequest;
