import React from 'react';

var PageIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 48;
	const color = ( props.color != null ) ? props.color : '#ffffff';
	return (
		<svg x="0px" y="0px" viewBox="0 0 48 48" width={ size } height={ size }>
			<g className="nc-icon-wrapper" strokeLinecap="square" strokeLinejoin="miter" strokeWidth="3" fill={ color } stroke={ color }>
				<polyline data-cap="butt" fill="none" stroke={ color } strokeMiterlimit="10" points="30,2 30,14 42,14 " strokeLinecap="butt"/>
				<polygon fill="none" stroke={ color } strokeMiterlimit="10" points="30,2 6,2 6,46 42,46 42,14 "/>
				<line data-color="color-2" fill="none" strokeMiterlimit="10" x1="14" y1="36" x2="34" y2="36"/>
				<line data-color="color-2" fill="none" strokeMiterlimit="10" x1="14" y1="26" x2="34" y2="26"/> 
				<line data-color="color-2" fill="none" strokeMiterlimit="10" x1="14" y1="16" x2="22" y2="16"/>
			</g>
		</svg>
	);
};

export default PageIcon;