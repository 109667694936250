/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import SphereAdminSession from '../model/SphereAdminSession';

/**
 * Correctly positions a modal on the screen. This exists as a Command to allow Modal content 
 * to position the Modal after its state/size has changed.
 * 
 * TODO: refactor to CSS if possible
 * 
 */
class PositionModalCommand extends Command {

	/**
	 * Handles execution of the command
	 */
	handleExecute(){
		const modal = SphereAdminSession.modal;
		if( modal?.ref?.current != null ){
			const modalElement = modal.ref.current.getElement();
			const background = modalElement.querySelector( '.background' );
			const contentContainer = modalElement.querySelector( '.content-container' );
			const rect = contentContainer.getBoundingClientRect();
			
			const padding = 13; // matches the panel spacing
			const top = Math.max( 0, Math.round(( window.innerHeight - rect.height ) * 0.5 ));
			const backgroundHeight = Math.max( window.innerHeight, rect.height + padding * 2 ); // * 2 for top and bottom

			// console.info( 'resize', rect.height );

			background.style.height = backgroundHeight + 'px';
			contentContainer.style.top = top + 'px';
			contentContainer.style.visibility = 'visible';
		}
	}
	
}

export default PositionModalCommand;
