/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import SphereAdminConfig from '../model/SphereAdminConfig';
import SphereAdminSession from '../model/SphereAdminSession';

import Header from '../components/Header';
import PrimaryNavigation from '../components/PrimaryNavigation';

import EnvironmentUtil from '../../util/EnvironmentUtil';
import OpenModalCommand from '../commands/OpenModalCommand';
import ViewOnboardingWizardCommand from '../commands/ViewOnboardingWizardCommand';
import Alert from '../components/controls/Alert';
import StateComponentMap from '../model/StateComponentMap';

//

/**
 * Renders the Admin UI for the administration app
 */
class Admin extends Component {
	
	/**
	 * Constructs the Component
	 */
	constructor(){
		super();
		this.state = { commandExecuted: false };

		const hasOnboarded = SphereAdminSession.hasOnboarded;
		if( !hasOnboarded && window.location === window.parent.location ) {
			const onboard = new ViewOnboardingWizardCommand();
			onboard.execute();
		}

		if( EnvironmentUtil.isMobile() ) {
			const alert = <Alert content={ 'Verra is a powerful tool and working on mobile isn\'t going to provide a good experience. We highly recommend you use a desktop environment.' } showCancelBtn={ false }/>;
			const openModal = new OpenModalCommand( 'Looks like you\'re on mobile', alert, '95vw', true );
			openModal.execute();
		}
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const PanelComponent = this.#actionAdminState();
		return ( 
			<div className='verra-admin'>
				<Header/>
				<div style={{ display: 'flex' }}>
					<PrimaryNavigation/>
					<div style={{ flexGrow: 1, minWidth: 0 }}>
						<div className='admin-panel-container'>
							<PanelComponent/>
						</div>
					</div>
				</div> 
			</div> 
		);
	}

	// Private

	/**
	 * Actions the current state, either executing a Command or displaying a Pane
	 */
	#actionAdminState() {
		var PanelComponent;
		var currentState = SphereAdminSession.currentState;
		var statesMap = SphereAdminConfig.statesMap;
		var stateData = statesMap[ currentState ];
		PanelComponent = StateComponentMap[ stateData.component ];
		return PanelComponent;
	}

	/**
	 * Handles completion of state Commands
	 */
	#handleCommandComplete( command, stateData ){
		// TODO: how to handle failures?
		this.setState({ commandExecuted: true });
	}

}

export default Admin;
