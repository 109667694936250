/*
 * (c) Verra Technology Corporation
 */

/**
 * A Channel's object return type determines how the data used to populate the channel is returned. 
 * In all cases the ID and the index is always returned.
 */
 const ChannelObjectReturnTypes = {

	/**
	 * The full object record is returned as a result when populating a Channel
	 */
	FULL: 0,

	/**
	 * Specifies to only return the ID of the Content
	 */
	VALUE: 1,

	/**
	 * The ID and the position of the object within the collection of objects assigned to the Channel. This is used to 
	 * return the smallest possible result when populating a Channel. This is useful because the HTTP requests 
	 * to populate are not cached. The position can then be used to make a subsequent request to retrieve the 
	 * object that is cacheable.
	 */
	ID_AND_INDEX: 2

};

Object.freeze( ChannelObjectReturnTypes );
export default ChannelObjectReturnTypes;