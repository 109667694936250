/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import SphereAdminSession from '../model/SphereAdminSession';
import RetrieveChannelRequest from '../requests/channels/RetrieveChannelRequest';
import EventsOverTimeChart from '../components/charts/EventsOverTimeChart';
import OpenModalCommand from '../commands/OpenModalCommand';
import EditChannelCommand from '../commands/EditChannelCommand';
import SetStateCommand from '../commands/SetStateCommand';
import AdminStates from '../model/AdminStates';
import Alert from '../components/controls/Alert';
import ContentChart from '../components/charts/ContentChart';
import DropDownField from '../components/controls/DropDownField';
import RetrieveChannelDirectEventMetricsRequest from '../requests/channels/RetrieveChannelDirectEventMetricsRequest';
import RetrieveChannelIndirectEventMetricsRequest from '../requests/channels/RetreiveChannelIndirectEventMetricsRequest';
import Loader from '../components/controls/Loader';
import ChannelModelType from '../model/ChannelModelTypes';
import RetrieveIndirectEventCountWithValueRequest from '../requests/channels/RetrieveIndirectEventCountWithValueRequest';
import ChartTimeValues from '../model/ChartTimeValues';
import EventInteractionTypes from '../model/EventInteractionTypes';
import ChannelMetrics from '../model/ChannelMetrics';
import PopulateChannelEventMetricsModelCommand from '../commands/PopulateChannelEventMetricsModelCommand';
import VariantWinnerChart from '../components/charts/VariantWinnerChart';

//

/**
 * The currently selected time period
 */
let selectedTimePeriod = ChartTimeValues.availableRanges[ 4 ];

//

/**
 * The ContentPanel contains the administration UI for a Channel
 */
class ViewChannelPanel extends Component {

	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();

		// split the account events by engagement and conversion
		const eventTypes = SphereAdminSession.eventTypes;
		const engagementEvents = eventTypes.filter( type => {
			return ( type.interactionType === EventInteractionTypes.ENGAGEMENT );
		});
		const conversionEvents = eventTypes.filter( type => {
			return ( type.interactionType === EventInteractionTypes.SUCCESS );
		});

	
		this.state = {
			metrics: null,
			engagementEvents: engagementEvents,
			conversionEvents: conversionEvents,
			selectedEngagementEvent: null,
			selectedConversionEvent: null,
			loadingConversionMetrics: true,
			hasAdditionalOrderData: false
		};

		this.metricsContainerRef = React.createRef();
		this.impressionsContainerRef = React.createRef();
		this.conversionsContainerRef = React.createRef();
		this.contentMetricsContainerRef = React.createRef();

		this.engagmentsOverTimeChart = React.createRef();
		this.conversionsOverTimeChart = React.createRef();

		// this.contentSelectionChart = React.createRef();

		SphereAdminSession.channel = null;

		const channelId = SphereAdminSession.stateParameters.channelId;
		// SphereAdminSession.loading = true;
		const retrieveChannel = new RetrieveChannelRequest( channelId );
		retrieveChannel.execute(( command ) => { this.#handleChannelRetrieved( command ); });

		//setInterval( this.#updatePerformanceMetrics.bind( this ), 1000 );
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render(){
		const jsx = ( SphereAdminSession.channel != null && this.state.metrics != null ) ? this.#getChannelViewMarkup() : '';
		return jsx;
	}

	//
	
	/**
	 * Handles the updating of the component
	 */
	componentDidUpdate(){
		this.#resizePanels();
	}
	
	// Markup 

	/**
	 * @return The markup for viewing the Channel
	 */
	#getChannelViewMarkup(){
		const channel = SphereAdminSession.channel;
		const analyticsMarkup = this.#getAnalyticsMarkup();

		return <div className='content-panel view-channel'>
				<div className='grid'>
					<div className='grid-cell default-60'>
						<h2>{channel.name}</h2>
						<div className='breadcrumb'>
							<a href='/channels/' className='breadcrumb'>Channels</a> / {channel.name}
						</div>
					</div>
					<div className='grid-cell default-40 align-right header-actions'>
						<button className={ 'button control-pad-left' } style={{ width: '90px' }} onClick={ this.#handleEdit.bind( this ) }>Edit</button>
					</div>
				</div>
				{analyticsMarkup}
			</div>;
	}

	/**
	 * @return the markup for displaying Channel analytics
	 */
	#getAnalyticsMarkup(){
		return 	<div>
					<div ref={this.metricsContainerRef} className='grid performance-metrics'>
						<div className={'grid-cell default-100 start-row align-right'}>
							<DropDownField
								className='padless'
								itemsWidth='150px' 
								hideBackground={true} 
								labelAlignRight={true} 
								alignRight={true}
								style={{ display: 'inline-block' }}
								items={ChartTimeValues.availableRanges}
								labelField='label'
								selectedItem={selectedTimePeriod}
								changeHandler={this.#handleDateRangeChange.bind( this )}/>
						</div>
						{this.#getImpressionsPanelMarkup()}
						{this.#getEngagementPanelMarkup()}
						{this.#getConversionsPanelMarkup()}
						{this.#getVariantWinnerMarkup()}
						{this.#getContentImpressionsPanelMarkup()}
						{this.#getContentConversionsPanelMarkup()}
						{this.#getChartsMarkup()}
						{this.#getReportMarkup()}
						{/* 
						<div className='grid-cell default-100'>
							<div ref={this.contentMetricsContainerRef} className='panel-cell'>
								<ContentSelectionChart ref={this.contentSelectionChart} channelId={channelId}/>
							</div>
						</div>
						*/}
					</div>
				</div>;	
	}

	/**
	 * @return the markup for the impressions metrics
	 */
	#getImpressionsPanelMarkup(){
		const views = this.#getEngagementEventMetric( 'view' );
		const loads = this.#getEngagementEventMetric( 'render' );
		const impressionsPanel = <div className={'grid-cell default-33 start-row'}>
			<div className='panel-cell'>
				<h3>Impressions</h3>
				<div className='info-block' style={{ color: 'rgb(217, 195, 255)' }}>
					<span className='info-stat'>{this.#roundMetric( views )}</span>
					<span className='info-stat-label'> Views</span>
					<p>The Channel has been viewed {views} times</p>
				</div>
				<div className='info-block' style={{ color: 'rgb(172, 123, 255)' }}>
					<span className='info-detail'>{this.#roundMetric( loads )}</span>
					<span className='info-stat-label'> Loads</span>
					<p>The Channel has been loaded {loads} times</p>
				</div>
			</div>
		</div>;
		return impressionsPanel;
	}

	/**
	 * @return the markup for the panel displaying engagement metrics
	 */
	#getEngagementPanelMarkup(){
		const selectedEngagementEvent = this.state.selectedEngagementEvent;
		const engagementEvents = this.state.engagementEvents;
		const engagements = this.#getEngagementEventMetric( selectedEngagementEvent.id );
		const views = this.#getEngagementEventMetric( 'view' );
		const engagementConversion = ( views > 0 ) ? this.#roundMetric( engagements / views * 100 ) : 0;
		
		const engagementPanel = <div>
			<div className={'grid-cell default-33 pad-cell-left'}>
				<div className='panel-cell'>
					<div className='grid'>
						<div className='grid-cell default-30'>
							<h3>Engagement</h3>
						</div>
						{ engagementEvents.length > 1 && 
							<div className='grid-cell default-70 align-right'>
								<DropDownField
									className='padless'
									itemsWidth='150px' 
									hideBackground={true} 
									labelAlignRight={true} 
									alignRight={true}
									style={{ display: 'inline-block' }}
									items={engagementEvents}
									labelField='name'
									selectedItem={selectedEngagementEvent} 
									changeHandler={ this.#handleEngagementEventSelected.bind( this )}/>
							</div>
						}
					</div>
					<div className='info-block' style={{ color: '#F6C7AE' }}>
						<span className='info-stat'>{this.#roundMetric( engagements )}</span>
						<span className='info-stat-label'>{selectedEngagementEvent.name}s</span>
						<p>The Channel has had {engagements} {selectedEngagementEvent.name}s</p>
					</div>
					<div className='info-block' style={{ color: 'rgb(255, 143, 87)' }}>
						<span className='info-detail'>{engagementConversion}%</span>
						<span className='info-stat-label'></span>
						<p>Views to {selectedEngagementEvent.name} conversion rate</p>
					</div>
				</div>
			</div>
		</div>;
		return engagementPanel;
	}

	/**
	 * @return the markup for the conversions metrics
	 */
	#getConversionsPanelMarkup(){
		let conversionPanel;
		if( this.state.loadingConversionMetrics ){
			conversionPanel = <div className={'grid-cell default-33 pad-cell-left'}>
				<div className='panel-cell'>
					<Loader/>
				</div>
			</div>;
		} else {
			const selectedEngagementEvent = this.state.selectedEngagementEvent;
			const selectedConversionEvent = this.state.selectedConversionEvent;
			const conversionEvents = this.state.conversionEvents;
			const engagements = this.#getEngagementEventMetric( selectedEngagementEvent.id );
			const conversions = this.#getConversionEventMetric( selectedConversionEvent.id );
			const conversionRate = ( engagements > 0 ) ? this.#roundMetric( conversions / engagements * 100 ) : 0;
			conversionPanel = <div>
				<div className={'grid-cell default-33 pad-cell-left'}>
					<div className='panel-cell'>
						<div className='grid'>
							<div className='grid-cell default-30'>
								<h3>Conversions</h3>
							</div>
							{ conversionEvents.length > 1 && 
								<div className='grid-cell default-70 align-right'>
									<DropDownField
										className='padless'
										itemsWidth='150px' 
										hideBackground={true} 
										labelAlignRight={true} 
										alignRight={true}
										style={{ display: 'inline-block' }}
										items={conversionEvents}
										labelField='name'
										selectedItem={selectedConversionEvent}
										changeHandler={ this.#handleConversionEventSelected.bind( this )}/>
								</div>
							}
						</div>
						<div className='info-block' style={{ color: 'rgb(172, 245, 225)' }}>
							<span className='info-stat'>{conversionRate}%</span>
							<span className='info-stat-label'></span>
							<p>{selectedEngagementEvent.name} to {selectedConversionEvent.name} conversion rate</p>
						</div>
						<div className='info-block' style={{ color: 'rgb(63, 187, 146)' }}>
							<span className='info-detail'>{this.#roundMetric( conversions )}</span>
							<span className='info-stat-label'>{selectedConversionEvent.name}</span>
							<p>{selectedConversionEvent.name} events have occurred</p>
						</div>
					</div>
				</div>
			</div>;
		}

		return conversionPanel;
	}

	/**
	 * @return the markup for the charts
	 */
	#getVariantWinnerMarkup(){
		const metrics = ( this.state.loadingConversionMetrics ) ? null : this.state.metrics;
		return <div>
			<div className='grid-cell default-100 start-row'>
				<div ref={this.impressionsContainerRef} className='panel-cell'>
					<VariantWinnerChart
						metrics={metrics} 
						selectedEngagementEvent={this.state.selectedEngagementEvent}
						selectedConversionEvent={this.state.selectedConversionEvent}/>
				</div>
			</div>
		</div>;
	}

	/**
	 * @return the markup for the content impression metrics
	 */
	#getContentImpressionsPanelMarkup(){
		const channel = SphereAdminSession.channel;
		const selectedEngagementEvent = this.state.selectedEngagementEvent;
		const contentImpressionsPanel = <div className='grid-cell default-50 start-row'>
			<div className='panel-cell'>
				<ContentChart 
					label={ selectedEngagementEvent.name }
					startColor={330}
					channel={ channel } 
					metrics={ this.state.metrics } 
					event={ selectedEngagementEvent }/>
			</div>
		</div>;
		return contentImpressionsPanel;
	}

	/**
	 * @return the markup for the content conversions metrics
	 */
	#getContentConversionsPanelMarkup(){
		let contentConversionsPanel;
		if( this.state.loadingConversionMetrics ){
			contentConversionsPanel = <div className='grid-cell default-50 pad-cell-left'>
				<div className='panel-cell'>
					<Loader/>
				</div>
			</div>;
		} else {
			const channel = SphereAdminSession.channel;
			const selectedConversionEvent = this.state.selectedConversionEvent;
			const selectedEngagementEvent = this.state.selectedEngagementEvent;

			contentConversionsPanel = <div className='grid-cell default-50 pad-cell-left'>
				<div className='panel-cell'>
					<ContentChart 
						label={ selectedConversionEvent.name }
						startColor={190}
						channel={ channel } 
						metrics={ this.state.metrics } 
						event={ selectedConversionEvent }
						engagementEvent={ selectedEngagementEvent }/>
				</div>
			</div>;
		}
		return contentConversionsPanel;
	}

	/**
	 * @return the markup for the charts
	 */
	#getChartsMarkup(){
		const channel = SphereAdminSession.channel;
		return <div>
			<div className='grid-cell default-100 start-row'>
				<div ref={this.impressionsContainerRef} className='panel-cell'>
					<EventsOverTimeChart 
						ref={this.engagmentsOverTimeChart} 
						eventsType={EventsOverTimeChart.EVENT_TYPES_ENGAGEMENT}
						eventsToRetrieve={this.state.engagementEvents} 
						selectedEventType={this.state.selectedEngagementEvent}
						channel={channel} 
						selectedTimePeriod={selectedTimePeriod}/>
				</div>
			</div>
			<div className='grid-cell default-100 start-row'>
				<div ref={this.impressionsContainerRef} className='panel-cell'>
					<EventsOverTimeChart 
						ref={this.conversionsOverTimeChart} 
						eventsType={EventsOverTimeChart.EVENT_TYPES_CONVERSION}
						eventsToRetrieve={this.state.conversionEvents} 
						selectedEventType={this.state.selectedConversionEvent}
						selectedEngagementEventType={this.state.selectedEngagementEvent}
						channel={channel} 
						selectedTimePeriod={selectedTimePeriod}/>
				</div>
			</div>
		</div>;
	}

	/**
	 * @return the markup for the charts
	 * TODO: refactor: extract class
	 */
	#getReportMarkup(){
		/*
		const eventMetrics = this.state.eventMetrics;

		// console.info( 'getReportMarkup' );
		// console.info( eventMetrics );

		const selectedEngagementEvent = this.state.selectedEngagementEvent;
		const selectedConversionEvent = this.state.selectedConversionEvent;

		const headerData = [];
		const headers = [];
		const data = [];

		let i = 0;

		SphereAdminSession.channel.content.forEach( content => {
			if( i === 0 ){
				headerData.push({ type: 'content', data: content });
				headers.push( <th>Content</th> );

				SphereAdminSession.eventTypes.forEach( event => {
					let className = ( event === selectedEngagementEvent ) ?  'highlight' : '';
					headerData.push({ type: 'event', data: event });
					headers.push( <th className={ className }>{event.name}</th> );
				});

				const additionalHeaders = [
					{label: `CVR (${selectedConversionEvent.name} / ${selectedEngagementEvent.name})`, id: 'cvr' },	
					{label: 'Revenue', id: 'rev' },	
					{label: 'RPV', id: 'rpv' }, 
					{label: 'AOV', id: 'aov' },
					{label: 'CVR Delta', id: 'cvrd' },
					{label: 'RPV Delta', id: 'rpvd' },
					{label: 'AOV Delta', id: 'aovd' }
				];
				additionalHeaders.forEach( header => {
					headerData.push({ type: 'calc', data: header });
					headers.push( <th>{ header.label }</th> );
				});
			}
			
			let contentData = [];
			headerData.forEach( headerDataObj => {
				if( headerDataObj.type === 'content' ){
					contentData.push( <td>{ content.name }</td> );
				} else if( headerDataObj.type === 'event' ){
					let eventCount = ( 
						eventMetrics[ headerDataObj.data.id ] != null && 
						eventMetrics[ headerDataObj.data.id ][ 1 ] != null && 
						eventMetrics[ headerDataObj.data.id ][ 1 ].content[ content.id ] != null 
					) ? eventMetrics[ headerDataObj.data.id ][ 1 ].content[ content.id ].count : 0;
					// console.info( content.id, headerDataObj.data.id, eventMetrics[ headerDataObj.data.id ] );
					contentData.push( <td>{ eventCount }</td> );
				} else if( headerDataObj.type === 'calc' ){
					contentData.push( <td>{ this.#getDetailedMetric( headerDataObj.data, content ) }</td> );
				}
			});
			data.push( <tr>{ contentData }</tr> );
			i++;
		});

		return <div>
			<div className='grid-cell default-100 start-row'>
				<div className='panel-cell'>
					<h3>Details</h3>
						<table className='channel-analytics-detail'>
							<tr>
								{headers}
							</tr>
							{data}
						</table>
					</div>
				</div>
		</div>;
		*/
		return '';
	}

	/**
	 * @return The value to display for a detailed metrics cell
	 */
	#getDetailedMetric( headerObj, content ){
		// console.info( 'getDetailedMetric', headerObj );

		const eventMetrics = this.state.eventMetrics;
		const selectedEngagementEvent = this.state.selectedEngagementEvent;
		const selectedConversionEvent = this.state.selectedConversionEvent;
		const engagementMetrics = eventMetrics?.[ selectedEngagementEvent.id ]?.[ 1 ]?.content?.[ content.id ];
		const placeOrderMetrics = eventMetrics?.[ selectedConversionEvent ]?.[ 1 ]?.content?.[ content.id ];

		let value = '';
		
		if( headerObj.id === 'cvr' ){
			const cvr = ( engagementMetrics != null && placeOrderMetrics != null ) ? placeOrderMetrics.count / engagementMetrics.count * 100 : 0;
			value = Intl.NumberFormat( 'en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 } ).format( cvr ) + '%';
		} else if ( headerObj.id === 'rpv' ){
			// const rev = ( engagementMetrics)
		}

		return value;
	}

	//

	/**
	 * Makes a request to retrieve performance metrics
	 */
	#updatePerformanceMetrics(){ 
		// SphereAdminSession.loading = true;
		this.#updateEngagementeMetrics();
	}

	/**
	 * Makes a request to retrieve performance metrics
	 */
	#updateEngagementeMetrics(){ 
		const channel = SphereAdminSession.channel;
		const { startDate, endDate } = ChartTimeValues.getDateRange( selectedTimePeriod.value );
		const startTime = startDate.getTime();
		const endTime = endDate.getTime();

		const retrieveEventCounts = new RetrieveChannelDirectEventMetricsRequest( 
			SphereAdminSession.selectedAccount.id,
			channel.id, 
			startTime,
			endTime
		);
		retrieveEventCounts.execute( command => this.#handleEventCountsComplete( command ));
	}

	/**
	 * Makes a request to retrieve performance metrics
	 */
	#updateConversionMetrics(){ 
		this.setState({ loadingConversionMetrics: true, hasAdditionalOrderData: false});

		const channel = SphereAdminSession.channel;
		const { startDate, endDate } = ChartTimeValues.getDateRange( selectedTimePeriod.value );
		const startTime = startDate.getTime();
		const endTime = endDate.getTime();

		const indirectEvents = [];
		this.state.conversionEvents.forEach( event => {
			// click is a unique case, we filter it out of the success events because it is not an indirect event
			// click event data is returned with the engagement metrics data
			//if( event.id !== 'click' ) 
			indirectEvents.push( event.id );
		});

		const retrieveEventCounts = new RetrieveChannelIndirectEventMetricsRequest( 
			SphereAdminSession.selectedAccount.id,
			channel.id, 
			this.state.selectedEngagementEvent.id,
			indirectEvents,
			startTime,
			endTime
		);
		retrieveEventCounts.execute( this.#handleIndirectEventCountsComplete.bind( this ));
	}

	/**
	 * Makes a request top retrieve additional, order specific, event data
	 */
	#updatePlaceOrderMetrics(){
		this.setState({ loadingConversionMetrics: true });

		const { startDate, endDate } = ChartTimeValues.getDateRange( selectedTimePeriod.value );
		const startTime = startDate.getTime();
		const endTime = endDate.getTime();

		const retrieveMetrics = new RetrieveIndirectEventCountWithValueRequest( 
			SphereAdminSession.selectedAccount.id,
			SphereAdminSession.channel.id, 
			this.state.selectedEngagementEvent.id,
			'place-order',
			'order-total',
			startTime,
			endTime
		);
		retrieveMetrics.execute( this.#handleRetrieveOrderMetricsComplete.bind( this ));
	}

	/**
	 * @return The metric for the specified event or zero if none exists
	 */
	#getEngagementEventMetric( eventId ){
		const eventMetric = this.state.metrics.getEngagementEventMetric( eventId );
		return eventMetric.total;
	}

	/**
	 * @return The metric for the specified event or zero if none exists
	 */
	#getConversionEventMetric( eventId ){
		const eventMetric = this.state.metrics.getConversionEventMetric( this.state.selectedEngagementEvent.id, eventId );
		return eventMetric.total;
	}

	/**
	 * @return A metric that has been rounded to the nearest thousandth, millionth, etc.
	 */
	#roundMetric( value ){
		return Intl.NumberFormat( 'en-US', { notation: "compact", maximumFractionDigits: 1 } ).format( value );
	}

	//

	/**
	 * Resizes the editing panels keeping the UI looking hot
	 */
	#resizePanels(){
		/*
		if( this.metricsContainerRef != null ){
			var resizeMetricsPanels = new MatchPanelsHeightCommand( 
				[ this.impressionsContainerRef.current, this.conversionsContainerRef.current ] //, this.contentMetricsContainerRef.current ]
			);
			resizeMetricsPanels.execute();
		}
		*/
	}

	// Handlers

	/**
	 * Handles the response from the request to retrieve the Channel
	 */
	#handleChannelRetrieved( command ) {
		// console.info( 'handleChannelRetrieved', SphereAdminSession.channel );
		if( SphereAdminSession.channel != null ){
			const channel = SphereAdminSession.channel;

			const metrics = new ChannelMetrics( channel, this.state.engagementEvents, this.state.conversionEvents );
			
			let selectedEngagementEvent;
			let selectedConversionEvent;
			if( channel.model === ChannelModelType.PREDICTIVE ){
				selectedEngagementEvent = this.state.engagementEvents.find( type => type.id === channel.engagementEventTypeId );
				selectedConversionEvent = this.state.conversionEvents.find( type => type.id === channel.successEventTypeId );
				if( selectedEngagementEvent == null ) selectedEngagementEvent = this.state.engagementEvents[ 0 ];
				if( selectedConversionEvent == null ) selectedConversionEvent = this.state.conversionEvents[ 0 ];
			} else if( channel.model === ChannelModelType.AB ) {
				selectedEngagementEvent = this.state.engagementEvents[ 0 ];
				selectedConversionEvent = this.state.conversionEvents[ 0 ];
			}

			this.setState({ invalidated: true, selectedEngagementEvent, selectedConversionEvent, metrics });
			this.#updatePerformanceMetrics();
		} else {
			SphereAdminSession.loading = false;
			
			const setState = new SetStateCommand( AdminStates.ADMIN_CHANNELS_BROWSE );
			setState.execute();

			const alert = <Alert content='Channel does not exist' showCancelBtn={false}/>;
			const openModal = new OpenModalCommand( '', alert, '500px', true );
			openModal.execute();
		}
	};

	/**
	 * Handles completion of the RetrieveChannelEventCountsRequest
	 */
	#handleEventCountsComplete( command ){
		// console.info( 'handleEventCountsComplete', this.state.metrics );

		// first, rebuild the engagement events
		if( this.state.metrics != null ) this.state.metrics.resetMetrics();

		// then, populate with data
		const config = { dataType: PopulateChannelEventMetricsModelCommand.DATA_TYPE_ENGAGEMENT, fields: { count: 'count' }};
		const populateCommand = new PopulateChannelEventMetricsModelCommand( this.state.metrics, command.getData(), config );
		populateCommand.execute();

		SphereAdminSession.loading = false;
		this.setState({ metrics: this.state.metrics }); // force a render

		this.#updateConversionMetrics();
	}

	/**
	 * Handles completion of the RetrieveChannelEventCountsRequest
	 */
	#handleIndirectEventCountsComplete( command ){
		const config = { 
			dataType: PopulateChannelEventMetricsModelCommand.DATA_TYPE_CONVERSION, 
			engagementEvent: this.state.selectedEngagementEvent.id,
			fields: { count: 'count' }
		};
		const populateCommand = new PopulateChannelEventMetricsModelCommand( this.state.metrics, command.getData(), config );
		populateCommand.execute();

		console.info( 'handleIndirectEventCountsComplete', this.state.metrics );

		if( this.state.selectedConversionEvent.id === 'place-order' && !this.state.hasAdditionalOrderData ){
			this.#updatePlaceOrderMetrics();
		}

		// this.setState({ eventMetrics: this.state.eventMetrics, loadingConversionMetrics: false });
		this.setState({ loadingConversionMetrics: false });
	}

	/**
	 * Handles completion of the RetrieveChannelEventCountsRequest
	 */
	#handleRetrieveOrderMetricsComplete( command ){

		// we first have to reset the metrics for place-order so we don't double count events
		this.state.metrics.resetConversionMetricsForEvent( this.state.selectedEngagementEvent.id, 'place-order' );

		const config = { 
			dataType: PopulateChannelEventMetricsModelCommand.DATA_TYPE_CONVERSION, 
			engagementEvent: this.state.selectedEngagementEvent.id,
			fields: { count: 'count', total: 'revenue', average: 'aov' }
		};
		const populateCommand = new PopulateChannelEventMetricsModelCommand( this.state.metrics, command.getData(), config );
		populateCommand.execute();

		// console.info( 'handleRetrieveOrderMetricsComplete', this.state.metrics );
		// this.state.metrics.buildReport();
		
		this.setState({ loadingConversionMetrics: false, hasAdditionalOrderData: true });
	}

	//

	/**
	 * Handles clicks on items in the Channel list
	 */
	#handleEdit() {
		const editChannel = new EditChannelCommand( SphereAdminSession.channel.id );
		editChannel.execute();
	};

	/**
	 * Handles changes to the date range selection
	 */
	#handleDateRangeChange( selectedItem ) {
		selectedTimePeriod = selectedItem;
		this.#updatePerformanceMetrics();
		this.engagmentsOverTimeChart.current.updateDateRange( selectedTimePeriod );
		this.conversionsOverTimeChart.current.updateDateRange( selectedTimePeriod );
	}
	
	/**
	 * Handles a change to the selected engagement event
	 */
	#handleEngagementEventSelected( event ){
		this.state.selectedEngagementEvent = event;
		this.#updateConversionMetrics();
	}

	/**
	 * Handles a change to the selected engagement event
	 */
	#handleConversionEventSelected( event ){
		if( event.id === 'place-order' && !this.state.hasAdditionalOrderData ){
			this.#updatePlaceOrderMetrics();
		}
		this.setState({ selectedConversionEvent: event });
	}

	/**
	 * Handles a click on the preview button
	 */
	/*
	#handlePreview() {

		// TODO: channel preview
		console.info( 'PREVIEW' );

		var previewModal = <PreviewChannelModal channel={SphereAdminSession.channel}/>;
		var openModal = new OpenModalCommand( null, previewModal, '90%', false );
		openModal.execute();
	
	};
	*/

}

//

export default ViewChannelPanel;
