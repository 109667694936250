/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminServices from '../../model/SphereAdminServices.js';

/**
 * Retrieves indirect event counts for a Channel along with the sum and average of a specified
 * AnalyticsEventParameter value. This is used to retrieve data for things like order and average 
 * order value. Important: the AnalyticsEventParameter value in the DB is expected to be a number
 * for the sum and avg functions to work properly.
 */
class RetrieveIndirectEventCountWithValueRequest extends ServiceRequestCommand {

	/**
	 * The data returned by the service
	 */
	#data;

	//

	/**
	 * Constructs the Request
	 */
	 constructor( accountId, channelId, engagementEvent, indirectEvent, parameterName, startTime, endTime ){
		super();
		this.accountId = accountId;
		this.channelId = channelId;
		this.engagementEvent = engagementEvent;
		this.indirectEvent = indirectEvent;
		this.parameterName = parameterName;
		this.startTime = startTime;
		this.endTime = endTime;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getService() {
		return SphereAdminServices.RETRIEVE_CHANNEL_INDIRECT_EVENT_METRICS_WITH_VALUE;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { 
			accountId: this.accountId, 
			channelId: this.channelId, 
			engagementEvent: this.engagementEvent,
			indirectEvent: this.indirectEvent, 
			parameterName: this.parameterName, 
			startTime: this.startTime, 
			endTime: this.endTime
		};
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		// console.info( 'RetrieveIndirectEventCountWithValueRequest::handleComplete', response );
		if( response.success ) this.#data = response.result;
	}
 
	//

	/**
	 * @return The event counts for the Channel
	 */
	getData(){
		return this.#data;
	}
 
}

//

export default RetrieveIndirectEventCountWithValueRequest;
