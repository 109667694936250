/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';
import CloseModalCommand from '../../commands/CloseModalCommand';
import OpenModalCommand from '../../commands/OpenModalCommand';
import SphereAdminServices from '../../model/SphereAdminServices';
import SphereAdminSession from '../../model/SphereAdminSession';
import ItemListPanel from '../controls/ItemListPanel';
import CreateAudienceModal from './CreateAudienceModal';

//

/**
 * Modal used to select an Experience
 */
class SelectExperienceModal extends Component {

	/**
	'* Constructs the Component
	*/
	constructor( props ) {
		super( props );
		this.state = { itemsSelected: false };
		this.itemsList = React.createRef();
	}
	
	/**
	* Renders the component
	* @see react docs
	*/
	render() {
		const hasExperiences = ( SphereAdminSession.experiences != null && SphereAdminSession.experiences.length > 0 );
		const selectDisabled = ( hasExperiences && this.state.itemsSelected );
		const selectDisabledClass = ( selectDisabled ) ? '' : 'disabled';
		const multiselect = ( this.props.multiselect != null ) ? this.props.multiselect : true;

		const noItemsContent = <div>No Experiences Found. <button className='link-button' onClick={ this.#handleCreateExperience.bind( this )}>Create Experience</button></div>;
		const headers = [ 
			{ label: 'Name', field: 'name', width: '95' }, 
		];

		return (
			<div className='dialog'>
				<ItemListPanel 
					ref={ this.itemsList }
					headers={ headers }
					showControls={ false } 
					checkOnRowClick={ true }
					multiselect={ multiselect }
					statusIndicator={ false }
					retrieveOnLoad={ true } 
					noItemsContent={ noItemsContent }
					maxBodyHeight={ 500 }
					listEndPoint={ SphereAdminServices.RETRIEVE_EXPERIENCES }
					searchEndPoint={ SphereAdminServices.SEARCH_EXPERIENCES }
					deleteEndPoint={ SphereAdminServices.DELETE_EXPERIENCES }
					retrievedHandler={ this.#handleItemsRetrieved.bind( this )} 
					checkBoxChangeHandler={ this.#handleCheckBoxChecked.bind( this )}/>
				<div className='grid-cell default-100' style={{ padding: '20px 0 0 0', textAlign: 'center' }}>
					<button className={'primary-button ' + selectDisabledClass } disabled={ !selectDisabled } style={{ width: '90px' }} onClick={ this.#handleSelect.bind( this )}>Select</button>
					<button className='button control-pad-left' style={{ width: '90px' }} onClick={ this.#handleCancel.bind( this )}>Cancel</button>
				</div>
			</div>
		);
	}

	//

	/**
	 * Handles the items retrieved event from the ItemListPanel
	 */
	#handleItemsRetrieved( itemsList ) {
		SphereAdminSession.experiences = itemsList.items;
		this.setState({});
	}

	/**
	 * Handles the onRetrieved event from the AudiencesList
	 */
	#handleCheckBoxChecked( selected ) {
		this.setState({ itemsSelected: ( selected.length > 0 )});
	}

	/**
	 * Handles the click to create an Audience
	 */
	#handleCreateExperience( e ) {
		const closeModal = new CloseModalCommand();
		closeModal.execute();

		const createAudienceModal = <CreateAudienceModal/>;
		const openModal = new OpenModalCommand( 'Create Audience', createAudienceModal, '60%', false );
		openModal.execute();
	}
	
	/**
	 * Closes the Predictive Attribute editor modal
	 */
	#handleSelect( e ) {
		const closeModal = new CloseModalCommand();
		closeModal.execute();
		this.props.selectHandler( this.itemsList.current.getChecked() );
	}

	/**
	 * Closes the Modal
	 */
	#handleCancel( e ) {
		const closeModal = new CloseModalCommand();
		closeModal.execute();
	}

}

export default SelectExperienceModal;