/*
 * (c) Verra Technology Corporation
 */

/**
 * Represents metrics for a specific Experience and event.
 */
class OptimizationExperienceAnalytics {

	// Private Members

	/**
	 * The Experience in which the metrics are for
	 */
	#experience;

	/**
	 * The event type object in which the metrics apply
	 */
	#eventType;

	// All Metrics 

	/**
	 * The number of times this event has occurred
	 */
	count = 0;

	/**
	 * The number of times this event has, counted once per session
	 */
	sessions = 0;

	// Conversion Metrics 

	/**
	 * The conversion rate for this event
	 */
	cvr = 0;

	/**
	 * The jstat beta
	 */
	beta;

	/**
	 * The probability that this content will be successful / win
	 */
	successProbability = 0;

	// Place Order Specific Metrics

	/**
	 * Total revenue for the order
	 */
	revenue = 0;

	/**
	 * The average order value associated with the event.
	 */
	aov = 0;

	/**
	 * Revenue per engagement
	 */
	rpe = 0;

	/**
	 * Revenue potential calculated using cvr from the experience
	 */
	rpcvr = 0;

	/**
	 * Revenue potential delta calculated using cvr
	 */
	rpcvrd = 0;

	/**
	 * Revenue potential calculated only using aov
	 */
	rpaov = 0;

	/**
	 * Revenue potential delta calculated only using aov
	 */
	rpaovd = 0;

	/**
	 * Revenue potential calculated using cvr and aov from the experience
	 */
	rpcvraov = 0;

	/**
	 * Revenue potential delta calculated using cvr and aov from the experience
	 */
	rpcvraovd = 0;

	//

	/**
	 * Constructs a new OptimizationExperienceAnalytics model object. 
	 * @param experience The Experience in which the analytics are for
	 * @param eventType The event type object in which the analytics apply
	 */
	constructor( experience, eventType ) {
		this.#experience = experience;
		this.#eventType = eventType;
	}

	//

	/**
	 * Updates the data for the analytics object
	 */
	updateAnalytics( eventData ) {
		this.count = eventData?.count || 0;
		this.sessions = eventData?.sessions || 0;
	}

	//

	/**
	 * @return The content associated with the metrics
	 */
	getExperience() {
		return this.#experience;
	}

	//

}

//

export default OptimizationExperienceAnalytics;