import React from 'react';

var ArrowRightIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg width={size} height={size} viewBox='0 0 24 24'>
			<g className='nc-icon-wrapper' stroke='none' fill={color}>
				<path d='M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z'/>
			</g>
		</svg>
	);
};

export default ArrowRightIcon;