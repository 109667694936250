/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SetStateCommand from './SetStateCommand';

/**
 * Creates a new Channel object and intiates the switch to the edit Channel panel in the admin
 */
class CreateChannelCommand extends Command {
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		var setState = new SetStateCommand( AdminStates.ADMIN_CHANNELS_CREATE );
		setState.execute();
	}
	
}

//

export default CreateChannelCommand;
