/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

/**
 * UI to render a set of tabs and swap content 
 */
class TabComponent extends Component {
	
	/**
	 * Constructs the Component
	 */
	constructor( props ){
		super();
		this.element = React.createRef();
		this.selectedIndex = ( props.selectedIndex != null ) ? props.selectedIndex : 0;
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() { 
		const contentField = ( this.props.contentField != null ) ? this.props.contentField : 'content';
		const buttons = [];
		const panels = [];
		const selectHandler = ( e ) => { this.#handleTabSelect( e ); };
		for( let i = 0; i < this.props.tabs.length; i++ ){
			const tab = this.props.tabs[ i ];
			const contentDisplay = ( this.selectedIndex === i ) ? 'block' : 'none';
			const selectedClass = ( this.selectedIndex === i ) ? 'selected' : '';
			const content = ( contentField instanceof Function ) ? contentField( tab ) : tab[ contentField ];
			buttons.push( <div key={ 'button-' + i } data-tab-id={ i } className={ 'button ' + selectedClass } onClick={ selectHandler }>{ tab.label }</div> );
			panels.push( <div key={ 'content-' + i } data-content-id={ i } className="tab-component-content" style={{ display: contentDisplay }}>{ content }</div> );
		}

		return ( 
			<div className='tab-component' ref={ this.element }>
				<div className="tab-component-tabs">
					{ buttons }
				</div>
				<div className="tab-component-content-container">
					{ panels }
				</div>
			</div>
		);
	}

	//

	/**
	 * Handles clicks on the tab button, swaps the tab content
	 */
	#handleTabSelect( e ){
		var index = Number( e.target.getAttribute( 'data-tab-id' ));
		// console.info( 'TabComponent::handleTabSelect', index, this.selectedIndex );
		if( index !== this.selectedIndex ){
			// hide the currently visible content
			if( this.selectedIndex != null ){
				var currentTab = this.element.current.querySelector( `.button[data-tab-id='${this.selectedIndex}'` );
				currentTab.classList.remove( 'selected' );

				var currentContent = this.element.current.querySelector( `.tab-component-content[data-content-id='${this.selectedIndex}'` );
				currentContent.style.display = 'none';
			}
			
			// show the selectd content
			var tab = this.element.current.querySelector( `.button[data-tab-id='${index}'` );
			tab.classList.add( 'selected' );
			
			var content = this.element.current.querySelector( `.tab-component-content[data-content-id='${index}'` );
			content.style.display = 'block';

			this.selectedIndex = index;

			if( this.props.onSelect != null ) this.props.onSelect( index );
		}
	}
	
}

export default TabComponent;
