/*
 * (c) Verra Technology Corporation
 */

/**
 * The event types available for creating Metrics
 * Some of these values are specific to the admin, should this be moved into the admin/model/ package?
 */
const MetricEventTypes = {

	engagementTypes: [
		{
			name: 'Experience Present',
			eventId: 'page-load',
			requiresValue: false
		}, 
		{
			name: 'Experience Present & Element Viewed', 
			eventId: 'element-view',
			requiresValue: true,
			valueHint: 'Element Path'
		},
		{
			name: 'Experience Present & Element Clicked',
			eventId: 'element-click',
			requiresValue: true,
			valueHint: 'Element Path'
		}
	],

	conversionTypes: [
		{
			name: 'Add to Cart',
			eventId: 'add-to-cart',
			requiresValue: false,
			analyticsParameters: [ 'product-qty' ],
			baselineCvr: 7.5,
			mde: 20
		},
		{
			name: 'Place Order',
			eventId: 'place-order',
			requiresValue: false,
			analyticsParameters: [ 'subtotal', 'total' ],
			baselineCvr: 2.5,
			mde: 20
		},
		{
			name: 'Page Viewed',
			eventId: 'page-load',
			requiresValue: true,
			valueHint: 'Page Path',
			mde: 20
		},
		{
			name: 'Element Viewed', 
			eventId: 'element-view',
			requiresValue: true,
			valueHint: 'Element Path',
			hasLocateButton: true,
			mde: 20
		},
		{
			name: 'Element Clicked',
			eventId: 'element-click',
			requiresValue: true,
			valueHint: 'Element Path',
			hasLocateButton: true,
			mde: 20
		},
		{
			name: 'Custom',
			eventId: 'custom',
			requiresValue: true,
			valueHint: 'Event ID',
			mde: 20
		}
	],

	eventFreqencies: [
		{
			name: 'Every Occurance', 
			value: 0
		},
		{
			name: 'Once Per Session',
			value: 1
		}
	]

};


//

export default MetricEventTypes;