/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand.js';
import SphereAdminServices from '../../model/SphereAdminServices.js';

/**
 * Retrieves direct events for a Channel
 */
class RetrieveChannelIndirectEventMetricsRequest extends ServiceRequestCommand {

	/**
	 * Holds the response data
	 */
	#data;

	//

	/**
	 * Constructs the Request
	 */
	 constructor( accountId, channelId, engagementEvent, indirectEvents, startTime, endTime ){
		super();
		this.accountId = accountId;
		this.channelId = channelId;
		this.engagementEvent = engagementEvent;
		this.indirectEvents = indirectEvents;
		this.startTime = startTime;
		this.endTime = endTime;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getService(){
		return SphereAdminServices.RETRIEVE_CHANNEL_INDIRECT_EVENT_METRICS;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams(){
		return { 
			accountId: this.accountId, 
			channelId: this.channelId, 
			engagementEvent: this.engagementEvent,
			indirectEvents: this.indirectEvents, 
			startTime: this.startTime, 
			endTime: this.endTime
		};
	}

   /**
	* Handles completion of the command
	*/
	handleComplete(){
		const response = this.getResponse();
		// console.info( 'RetrieveChannelIndirectEventMetricsRequest::handleComplete', response );
		if( response.success ) this.#data = response.result;
	}
 
	//

	/**
	 * @return The event counts for the Channel
	 */
	getData(){
		return this.#data;
	}
 
}

//

export default RetrieveChannelIndirectEventMetricsRequest;
