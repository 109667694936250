/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SphereAdminSession from '../model/SphereAdminSession';
import SetStateCommand from './SetStateCommand';

/**
 * Intiates the edit audience action in the admin
 */
class EditAudienceCommand extends Command {
	
	/**
	 * Constructs the command.
	 * @param audience the audience to edit
	 */
	constructor( audience ) {
		super();
		this.audience = audience;
	}
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		SphereAdminSession.audience = this.audience;
		var setState = new SetStateCommand( AdminStates.ADMIN_AUDIENCES_EDIT, { audienceId: this.audience.id });
		setState.execute();
	}
	
}

export default EditAudienceCommand;
