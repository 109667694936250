/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryLabel
} from 'victory';
import Optimization from '../../../model/Optimization';
import StatisticsUtil from '../../../util/StatisticsUtil';
import Hint from '../controls/Hint';
import ChartsTheme from './ChartsTheme';

//

const CHANNEL_VIEW_TYPE = 'Channel';
const VARIANTS_VIEW_TYPE = 'Variants'; 

/**
 * 
 */
const viewTypes = [
	CHANNEL_VIEW_TYPE,
	VARIANTS_VIEW_TYPE
];

//

/**
 * Displays a bar graph of winner probabilities
 */
class ExperienceWinnerChart extends Component {

	/**
	 * Constructs the component
	 */
	constructor() {
		super();
		this.state = {
			significanceLevel: 0.95
		};
	}

	//

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const { optimization, selectedMetric } = this.props; 

		const engagementsCount = this.#getImpressionAnalytics( selectedMetric.engagementEventId, selectedMetric.engagementEventFrequency );
		const experiencesCount = optimization.experiences.length;
		const significancePercent = Intl.NumberFormat( 'en-US', { style: 'percent' } ).format( this.state.significanceLevel );

		const baselineCvr = Number( selectedMetric.baselineCvr ) * 0.01;
		const cvrFormatted = Intl.NumberFormat( 'en-US', { maximumFractionDigits: 2, style: 'percent' } ).format( baselineCvr );
		const mde = Number( selectedMetric.mde ) * 0.01;
		const mdeFormatted = Intl.NumberFormat( 'en-US', { maximumFractionDigits: 2, style: 'percent' } ).format( Number( selectedMetric.mde ) * 0.01 );
		const sampleSize = StatisticsUtil.getMinimumSampleSize( baselineCvr, mde, this.state.significanceLevel );

		const isComplete = ( optimization.status === Optimization.COMPLETE );
		const hasSignificance = ( sampleSize * experiencesCount < engagementsCount );
		
		const markup = (
			<div>
				{( isComplete ) &&
					<h3>Probabilty to be the Best - { selectedMetric.name } &nbsp;
						{ !hasSignificance && 
							<Hint error={ true } width='300px' content={ `This metric did not reach statistical significance using a baseline conversion of ${ cvrFormatted }, minimum detectable effect of ${ mdeFormatted }, and statistical significance of ${ significancePercent }` }/>
						}
					</h3>
				}
				{( !isComplete && !hasSignificance ) &&
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
						<h3>
							Probabilty to be the Best - { selectedMetric.name } &nbsp;
							{ !hasSignificance && 
								<Hint error={ true } width='300px' content={ `You need a sample size of ${ Intl.NumberFormat( 'en-US', { maximumFractionDigits: 1 } ).format( sampleSize ) } per variant to reach statistical significance with ${ significancePercent } confidence` }/>
							}
						</h3>
						{/* <Slider
							label='Statistical Significance'
							isPercent={ true }
							value={ this.state.significanceLevel * 100 }
							onChange={ this.#handleSignificanceChange.bind( this )}/> */}
					</div>
				}
				<div style={{ position: 'relative' }}>
					{ this.#getChartMarkup() }
				</div>
			</div>
		);

		return markup;
	}
	
	/**
	 * @return the markup for the chart
	 */
	#getChartMarkup() {
		const chartData = this.#getVariantsChartData();
		
		const height = chartData.length * 15;
		const theme =  ChartsTheme;
		
		const labelStyle = { ...ChartsTheme.bar.style.labels };
		labelStyle.fontSize = 3.5;

		const markup = <div style={{ padding: '25px 0 5px 0' }}>
			<VictoryChart 
				theme={ theme }
				width={ 350 }
				height={ height } 
				sortKey='i'
				sortOrder='ascending'
				domain={{ y:[ 0, 100 ]}}
				singleQuadrantDomainPadding={ false }
				domainPadding={{ x: [ 5, 5 ], y: [ 0, 2 ] }}
				padding={{ top: 0, right: 15, bottom: 0, left: 0 }}
			>
				<VictoryAxis 
					style={{ axis: { stroke: "none" }, ticks: { stroke: "transparent" } }}
					tickFormat={( datum ) => '' }
					dependentAxis={ true }/>
				<VictoryAxis 
					style={{ axis: { stroke: "none" }, ticks: { stroke: "transparent" } }} 
					tickFormat={( datum ) => {
						return `${ datum }`;
					}}
					dependentAxis={ false } 
					crossAxis={ true }
					tickLabelComponent={
						<VictoryLabel 
							dx={ 8 }
							dy={ -7 }
							textAnchor='start'
							verticalAnchor='middle'
							style={ labelStyle }/>
					}/>
				<VictoryBar
					horizontal={ true }
					barWidth={ 7 }
					style={{ data: { fill: ({ datum }) => datum.color } }}
					data={ chartData }
					labels={({ datum }) => {
						let percent = `${ Intl.NumberFormat( 'en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 } ).format( datum.y ) }%`;
						return percent;
					}}
					labelComponent={
						<VictoryLabel 
							dx={ 3 }
							style={ labelStyle }/>
					}
				/>
			</VictoryChart>	
		</div>;
		return markup;
	}
	
	//

	/**
	 * @return data for displaying variants in the chart
	 */
	#getVariantsChartData() {
		const chartData = [];
		const experienceAnalytics = this.props.analytics.getMetricExperiencesAnalytics( this.props.selectedMetric.id );
		const { experiences } = this.props.optimization;
		experiences.forEach( experience => {
			const expAnalytics = experienceAnalytics?.[ experience.id ];
			const y = expAnalytics?.successProbability || 0;
			chartData.push({ x: experience.name, y, color: experience.color });
		});
		chartData.sort(( a, b ) => a.y > b.y );
		return chartData; 
	}

	/**
	 * @return The metric for the specified event or zero if none exists
	 */
	#getImpressionAnalytics( eventId, frequency ) {
		const metricProp = ( frequency === 0 ) ? 'count' : 'sessions';
		const eventMetric = this.props.analytics.getEngagementEventMetric( eventId, frequency );
		return ( eventMetric != null ) ? eventMetric[ metricProp ] : 0;
	}

	//

	/**
	 * Handles changes to the significance level
	 */
	#handleSignificanceChange( value ) {
		this.setState({ significanceLevel: value * 0.01 });
	}

}

export default ExperienceWinnerChart;
