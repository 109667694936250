/*
 * (c) Verra Technology Corporation
 */

import ModifiableObject from "./ModifiableObject.mjs";

/**
 * Represents a PredictiveAttribute
 */
class PredictiveAttribute extends ModifiableObject {

	// TODO: define properties, @see PredictiveAttributeModal
	// TODO: this likely doesn't need to be a ModifiableObject

}

//

export default PredictiveAttribute;