/*
 * (c) Verra Technology Corporation
 */

/**
 * Represents the types of ML optimizations for predictive channels
 */
const ChannelOptimizationTypes = {

	/** 
	 * The channel is trained on how many of the success events occur
	 */
	VOLUME: 0,

	/**
	 * The channel is trained on the conversion rate between the success and engagement event
	 */
	CONVERSION: 1,

	/**
	 * The channel is trained on a particular value from the success event's data layer
	 */
	VALUE: 2

};

export default ChannelOptimizationTypes;