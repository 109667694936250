/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import EditContentCommand from '../commands/EditContentCommand';
import ContentSearch from '../components/ContentSearch';

//

/**
 * The ContentPanel contains the administration UI for content
 */
class ContentPanel extends Component {
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		return ( 
			<div className='content-panel'>
				<div className='grid'>
					<h2 className='grid-cell default-100'>Content</h2>
				</div>
				<ContentSearch 
					showControls={true} 
					retrieveOnLoad={true} 
					clickHandler={this.#handleContentListItemClick.bind( this )}/>
			</div>
		);
	}

	//

	/**
	 * Handles clicks on items in the content list
	 */
	#handleContentListItemClick( item ) {
		// console.info( 'handleContentListItemClick', item );
		var editContent = new EditContentCommand( item.id );
		editContent.execute();
		
	};

}

export default ContentPanel;
