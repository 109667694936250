/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import CloseModalCommand from '../../commands/CloseModalCommand';
import PositionModalCommand from '../../commands/PositionModalCommand';

/**
 * Renders a Modal window
 */
class Modal extends Component {

	/**
	 * Constructs the component
	 */
	constructor(){
		super();
		this.modalRef = React.createRef();
	}
	
	/**
	 * Handles the mounting of the component
	 */
	componentDidMount(){
		// setTimeout( () => { this.#positionContent() }, 100 );

		// console.info( 'component did mount', this.modalRef );

		if( this.modalRef.current != null && this.resizeObserver == null ){
			this.resizeObserver = new ResizeObserver((entries) => {
				for (const entry of entries) {
					// console.info( entry );
				}
	
				// console.log("Size changed");
				this.#positionContent()
			});
	
			const content = this.modalRef.current.querySelector( '.content' );
			this.resizeObserver.observe( content );
		}

		
	}

	/**
	 * 
	 * Handles the mounting of the component
	 */
	componentDidUpdate() {
		// console.info( 'Modal::componentDidUpdate' );
		// setTimeout( () => { this.#positionContent() }, 100 );
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		
		const backgroundClickHandler = ( this.props.closeOnClick ) ? handleBackgroundClick.bind( this ) : null;
		const title = ( this.props.title != null ) ? <h3>{this.props.title}</h3> : '';

		return ( 
			<div ref={this.modalRef} className='modal'>
				<div className='background' onClick={backgroundClickHandler}></div>
				<div className='content-container' style={{ visibility: 'hidden', width: this.props.width }}>
					<div className='content'>
						{title}
						{this.props.content}
					</div>
				</div>
			</div> 
		);
	}

	/**
	 * Vertically centers the content 
	 */
	#positionContent( e ) {
		const positionModal = new PositionModalCommand();
		positionModal.execute();
	}

	//

	/**
	 * @return The modals top level DOM element
	 */
	getElement() {
		return this.modalRef.current;
	}

}

//

/**
 * Handles clicks on the background, closing the modal
 */
function handleBackgroundClick( e ) {
	const closeModal = new CloseModalCommand();
	closeModal.execute();
}

//

export default Modal;
