/*
 * (c) Verra Technology Corporation
 */

/**
 * Manages the Sphere Administration configuration data
 */
const SphereAdminConfig = {

	/**
	 * A URL pointing to configuration data that is loaded at run time.
	 */
	configDataUrl: '/data/AdminConfigData.json',

	/**
	 * The Google API client ID.
	 * https://console.developers.google.com/apis/dashboard?project=sphere-ai&authuser=0 - credentials
	 */
	gapiClientId: '968459507266-79ii39mf68tjva3euar0vbhj6ei352rh.apps.googleusercontent.com'

}

export default SphereAdminConfig;
