/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand.js';
import SphereAdminSession from '../../model/SphereAdminSession.js';
import SphereAdminServices from '../../model/SphereAdminServices.js';
import SetStateCommand from '../../commands/SetStateCommand.js';
import AdminStates from '../../model/AdminStates.js';

/**
 * Makes a request to register a user
 */
class RegisterUserRequest extends ServiceRequestCommand {


	/**
	 * Constructs the request
	 */
	 constructor( code, email, password ){
		super();
		this.code = code;
		this.email = email;
		this.password = password;
	}

   /**
	* Handles execution of the Command
	*/
	handleExecute() {
		// reset the error state
		SphereAdminSession.registrationError = null;
		var setState = new SetStateCommand( AdminStates.REGISTERING );
		setState.execute();

		// execute the request
		super.handleExecute();
   	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
	getService() {
		return SphereAdminServices.REGISTER;
	}

	/**
	 * @return Whether or not the request requires an authenticated session. Default is true
	 */
	 requiresAuth() {
		return false;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { code: this.code, email: this.email, password: this.password };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		var response = this.getResponse();
		//console.info( 'RegisterUserRequest::handleComplete', response );

		var setState;

		if( response.success ){
			window.history.replaceState( {}, '', '/' );
			SphereAdminSession.user = response.result.user;
			setState = new SetStateCommand( AdminStates.AUTHENTICATED );
		} else {
			SphereAdminSession.registrationError = response.error;
			setState = new SetStateCommand( AdminStates.REGISTRATION_FAILED );
		}
		
		setState.execute();
	}
 
}

export default RegisterUserRequest;
