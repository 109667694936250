import React from 'react';

var PlaceIcon = function( props ) {
	return (
		<svg width='52' height='52' viewBox='0 0 48 48'>
			<g className='nc-icon-wrapper' stroke='none' fill='#ffffff'>
				<path d='M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z'/>
			</g>
		</svg>
	);
};

export default PlaceIcon;