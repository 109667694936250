/*
 * (c) Verra Technology Corporation
 */

/**
 * A Channel's object retrieval depth determines how many records are returned when populating the Channel
 */
 const ChannelObjectRetrievalDepth = {

	/**
	 * The max number of objects are returned
	 * NOTE: max is used instead of all has a hedge to performance in the case there are many, many records
	 * this max is defined as a soft limit set in the code
	 */
	MAX: 0,

	/**
	 * Only a single object is returned
	 */
	SINGLE: 1,

	/**
	 * A specific number of objects are returned with a minimum of 1 and max of MAX
	 */
	SPECIFIC: 2

};

Object.freeze( ChannelObjectRetrievalDepth );
export default ChannelObjectRetrievalDepth;