/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand.js';
import SphereAdminSession from '../../model/SphereAdminSession.js';
import SphereAdminServices from '../../model/SphereAdminServices.js';

/**
 * Request to delete an Event Type
 */
class DeleteEventTypesRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param typeIds An array of Event Type IDs to delete
	 */
	constructor( typeIds ){
		super();
		this.typeIds = typeIds;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.DELETE_EVENT_TYPES;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id, typeIds: this.typeIds };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		// console.info( 'DeleteEventTypesRequest::handleComplete', response );
		this.typesInUse = ( response.success ) ? response.result : [];
		const typesInUseIds = this.typesInUse.map( type => type.eventTypeId );

		// Remove any types that were successfully deleted
		const updatedTypes = []; 
		const updatedMap = {};
		SphereAdminSession.eventTypes.forEach(( type ) => {
			if( !this.typeIds.includes( type.id ) || typesInUseIds.includes( type.id )){
				updatedTypes.push( type );
				updatedMap[ type.id ] = type;
			}
		});

		SphereAdminSession.eventTypes = updatedTypes;
		SphereAdminSession.eventTypesMap = updatedMap;
	}
 
	//

	/**
	 * @return Any Content that could not be deleted because it's in use by a Channel
	 */
	getTypesInUse(){
		return this.typesInUse;
	}

}

export default DeleteEventTypesRequest;
