import React from 'react';

const MoveIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg height={size} width={size} viewBox="0 -960 960 960" >
			<g className='nc-icon-wrapper' stroke='none' fill={color}>
				<path d="M390-226v-508h509v508H390Zm95-94h319v-320H485v320Zm-259 94v-94h94v94h-94Zm0-414v-94h94v94h-94ZM61-226v-94h95v94H61Zm0-207v-94h95v94H61Zm0-207v-94h95v94H61Zm584 160Z"/>
			</g>
		</svg>
	);
};

export default MoveIcon;