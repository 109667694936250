/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';

/**
 * Retrieves the AnalyticsEventTypes for the selected Account
 */
class RetrieveEventTypesRequest extends ServiceRequestCommand {

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getService() {
		return SphereAdminServices.RETRIEVE_EVENT_TYPES;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete(){
		const response = this.getResponse();
		if( response.success ){
			const eventTypes = response.result;
			eventTypes.sort(( event1, event2 ) => event1.interactionType >= event2.interactionType );
			SphereAdminSession.eventTypes = eventTypes;
			SphereAdminSession.eventTypesMap = {};
			eventTypes.forEach(( type ) => {
				SphereAdminSession.eventTypesMap[ type.id ] = type;
			});
		}
	}
 
}

export default RetrieveEventTypesRequest;
