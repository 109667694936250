/*
 * (c) Verra Technology Corporation
 */

import AudienceRuleOperators from "./AudienceRuleOperators";

/**
 * Constants for defining data requirements of the rule types
 */
const DataRequirements = {
	LOCATION: 'LOCATION',
	TIME: 'TIME',
	URL: 'URL',
	CLIENT: 'CLIENT'
};

/**
 * Represents the available data that can be used to define as an AudienceRule
 */
const AudienceRuleTypes = [
	
	// Context

	{ 
		id: 1, group: 'Context', name: 'URL Parameter', variablePrefix: 'verra.context.param.', 
		requiresValue: true, dataRequirements: [ DataRequirements.URL ],
		operators: [ 
			AudienceRuleOperators.EQUALS, 
			AudienceRuleOperators.NOT_EQUALS, 
			AudienceRuleOperators.CONTAINS, 
			AudienceRuleOperators.DOES_NOT_CONTAIN, 
			AudienceRuleOperators.LESS_THAN, 
			AudienceRuleOperators.GREATER_THAN 
		] 
	},
	{ 
		id: 2, group: 'Context', name: 'Current Page', variableName: 'verra.context.href', 
		requiresValue: true, dataRequirements: [ DataRequirements.URL ],
		operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS, AudienceRuleOperators.CONTAINS, AudienceRuleOperators.DOES_NOT_CONTAIN ]  
	},
	{ 
		id: 3, group: 'Context', name: 'Referrer', variableName: 'verra.context.referrer', 
		requiresValue: true, dataRequirements: [ DataRequirements.URL ],
		operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS, AudienceRuleOperators.CONTAINS, AudienceRuleOperators.DOES_NOT_CONTAIN ] 
	},

	// Data

	{ 
		id: 4, group: 'Data', name: 'Data Layer Variable', variablePrefix: 'data.', 
		requiresValue: true, 
		operators: [ 
			AudienceRuleOperators.EQUALS, 
			AudienceRuleOperators.NOT_EQUALS, 
			AudienceRuleOperators.CONTAINS, 
			AudienceRuleOperators.DOES_NOT_CONTAIN,
			AudienceRuleOperators.LESS_THAN, 
			AudienceRuleOperators.GREATER_THAN 
		] 
	},
	// { 
	// 	id: 5, group: 'Data', name: 'JavaScript Variable', variablePrefix: 'window.',
	// 	requiresValue: true, dataRequirements: [ DataRequirements.CLIENT ],
	// 	operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS, AudienceRuleOperators.LESS_THAN, AudienceRuleOperators.GREATER_THAN ] 
	// },

	// Date & Time

	{ 
		id: 6, group: 'Date & Time', name: 'Hour', variableName: 'verra.time.hour', 
		requiresValue: true, dataRequirements: [ DataRequirements.TIME ],
		operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS, AudienceRuleOperators.LESS_THAN, AudienceRuleOperators.GREATER_THAN ] 
	},
	{ 
		id: 7, group: 'Date & Time', name: 'Weekday', variableName: 'verra.time.weekday', 
		requiresValue: true, dataRequirements: [ DataRequirements.TIME ],
		operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS, AudienceRuleOperators.LESS_THAN, AudienceRuleOperators.GREATER_THAN ] 

	},
	{ 
		id: 8, group: 'Date & Time', name: 'Day of Month', variableName: 'verra.time.date', 
		requiresValue: true, dataRequirements: [ DataRequirements.TIME ],
		operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS, AudienceRuleOperators.LESS_THAN, AudienceRuleOperators.GREATER_THAN ]  
	},
	{ 
		id: 9, group: 'Date & Time', name: 'Month', variableName: 'verra.time.month', 
		requiresValue: true, dataRequirements: [ DataRequirements.TIME ],
		operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS, AudienceRuleOperators.LESS_THAN, AudienceRuleOperators.GREATER_THAN ] 
	},

	// Device

	{ 
		id: 10, group: 'Device', name: 'Mobile', variableName: 'verra.device.mobile', 
		requiresValue: false, 
		operators: [ AudienceRuleOperators.TRUE, AudienceRuleOperators.FALSE ] 
	},

	// Location

	{ 
		id: 13, group: 'Location', name: 'Lat & Long', variableName: 'verra.location.latlong', 
		requiresValue: true, dataRequirements: [ DataRequirements.LOCATION ],
		operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS ] 
	},
	{ 
		id: 14, group: 'Location', name: 'Postal Code', variableName: 'verra.location.zip', 
		requiresValue: true, dataRequirements: [ DataRequirements.LOCATION ],
		operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS ] 
	},
	{ 
		id: 15, group: 'Location', name: 'City', variableName: 'verra.location.city', 
		requiresValue: true, dataRequirements: [ DataRequirements.LOCATION ],
		operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS ] 
	},
	{ 
		id: 16, group: 'Location', name: 'State Code', variableName: 'verra.location.state', 
		requiresValue: true, dataRequirements: [ DataRequirements.LOCATION ],
		operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS ] 
	},
	{ 
		id: 17, group: 'Location', name: 'Country Code', variableName: 'verra.location.country', 
		requiresValue: true, dataRequirements: [ DataRequirements.LOCATION ],
		operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS ] 
	},
	// { 
	// 	id: 18, group: 'Location', name: 'Language Code', variableName: 'verra.location.language', 
	// 	requiresValue: true, dataRequirements: [ DataRequirements.LOCATION ],
	// 	operators: [ AudienceRuleOperators.EQUALS, AudienceRuleOperators.NOT_EQUALS ] 
	// },

	// User

	{ 
		id: 19, group: 'User', name: 'New User', variableName: 'verra.user.new', 
		requiresValue: false, 
		operators: [ AudienceRuleOperators.TRUE, AudienceRuleOperators.FALSE ]  
	},
	{ 
		id: 19, group: 'User', name: 'New Session', variableName: 'verra.user.session', 
		requiresValue: false, 
		operators: [ AudienceRuleOperators.TRUE, AudienceRuleOperators.FALSE ]  
	}

	// Last Visit
	// Last Purchase


];

//

export default AudienceRuleTypes;