/*
 * (c) Verra Technology Corporation
 */

import AudienceRule from "./AudienceRule";

/**
 * Represents the available operators that can be used for an AudienceRule
 */
class AudienceRuleOperators {

	/**
	 * Is true operator type
	 */
	static TRUE = { label: 'True', symbol: '= true', value: 0 };

	/**
	 * Is false operator type
	 */
	static FALSE = { label: 'False', symbol: '= false', value: 1 };

	/**
	 * Equals operator type
	 */
	static EQUALS = { label: 'Equals', symbol: '=', value: 2 };

	/**
	 * Not equals operator type
	 */
	static NOT_EQUALS = { label: 'Does not Equal', symbol: '!=', value: 3 };
	
	/**
	 * Less than operator
	 */
	static LESS_THAN = { label: 'Less Than', symbol: '<', value: 4 };
	
	/**
	 * Greater than operator type
	 */
	static GREATER_THAN = { label: 'Greater Than', symbol: '>', value: 5 };

	/**
	 * Contains operator type
	 */
	static CONTAINS = { label: 'Contains', symbol: '∈', value: 6 };

	/**
	 * Does not contain operator type
	 */
	static DOES_NOT_CONTAIN = { label: 'Does not Contain', symbol: '∉', value: 7 };

	//
	
	/**
	 * Stores the available operator names by they value for easy look up
	 */
	static operatorsByValue = {
		'0': 'TRUE', 
		'1': 'FALSE', 
		'2': 'EQUALS', 
		'3': 'NOT_EQUALS', 
		'4': 'LESS_THAN', 
		'5': 'GREATER_THAN',
		'6': 'CONTAINS'
	};
	
}

//

export default AudienceRuleOperators;