/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import SphereAdminSession from '../model/SphereAdminSession';
import RetrieveChannelsListRequest from '../requests/channels/RetrieveChannelsListRequest';
import CreateChannelCommand from '../commands/CreateChannelCommand';
import EditChannelCommand from '../commands/EditChannelCommand';
import DataGrid from '../components/controls/DataGrid';
import ViewChannelCommand from '../commands/ViewChannelCommand';
import Alert from '../components/controls/Alert';
import OpenModalCommand from '../commands/OpenModalCommand';
import DeleteChannelsRequest from '../requests/channels/DeleteChannelsRequest';
import PencilIcon from '../icons/PencilIcon';

//

/**
 * Defines the model type labels
 */
const modelTypes = {
	1: 'A/B',
	// 2: 'Personalization',
	4: 'Predictive'
};

//

/**
 * The ChannelsListPanel contains the administration UI for Channel
 */
class ChannelsListPanel extends Component {
	
	/**
	 * Constructs the ChannelPanel.
	 */
	constructor() {
		super();
		
		this.state = {
			channelsSelected: false,
			channels: []
		};

		this.channelsGrid = React.createRef();

		// TODO: add loader
		// TODO: this should be moved into a ViewChannelsListCommand?
		SphereAdminSession.loading = true;
		var retrieveChannels = new RetrieveChannelsListRequest();
		retrieveChannels.execute(( command ) => { this.#handleChannelsRetrieved( command ); } );
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		var channels = this.state.channels;

		var deleteDisabled = ( this.state.channelsSelected ) ? '' : ' disabled';
		var emptyChannelsMarkup = <div>You do not have any Channels. <button className='link-button' onClick={this.#handleCreateNewChannel.bind( this )}>Create Channel</button></div>;

		var headers = [ 
			{label: 'Name', field: 'name', width: '30'}, 
			{label: 'ID', field: 'id', width: '30'}, 
			{label: 'Model', field: ( data ) => { return modelTypes[ data.model ]; }, width: '15'}, 
			{label: 'Last Modified', field: 'attributes.date-modified', width: '15'}
		];

		var markup = ( 
			<div className='Channel-panel'>
				<div className='grid'>
					<h2 className='grid-cell default-100'>Channels</h2>
					<div className='grid-cell'>
						<button className='button' onClick={this.#handleCreateNewChannel.bind( this )}>Create</button>
					</div>
					<div className='grid-cell pad-cell-left'>
						<button className={'button' + deleteDisabled} onClick={this.#handleDelete.bind( this )}>Delete</button>
					</div>
				</div>
				<div className='pad-cell-top'>
					<DataGrid 
						ref={this.channelsGrid}
						headers={headers} 
						data={channels} 
						idField='id' 
						checkColumn={true} 
						editColumn={true}
						editIcon={<PencilIcon size={16}/>}
						statusIndicator={true} 
						statusField='status' 
						noContent={emptyChannelsMarkup}
						rowClickHandler={this.#handleChannelListItemClick.bind( this )}
						editHandler={this.#handleEditChannel.bind( this )}
						checkBoxChangeHandler={this.#handleGridCheckBoxSelect.bind( this )}/>
				</div>
			</div>
		);

		return markup;
	}

	// Handlers

	/**
	 * Handles the response from the request to retrieve Channel
	 */
	#handleChannelsRetrieved( command ) {
		SphereAdminSession.loading = false;
		this.setState({ channels: SphereAdminSession.channels });
	};

	/**
	 * Handles clicks on the new Channel button
	 */
	#handleCreateNewChannel( e ) {
		// console.info( 'handleCreateNewChannel', this )
		var createChannel = new CreateChannelCommand();
		createChannel.execute();
	};

	/**
	 * Handles clicks on items in the Channel list
	 */
	#handleChannelListItemClick( channel ) {
		//console.info( 'handleChannelListItemClick', channel );
		var viewChannel = new ViewChannelCommand( channel.id );
		viewChannel.execute();
	};

	/**
	 * Handles clicks on items in the Channel list
	 */
	#handleEditChannel( channel ) {
		var editChannel = new EditChannelCommand( channel.id );
		editChannel.execute();
	};

	/**
	 * Handles clicks on a checkbox in the Content grid
	 */
	#handleGridCheckBoxSelect( grid ){
		var selected = grid.getChecked();
		this.setState({ channelsSelected: ( selected.length > 0 )});
	}

	/**
	 * Handles the click to delete a Channel
	 */
	#handleDelete( e ){
		if( this.state.channelsSelected ){
			var alert = <Alert content='Are you sure you want to delete these Channels?' okHandler={ this.#handleConfirmDelete.bind( this ) }/>;
			var openModal= new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		}
	}

	/**
	 * Handles confirmation to confirm the deletion of the Channel
	 */
	 #handleConfirmDelete( e ){
		SphereAdminSession.loading = true;
		var selectedChannels = this.channelsGrid.current.getChecked();
		var channelIds = selectedChannels.map( channel => channel.id );
		var deleteChannels = new DeleteChannelsRequest( channelIds );
		deleteChannels.execute( command  => this.#handleDeleteComplete( command ));
	}

	/**
	 * Handles the completion of the delete request
	 */
	 #handleDeleteComplete( command ){
		// reload the channels
		// TODO: why are we reloading here?
		this.channelsGrid.current.unCheckAll();
		var retrieveChannels = new RetrieveChannelsListRequest();
		retrieveChannels.execute(( command ) => { this.#handleChannelsRetrieved( command ); } );
	}

}

//

export default ChannelsListPanel;
