/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import ItemListPanel from '../components/controls/ItemListPanel';
import SphereAdminServices from '../model/SphereAdminServices';
import SphereAdminSession from '../model/SphereAdminSession';
import CreateMetricCommand from '../commands/CreateMetricCommand';
import MetricEventTypes from '../../model/MetricEventTypes';
import EditMetricCommand from '../commands/EditMetricCommand';

//

/**
 * Displays the list of Metrics created within the account
 */
class MetricsListPanel extends Component {

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		const noItemsContent = <div>No metrics found. <button className='link-button' onClick={ this.#handleCreateExperience.bind( this )}>Create Metric</button></div>;
		const headers = [ 
			{ label: 'Name', field: 'name', width: 25 }, 
			{ 
				label: 'Conversion',
				field: ( metric ) => {
					const type = MetricEventTypes.conversionTypes.find( type => type.eventId === metric.conversionEventId );
					const freqency = MetricEventTypes.eventFreqencies.find( frequency => frequency.value === metric.conversionEventFrequency );
					const eventName = ( type != null ) ? type.name : metric.conversionEventId;
					return `${ eventName }, ${ freqency.name }`;
				}, 
				width: 35 
			},
			{ 
				label: 'Engagement', 
				field: ( metric ) => {
					const type = MetricEventTypes.engagementTypes.find( type => type.eventId === metric.engagementEventId );
					const freqency = MetricEventTypes.eventFreqencies.find( frequency => frequency.value === metric.engagementEventFrequency );
					const eventName = ( type != null ) ? type.name : metric.conversionEventId;
					return `${ eventName }, ${ freqency.name }`;
				}, 
				width: 35 
			}
		];
		const markup = ( 
			<div className='experiences-panel'>
				<div className='grid'>
					<h2 className='grid-cell default-100'>Metrics</h2>
				</div>
				<ItemListPanel 
					headers={ headers }
					showControls={ true } 
					retrieveOnLoad={ true } 
					noItemsContent={ noItemsContent }
					createCommand={ CreateMetricCommand }
					listEndPoint={ SphereAdminServices.RETRIEVE_METRICS }
					searchEndPoint={ SphereAdminServices.SEARCH_METRICS }
					deleteEndPoint={ SphereAdminServices.DELETE_METRICS }
					retrievedHandler={ this.#handleItemsRetrieved.bind( this )} 
					clickHandler={ this.#handleListItemClick.bind( this )}/>
			</div>
		);

		return markup;
	}

	/**
	 * Handles the click to create a new item
	 */
	#handleCreateExperience( e ){
		const create = new CreateMetricCommand();
		create.execute();
	}

	/**
	 * Handles the items retrieved event from the ItemListPanel
	 */
	#handleItemsRetrieved( itemsList ) {
		SphereAdminSession.metrics = itemsList.items;
		this.setState({});
	}

	/**
	 * Handles clicks on items in the content list
	 */
	#handleListItemClick( item ) {
		const editExperience = new EditMetricCommand( item.id );
		editExperience.execute();
	}

}

//

export default MetricsListPanel;
