/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import {
	createContainer,
	VictoryArea,
	VictoryAxis,
	VictoryChart,
	VictoryStack,
	VictoryTooltip
} from 'victory';
import ChartTimeValues from '../../model/ChartTimeValues';
import SphereAdminSession from '../../model/SphereAdminSession';
import RetrieveChannelConversionMetricsRequest from '../../requests/channels/RetrieveChannelConversionMetricsRequest';
import RetrieveChannelEngagementMetricsRequest from '../../requests/channels/RetrieveChannelEngagementMetricsRequest';
import DropDownField from '../controls/DropDownField';
import Loader from '../controls/Loader';
import ChartsTheme from './ChartsTheme';

//

/**
 * Constants defining the available chart styles
 */
const chartStyles = {
	STACKED: 'Stacked',
	LINE: 'Line'
};

//

/**
 * Renders event data over time
 */
class EventsOverTimeChart extends Component {

	/**
	 * Constant for specifying that the chart is displaying engagement events
	 */
	static EVENT_TYPES_ENGAGEMENT = 0;

	/**
	 * Constant for specifying that the chart is displaying conversion events
	 */
	static EVENT_TYPES_CONVERSION = 1;

	//
	
	/**
	 * Constructs the chart
	 */
	constructor( props ) {
		super( props );
		this.state = {
			events: null,
			selectedChartStyle: chartStyles.LINE,
			selectedEventType: this.props.selectedEventType
		};
		this.selectedTimePeriod = this.props.selectedTimePeriod;
		this.selectedIncrement = this.selectedTimePeriod.defaultIncrement;
		this.#retrieveEvents();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		// console.info( 'ImpressionsChart::render', this.props.eventsType, this.state.events == null );
		const events = this.state.events;
		const label = ( this.props.eventsType === EventsOverTimeChart.EVENT_TYPES_ENGAGEMENT ) ? 'Engagement' : 'Conversion';
		const markup = ( events == null ) ? <Loader/> : 
					<div className='grid'>
						<div className='grid-cell default-33'>
							<h3 style={{ display: 'inline-block' }}>{this.props.selectedEventType.name}</h3>
						</div>
						<div className='grid-cell default-66 align-right'>
							{/* 
							<DropDownField
								className='padless pad-cell-left'
								itemsWidth='150px' 
								hideBackground={true} 
								labelAlignRight={true} 
								alignRight={true}
								style={{ display: 'inline-block' }}
								items={SphereAdminSession.eventTypes}
								labelField='name'
								selectedItem={this.state.selectedEventType}
								changeHandler={this.#handleSelectedEventChange.bind( this )}/>
							*/}
							<DropDownField
								className='padless pad-cell-left'
								itemsWidth='150px' 
								hideBackground={true} 
								labelAlignRight={true} 
								alignRight={true}
								style={{ display: 'inline-block' }}
								items={[ chartStyles.STACKED, chartStyles.LINE ]}
								selectedItem={this.state.selectedChartStyle}
								changeHandler={this.#handleChartStyleChange.bind( this )}/>
							<DropDownField
								className='padless pad-cell-left'
								itemsWidth='150px' 
								hideBackground={true} 
								labelAlignRight={true} 
								alignRight={true}
								style={{ display: 'inline-block' }}
								items={this.selectedTimePeriod.increments}
								labelField='label'
								selectedItem={this.selectedIncrement}
								changeHandler={this.#handleTimeIncrementChange.bind( this )}/>
						</div>
						{ this.#getChartMarkup() }
					</div>;

		return markup;
	}

	/**
	 * @return the markup for the chart
	 */
	#getChartMarkup(){
		const events = this.state.events;

		const selectedEventTypeId = this.props.selectedEventType.id;
		const eventMetrics = ( events[ selectedEventTypeId ] != null ) ? events[ selectedEventTypeId ] : { total: [], content: {}, control: [] };

		const chartStyle = this.state.selectedChartStyle;
		
		const theme = ChartsTheme;
		const brushColor = '#A66FDD';
		const fontSize = 3.5;
		const controlColor = '#555555';
		const fillOpacity = ( chartStyle === chartStyles.STACKED ) ? 1 : 0;

		const contentIdMap = {};
		this.props.channel.content.forEach( content => contentIdMap[ content.id ] = content );

		let chartsMarkup = '';
		let brushChart = '';

		const areaCharts = [];

		Object.keys( eventMetrics.content ).forEach(( contentId, index ) => {
			let content = contentIdMap[ contentId ];
			if( content != null ){
				let chart = <VictoryArea
							key={index}
							data={eventMetrics.content[ contentId ]}
							style={{
								data: { stroke: content.color, strokeWidth: 0.4, fill: content.color, fillOpacity },
							}}/>;
				areaCharts.push( chart );
			}
		});

		areaCharts.push( <VictoryArea
							key='control'
							data={eventMetrics.control}
							style={{data: { stroke: controlColor, strokeWidth: 0.4, fill: controlColor, fillOpacity }}}/>
		);

		brushChart = <VictoryArea
						key='control'
						data={eventMetrics.total}
						style={{data: { stroke: controlColor, strokeWidth: 0.4, fill: controlColor, fillOpacity: 1 }}}/>;

		chartsMarkup = ( chartStyle === chartStyles.STACKED ) ? <VictoryStack>{areaCharts}</VictoryStack> : areaCharts;
		

		// const Container = createContainer("zoom", "voronoi");
		const Container = createContainer( "voronoi");

		const markup = <div className='grid-cell default-100 start-row'>
			<VictoryChart 
				theme={theme}
				width={300}
				height={80} 
				padding={{ top: 5, right: 5, bottom: 15, left: 15 }}
				scale={{ x: "time" }}
				//domain={{ x: [ this.startDate, this.endDate ]}}
				containerComponent={
					<Container
						zoomDimension="x"
						zoomDomain={this.state.zoomDomain}
						onZoomDomainChange={this.#handleZoom.bind( this )}
						cursorLabel={({ datum }) => `${Math.round( datum.y )}`}
						labels={({ datum }) => { return `${ datum.y }`;}}
						labelComponent={
							<VictoryTooltip
								renderInPortal={true}
								cornerRadius={2}
  								pointerWidth={2}
								pointerLength={4}
								text={({ datum }) => { 
									// let contentLabel = ( datum.control ) ? 'Control' : contentIdMap?.[ datum.contentId ]?.name;
									let label = datum.y + ' - ' + datum.x.toLocaleString( 'en-US' );
									return label;
								}}
								style={{ 
									fontSize, 
									// fill: 'white'
									// fill: ({ datum }) =>  contentIdMap[ datum.contentId ].color
								}} 
								flyoutPadding={3}
								flyoutStyle={{
									stroke: ({ datum }) => ( datum.control ) ? controlColor : contentIdMap?.[ datum.contentId ]?.color,
									strokeWidth: 0.3
									// fill: 
								}}
							/>
						}
					/>}
			>
				<VictoryAxis dependentAxis style={{tickLabels: { fontSize }, axisLabel: { fontSize }}} />
				<VictoryAxis crossAxis style={{tickLabels: { fontSize }, axisLabel: { fontSize }}} />
				{ chartsMarkup }
			</VictoryChart>	
			{/* <VictoryChart
				theme={theme}
				padding={{ top: 0, right: 10, bottom: 18, left: 35 }}
				height={35} 
				scale={{ x: "time" }}
				domain={{ x: [ this.startDate, this.endDate ]}}
				containerComponent={
					<VictoryBrushContainer
						brushDimension="x"
						brushDomain={ this.state.zoomDomain }
						onBrushDomainChange={ this.#handleZoom.bind( this ) }
						brushStyle={{ fill: brushColor, opacity: 0.3 }}
					/>
				}
			>
				{brushChart}
			</VictoryChart> */}
		</div>;
		return markup;
	}
	
	//

	/**
	 * Handles selections to the chart style
	 */
	#handleChartStyleChange( selectedChartStyle ){
		this.setState({ selectedChartStyle })
	}

	/**
	 * Handles changes to the selected event
	 */
	/* 
	#handleSelectedEventChange( selectedEventType ){
		this.setState({ selectedEventType })
	}
	*/

	/**
	 * Handles changes to the time increment selection
	 */
	#handleTimeIncrementChange( selectedItem ){
		this.selectedIncrement = selectedItem;
		this.#retrieveEvents();
	}

	/**
	 * Handles changes to the zoom container
	 */
	#handleZoom( domain ) {
		this.setState({ zoomDomain: domain });
	}
	
	//

	/**
	 * Executes the request to retrieve channel impressions
	 * TODO: this should probably be refactored, along with all of the data retrieval
	 * in ViewChannelPanel and other charts into a separate command or set of commands
	 * that is responsible for retrieving and pulling together all event data
	 */
	#retrieveEvents(){

		this.setState({ events: null });

		const eventTypeIds = this.props.eventsToRetrieve.map( event => event.id );

		const { startDate, endDate } = ChartTimeValues.getDateRange( this.selectedTimePeriod.value );
		this.startDate = startDate;
		this.endDate = endDate;

		if( this.props.eventsType === EventsOverTimeChart.EVENT_TYPES_ENGAGEMENT ){
			
			const retrieveImpressions = new RetrieveChannelEngagementMetricsRequest( 
				SphereAdminSession.selectedAccount.id,
				this.props.channel.id, 
				eventTypeIds,
				this.selectedIncrement.value,
				startDate, 
				endDate
			);
			retrieveImpressions.execute(( command ) => { this.#handleEventsRetrieved( command ); } );

		} else {
			
			const retrieveConversions = new RetrieveChannelConversionMetricsRequest( 
				SphereAdminSession.selectedAccount.id,
				this.props.channel.id,
				this.props.selectedEngagementEventType.id,
				eventTypeIds,
				this.selectedIncrement.value,
				startDate, 
				endDate
			);
			retrieveConversions.execute(( command ) => { this.#handleEventsRetrieved( command ); } );

		}
	}

	/**
	 * Handles the response from the request to retrieve the Channel events
	 */
	#handleEventsRetrieved( command ) {
		// console.info( 'handleEventsRetrieved', command.getImpressions() );
		const events = command.getEvents();
		
		// const now = new Date();
		// const startDate = this.startDate; //( loadStartDate < viewStartDate ) ? loadStartDate : viewStartDate;
		// const endDate = this.endDate; //( loadsEndDate > viewEndDate ) ? loadsEndDate : viewEndDate;
		// const delta = ( endDate.getTime() - startDate.getTime() ) * 0.33;
		// const startZoom = new Date( now.getTime() - delta ); //new Date( startDate.getTime() );
		// const endZoom = new Date( now );
		// endZoom.setDate( endZoom.getDate() );

		this.setState({ events });

		/*
		const retrieveConversions = new RetrieveChannelConversionMetricsReque( 
			SphereAdminSession.selectedAccount.id,
			this.props.channel.id,
			this.state.sele, 
			directConversionEvents,
			indirectConversionEvents,
			this.selectedIncrement.value,
			startDate, 
			endDate,
			false
		);
		*/
		//retrieveConversions.execute(( command ) => { this.#handleConversionsRetrieved( command ); } );
	};

	// Public

	/**
	 * Handles changes to the date range selection
	 */
	updateDateRange( selectedTimePeriod ) {
		this.selectedTimePeriod = selectedTimePeriod;
		this.selectedIncrement = this.selectedTimePeriod.defaultIncrement;
		this.#retrieveEvents();
	}

}

export default EventsOverTimeChart;
