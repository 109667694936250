import React from 'react';

var UploadIcon = function( props ) {
	return (
		<svg viewBox="0 0 24 24" width="18" height="18">
			<g className="nc-icon-wrapper" fill="#1EDFFD"><polygon fill="#1EDFFD" points="6,8 12,1 18,8 13,8 13,17 11,17 11,8 "/> 
				<path data-color="color-2" d="M22,21H2v-6H0v7c0,0.552,0.448,1,1,1h22c0.552,0,1-0.448,1-1v-7h-2V21z"/>
			</g>
		</svg>
	);
};

export default UploadIcon;