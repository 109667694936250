/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import SphereAdminSession from '../model/SphereAdminSession';
import ChannelEditor from '../components/editors/ChannelEditor';
import ChannelFactory from '../managers/ChannelFactory';

//

/**
 * The CreateChannelPanel contains the administration UI for ceating a new Channel
 */
class CreateChannelPanel extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();
		var channel = ChannelFactory.createChannel( SphereAdminSession.selectedAccount.id );
		SphereAdminSession.channel = channel;
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return <ChannelEditor showPublishBtn={false}/>;
	}

}

//

export default CreateChannelPanel;
