/*
 * (c) Verra Technology Corporation
 */

import SphereAdminServices from '../../model/SphereAdminServices';
import SphereAdminSession from '../../model/SphereAdminSession';
import ServiceRequestCommand from '../ServiceRequestCommand';

/**
 * Retrieves event analytics for an Optimization
 */
class RetrieveEventAnalyticsRequest extends ServiceRequestCommand {

	/**
	 * The retrieved event analytics data
	 */
	#data;

	//

	/**
	 * Constructs the Request
	 */
	constructor( startTime, endTime ) {
		super();
		this.startTime = startTime;
		this.endTime = endTime;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getService() {
		return SphereAdminServices.RETRIEVE_ANALYTICS;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { 
			accountId: SphereAdminSession.selectedAccount.id, 
			startTime: this.startTime, 
			endTime: this.endTime
		};
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		if( response.success ) this.#data = response.result;
	}
 
	//

	/**
	 * @return The event counts for the Channel
	 */
	getData() {
		return this.#data;
	}
 
}

export default RetrieveEventAnalyticsRequest;
