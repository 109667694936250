/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

/**
 * ColorPicker Component
 */
class ColorPicker extends Component {
	
	/**
	 * Constructs the component
	 */
	constructor( props ) {
		super( props );

		let color = ( props.color != null ) ? props.color : { r: 0, g: 0, b: 0, a: 1 };

		this.state = {
			open: false,
			color
		};

		this.colorChipRef = React.createRef();
		this.pickerRef = React.createRef();

		this.clickHandler = this.#handleClick.bind( this );
		this.handleKeyDownHandler = this.#handleKeyDown.bind( this );
	}

	/**
	 * Handles the update to props
	 */
	componentWillReceiveProps( nextProps ) {
		if( nextProps.color !== this.props.color ) {
			this.setState({ color: nextProps.color })
		}
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const color = this.state.color;
		const styleColor = ( this.state.color instanceof Object ) ? `rgba( ${color.r}, ${color.g}, ${color.b}, ${color.a})` : this.state.color;

		let pickerLeft = 'auto';
		let pickerTop = 'auto';

		const colorChip = this.colorChipRef.current;
		if( colorChip != null ) {
			const colorChipRect = colorChip.getBoundingClientRect();
			pickerLeft = ( colorChipRect.left - 250 ) + 'px';

			// const scrollParent = this.getScrollParent( colorChip );
			// const scrollTop = scrollParent.scrollTop;
			// pickerTop = ( colorChipRect.top + colorChipRect.height + 36 - scrollTop ) + 'px'; // fuck, not sure where the 36 comes from
			// // + colorChipRect.height + 36
			// console.info( colorChipRect.top, scrollTop );
		}

		return ( 
			<div className='color-picker'>
				<button ref={ this.colorChipRef } className='color-chip' style={{ backgroundColor: styleColor }} onClick={ this.#handleChipClicked.bind( this )}></button>
				{ 
					this.state.open && 
					<div 
						ref={ this.pickerRef } 
						className='picker-container'
						style={{ left: pickerLeft, top: pickerTop }}>
							<SketchPicker color={ this.state.color } onChange={ this.#handleColorChanged.bind( this )}/>
						</div> 
				}
			</div>
		);
	}

	getScrollParent(node) {
		if (node == null) {
			return null;
		}

		if (node.scrollHeight > node.clientHeight) {
			return node;
		} else {
			return this.getScrollParent(node.parentNode);
		}
	}

	/**
	 * Handles the click on the color chip, toggles the editor open and closed
	 */
	#handleChipClicked = function( e ){
		e.stopPropagation();
		if(( !this.props.disabled || this.props.disabled === undefined ) && !this.state.open ){
			this.open();
		} else if( this.state.open ) {
			this.close();
		}
	}

	/**
	 * Handles changes to the color
	 */
	#handleColorChanged = function( e ){
		this.setState({ color: e.rgb });
		if( this.props.onChange != null ) this.props.onChange( e );
	}

	/**
	 * Handles any click within the document, closes the picker if the click is outside
	 * of the picker UI
	 */
	#handleClick = function( e ){
		const picker = e.target.closest( '.picker-container' );
		if( picker == null ) this.close();
	}

	/**
	 * Handles a key press, closing the picker if it was the enter key
	 */
	#handleKeyDown = function( e ){
		if( e.key === 'Enter' ) this.close();
	}

	//

	/**
	 * Opens the drop down
	 */
	open() {
		window.document.addEventListener( 'click', this.clickHandler );
		window.document.addEventListener( 'keyup', this.handleKeyDownHandler );
		this.setState({ open: true });
	}

	/**
	 * Closes the drop down
	 */
	close() {
		window.document.removeEventListener( 'click', this.clickHandler );
		window.document.addEventListener( 'keyup', this.handleKeyDownHandler );
		this.setState({ open: false });
	}

}

export default ColorPicker;
