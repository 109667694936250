/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';

/**
 * Command used to set a collection of panels' heights to match
 */
class MatchPanelsHeightCommand extends Command {
	
	/**
	 * Constructs the Command
	 * @param panels An array of panels to resize
	 */
	constructor( panels ){
		super();
		this.panels = panels;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		var height = 0;
		var i;
		var panel;

		for( i = 0; i < this.panels.length; i++ ){
			panel = this.panels[ i ];
			if( panel == null ) break;
			panel.style.height = null;
			var rect = panel.getBoundingClientRect();
			if( rect.height > height ) height = rect.height;
		}

		if( height > 0 ){
			for( i = 0; i < this.panels.length; i++ ){
				panel = this.panels[ i ];
				panel.style.height = height + 'px';
			}	
		}
		
	}
	
}

export default MatchPanelsHeightCommand;
