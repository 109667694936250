/*
 * (c) Verra Technology Corporation
 */

import ModifiableObject from "./ModifiableObject.mjs";

/**
 * Represents a model object that can be edited, saved, and published
 */
class PublishableObject extends ModifiableObject {

	// Publish States

	/**
	 * Represents an object that has been published
	 */
	static PUBLISHED = 3;

}

//

export default PublishableObject;