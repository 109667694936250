/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';
import MetricEventTypes from '../../../model/MetricEventTypes';

/**
 * Retrieves event analytics for an Optimization
 */
class RetrieveOptimizationConversionAnalyticsRequest extends ServiceRequestCommand {

	/**
	 * The retrieved event analytics data
	 */
	#data;

	//

	/**
	 * Constructs the Request
	 */
	constructor( optimizationId, metric, startTime, endTime ) {
		super();
		this.optimizationId = optimizationId;
		this.metric = metric;
		this.startTime = startTime;
		this.endTime = endTime;

		const eventType = MetricEventTypes.conversionTypes.find( type => type.eventId === this.metric.conversionEventId );
		this.parameters = ( eventType.analyticsParameters != null ) ? eventType.analyticsParameters : [];
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getService() {
		return SphereAdminServices.RETRIEVE_OPTIMIZATION_CONVERSION_ANALYTICS;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { 
			accountId: SphereAdminSession.selectedAccount.id, 
			optimizationId: this.optimizationId, 
			metric: this.metric,
			parameters: this.parameters,
			startTime: this.startTime, 
			endTime: this.endTime
		};
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		// console.info( 'RetrieveOptimizationConversionAnalyticsRequest::handleComplete', response );
		if( response.success ) this.#data = response.result;
	}
 
	//

	/**
	 * @return The event counts for the Channel
	 */
	getData(){
		return this.#data;
	}
 
}

export default RetrieveOptimizationConversionAnalyticsRequest;
