/*
 * (c) Verra Technology Corporation
 */

/**
 * Represents the ways in which Verra will select content for a Channel
 */
const ChannelContentSelectionTypes = {

	/** 
	 * The control is selected. For predictive Channels, if no control is assigned, Verra selects content randomly
	 */
	CONTROL: 0,

	/**
	 * Content is selected. For A/B Channels, content is randomly selected at an equal distribution. For predictive, the ML model is used to select content.
	 */
	CONTENT: 1,

};

export default ChannelContentSelectionTypes;