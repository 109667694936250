/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SphereAdminSession from '../model/SphereAdminSession';
import SetStateCommand from './SetStateCommand';

/**
 * Initiates the view to locate an element on a site
 */
class LocateElementCommand extends Command {
	
	/**
	 * The ID of the site in which to locate an element. If null, the user will be prompted to select a site
	 */
	#siteId;

	//

	/**
	 * Constructs the Command
	 * @param siteId The ID of the site in which to locate an element. If null, the user will be prompted to select a site.
	 */
	constructor( siteId ) {
		super();
		this.#siteId = siteId;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		SphereAdminSession.siteId = this.#siteId;
		const setState = new SetStateCommand( AdminStates.ADMIN_OPTIMIZATIONS_LOCATE_ELEMENT, null, false, false );
		setState.execute();
	}
	
}

//

export default LocateElementCommand;
