/*
 * (c) Verra Technology Corporation
 */

/**
 * Represents status' that are used to map to visual states
 */
const ObjectStatusMap = [

	'changed', // created
	'changed', // modified
	'active', // saved
	'published',
	'locked',
	'complete'

];

export default ObjectStatusMap;