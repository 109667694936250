/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import SphereAdminSession from '../model/SphereAdminSession';
import OptimizationEditor from '../components/editors/OptimizationEditor';
import OptimizationFactory from '../managers/OptimizationFactory';

//

/**
 * The CreateOptimizationPanel contains the administration UI for ceating a new Optimization
 */
class CreateOptimizationPanel extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();
		SphereAdminSession.optimization = OptimizationFactory.createOptimization();
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return <OptimizationEditor/>;
	}

}

//

export default CreateOptimizationPanel;
