/*
 * (c) Verra Technology Corporation
 */

/**
 * Represents the available model types for a Channel
 * TOOD: there is a ContentModel object that is similar in the services layer
 */
const ChannelModelTypes = {

	//'Static',
	//'Random',
	//'Weighted Random',
	
	/** 
	 * Standard, static AB test
	 */
	AB: 1,

	/**
	 * Static, personalized experience
	 */
	PERSONALIZATION: 2,

	/**
	 * Predictive model using ML to determine experience
	 */
	PREDICTIVE: 4

};

export default ChannelModelTypes;