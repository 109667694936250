/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import {
	createContainer,
	VictoryArea,
	VictoryAxis,
	VictoryChart,
	VictoryStack,
	VictoryTooltip
} from 'victory';
import MetricEventTypes from '../../model/MetricEventTypes';
import FormatUtil from '../../util/FormatUtil';
import SetStateCommand from '../commands/SetStateCommand';
import ChartsTheme from '../components/charts/ChartsTheme';
import DropDownField from '../components/controls/DropDownField';
import Loader from '../components/controls/Loader';
import LargePencilIcon from '../icons/LargePencilIcon';
import LayersIcon from '../icons/LayersIcon';
import PerformanceIcon from '../icons/PerformanceIcon';
import UserIcon from '../icons/UserIcon';
import AdminStates from '../model/AdminStates';
import ChartTimeValues from '../model/ChartTimeValues';
import RetrieveEventAnalyticsRequest from '../requests/analytics/RetrieveEventAnalyticsRequest';

//

/**
 * The currently selected time period
 */
let selectedTimePeriod = ChartTimeValues.availableRanges[ 6 ];

/**
 * The dashboard analytics
 */
let analytics;

//

/**
 * The administration dashboard
 */
class DashboardPanel extends Component {
	
	/**
	 * Constructs the Component
	 */
	constructor(){
		super();
		const engagementMetricTypes = MetricEventTypes.engagementTypes;
		const conversionMetricTypes = MetricEventTypes.conversionTypes;
		this.state = {
			loadingEvents: true,
			engagementMetricTypes,
			conversionMetricTypes,
			selectedEngagementMetric: engagementMetricTypes[ 0 ],
			selectedConversionMetric: conversionMetricTypes[ 0 ]
		};
		this.#retrieveEventAnalytics();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render(){

		return ( 
			<div className='content-panel'>
				<h2>Dashboard</h2>
				{ this.#getAnalyticsMarkup() }
				{/* { this.#getChartsMarkup() } */}
				{ this.#getTasksMarkup() }
					{/*
					<div className='grid-cell default-100'>
						<div className='panel-cell'>
							<h3>Getting Started</h3>
							<p>Welcome to Verra! If you're new to the platform we suggest getting started with the following videos.</p>
							<iframe 
								src="https://www.youtube.com/embed/bhiR4A4zMLg" 
								title="Introduction to Verra" 
								frameBorder="0" 
								allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
								allowFullScreen>
							</iframe>
						</div>
					</div>
					
					*/}
			</div>
		);
	}

	/**
	 * @return the markup for general analytics for the site
	 */
	#getAnalyticsMarkup() {
		const panelStyle = { flexBasis: 0, flexGrow: 1 };
		return (
			<div>
				<div style={{ textAlign: 'right' }}>
					<DropDownField
						className='padless pad-cell-left' 
						style={{ display: 'inline-block' }}
						itemsWidth='150px' 
						hideBackground={ true } 
						labelAlignRight={ true } 
						alignRight={ true }
						items={ ChartTimeValues.availableRanges }
						labelField='label'
						selectedItem={ selectedTimePeriod }
						changeHandler={ this.#handleDateRangeChange.bind( this ) }/>
				</div>
				<div className='performance-metrics' style={{ display: 'flex', alignItems: 'stretch', gap: 13 }}>
					{ this.#getImpressionsPanelMarkup() }
					{ this.#getConversionsPanelMarkup() }
					{ this.#getConversionRatePanelMarkup() }
				</div>
			</div>
		);
	}

	/**
	 * @return the markup for the engagement analytics
	 */
	#getImpressionsPanelMarkup() {
		let panel;
		if( this.state.loadingEvents ){
			panel = <div className='panel-cell info-panel loader'>
				<Loader/>
			</div>;
		} else {
			const sessions = this.#getEventAnalytics( 'page-load', 'sessions' );
			const users = this.#getEventAnalytics( '__users', 'count' );
			panel = <div className='panel-cell info-panel'>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h3>Engagement</h3>
					{/* <div>
						<DropDownField
							className='padless'
							style={{ display: 'inline-block' }}
							itemsWidth='250px'
							hideBackground={ true }
							labelAlignRight={ true }
							alignRight={ true }
							items={ this.state.engagementMetricTypes }
							labelField='name'
							selectedItem={ this.state.selectedEngagementMetric }
							changeHandler={ this.#handlEngagementMetricSelected.bind( this )}/>
					</div> */}
				</div>
				<div className='info-block' style={{ color: 'rgb(244, 129, 197)' }}>
					<span className='info-stat'>{ FormatUtil.roundMetric( sessions )}</span>
					<span className='info-stat-label'> Sessions</span>
					<p>There were { sessions } sessions over the { selectedTimePeriod.label.toLowerCase() }</p>
				</div>
				<div className='info-block' style={{ color: 'rgb(236, 25, 136)' }}>
					<span className='info-detail'>{ FormatUtil.roundMetric( users )}</span>
					<span className='info-stat-label'> Customers</span>
					<p>There were { users } unique customers over the { selectedTimePeriod.label.toLowerCase() }</p>
				</div>
			</div>;
		}
		return panel;
	}

	/**
	 * @return the markup for the conversion analytics
	 */
	#getConversionsPanelMarkup() {
		let panel;
		if( this.state.loadingEvents ){
			panel = <div className='panel-cell info-panel loader'>
				<Loader/>
			</div>;
		} else {
			const views = this.#getEventAnalytics( this.state.selectedConversionMetric.eventId, 'count' );
			const sessions = this.#getEventAnalytics( this.state.selectedConversionMetric.eventId, 'sessions' );
			panel = <div className='panel-cell info-panel'>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h3>Conversion</h3>
					<div>
						<DropDownField
							className='padless pad-cell-left'
							style={{ display: 'inline-block' }}
							itemsWidth='250px'
							hideBackground={ true }
							labelAlignRight={ true }
							alignRight={ true }
							items={ this.state.conversionMetricTypes }
							labelField='name'
							selectedItem={ this.state.selectedConversionMetric }
							changeHandler={ this.#handlConversionMetricSelected.bind( this )}/>
					</div>
				</div>
				<div className='info-block' style={{ color: 'rgb(167, 255, 240)' }}>
					<span className='info-stat'>{ FormatUtil.roundMetric( sessions )}</span>
					<span className='info-stat-label'> Sessions</span>
					<p>There were { sessions } sessions with { this.state.selectedConversionMetric.name } events over the { selectedTimePeriod.label.toLowerCase() }</p>
				</div>
				<div className='info-block' style={{ color: 'rgb(0, 240, 199)' }}>
					<span className='info-detail'>{ FormatUtil.roundMetric( views )}</span>
					<span className='info-stat-label'> { this.state.selectedConversionMetric.name }</span>
					<p>There were { views } total { this.state.selectedConversionMetric.name } events over the { selectedTimePeriod.label.toLowerCase() }</p>
				</div>
			</div>;
		}
		return panel;
	}

	/**
	 * @return the markup for the impressions metrics
	 */
	#getConversionRatePanelMarkup() {
		let panel;
		if( this.state.loadingEvents ){
			panel = <div className='panel-cell info-panel loader'>
				<Loader/>
			</div>;
		} else {
			const engagements = this.#getEventAnalytics( 'page-load', 'sessions' );
			const conversions = this.#getEventAnalytics( this.state.selectedConversionMetric.eventId, 'sessions' );
			const cvr = FormatUtil.roundMetric(( engagements > 0 ) ? conversions / engagements * 100 : 0 );
			panel = <div className='panel-cell info-panel'>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h3>Conversion Rate</h3>
				</div>
				<div className='info-block' style={{ color: 'rgb(175, 143, 255)' }}>
					<span className='info-stat'>{ cvr }%</span>
					<span className='info-stat-label'> Conversion Rate</span>
					<p></p>
				</div>
				<div className='info-block' style={{ color: 'rgb(0, 240, 199)' }}>
					<span className='info-detail'></span>
					<span className='info-stat-label'></span>
					<p></p>
				</div>
			</div>;
		}
		return panel;
	}

	/**
	 * @return the markup for display charts
	 */
	#getChartsMarkup() {

		// const chartStyle = this.state.selectedChartStyle;
		// const fillOpacity = ( chartStyle === chartStyles.STACKED ) ? 1 : 0;
		const theme = ChartsTheme;
		const fontSize = 3.5;
		const controlColor = '#555555';
		const Container = createContainer( "voronoi");

		let d = new Date();

		const getDate = function() {
			console.info( d );
			d.setDate( d.getDate() - 1 );
			return new Date( d );
		};


		const events = {
			'add-to-cart': [
				{ x: getDate(), y: 1 },
				{ x: getDate(), y: 3 },
				{ x: getDate(), y: 6 },
				{ x: getDate(), y: 2 },
				{ x: getDate(), y: 8 },
				{ x: getDate(), y: 2 },
			],
			'page-load': [
				{ x: getDate(), y: 5 },
				{ x: getDate(), y: 14 },
				{ x: getDate(), y: 10 },
				{ x: getDate(), y: 12 },
				{ x: getDate(), y: 9 },
				{ x: getDate(), y: 8 },
			],
			
		}

		const areaCharts = [];
		Object.keys( events ).forEach(( eventId, index ) => {
			// let content = contentIdMap[ contentId ];
				const chart = <VictoryArea
								key={ index }
								data={ events[ eventId ] }
								style={{ data: { fill: ( index === 0 ) ? '#00f0c7' : '#ec1988' }}}
								// style={{ data: { stroke: content.color, strokeWidth: 0.4, fill: content.color, fillOpacity }}}
							/>;
				areaCharts.push( chart );
			// }
		});

		const chartsMarkup = <VictoryStack>{ areaCharts }</VictoryStack>;

		return (
			<div style={{ display: 'flex', gap: 13 }}>
				<div className='panel-cell' style={{ flexBasis: 0, flexGrow: 1 }}>
					<VictoryChart
						theme={theme}
						width={200}
						height={80}
						padding={{ top: 5, right: 5, bottom: 15, left: 15 }}
						scale={{ x: "time" }}
						//domain={{ x: [ this.startDate, this.endDate ]}}
						containerComponent={
							<Container
								zoomDimension="x"
								zoomDomain={this.state.zoomDomain}
								// onZoomDomainChange={this.#handleZoom.bind( this )}
								cursorLabel={({ datum }) => `${Math.round( datum.y )}`}
								labels={({ datum }) => { return `${ datum.y }`;}}
								labelComponent={
									<VictoryTooltip
										renderInPortal={true}
										cornerRadius={2}
										pointerWidth={2}
										pointerLength={4}
										text={({ datum }) => {
											// let contentLabel = ( datum.control ) ? 'Control' : contentIdMap?.[ datum.contentId ]?.name;
											let label = datum.y + ' - ' + datum.x.toLocaleString( 'en-US' );
											return label;
										}}
										style={{
											fontSize,
											// fill: 'white'
											// fill: ({ datum }) =>  contentIdMap[ datum.contentId ].color
										}}
										flyoutPadding={3}
										flyoutStyle={{
											// stroke: ({ datum }) => ( datum.control ) ? controlColor : contentIdMap?.[ datum.contentId ]?.color,
											strokeWidth: 0.3
											// fill:
										}}
									/>
								}
							/>}
						>
						<VictoryAxis dependentAxis style={{tickLabels: { fontSize }, axisLabel: { fontSize }}} />
						<VictoryAxis crossAxis style={{tickLabels: { fontSize }, axisLabel: { fontSize }}} />
						{ chartsMarkup }
					</VictoryChart>
				</div>
				<div className='panel-cell' style={{ flexBasis: 0, flexGrow: 1 }}></div>
			</div>
		);
	}

	/**
	 * @return the markup for common tasks
	 */
	#getTasksMarkup() {
		return (
				<div style={{ display: 'flex', gap: 13 }}>
					<div className='panel-cell' style={{ flexBasis: 0, flexGrow: 1 }}>
						<h3>Common Tasks</h3>
						<div style={{ display: 'flex', gap: 13, alignItems: 'flex-end', justifyContent: 'center' }}>
							<button className='dashboard-task-icon' onClick={() => this.#handleTask( AdminStates.ADMIN_OPTIMIZATIONS_CREATE )}>
								<div className='icon'>
									<LargePencilIcon size={ 46 }/>
								</div>
								<div className='label'>Create Optimization</div>
							</button>
							<button className='dashboard-task-icon' onClick={() => this.#handleTask( AdminStates.ADMIN_OPTIMIZATIONS )}>
								<div className='icon'>
									<PerformanceIcon size={ 54 }/>
								</div>
								<div className='label'>View Optimizations</div>
							</button>
							<button className='dashboard-task-icon pad-control-left' onClick={() => this.#handleTask( AdminStates.ADMIN_EXPERIENCES_CREATE )}>
								<div className='icon'>
									<LayersIcon size={ 46 }/>
								</div>
								<div className='label'>Create Experience</div>
							</button>
							<button className='dashboard-task-icon pad-control-left' onClick={() => this.#handleTask( AdminStates.ADMIN_AUDIENCES )}>
								<div className='icon'>
									<UserIcon size={ 54 }/>
								</div>
								<div className='label'>Manage Audiences</div>
							</button>
						</div>
					</div>
					<div className='panel-cell' style={{ flexBasis: 0, flexGrow: 1 }}>
						<h3>Other Tasks</h3>
							<ul>
								<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_EXPERIENCES )}>View Experiences</button></li>
								<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_AUDIENCES )}>View Audiences</button></li>
								<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_AUDIENCES_CREATE )}>Create Audience</button></li>
								<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_METRICS )}>View Metrics</button></li>
								<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_METRICS_CREATE )}>Create Metric</button></li>
								<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_CONFIGURATION_SITES )}>Manage Sites</button></li>
							</ul>
					</div>
				</div>
		);
	}

	//

	/**
	 * Retrieves event analytics
	 */
	#retrieveEventAnalytics() {
		const { startDate, endDate } = ChartTimeValues.getDateRange( selectedTimePeriod.value );
		const startTime = startDate.getTime();
		const endTime = endDate.getTime();
		const retrieveImpressions = new RetrieveEventAnalyticsRequest( startTime, endTime );
		retrieveImpressions.execute( command => this.#handleRetrieveAnalyticsComplete( command ));
		this.setState({ loadingEvents: true });
	}

	/**
	 * Retrieves event analytics
	 */
	#handleRetrieveAnalyticsComplete( command ) {
		analytics = command.getData();
		this.setState({ loadingEvents: false });
	}

	//

	/**
	 * @return The metric for the specified event or zero if none exists
	 */
	#getEventAnalytics( eventId, metric ) {
		const count = analytics?.[ eventId ]?.[ metric ];
		return ( count != null ) ? count : 0;;
	}

	/**
	 * @return The metric for the specified event or zero if none exists
	 */
	#getConversionAnalytics( metric ){
		// const eventMetric = this.state.analytics.getConversionEventMetric( this.state.selectedMetric.id );
		// return ( eventMetric != null ) ? eventMetric[ metric ] : 0;;
	}

	//	

	/**
	 * Handles a change to the selected engagement event
	 */
	#handlEngagementMetricSelected( metric ){
		this.setState({ selectedEngagementMetric: metric });
	}

	/**
	 * Handles a change to the selected conversion event
	 */
	#handlConversionMetricSelected( metric ){
		this.setState({ selectedConversionMetric: metric })
	}

	/**
	 * Handles changes to the date range selection
	 */
	#handleDateRangeChange( selectedItem ) {
		selectedTimePeriod = selectedItem;
		this.#retrieveEventAnalytics();
	}

	/**
	 * Handles clicks on the tasks buttons / links
	 */
	#handleTask( state ){
		var setState = new SetStateCommand( state );
		setState.execute();
	}

}

export default DashboardPanel;
