import React from 'react';

const UserIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 52;
	return (
		<svg viewBox="0 -960 960 960" height={ size } width={ size }  fill="#ffffff">
			<path d="M71.93-187.69v-88.93q0-30.92 15.96-55.19 15.96-24.27 42.63-37.76 57.02-27.89 114.67-43.01 57.66-15.11 126.73-15.11 69.08 0 126.73 15.11 57.66 15.12 114.68 43.01 26.67 13.49 42.63 37.76 15.96 24.27 15.96 55.19v88.93H71.93Zm679.99 0v-93.85q0-39.38-19.28-75.07-19.29-35.68-54.72-61.23 40.23 6 76.39 18.57 36.15 12.58 69 29.73 31 16.54 47.88 38.99 16.88 22.44 16.88 49.01v93.85H751.92Zm-380-304.62q-57.75 0-98.87-41.12-41.12-41.13-41.12-98.88 0-57.75 41.12-98.87 41.12-41.13 98.87-41.13 57.75 0 98.88 41.13 41.12 41.12 41.12 98.87 0 57.75-41.12 98.88-41.13 41.12-98.88 41.12Zm345.38-140q0 57.75-41.12 98.88-41.12 41.12-98.87 41.12-6.77 0-17.23-1.54-10.47-1.54-17.23-3.38 23.66-28.45 36.37-63.12 12.7-34.67 12.7-72 0-37.34-12.96-71.73-12.96-34.38-36.11-63.3 8.61-3.08 17.23-4 8.61-.93 17.23-.93 57.75 0 98.87 41.13 41.12 41.12 41.12 98.87ZM131.92-247.69h480v-28.93q0-12.53-6.27-22.3-6.26-9.77-19.88-17.08-49.38-25.46-101.69-38.58-52.31-13.11-112.16-13.11-59.84 0-112.15 13.11-52.31 13.12-101.69 38.58-13.62 7.31-19.89 17.08-6.27 9.77-6.27 22.3v28.93Zm240-304.62q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0 304.62Zm0-384.62Z"/>
		</svg>
		// <svg x="0px" y="0px" viewBox="0 0 48 48" height={size} width={size} >
		// 	<g className="nc-icon-wrapper" strokeLinecap="square" strokeLinejoin="miter" strokeWidth="3" stroke="#ffffff">
		// 		<path fill="none" stroke="#ffffff" strokeMiterlimit="10" d="M24,24L24,24 c-5.523,0-10-4.477-10-10v-2c0-5.523,4.477-10,10-10h0c5.523,0,10,4.477,10,10v2C34,19.523,29.523,24,24,24z"/>
		// 		<path data-color="color-2" fill="none" strokeMiterlimit="10" d="M44,38.856 c0-3.101-2.034-5.828-5.007-6.712C35.477,31.098,30.288,30,24,30s-11.477,1.098-14.993,2.144C6.034,33.028,4,35.755,4,38.856V46h40 V38.856z"/>
		// 	</g>
		// </svg>
	);
};

export default UserIcon;