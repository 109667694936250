/*
 * (c) Verra Technology Corporation
 */

import AsynchronousCommand from '../../commands/AsynchronousCommand.mjs';
import UpSellModal from '../components/modals/UpSellModal';
import PermissionsManager from '../managers/PermissionsManager';
import Permissions from '../model/Permissions';
import SphereAdminSession from '../model/SphereAdminSession';
import PublishOptimizationRequest from '../requests/optimizations/PublishOptimizationRequest';
import OpenModalCommand from './OpenModalCommand';

/**
 * Publishes an Optimization
 */
class PublishOptimizationCommand extends AsynchronousCommand {
	
	#success;

	//

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const hasPermission = PermissionsManager.hasPermissionForMore( Permissions.OPTIMIZATIONS_PUBLISH_MAX );
		if( hasPermission.allowed ) {
			const publish = new PublishOptimizationRequest( SphereAdminSession.optimization );
			publish.execute( this.#handlePublishComplete.bind( this ));
		} else {
			const msg = <p style={{ margin: 0 }}>You've reached your maximum number of published Optimizations. Your account only allows { hasPermission.permitted }.</p>;
			const upSell = <UpSellModal message={ msg } callout='published_optimizations'/>;
			const openModal = new OpenModalCommand(  'Limit Reached', upSell, '600px', true );
			openModal.execute();

			this.#success = false;
			this.complete();
		}
	}
	
	/**
	 * Handles completion of the publish optimization command
	 */
	#handlePublishComplete( command ) {
		this.#success = command.getResponse().success;
		this.complete();
	}

	//

	/**
	 * @return whether or not the command was successful
	 */
	getSuccess() {
		return this.#success;
	}

}

//

export default PublishOptimizationCommand;
