/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

/**
 * InputField Component
 */
class InputField extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor( props ) {
		super();
		this.input = React.createRef();
		var value = ( props.value === undefined ) ? null : props.value;
		var disabled = ( props.disabled === undefined ) ? false : props.disabled;
		this.state = { value: value, valid: true, disabled: disabled, hasLostFocus: false };
	}

	/**
	 * Handles the update to props
	 */
	componentWillReceiveProps( nextProps ) {
		if( nextProps.value !== this.props.value ) {
			this.setState({ value: nextProps.value })
		}
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {

		// TODO: add required attribute
		
		let className = ( this.props.className != null ) ? this.props.className : '';
		if( !this.state.valid || this.props.invalid ){
			className += ' invalid';
		} else if( this.props.disabled ){
			// className += ' disabled';
		}

		if( this.state.hasLostFocus ) className += ' has-lost-focus';

		const type = ( this.props.type !== undefined ) ? this.props.type : 'text';
		let input; 
		if( this.state.value != null ) {
			input = <input 
						ref={this.input}
						type={type}
						className={className}
						id={this.props.id} 
						placeholder={this.props.placeholder} 
						value={ this.state.value } 
						maxLength={this.props.maxLength} 
						pattern={this.props.pattern} 
						disabled={this.props.disabled}
						readOnly={this.props.readOnly}
						autoComplete={this.props.autoComplete} 
						onChange={this.#handleChange.bind( this )}
						onBlur={this.#handleBlur.bind( this )}
						style={this.props.style}/>;
		} else {
			input = <input 
						ref={this.input}
						type={type}
						className={className}
						id={this.props.id} 
						placeholder={this.props.placeholder} 
						defaultValue={this.props.defaultValue} 
						maxLength={this.props.maxLength} 
						pattern={this.props.pattern} 
						disabled={this.props.disabled}
						readOnly={this.props.readOnly}
						autoComplete={this.props.autoComplete} 
						onChange={this.#handleChange.bind( this )}
						onBlur={this.#handleBlur.bind( this )}
						style={this.props.style}/>;
		}

		return input;
	}

	/**
	 * Updates the validation state of the field
	 */
	updateValidationState() {
		this.input.current.checkValidity();
	}

	/**
	 * Gets the value of the field
	 */
	getValue() {
		return this.input.current.value;
	}

	// Private

	/**
	 * Handles changes to the input field
	 */
	#handleChange( e ) {
		var isValid = e.currentTarget.checkValidity();
		var state = { value: e.currentTarget.value };
		if( this.state.hasLostFocus ) state.valid = isValid;
		this.setState( state );
		if( this.props.onChange != null ) this.props.onChange( e.currentTarget.value );
	};

	/**
	 * Handles changes to the input field
	 */
	#handleBlur( e ) {
		var isValid = e.currentTarget.checkValidity();
		this.setState({ valid: isValid, hasLostFocus: true });
		if( this.props.onBlur != null ) this.props.onBlur( e.currentTarget.value );
	};

}

//

export default InputField;
