/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import UpSellModal from '../components/modals/UpSellModal';
import PermissionsManager from '../managers/PermissionsManager';
import AdminStates from '../model/AdminStates';
import Permissions from '../model/Permissions';
import OpenModalCommand from './OpenModalCommand';
import SetStateCommand from './SetStateCommand';

/**
 * Initiates the view to create an Optimization
 */
class CreateOptimizationCommand extends Command {
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const hasPermission = PermissionsManager.hasPermissionForMore( Permissions.OPTIMIZATIONS_MAX );
		// console.info( hasPermission );
		if( hasPermission.allowed ) {
			const setState = new SetStateCommand( AdminStates.ADMIN_OPTIMIZATIONS_CREATE );
			setState.execute();
		} else {
			const msg = <p style={{ margin: 0 }}>You've reached your maximum number of allowed Optimizations. Your account only allows { hasPermission.permitted }.</p>;
			const upSell = <UpSellModal message={ msg } callout='optimizations'/>;
			const openModal = new OpenModalCommand(  'Limit Reached', upSell, '600px', true );
			openModal.execute();
		}
	}
	
}

//

export default CreateOptimizationCommand;
