/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import AddIcon from '../../icons/AddIcon';
import DropDownField from '../controls/DropDownField';
import CSSProperties from '../../model/CSSProperties';

/**
 * StylePropertyPicker Component
 */
class StylePropertyPicker extends Component {
	
	/**
	 * Constructs the component
	 */
	constructor() {
		super();
		this.state = {
			pickingProperty: false
		};
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return ( this.state.pickingProperty ) ? this.#getPropertyPickerMarkup() : this.#getAddButtonMarkup();
	}

	/**
	 * @return the markup for displaying the add button
	 */
	#getAddButtonMarkup() {
		return 	<button className='style-property-picker' onClick={ this.#handleAddNewStyleProperty.bind( this )}>
					{ this.props.showLabel && 
						<span>Add Style</span>
					}
					<AddIcon size='26'/>
				</button>;
	}

	/**
	 * @return the markup for displaying CSS property drop down
	 */
	#getPropertyPickerMarkup() {
		return <DropDownField 
					items={ Object.keys( CSSProperties ) } 
					label='Choose a Property'
					style={{ width: '100%' }}
					changeHandler={ this.#handlePropertyPicked.bind( this )}/>;
	}

	/**
	 * Handles the click on the add button
	 */
	#handleAddNewStyleProperty() {
		this.setState({ pickingProperty: true });
	}
	
	/**
	 * Handles the click on the add button
	 */
	#handlePropertyPicked( value ) {
		this.props.selectHandler( value );
		this.setState({ pickingProperty: false });
	}
	
}

export default StylePropertyPicker;
