import React from 'react';

const HtmlIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg height={size} width={size} viewBox="0 -960 960 960" >
			<g className='nc-icon-wrapper' stroke='none' fill={color}>
				<path d="M0-354v-252h59v89h97v-89h59v252h-59v-103H59v103H0Zm316 0v-192h-70v-60h199v60h-70v192h-59Zm160 0v-212.157q0-18.243 10.8-29.043T516-606h200q17.975 0 28.487 10.8Q755-584.4 755-566.157V-354h-59v-192h-56v150h-48v-150h-57v192h-59Zm320 0v-252h60v192h104v60H796Z"/>
			</g>
		</svg>
	);
};

export default HtmlIcon;