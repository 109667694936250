/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import OnboardingModal from '../components/modals/OnboardingModal';
import OpenModalCommand from './OpenModalCommand';

/**
 * Command used to open the onboarding modal
 */
class ViewOnboardingWizardCommand extends Command {
	
	/**
	 * Constructs the command.
	 */
	constructor(){
		super();
	}
	
	/**
	 * Handles execution of the command
	 */
	handleExecute(){
		const modal = <OnboardingModal/>;
		const openModal = new OpenModalCommand( null, modal, '800px', false );
		openModal.execute();
	}
	
}

export default ViewOnboardingWizardCommand;
