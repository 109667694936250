/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';
import CloseModalCommand from '../../commands/CloseModalCommand';
import DropDownField from '../controls/DropDownField';
import SphereAdminSession from '../../model/SphereAdminSession';

//

/**
 * Modal used to select a Site
 */
class SelectSiteModal extends Component {

	/**
	'* Constructs the Component
	*/
	constructor( props ) {
		super( props );
		this.state = { selectedSite: null };
	}
	
	/**
	* Renders the component
	* @see react docs
	*/
	render() {
		const siteSelected = ( this.state.selectedSite != null );
		const selectDisabled = ( siteSelected ) ? '' : 'disabled';
		return (
			<div className='dialog'>
				<div style={{ marginBottom: 18 }}>Choose site for which the Experience will apply</div>
				<DropDownField 
					label="Select Site"
					labelField='name' 
					items={ SphereAdminSession.sites }
					changeHandler={ this.#handleSiteSelected.bind( this )}/>
				<div className='grid-cell default-100' style={{ padding: '20px 0 0 0', textAlign: 'center' }}>
					<button className={'primary-button ' + selectDisabled } disabled={ !siteSelected } style={{ width: '90px' }} onClick={ this.#handleSelect.bind( this )}>Select</button>
					<button className='button control-pad-left' style={{ width: '90px' }} onClick={ this.#handleCancel.bind( this )}>Cancel</button>
				</div>
			</div>
			
		);
	}

	/**
	 * Handles a selection of a site from the Sites drop down
	 */
	#handleSiteSelected( site ) {
		this.setState({ selectedSite: site });
	}

	/**
	 * Closes the Predictive Attribute editor modal
	 */
	#handleSelect( e ) {
		this.props.selectHandler( this.state.selectedSite );
		const closeModal = new CloseModalCommand();
		closeModal.execute();
	}

	/**
	 * Closes the Modal
	 */
	#handleCancel( e ) {
		this.props.cancelHandler();
		const closeModal = new CloseModalCommand();
		closeModal.execute();
	}

}

export default SelectSiteModal;