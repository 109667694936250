/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import OpenModalCommand from '../commands/OpenModalCommand';
import CreateEventTypeModal from '../components/modals/CreateEventTypeModal';
import DataGrid from '../components/controls/DataGrid';
import SphereAdminSession from '../model/SphereAdminSession';
import Alert from '../components/controls/Alert';
import DeleteEventTypesRequest from '../requests/events/DeleteEventTypesRequest';
import DropDownField from '../components/controls/DropDownField';

//

/**
 * The EventsPanel contains the administration UI for managing Events
 */
class EventTypesPanel extends Component {
	
	/**
	 * Constructs the ChannelPanel.
	 */
	 constructor() {
		super();
		
		this.state = {
			typesSelected: false
		};

		this.typesGrid = React.createRef();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		const eventTypes = SphereAdminSession.eventTypes; 
		const deleteDisabled = ( this.state.typesSelected ) ? '' : ' disabled';
		const emptyEventsMarkup = <div>You do not have any Events Types. <button className='link-button' onClick={this.#handleCreateEventType.bind( this )}>Create Event Type</button></div>;
		
		const headers = [ 
			{label: 'Name', field: 'name', width: '45'},
			{label: 'ID', field: 'id', width: '50'}
		];

		const customEvents = [];
		const standardEvents = [];
		eventTypes.forEach(( event ) => {
			( event.readOnly ) ? standardEvents.push( event ) : customEvents.push( event );
		});
		
		return ( 
			<div className='events-panel'>
				<h2>Event Types</h2>
				<div>
					<h3 className='pad-cell-top'>System Events</h3>
					<div className='pad-cell-top'>
						<DataGrid
							headers={ headers } 
							data={ standardEvents } 
							idField='id' 
							statusField='status'
							noContent={ emptyEventsMarkup }/>
					</div>
				</div>
				<div className='grid pad-cell-top'>
					<div className='grid-cell'>
						{/* <div className='button' onClick={this.#handleCreateEventType.bind( this )}>Create</div> */}
						<DropDownField
							width='150px'
							label='Create Event'
							items={ [ 'Page View', 'Element View', 'Click', 'Custom' ] }
							changeHandler={( combinator ) => { console.info( 'test' ); }}/>
					</div>
					<div className='grid-cell pad-cell-left'>
						<button className={'button' + deleteDisabled} onClick={this.#handleDelete.bind( this )}>Delete</button>
					</div>
				</div>
				<div className='pad-cell-top'>
					<DataGrid
						ref={ this.typesGrid }
						headers={ headers } 
						data={ customEvents } 
						idField='id' 
						checkColumn={ true } 
						checkOnRowClick={ true }
						statusField='status'
						noContent={ emptyEventsMarkup }
						checkBoxChangeHandler={this.#handleGridCheckBoxSelect.bind( this )}/>
				</div>
			</div>
		);
	}

	// Private

	/**
	 * Handles the click to create a new Event
	 */
	#handleCreateEventType( e ){
		var content = <CreateEventTypeModal saveHandler={this.#handleEventTypeSaved.bind( this )}/>;
		var openModal = new OpenModalCommand( 'Create Event', content, '300px', false );
		openModal.execute();	
	}

	/**
	 * Handles the save event from the CreateEventTypeModal
	 */
	#handleEventTypeSaved( eventType ){
		this.setState({}); // force a render
	}

	/**
	 * Handles clicks on a checkbox in the Content grid
	 */
	#handleGridCheckBoxSelect( grid ){
		const selected = grid.getChecked();
		this.setState({ typesSelected: ( selected.length > 0 )});
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleDelete( e ){
		if( this.state.typesSelected ){
			const alert = <Alert content='Are you sure you want to delete these Event Types?' okHandler={ this.#handleConfirmDelete.bind( this ) }/>;
			const openModal = new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		}
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleConfirmDelete( e ){
		SphereAdminSession.loading = true;
		var selectedTypes = this.typesGrid.current.getChecked();
		var typeIds = selectedTypes.map( channel => channel.id );
		var deleteTypes = new DeleteEventTypesRequest( typeIds );
		deleteTypes.execute( command  => this.#handleDeleteComplete( command ));
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleDeleteComplete( command ){
		SphereAdminSession.loading = false;
		var typesInUse = command.getTypesInUse();
		if( typesInUse.length > 0 ){
			const inUseMarkup = typesInUse.map( type => <li key={type.eventTypeId + type.channelId}>{type.eventTypeId} is used by the Channel <a href={'/channels/edit/' + type.channelId + '/'}>{type.channelId}</a></li> );
			const alertContent = <div>The following Event Type could not be deleted because it is in use by a Channel <ul>{inUseMarkup}</ul></div>;
			const alert = <Alert content={alertContent} showCancelBtn={false}/>;
			const openModal = new OpenModalCommand( 'Cound not delete Event Type', alert, '500px', true );
			openModal.execute();
		}

		this.typesGrid.current.unCheckAll();
		var selectedTypes = this.typesGrid.current.getChecked();
		if( selectedTypes.length !== typesInUse.length ) this.setState({}); // force a refresh of the grid
	}

}

//

export default EventTypesPanel;
