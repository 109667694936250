/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import Optimization from '../../../model/Optimization';
import OptimizationModelTypes from '../../../model/OptimizationModelTypes';
import PublishableObject from '../../../model/PublishableObject.mjs';
import AddAudienceToOptimiztionCommand from '../../commands/AddAudiencesToOptimiztionCommand';
import CreateAudienceCommand from '../../commands/CreateAudienceCommand';
import OpenModalCommand from '../../commands/OpenModalCommand';
import PencilIcon from '../../icons/PencilIcon';
import SphereAdminSession from '../../model/SphereAdminSession';
import Alert from '../controls/Alert';
import DataGrid from '../controls/DataGrid';
import Hint from '../controls/Hint';
import SelectAudienceModal from '../modals/SelectAudienceModal';
import SelectExperienceModal from '../modals/SelectExperienceModal';

//

/**
 * The TargetingTab contains the UI for setting up and managing URL rules and audiences for Optimizations
 */
class TargetingTab extends Component {
	
	/**
	 * Constructs the panel.
	 */
	 constructor() {
		super();
		this.state = { audiencesSelected: false };
		this.audiencesGridRef = React.createRef();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return (
			<div>
				{ this.#getAudiencesMarkup() }
			</div>
		);
	}

	/**
	 * @return The markup for managing Audiences
	 */
	#getAudiencesMarkup() {
		const optimization = SphereAdminSession.optimization;
		const isPublished = ( optimization.status === PublishableObject.PUBLISHED );
		const isComplete = ( optimization.status === Optimization.COMPLETE );
		const audiencesToolTip = 'Choose the audience for which this optimization will apply';
		const noAudiencesMsg = <div className='grid-cell default-100'>No audiences have been assigned to the Optimization</div>;
		const buttonsDisabled = ( optimization.locked ) ? 'disabled' : '';
		const removeDisabled = ( !optimization.locked && this.state.audiencesSelected ) ? '' : 'disabled';
		
		const headers = [{ label: 'Name', field: 'name', width: '50' }];

		if( optimization.type === OptimizationModelTypes.PERSONALIZATION ) {
			headers.push({ 
				label: 'Experience', 
				width: 40, 
				field: ( audience ) => {
					if ( audience.experienceId == null ) {
						if( isPublished || isComplete ) {
							return <span>No Experience Selected</span>;
						} else {
							return (
								<button 
									className={ 'link-button ' + buttonsDisabled } 
									onClick={ ( e ) => { e.stopPropagation(); this.#handleAddExperience( audience ); } }>Select Experience</button>
							);
						}
					} else {
						const experience = optimization.experiences.find( experience => experience.id === audience.experienceId );
						if( isPublished || isComplete ) {
							return <span>{ experience.name }</span>;
						} else {
							return (
								<div>
									<button 
										className={ 'control-button ' + buttonsDisabled } 
										style={{ marginRight: 10 }}
										onClick={ ( e ) => { e.stopPropagation(); this.#handleAddExperience( audience ); } }>
											<PencilIcon size='15'/>
									</button>
									<span>{ experience.name }</span>
								</div>
							);
						}
					}
					
				}
			});
		}

		return (
			<div>
				<div className='content-panel'>
					<div className='grid panel-cell'>
						<div className='grid-cell default-60'>
							<h3>Audiences <Hint width='250px' content={ audiencesToolTip }/></h3>
						</div>
						{ ( !isPublished && !isComplete ) && 
							<div className='grid-cell default-40 align-right'>
								<button className={ 'button ' + buttonsDisabled } onClick={ this.#handleCreateNewAudience.bind( this ) }>Create</button>
								<button className={ 'button control-pad-left ' + buttonsDisabled } onClick={ this.#handleAddAudience.bind( this ) }>Add</button>
								<button className={ 'button control-pad-left ' + removeDisabled }  onClick={ this.#handleRemoveAudience.bind( this ) }>Remove</button>
							</div>
						}
					</div>
				</div>
				<DataGrid 
					ref={ this.audiencesGridRef } 
					headers={ headers } 
					data={ optimization.audiences } 
					idField='id' 
					checkColumn={ true } 
					checkOnRowClick={ true }
					showHeaderIfEmpty={ false } 
					statusIndicator={ false } 
					disabled={ optimization.locked || isPublished || isComplete } 
					maxBodyHeight='300px'
					noContent={ noAudiencesMsg }
					checkBoxChangeHandler={ this.#handleGridCheckBoxSelect.bind( this )}
				/>
			</div>
		);
	}

	//

	/**
	 * Handles clicks on the new Channel button
	 */
	#handleCreateNewAudience( e ) {
		const createAudience = new CreateAudienceCommand( true, this.#handleAudienceCreatedAndAdded.bind( this ));
		createAudience.execute();
	};

	/**
	 * Handles a click to add an existing Audience
	 */
	#handleAddAudience() {
		const selectAudienceModal = <SelectAudienceModal selectHandler={ this.#handleAudienceSelectedForAdd.bind( this )}/>;
		const openModal = new OpenModalCommand( 'Select Audience', selectAudienceModal, '700px', false );
		openModal.execute();
	}

	/**
	 * Handles selection of Audiences in the SelectAudienceModal
	 */
	#handleAudienceCreatedAndAdded() {
		this.props.changeHandler();
		this.setState({});
	}

	/**
	 * Handles selection of Audiences in the SelectAudienceModal
	 */
	#handleAudienceSelectedForAdd( selectedAudiences ) {
		const addAudiences = new AddAudienceToOptimiztionCommand( selectedAudiences );
		addAudiences.execute();

		this.audiencesGridRef.current.unCheckAll();
		this.props.changeHandler();
		this.setState({});
	}

	/**
	 * Handles clicks on a checkbox in the Content grid
	 */
	#handleGridCheckBoxSelect( grid ) {
		const selected = grid.getChecked();
		this.setState({ audiencesSelected: ( selected.length > 0 )});
	}

	/**
	 * Handles the click to remove and audience from the optimization
	 */
	#handleRemoveAudience() {
		const selectedAudiences = this.audiencesGridRef.current.getChecked();
		const optimization = SphereAdminSession.optimization;

		optimization.audiences = optimization.audiences.filter(( audience ) => {
			const matchedAudience = selectedAudiences.find( selectedAudience => audience === selectedAudience );
			
			// if this is a pesonalization and the audience being removed have experiences assigned, we also need to remove those audiences
			if( optimization.type === OptimizationModelTypes.PERSONALIZATION && matchedAudience != null && matchedAudience.experienceId != null ) {
				optimization.experiences = optimization.experiences.filter( experience => experience.id !== matchedAudience.experienceId );
			}

			return matchedAudience == null;
		});

		this.audiencesGridRef.current.unCheckAll();
		this.props.changeHandler();
		this.setState({ audiencesSelected: false });
	}

	/**
	 * Handles a click to add an existing Experience. This is only used for Personalizations
	 */
	#handleAddExperience( audience ) {
		// const selectAudienceModal = <SelectAudienceModal multiselect={ false } selectHandler={ ( audiences ) => this.#handleAddAudienceToExperience( experience, audiences )}/>;
		// const openModal = new OpenModalCommand( 'Select Audience', selectAudienceModal, '700px', false );
		// openModal.execute();
		const selectExperienceModal = <SelectExperienceModal multiselect={ false } selectHandler={ ( experiences ) => this.#handleAddExperienceToAudience( audience, experiences )}/>;
		const openModal = new OpenModalCommand( 'Select Experience', selectExperienceModal, '700px', false );
		openModal.execute();
	}

	/**
	 * Handles selection of Audiences in the SelectAudienceModal
	 */
	#handleAddExperienceToAudience( audience, experiences ) {
		const optimization = SphereAdminSession.optimization;
		const experience = experiences[ 0 ];

		// check to see if the experience is already in use by another audience
		let experienceInUse = false;
		for( let i = 0; i < optimization.audiences.length; i++ ) {
			const audience = optimization.audiences[ i ];
			if( audience.experienceId === experience.id ) {
				experienceInUse = true;
				break;
			}
		}

		if( !experienceInUse ) {
			optimization.status = ModifiableObject.MODIFIED;

			// if there is already an experience assigned to this audience, remove that experience
			if( audience.experienceId != null ) {
				optimization.experiences = optimization.experiences.filter( experience => experience.id !== audience.experienceId );
			}

			audience.experienceId = experience.id;
			optimization.experiences.push( experience );

			this.setState({});
		} else {
			const alert = <Alert content='The Experience is already in use by another Audience' showCancelBtn={ false }/>;
			const openModal = new OpenModalCommand( 'In Use', alert, '500px', true );
			openModal.execute();
		}
	}

}

//

export default TargetingTab;
