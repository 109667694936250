/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import SphereAdminSession from '../model/SphereAdminSession';

/**
 * Command used to close the open Modal
 */
class CloseModalCommand extends Command {
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		//SphereAdminSession.adminInstance.setState({ modal: null });
		SphereAdminSession.popModal();
	}
	
}

export default CloseModalCommand;
