/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';

/**
 * Deletes Channels
 */
class DeleteChannelsRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param channelIds An array of Channel IDs to delete
	 */
	constructor( channelIds ){
		super();
		this.channelIds = channelIds;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.DELETE_CHANNELS;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id, channelIds: this.channelIds };
	}

   /**
	* Handles completion of the command
	*/
	//handleComplete() {
		// TODO: handle errors
		//var response = this.getResponse();
		//console.info( 'DeleteChannelsRequest::handleComplete', response );
	//}

}

export default DeleteChannelsRequest;
