import React from 'react';

const JavaScriptIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg height={size} width={size} viewBox="0 -960 960 960" >
			<g className='nc-icon-wrapper' stroke='none' fill={color}>
				<path d="M257-354q-12.5 0-20.25-8.042Q229-370.083 229-383v-77h59v41h78v-187h60v223q0 12.917-8.042 20.958Q409.917-354 397-354H257Zm257 0q-18.4 0-29.2-10.8T474-394v-46h60v26h92v-47H514.291q-17.691 0-28.991-11.3T474-501.362v-64.276q0-18.762 10.8-29.562T514-606h132q18.4 0 29.2 10.8T686-566v46h-60v-26h-92v47h111.709q17.691 0 28.991 11.3t11.3 29.062v64.276q0 18.762-10.8 29.562T646-354H514Z"/>
			</g>
		</svg>
	);
};

export default JavaScriptIcon;