/*
 * (c) Verra Technology Corporation
 */

/**
 * Represents metrics for a specific channel, content, and event.
 */
class ChannelContentEventMetrics {

	// Protected Members

	/**
	 * The Channel in which the metrics are for
	 */
	#channel;

	/**
	 * The Content in which the metrics are for
	 */
	#content;

	/**
	 * The event type object in which the metrics apply
	 */
	#eventType;

	// All Metrics 

	/**
	 * The number of times this event has occurred
	 */
	count = 0;

	// Conversion Metrics 

	/**
	 * The conversion rate for this event
	 */
	cvr = 0;

	/**
	 * The jstat beta
	 */
	beta;

	/**
	 * The probability that this content will be successful / win
	 */
	successProbability;

	// Place Order Specific Metrics

	/**
	 * Total revenue for the order
	 */
	revenue = 0;

	/**
	 * The average order value associated with the event.
	 */
	aov = 0;

	/**
	 * Revenue per engagement
	 */
	rpe = 0;

	/**
	 * Revenue potential calculated using cvr from the content
	 */
	rpcvr = 0;

	/**
	 * Revenue potential delta calculated using cvr
	 */
	rpcvrd = 0;

	/**
	 * Revenue potential calculated only using aov
	 */
	rpaov = 0;

	/**
	 * Revenue potential delta calculated only using aov
	 */
	rpaovd = 0;

	/**
	 * Revenue potential calculated using cvr and aov from the content
	 */
	rpcvraov = 0;

	/**
	 * Revenue potential delta calculated using cvr and aov from the content
	 */
	rpcvraovd = 0;

	//

	/**
	 * Constructs a new ChannelEventMetrics model object. 
	 * @param channel The Channel the metrics are for
	 * @param content The Content in which the metrics are for
	 * @param eventType The event type object in which the metrics apply
	 */
	constructor( channel, content, eventType ){
		this.#channel = channel;
		this.#content = content;
		this.#eventType = eventType;
	}

	//

	/**
	 * @return The content associated with the metrics
	 */
	getContent(){
		return this.#content;
	}

	//

	/**
	 * 
	 */
	buildReport( engagementEventName, isConversion, isControl ){
		const conversionEventName = ( isConversion ) ? this.#eventType.name : '';
		const objType = ( isControl ) ? 'Control' : 'Content';
		const contentName = ( isControl ) ? 'Control' : this.#content.name;
		let report = `${this.#channel.name},${objType},${engagementEventName},${conversionEventName},${contentName},${this.count},${this.cvr},${this.#getMetricAsCurrency( this.aov )},${this.#getMetricAsCurrency( this.revenue )},${this.#getMetricAsCurrency( this.rpe )},${this.#getMetricAsCurrency( this.rpcvr )},${this.#getMetricAsCurrency( this.rpcvrd )},${this.#getMetricAsCurrency( this.rpaov )},${this.#getMetricAsCurrency( this.rpaovd )},${this.#getMetricAsCurrency( this.rpcvraov )},${this.#getMetricAsCurrency( this.rpcvraovd )}\n`;
		return report;
	}

	// TODO: create util 
	#getMetricAsCurrency( metric ){
		const formatConfig = { 
			style: 'currency',
			currency: 'USD',
			maximumFractionDigits: 2, 
			minimumFractionDigits: 2 
		};
		return `"${Intl.NumberFormat( 'en-US', formatConfig ).format( metric * 0.01 )}"`;
	}
}

//

export default ChannelContentEventMetrics;