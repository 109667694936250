/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminServices from '../../model/SphereAdminServices';
import ModifiableObject from '../../../model/ModifiableObject.mjs';

/**
 * Saves Content
 */
class SaveContentRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param content The Content to save
	 */
	constructor( content ){
		super();
		this.content = content;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.SAVE_CONTENT;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { content: this.content };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		var response = this.getResponse();
		// console.info( 'SaveContentRequest::handleComplete', response );
		if( response.success ) {
			this.content.status = ModifiableObject.SAVED; // TODO: should this come from the DB?
		}
		//console.info( 'SaveContentRequest::handleComplete content', this.content );
	}
 
}

export default SaveContentRequest;
