/*
 * (c) Verra Technology Corporation
 */

import AudienceRuleOperators from "./AudienceRuleOperators";



/**
 * Represents a rule used by an Audience
 */
class AudienceRule {

	/**
	 * Defines the structure of the class. Used with ObjectUtil.CreateFromDefinition
	 */
	static definition = {
		type: AudienceRule
	}

	// Properties

	/**
	 * The ID of the account in which the AudienceRule is associated
	 */
	accountId;

	/**
	 * The id of the AudienceRule
	 */
	id;

	/**
	 * The id of the AudienceRule's group. Used to group conditionals ( a == 1 || a == 2 ) and ( b == 1 && b == 2 )
	 */
	groupId;

	/**
	 * The type ID of the AudienceRule, matches the ID of an AudienceRuleType
	 */
	typeId;

	/**
	 * Defines the position of the rule relative to other rules
	 */
	position;

	/**
	 * The name of the AudienceRule
	 */
	name = '';

	/**
	 * The name of the variable used to look up data
	 */
	variableName;

	/**
	 * The value of the AudienceRule
	 */
	value = '';

	/**
	 * The operator of the AudienceRule. Matches a value found in an AudienceRuleOperators.
	 */
	operator;

	/**
	 * Determines how this rule is combined with the previous rule.
	 */
	combinator;

}

//

export default AudienceRule;