/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import OptimizationEditor from '../components/editors/OptimizationEditor';
import SphereAdminSession from '../model/SphereAdminSession';
import RetrieveOptimizationRequest from '../requests/optimizations/RetrieveOptimizationRequest';

//

/**
 * The EditOptimizationPanel contains the administration UI for editing an Optimization
 */
class EditOptimizationPanel extends Component {

	/**
	 * Constructs the Component.
	 */
	constructor() {
		super();

		const optimizationId = SphereAdminSession.stateParameters.optimizationId;

		SphereAdminSession.loading = true;
		const retrieveOptimization = new RetrieveOptimizationRequest( optimizationId );
		retrieveOptimization.execute(( command ) => { this.#handleOptimizationRetrieved( command ); } );
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const jsx = ( SphereAdminSession.optimization != null ) ? <OptimizationEditor/> : '';
		return jsx;
	}

	//

	/**
	 * Handles the response from the request to retrieve Channel
	 */
	#handleOptimizationRetrieved( command ) {
		SphereAdminSession.loading = false;
		this.setState({}); // redraw
	};

}

//

export default EditOptimizationPanel;
