import React from 'react';

const ArrowLeftIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg width={size} height={size} viewBox='0 0 24 24'>
			<g className='nc-icon-wrapper' stroke='none' fill={color}>
				<path d='M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z'/>
			</g>
		</svg>
	);
};

export default ArrowLeftIcon;