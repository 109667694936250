/*
 * (c) Verra Technology Corporation
 */

import Channel from '../../model/Channel';
import ChannelObjectRetrievalDepth from '../../model/ChannelObjectRetrievalDepth';
import ChannelObjectReturnTypes from '../../model/ChannelObjectReturnTypes';
import ModifiableObject from '../../model/ModifiableObject.mjs';
import ChannelModelType from '../model/ChannelModelTypes';
import ChannelOptimizationTypes from '../model/ChannelOptimizationTypes';
import PredictiveAttributeTypes from '../model/PredictiveAttributeTypes';

/**
 * Factory class for creating Channels
 */
var ChannelFactory = {

	/**
	 * Creates a new, empty Channel
	 * @param accountId The account ID to use for the Channel
	 * @return Channel
	 */	
	createChannel: function( accountId ) {
		const channel = new Channel();
		channel.accountId = accountId;
		channel.id = '';
		channel.name = '';
		channel.status = ModifiableObject.CREATED;
		channel.model = ChannelModelType.PREDICTIVE; // TODO: default will need to based on account permissions, they can't create predictive models if they haven't paid for it
		channel.controlType = 0;
		channel.controlWeight = 25;
		channel.content = [];

		if( channel.model === ChannelModelType.PREDICTIVE ){
			channel.optimizationType = ChannelOptimizationTypes.CONVERSION;
			channel.engagementEventTypeId = 'view';
			channel.successEventTypeId = 'place-order';
			channel.predictiveAttributes = ChannelFactory.getDefaultPredictiveAttributes();
			channel.trainingFrequency = 24;
		}

		channel.retrievalDepth = ChannelObjectRetrievalDepth.SINGLE;
		channel.returnType = ChannelObjectReturnTypes.VALUE;

		return channel;
	},

	/**
	 * @return An array of Predictive Attributes that are setup as the default for a new Channel
	 */
	getDefaultPredictiveAttributes: function() {
		return [
			PredictiveAttributeTypes[ 0 ].items[ 0 ], // Hour
			PredictiveAttributeTypes[ 0 ].items[ 1 ], // Weekday
			// PredictiveAttributeTypes[ 0 ].items[ 2 ], // Day of Month
			// PredictiveAttributeTypes[ 0 ].items[ 3 ], // Month

			PredictiveAttributeTypes[ 1 ].items[ 0 ], // Lat and Long
			// PredictiveAttributeTypes[ 1 ].items[ 1 ], // Postal Code
			// PredictiveAttributeTypes[ 1 ].items[ 2 ], // City
			// PredictiveAttributeTypes[ 1 ].items[ 3 ], // State
			// PredictiveAttributeTypes[ 1 ].items[ 4 ], // Country
			
			// PredictiveAttributeTypes[ 2 ].items[ 0 ], // Current Page
			// PredictiveAttributeTypes[ 2 ].items[ 1 ], // Referrer
			// PredictiveAttributeTypes[ 2 ].items[ 2 ], // User ID
			// PredictiveAttributeTypes[ 2 ].items[ 3 ], // Session ID

			// PredictiveAttributeTypes[ 4 ].items[ 0 ], // Pages Viewed
		];
	},
	
	/**
	 * @return An array of Predictive Attributes that are setup as the default for a new Channel
	 */
	getDefaultEvents: function(){
		// TODO: this may need to be dependent on the model type
		return [ 
			{ id: 'view', name: 'View', direct: 1, indirect: 0, engagement: 1 },
			{ id: 'place-order', name: 'Place Order', direct: 0, indirect: 1, engagement: 0 }
		];
	}
	
};

export default ChannelFactory;
