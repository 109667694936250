/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';
import CloseModalCommand from '../../commands/CloseModalCommand';
import MetricEditor from '../editors/MetricEditor';
import SphereAdminSession from '../../model/SphereAdminSession';
import MetricFactory from '../../managers/MetricFactory';

//

/**
 * Modal used to select an Metric
 */
class CreateMetricModal extends Component {

	/**
	'* Constructs the Component
	*/
	constructor( props ) {
		super( props );
		this.metricEditor = React.createRef();
		SphereAdminSession.metric = MetricFactory.createMetric();
	}
	
	/**
	* Renders the component
	* @see react docs
	*/
	render() {
		return (
			<div className='dialog'>
				<MetricEditor ref={ this.metricEditor } modal={ true } saveHandler={ this.#handleSaveComplete.bind( this )}/>
				<div className='grid-cell default-100' style={{ padding: '20px 0 0 0', textAlign: 'center' }}>
					<div className='primary-button' style={{ width: '90px' }} onClick={ this.#handleSave.bind( this )}>Save</div>
					<div className='button control-pad-left' style={{ width: '90px'}} onClick={ this.#handleCancel.bind( this )}>Cancel</div>
				</div>
			</div>
		);
	}

	/**
	 * Saves the metric and closes the modal
	 */
	#handleSave( e ) {
		this.metricEditor.current.save();
	}

	/**
	 * Saves the metric and closes the modal
	 */
	#handleSaveComplete( metric ) {
		SphereAdminSession.optimization.metrics.push( metric );
		const closeModal = new CloseModalCommand();
		closeModal.execute();
		this.props.createdHandler();
	}

	/**
	 * Closes the Modal
	 */
	#handleCancel( e ) {
		const closeModal = new CloseModalCommand();
		closeModal.execute();
	}

}

export default CreateMetricModal;