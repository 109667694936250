import React from 'react';

const CodeIcon = function( props ) {
	const size = ( props.size != null ) ? props.size : 24;
	const color = ( props.color != null ) ? props.color : '#1EDFFD';
	return (
		<svg height={size} width={size} viewBox="0 -960 960 960" >
			<g className='nc-icon-wrapper' stroke='none' fill={color}>
				<path d="M309-218 44-482l267-267 68 67-199 199 197 197-68 68Zm340 3-67-67 199-199-197-197 67-68 265 264-267 267Z"/>
			</g>
		</svg>
	);
};

export default CodeIcon;