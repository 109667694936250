/*
 * (c) Verra Technology Corporation
 */

/**
 * Abstract Command class
 */
class AsynchronousCommand {

	/**
	 * Executes the function
	 */
	execute( callbackHandler ){
		this.callbackHandler = callbackHandler;
		this.handleExecute();
	}

	/**
	 * Completes the command
	 */
	complete(){
		this.handleComplete();
		if( this.callbackHandler != null ) this.callbackHandler( this );
	}

	/**
	 * Template method. Handles completion of the Command. Called before the callback handler
	 */
	handleComplete(){
		// override
	}
	
}

export default AsynchronousCommand;
//export { AsynchronousCommand as AsynchronousCommand };
