/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SetStateCommand from './SetStateCommand';

/**
 * Intiates the edit or create content action in the admin
 */
class EditChannelCommand extends Command {
	
	/**
	 * Constructs the command.
	 * @param channelId the ID of the Channel object to edit or null if creating new Channel
	 */
	constructor( channelId ) {
		super();
		this.channelId = channelId;
		// TODO: consider - is it weird that this command doesn't also do the retrieval of the channel?
		// I'm thinking not, the channel edit panel could be navigated to directly, in which case we still
		// need to retrieve the channel -- but ... the panel could call this command
	}
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		var setState = new SetStateCommand( AdminStates.ADMIN_CHANNELS_EDIT, { channelId: this.channelId });
		setState.execute();
	}
	
}

export default EditChannelCommand;
