/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

/**
 * NewPredictiveAttributeButton Component
 */
class NewPredictiveAttributeButton extends Component {
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		
		return ( 
			<div className='new-predictive-attribute-button'>
				<div className='icon'>+</div>
				<div className='label'>Add</div>
			</div>
		);
	}
	
}

export default NewPredictiveAttributeButton;
