/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

//

/**
 * Panel used while waiting for content or data to load
 */
class LoadingPanel extends Component {
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		
		return ( 
			<div className='loading-panel'>
				Loading
			</div>
		);
	}

}

export default LoadingPanel;
