/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SphereAdminSession from '../model/SphereAdminSession';
import SetStateCommand from './SetStateCommand';

/**
 * Loads the Experience editor to edit an Experience
 */
class EditExperienceCommand extends Command {
	
	/**
	 * Constructs the command.
	 * @param experienceId the ID of the Experience objdct ot edit
	 */
	constructor( experienceId ) {
		super();
		this.experienceId = experienceId;
	}
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		SphereAdminSession.experienceId = this.experienceId;
		var setState = new SetStateCommand( AdminStates.ADMIN_EXPERIENCES_EDIT, { experienceId: this.experienceId });
		setState.execute();
	}
	
}

export default EditExperienceCommand;
