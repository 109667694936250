/*
 * (c) Verra Technology Corporation
 */

import SphereAdminSession from "../model/SphereAdminSession";


/**
 * Manages permissions
 */
const PermissionsManager = {

	
	/**
	 * Determines if the user has a specific permission. This evaluation is a simple yes or no
	 * @param permissionId The name of the permission to check
	 */
	hasPermission( permissionId ) {
		const permissions = SphereAdminSession.selectedAccount.permissions;
		return permissions[ permissionId ] == true; // force the return of a boolean and not an int by evaluating against true
	},
	
	/**
	 * Determines if the user has permission to perform more than zero of specific action (create a site for example). 
	 * @param permissionId The name of the permission to check
	 */
	hasPermissionForAny( permissionId ) {
		const permissions = SphereAdminSession.selectedAccount.permissions;
		return permissions[ permissionId ] > 0;
	},
	
	/**
	 * Determines if the user has permission to perform more of specific action (create another site for example). 
	 * @param permissionId The name of the permission to check
	 */
	hasPermissionForMore( permissionId ) {
		const permissions = SphereAdminSession.selectedAccount.permissions;
		const counts = SphereAdminSession.selectedAccount.objectCounts;
		// console.info( 'hasPermissionForMore', permissions, counts );
		return {
			allowed: permissions[ permissionId ] > counts[ permissionId ],
			permitted: permissions[ permissionId ],
			actual: counts[ permissionId ],
		}
	},

	/**
	 * Determines if the user has permission to perform more of specific action for a given object (add rules to an audience, etc.) 
	 * @param permissionId The name of the permission to check
	 * @param items The item that are being checked
	 */
	hasPermissionForMoreItems( permissionId, items ) {
		const permissions = SphereAdminSession.selectedAccount.permissions;
		let count = 0;
		if( items instanceof Array ) {
			count = items.length;
		} else if( items instanceof Object ) {
			count = Object.keys( items ).length;
		}
		return {
			allowed: permissions[ permissionId ] > count,
			permitted: permissions[ permissionId ],
			actual: count,
		}
	},

};

export default PermissionsManager;
