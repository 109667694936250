/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminServices from '../../model/SphereAdminServices';

/**
 * Makes a request to reset a password
 */
class ResetPasswordRequest extends ServiceRequestCommand {


	/**
	 * Constructs the request
	 */
	 constructor( email, password, token ){
		super();
		this.email = email;
		this.password = password;
		this.token = token;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
	getService() {
		return SphereAdminServices.RESET_PASSWORD;
	}

	/**
	 * @return Whether or not the request requires an authenticated session. Default is true
	 */
	requiresAuth() {
		return false;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { email: this.email, password: this.password, token: this.token };
	}

	/**
	 * @return whether or not the request was successful
	 */
	getSuccess(){
		return this.getResponse().success;
	}
 
}

//

export default ResetPasswordRequest;
