/*
 * (c) Verra Technology Corporation
 */

/**
 * Constants used to identify permissions. The actual persmissions themselves are provided in the user object.
 */
const Permissions = {

	// Account Permissions	
	
	/**
	 * How many client sessions are allowed for the account
	 */
	MONTHLY_SESSIONS: 'MONTHLY_SESSIONS',

	// Sites

	/**
	 * How many development sites can be created for the account
	 */
	SITES_DEVELOPMENT_MAX: 'SITES_DEVELOPMENT_MAX',

	/**
	 * How many production sites can be created for the account
	 */
	SITES_PRODUCTION_MAX: 'SITES_PRODUCTION_MAX',

	// Experiences

	/**
	 * How many experiences can be created per account
	 */
	EXPERIENCES_MAX: 'EXPERIENCES_MAX',

	/**
	 * How many modifications per experiences can be created per account
	 */
	EXPERIENCES_MODIFICATIONS_MAX: 'EXPERIENCES_MODIFICATIONS_MAX',

	/**
	 * Whether or not images can be added when creating an experience
	 */
	EXPERIENCES_MODIFICATIONS_IMAGES: 'EXPERIENCES_MODIFICATIONS_IMAGES',

	/**
	 * Whether or not AI suggestions are allowed
	 */
	EXPERIENCES_MODIFICATIONS_SUGGEST: 'EXPERIENCES_MODIFICATIONS_SUGGEST',

	// Metrics

	/**
	 * How many metrics can be created per account
	 */
	METRICS_MAX: 'METRICS_MAX',

	// Audiences

	/**
	 * How many audiences can be created per account
	 */
	AUDIENCES_MAX: 'AUDIENCES_MAX',

	/**
	 * How many audiences can be created per account
	 */
	AUDIENCE_RULES_MAX: 'AUDIENCE_RULES_MAX',

	// Optimizations

	/**
	 * Total number of optimizations that can be created per account
	 */
	OPTIMIZATIONS_MAX: 'OPTIMIZATIONS_MAX',

	/**
	 * How many AB test optimizations can be created
	 */
	OPTIMIZATIONS_AB_MAX: 'OPTIMIZATIONS_AB_MAX',

	/**
	 * How many AB test optimizations can be created
	 */
	OPTIMIZATIONS_PERSONALIZATION_MAX: 'OPTIMIZATIONS_PERSONALIZATION_MAX',

	/**
	 * How many AB test optimizations can be created
	 */
	OPTIMIZATIONS_PREDICTIVE_MAX: 'OPTIMIZATIONS_PREDICTIVE_MAX',

	/**
	 * How many optimizations can be active (published) per account
	 */
	OPTIMIZATIONS_PUBLISH_MAX: 'OPTIMIZATIONS_PUBLISH_MAX',

	/**
	 * How many experiences can be added to an optimization
	 */
	OPTIMIZATION_EXPERIENCES_MAX: 'OPTIMIZATION_EXPERIENCES_MAX',

	/**
	 * How many audiences can be added to an optimization
	 */
	OPTIMIZATION_AUDIENCES_MAX: 'OPTIMIZATION_AUDIENCES_MAX',

	/**
	 * How many metrics can be added to an optimization
	 */
	OPTIMIZATION_METRICS_MAX: 'OPTIMIZATION_METRICS_MAX',

	/**
	 * Whether or not report generation is allowed
	 */
	OPTIMIZATION_REPORTS: 'OPTIMIZATION_REPORTS',

	// User Permissions
	
	/** 
	 * Determines if a user can create a site or not
	 */
	CREATE_SITE: 'CREATE_SITE'
			
}

//

export default Permissions;