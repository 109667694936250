/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';

import OpenModalCommand from '../commands/OpenModalCommand';

import PredictiveAttributeIcon from '../components/PredictiveAttributeIcon';
import NewPredictiveAttributeButton from '../components/NewPredictiveAttributeButton';
import Hint from '../components/controls/Hint';
import PredictiveAttributeModal from './modals/PredictiveAttributeModal'
import PredictiveAttribute from '../../model/PredictiveAttribute.mjs';

//

/**
 * Manages adding and removing of PredictiveAttributes to and from a Channel
 */
class PredictiveAttributesEditor extends Component {

	/**
	 * Constructs the Component
	 */
	constructor( props ) {
		super( props );
		this.state = {
			predictiveAttributes: props.predictiveAttributes
		};
	}

	/**
	 * Handles the update to props
	 */
	componentWillReceiveProps( nextProps ) {
		if( nextProps.predictiveAttributes !== this.props.predictiveAttributes ) {
			this.setState({ predictiveAttributes: nextProps.predictiveAttributes })
		}
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {

		// TODO: move to resources file
		var predictiveToolTip = 'Predictive Attributes define input that is used to make predictions about the best content to display. For example, the user\'s location.'

		var predictiveAttributeElements = [];
		if( this.state.predictiveAttributes != null ){
			for( var i = 0; i < this.state.predictiveAttributes.length; i++ ){
				var attribute = this.state.predictiveAttributes[ i ];
				var className = 'grid-cell predictive-attribute-container pad-cell-right pad-cell-bottom';
				//if( i > 0 ) className += '';
				var element = <div key={'predictive-attribute-' + i}  className={className}>
					<PredictiveAttributeIcon 
						attribute={attribute} 
						editHandler={addEditPredictiveAttribute.bind( this )} 
						removeHandler={removePredictiveAttribute.bind( this )}/>
				</div>;
				predictiveAttributeElements.push( element );
			}
		}

		var disabled = ( this.props.disabled ) ? 'disabled' : '';

		return ( 
			<div className={'panel-cell pad-cell-top predictive-attributes ' + disabled}>
				<div className='grid'>
					<div className='grid-cell default-60'>
						<h3>Predictive Attributes <Hint width='250px' content={predictiveToolTip}/></h3>
					</div>
				</div>
				<div className='grid predictive-attributes-grid'>
					{predictiveAttributeElements}
					<div className='grid-cell predictive-attribute-container pad-cell-right' onClick={addEditPredictiveAttribute.bind( this, null )}><NewPredictiveAttributeButton/></div>
				</div>
			</div> 
		);
	}

}

//

/**
 * Opens the Predictive Attribute editor modal
 */
function addEditPredictiveAttribute( attribute ) {
	if( !this.props.disabled ){
		var attributeIndex = this.state.predictiveAttributes.indexOf( attribute );
		if( attribute == null ) attribute = new PredictiveAttribute();
		var content = <PredictiveAttributeModal attribute={attribute} saveHandler={handleSaveEdit.bind( this, attributeIndex )}/>;
		var openModal = new OpenModalCommand( 'Predictive Attribute', content, '500px', false );
		openModal.execute();
	}
};

/**
 * Removes a predictive attribute
 */
function removePredictiveAttribute( attribute ) {
	if( !this.props.disabled ){
		var predictiveAttributes = this.state.predictiveAttributes;
		predictiveAttributes.splice( predictiveAttributes.indexOf( attribute ), 1 );
		this.setState({ predictiveAttributes: predictiveAttributes });
		this.props.onChange();
	}
};

/**
 * Closes the Predictive Attribute editor modal
 */
function handleSaveEdit( attributeIndex, attribute ) {
	console.info( 'handleSaveEdit', attribute );
	if( !this.props.disabled ){
		if( attributeIndex !== -1 ){
			this.state.predictiveAttributes[ attributeIndex ] = attribute;
		} else {
			this.state.predictiveAttributes.push( attribute );
		}
		this.setState({ predictiveAttributes: this.state.predictiveAttributes });
		this.props.onChange();
	}
}

//

export default PredictiveAttributesEditor;